import styled from 'styled-components'
import { COLORS } from '../../../theme/Colors'

export interface childProps {
  children: React.ReactElement
}

const StyledArticle = styled.div<childProps>`
  /* display: flex; */
  /* flex-direction: column; */
  padding: 0rem;
  justify-content: flex-start;
  /* flex: 0 1 65.3%%; */
  /* min-height: 100%; */
  width: 100%;

  // color: white;
  /* flex: 0 1 65.3%; */
  background-color: ${COLORS.Background.NeutralBackgroundWeak};
`

export default StyledArticle

export const FlexItem = styled.div<childProps>`
  display: flex;
  width: 65.33%;
  justify-content: center;
  padding-left: 2rem;
`
