import { ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../shared/styles";

export interface LabelProps {
  fontFamily?: string;
  fontSize?: string;
  fontStyle?: string;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  children: ReactNode
  label?: string;
  padding?: string;
  bgColor?: string;
}

export const StyledLabel = styled.p<LabelProps>`
  font-family: ${(props) => props.fontFamily};
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
`;

export const StyledLabelSectionHeader = styled.p<LabelProps>`
  font-family: SourceSansPro-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 3rem;
  color: ${colors.Neutrals90};
`;

export const StyledLabelInputField = styled.p<LabelProps>`
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 2rem;
  color: ${colors.Neutrals90};
`;

export const StyledLabelSidebarMenu = styled.p<LabelProps>`
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${colors.Neutrals90};

  &:active{
    color: ${colors.NeutralsWhite};
    font-weight: 700;
  }
`;

export const StyledSubHeading = styled.p<LabelProps>`
  font-family: SourceSansPro-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${colors.Neutrals90};
`;