import styled from 'styled-components'
import { colors } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'

interface AddressDetailsUIProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  color: black;
  width: 100%;
  background-color: ${colors.BackgroundWeak};
  padding: 8px 0px;
  gap: 1.5rem;
  z-index: 1;
  margin-left: 49px;
  height: 72px;
  align-items: flex-start;
  justify-content: flex-start;
  p {
    z-index: 1;
  }
`

export const FlexItem1 = styled.div`
  display: flex;
  background: ${colors.BackgroundWeak};
  position: fixed;
  width: 100%;
  height: 880px;
  /* z-index: 1; */
  top: 0;
  right: 0;
  justify-content: flex-start;
  color: red;
`

export const FlexItem2 = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  position: relative;
  justify-content: flex-start;
`

export const FlexItem3 = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-start;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 144px;
  max-height: 32px;
  z-index: 2;
  // margin-top:2.5rem;
  // position:absolute;

  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0;
`

export const onWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 144px;
  max-height: 32px;
  z-index: 2;

  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  opacity: 0;
`
export const ApprovedWrapper = styled.div`
  position: absolute;
  margin-top:-6px;
  right:3.5%;
  /* justify-content:flex-end; */
  width: 280px;
  height: 90px;
  background-color: #ebf7e6;
  align-items: flex-end;
  border: 1px solid #41b658;
  border-radius: 4px;
  padding-left: 16px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  /* display: flex; */
`
export const PhoneTypeWrapper = styled.div`
  position: absolute;
  /* justify-content:flex-end; */
  right: 30%;
  margin-top: 4px;
  width: 160px;
  height: 50px;
  font-weight: 500;
  background-color: white;
  align-items: flex-end;
  border: 1px solid red;
  border-radius: 4px;
  padding-left: 16px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  /* display: flex; */
`
export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 12px;
  margin: 6px 0px;
`
export const ChangeTextWrapper = styled.div`
 margin-left:-16px;
`
export const CancelWrapper = styled.div`
background: ${COLORS.Background.Secondary};
border-radius:5px;
width: 75%;
padding: 6px;
margin-left:-24px;
display: flex;
align-self: stretch;
align-items: center;
justify-content: space-between;
margin-top: -13px;
border: 2px ;
`

export const CancelWrapperLeft = styled.div`
  display: flex;
  flex: 0 1 100%;
  gap: 8px;
  align-items: center;
`
export const CancelWrapperRight = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 8px;
  align-items: center;
`
export const PopUpTextWrapper = styled.div`
 margin-top:4px;
  cursor:pointer;
`
export const CancelPhoneWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 22px;
`
export const InputFieldWrapper=styled.div`
  display:flex;
  flex: 0 1 10%;
  justify-content:flex-end;

`

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  /* padding-top: 32px;
padding-bottom: 32px;
gap: 1 rem; */
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: AddressDetailsUIProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`
