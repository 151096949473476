import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface ClientProfileProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 600px !important;
  position: absolute;
  flex: 0 1 100%;
  width: 62.77%;
  /* padding: 8px 24px; */
  margin-bottom: 16px;
  margin-left: 24px;
  margin-top: 16px;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  box-shadow: ${(ContactUIProps: ClientProfileProps) =>
    ContactUIProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: scroll;
  }
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  align-items: end;
  // width: 100%;
  /* max-height: 5.25rem; */
  gap: 16px;
  // margin: 8px 0px;
  padding: 16px;
    button {
    font-family: SourceSansPro-SemiBold;
    padding: 4px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
`
export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const PreviousButtonWrapper = styled.div`
  display: flex;
  // flex-direction: row;
  max-height: 2.5rem;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const StyledBoldLabel = styled.b`
  font-family: SourceSansPro-Bold;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #0f172a;
`
export const TopRow = styled.div`
  display: flex;
  flex: 0 1 100%;
  z-index: 1;
  position: relative;
  top: -2.75rem;
  justify-content: space-around;
  align-self: stretch;
  width: 100%;
  max-height: 1.25rem;
  gap: 16px;
  margin: 16px 0px;
`
export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 24px;
  gap: 16px;
  padding: 32px 16px 16px;
`

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #0f172a;
  }
  b {
    font-family: SourceSansPro-SemiBold;
  }
`

export const Row = styled.div`
  /* display: flex; */
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  width: 100%;
  max-height: 6.5rem;
  margin: 8px 0px;
  padding: 0px 16px;
  :first-child{
    color: "#475569" !important;
    line-height: 16px !important;
  }
  &#textarea {
    position: relative;
  }
`

export const BoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  /* max-height: 6.5rem; */
  margin: 8px 0px;
  padding: 0px 16px;
`

export const TopRowLabelWrap = styled.div`
  background-color: #ffffff;
  padding: 0px 8px;
  display: flex;
  z-index: 1;
  gap: 8px;
  align-items: center;
`
export const LabelWrapper = styled.div`
  align-self: stretch;
`
export const CloseWrapper = styled.div`
  cursor: pointer;
  align-self: center;
`
export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px;
`

export const ModalWrapper = styled.div`
  position: fixed;
  /* overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 1;
  // overflow-y: scroll;
  display: flex;
  height:100%;
  position: fixed;
  //padding-top: 24px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* padding-bottom: 30px; */
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 116, 139, 0.8);
  width: 100%;
  // margin: 0 -5%;
`

export const DropdownWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2rem !important;
  align-items: stretch;
  div {
    div {
      height: 2rem !important;
    }
  }
`

export const StyledTableContainer = styled.div`
  width: 100%;
  /* padding: 24px; */
  padding: 0px 16px;
  background: '#FFFFFF';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 8px 0px;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #0f172a;
        }
      }
    }
  }

  thead {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0f172a;
    tr {
      th {
        padding: 4px 0px 4px 8px;
        font-family: SourceSansPro-Bold !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #0f172a;
      }
    }
  }
`
export const CompactWrapper = styled.div`
  flex: 0 1 50%;
  max-width: 80px;
  div {
    :nth-child(2) {
      div {
        height: 32px;
      }
    }
  }
`

export const CountWrapper = styled.div`
  position: relative;
  /* top: 595px; */
  /* right: 25px; */
`