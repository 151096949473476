export interface Household {
    accountNumber?: number
    accountTitle?: string
    accountNickName?: string
    accountType?: string
    phone?: string
    email?: string
    accountStatus?: string
    closingDate?: string | null
    accountValue?: string
}

export const defaultHousehold : Household = {
    accountNumber: 1,
    accountTitle: "",
    accountNickName: "",
    accountType: "",
    phone: "",
    email: "",
    accountStatus: "Open",
    closingDate: null,
    accountValue: ""
}