import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import {
    MasterWrapper,
    HeaderRow,
    IconWrapper,
    DividerWrapper,
    StyledTableContainer,
    TitleIconWrap,
    TopRow,
    Row,
} from './styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Table from '../../components/table/Table'
import { COLORS } from '../../theme/Colors'
import { WDStyledSectionTitle } from '../../components/ui/WDTypography'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'
import { SvgAuditTrail } from '../../components/svg/SvgAuditTrail'
import { WDStyledCancelButton } from '../../components/ui/WDButtons'
import GroupingTable from '../../components/groupingTable/GroupingTable'
import { AccountsDetails } from './tableData'
import {
    AuditTrail,
    defaultAuditTrail,
} from '../../services/auditTrailContext/AuditTrailInterface'
import { LoaderRow } from './styles'
import Loader from '../../components/loader/Loader'
import { Tag } from '../Tag/Tag'
import { ErrorModal } from '../errorModal/ErrorModal'
import { AccountRestrictionsAuditTrailInterface } from '../../services/accountRestrictionsContext/AccountRestrictionsInterface'
import {AccountRestrictionsContext} from '../../services/accountRestrictionsContext/AccountRestrictionsContext '


export const AccountRestrictionsAuditTrailUI = () => {
    const auditTrailValues = useContext(AuditTrailContext)
    const { accountRestrictionsAuditTrail } = useContext(AccountRestrictionsContext)
    const auditTrail = auditTrailValues?.auditTrail//
    const err = auditTrailValues?.errorMsg

    const tableAllData = accountRestrictionsAuditTrail//AccountsDetails


    let Bg = ''
    let Font = ''

    function convertResponse(apiResponse: AccountRestrictionsAuditTrailInterface[] | undefined) {
        if (apiResponse !== undefined) {
            const convertedResponse = []
            const resultData: any[] = []
            let id = 1
            for (let i = 0; i < apiResponse.length; i++) {
                //resultData.splice(0)
                if (
                    apiResponse[i]?.restrictionStatus?.toLowerCase() === 'disabled'
                ) {
                    Font = '#C2410C'
                    Bg = ' #FFF7ED'
                } else if (apiResponse[i]?.restrictionStatus?.toLowerCase() === 'enabled') {
                    Font = '#2E844A'
                    Bg = '#EBF7E6;'
                } else if (apiResponse[i]?.restrictionStatus?.toLowerCase() === 'updated') {
                    Font = '#2563EB'
                    Bg = '#EFF6FF;'
                }
                //console.log('Going through loop!',apiResponse[i]) 
                const mainData = {
                    //ID: id,//apiResponse[i].id,
                    UpdatedBy: apiResponse[i].updateBy,
                    UpdatedDate: apiResponse[i].updateDate,
                    Category: apiResponse[i].category,
                    StartDate: apiResponse[i].startDate?.split(' ')[0],
                    EndDate: apiResponse[i].endDate?.split(' ')[0],
                    Notes: apiResponse[i].note,
                    Status: (
                        <Tag
                            ClientType={apiResponse[i]?.restrictionStatus}
                            FontColor={Font}
                            BgColor={Bg}
                        />
                    ),
                    status: apiResponse[i]?.status,
                    id: id,
                    
                }
                //const subData = apiResponse[i].categoryList
                
                //const subData  = {
                //    /*id: id,*/
                //    Notes: apiResponse[i].note
                //}
                //resultData.push(subData)

               

                convertedResponse.push({
                    mainData: { ...mainData },
                    subData: [...resultData],
                })
                id++
            }
            return convertedResponse
        }
    }

    const convertedResponse = convertResponse(tableAllData)
    const convertedResponseMainData = convertedResponse?.map(
        (obj) => obj.mainData
    )


    const [isCollapse, setIsCollapse] = useState(true)

    const columnData = [
        //{
        //    Header: 'ID',
        //    accessor: 'ID',
        //},
        {
            Header: 'Requested By',
            accessor: 'UpdatedBy',
        },
        {
            Header: 'Status Updated On',
            accessor: 'UpdatedDate',
        },
        {
            Header: 'Category',
            accessor: 'Category',
        },
        {
            Header: 'Start Date',
            accessor: 'StartDate',
        },
        {
            Header: 'End Date',
            accessor: 'EndDate',
        },
        {
            Header: 'Status',
            accessor: 'Status',
        },

        // {
        //   Header: 'Field',
        //   accessor: 'Field',
        // },
        // {
        //   Header: 'Old Value',
        //   accessor: 'OldValue',
        // },
        // {
        //   Header: 'New Value',
        //   accessor: 'NewValue',
        // },
    ]

    const subTableColumns = ['']//['Notes']//['Field', 'Old Value', 'New Value']

    const cardSummary = [
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Field: 'Phone number',
            OldValue: '123456546',
            NewValue: '234567357568',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Field: 'Email',
            OldValue: 'abc@abc.com',
            NewValue: 'abc@ab.com',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Field: 'Phone number',
            OldValue: '234567357568',
            NewValue: '23456746343',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Field: 'Zip code',
            OldValue: '12345',
            NewValue: '23456',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '10/10/21',
            Field: 'Email',
            OldValue: 'xyz@abc.com',
            NewValue: 'abc@ab.com',
        },
        {
            UpdatedBy: 'Harry',
            UpdatedDate: '10/10/21',
            Field: 'Phone number',
            OldValue: '123456546',
            NewValue: '234567357568',
        },
        {
            UpdatedBy: 'Harry',
            UpdatedDate: '10/10/21',
            Field: 'Phone number',
            OldValue: '1234',
            NewValue: '234567357568',
        },
        {
            UpdatedBy: 'Harry',
            UpdatedDate: '10/10/21',
            Field: 'Email',
            OldValue: 'pr@abc.com',
            NewValue: 'abc@xy.com',
        },
        {
            UpdatedBy: 'Harry',
            UpdatedDate: '10/10/21',
            Field: 'Zip code',
            OldValue: '12345',
            NewValue: '23456',
        },
        {
            UpdatedBy: 'Henry',
            UpdatedDate: '11/11/21',
            Field: 'Phone number',
            OldValue: '123456546',
            NewValue: '234567357568',
        },
        {
            UpdatedBy: 'Henry',
            UpdatedDate: '11/11/21',
            Field: 'Phone number',
            OldValue: '12323256',
            NewValue: '2345668',
        },
        {
            UpdatedBy: 'Henry',
            UpdatedDate: '11/11/21',
            Field: 'Phone number',
            OldValue: '456546',
            NewValue: '67357568',
        },
        {
            UpdatedBy: 'Henry',
            UpdatedDate: '11/11/21',
            Field: 'Email',
            OldValue: 'xyx@iol.com',
            NewValue: 'abc@ab.com',
        },
        {
            UpdatedBy: 'Henry',
            UpdatedDate: '11/11/21',
            Field: 'Zip code',
            OldValue: '12345',
            NewValue: '67357',
        },
    ]

    const tableData = [
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Category: 'Phone',
        },

        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Category: 'Email',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Category: 'Phone',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '04/01/22',
            Category: 'Address',
        },
        {
            UpdatedBy: 'Luis',
            UpdatedDate: '10/10/21',
            Category: 'Email',
        },
    ]

    const subTableData = [
        {
            Field: 'Phone number',
            OldValue: '123456546',
            NewValue: '234567357568',
        },
        {
            Field: 'Email',
            OldValue: 'abc@abc.com',
            NewValue: 'abc@ab.com',
        },
        {
            Field: 'Phone number',
            OldValue: '234567357568',
            NewValue: '23456746343',
        },
        {
            Field: 'Zip code',
            OldValue: '12345',
            NewValue: '23456',
        },
        {
            Field: 'Email',
            OldValue: 'xyz@abc.com',
            NewValue: 'abc@ab.com',
        },
    ]

    // interface AuditTrail {
    //   UpdatedBy?: string
    //   UpdatedDate?: string
    //   Field?: string
    //   OldValue?: string
    //   NewValue?: string
    // }

     const [visibleData, setVisibleData] = useState<any>()
     const [remainingData, setRemainingData] = useState<any>()

     useEffect(() => {
       const initial = cardSummary?.slice(0, 5)
       const remaining = cardSummary?.slice(5)
       setVisibleData(initial)
       setRemainingData(remaining)
     }, [])

    // const loadMore = () => {
    //   if (visibleData !== undefined && remainingData !== undefined) {
    //     const newData = [...visibleData, ...remainingData]
    //     setVisibleData(newData)
    //     setRemainingData([])
    //   }
    // }

    const collapse = () => {
        auditTrailValues?.setAuditTrailCollapse(
            !auditTrailValues?.auditTrailCollapse
        )
    }

    // if (auditTrailValues?.isLoading) {
    //   return (
    //     <MasterWrapper>
    //       <TopRow>
    //         <HeaderRow>
    //           <TitleIconWrap>
    //             <IconWrapper>
    //               <SvgAuditTrail />
    //             </IconWrapper>
    //             <WDStyledSectionTitle>{'Audit Trail'}</WDStyledSectionTitle>
    //           </TitleIconWrap>
    //         </HeaderRow>
    //       </TopRow>
    //       {
    //         <DividerWrapper>
    //           <Divider
    //             bgColor={COLORS.Background.Border}
    //             horizontal={true}
    //             thickness="1px"
    //           />
    //         </DividerWrapper>
    //       }
    //       <LoaderRow>
    //         <Loader />
    //       </LoaderRow>
    //     </MasterWrapper>
    //   )
    // }
    return (
        <MasterWrapper
            id="audit-trail"
            borderShadow={auditTrailValues?.auditTrailCollapse ? true : false}
        >
            <TopRow>
                <HeaderRow onClick={() => collapse()}>
                    <TitleIconWrap>
                        <IconWrapper>
                            {/* <Icon icon={Icons.Relationship} /> */}
                            <SvgAuditTrail />
                        </IconWrapper>
                        <WDStyledSectionTitle>{'Audit Trail'}</WDStyledSectionTitle>
                    </TitleIconWrap>
                </HeaderRow>
                <IconWrapper onClick={() => collapse()}>
                    {auditTrailValues?.auditTrailCollapse && <SvgChevronUp />}
                    {!auditTrailValues?.auditTrailCollapse && <SvgChevronDown />}
                </IconWrapper>
            </TopRow>
            {auditTrailValues?.auditTrailCollapse && (
                <DividerWrapper>
                    <Divider
                        bgColor={COLORS.Background.Border}
                        horizontal={true}
                        thickness="1px"
                    />
                </DividerWrapper>
            )}

            {auditTrailValues?.auditTrailCollapse && (
                <React.Fragment>
                    <StyledTableContainer>
                        {/* <Table
              tableColumns={columnData}
              tableData={
                // auditTrailData === undefined ? cardSummary : auditTrailData
                visibleData} onClick={() =>''}            /> */}
                        <GroupingTable
                            tableColumns={columnData}
                            tableData={
                                convertedResponseMainData ? convertedResponseMainData : []
                            }
                            subTableHead={subTableColumns}
                            subTableData={convertedResponse}
                            totalDataAvailable={
                                convertedResponseMainData ? convertedResponseMainData.length : 0
                            }
                            auditTrail={false}
                        />
                    </StyledTableContainer>
                    {/* <Row>
            {remainingData.length > 0 && (
              <WDStyledCancelButton onClick={() => loadMore()}>
                Load more
              </WDStyledCancelButton>
            )}
          </Row> */}
                </React.Fragment>
            )}
            {err === undefined || err === 100 ? null : err === 200 ? (
                <ErrorModal
                    success={200}
                    onClick={() => {
                        auditTrailValues?.setErrorMsg(100)
                        window.location.reload()
                    }}
                    successMsg={
                        'This request has been cancelled successfully.'
                    }
                />
            ) : (
                <ErrorModal
                    onClick={() => {
                        auditTrailValues?.setErrorMsg(100)
                        window.location.reload()
                    }}
                    success={500}
                />
            )}
        </MasterWrapper>
    )
}
