import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface AddressProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  /* padding-top: 32px;
padding-bottom: 32px;
gap: 1 rem; */
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: AddressProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`
export const FlexItem = styled.div`
  flex: 0 1 50%;
  width: 50%;
`
export const FlexItem2 = styled.div`
  flex: 0 1 33.33%;
  width: 33.33%;
`
export const EditWrapper = styled.div`
  padding-left:7px
`

export const FlexItem1 = styled.div`
  flex: 0 1 100%;
  width: 33.33%;
`
export const SectionWrapper = styled.div``
export const SectionWrapper2 = styled.div`
  padding-top: 16px;
`
export const AddSection = styled.div`
  margin-top: 8px;
`
export const LegalTab = styled.div``

export const InlineErrorWrapper = styled.div`
  color: #ba0517;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
`

export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  cursor: pointer;
`

export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  // max-height: 84px;
  gap: 24px;
  margin: 8px 0px;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
`
export const ButtonRow1 = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
`
export const AccountDetails = styled.div``

export const Wrapper = styled.div`
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0f172a;
`
export const LabelWrap = styled.div`
  padding: 4px 0px;
`
export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const RemoveButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  max-height: 32px;
  // margin-top:40px;
  // position:absolute;
  right: 363.2px;

  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper1 = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const TopRow = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  cursor: pointer;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* margin-bottom: 16px; */
`

export const DividerWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px 0px 0px;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
`

export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`
