import axios from 'axios'
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useLayoutEffect,
} from 'react'
import { useLocation } from 'react-router-dom'

interface SetAuditTrailCollapse {
  (value: boolean): void | boolean
}

interface AccountMaintenanceGetContextInterface {
  clientId: number | null | undefined
  accountNumber: string | null | undefined
}

export const AccountMaintenanceGetContext = createContext<
  AccountMaintenanceGetContextInterface | undefined | null
>(undefined)

interface AccountMaintenanceGetCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const AccountMaintenanceGetContextProvider = ({
  children,
}: AccountMaintenanceGetCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [clientId, setClientId] = useState<number | null>()
  const [accountNumber, setAccountNumber] = useState<string | null>('')
  const [error, setError] = useState<unknown | null | object>(null)

  const GET_CTX_URL = 'https://dev.api.wedbush.com/clientlinkapi/GetContext'
  const location: any = useLocation()

  useLayoutEffect(() => {
    async function retrieveAccountMaintenanceGetContext() {
      try {
        const contextId = localStorage.getItem('key')
        // localStorage.setItem('key', '')
        // console.log('stateID.....', contextId)
        // const search = location
        const cid = new URLSearchParams(location).get('state')
        // console.log('CID...' + cid)
        if(contextId !== undefined && contextId !== null){
          const response = await axios.post(GET_CTX_URL, { key: contextId })
        const parsedResponse = JSON.parse(response.data)
        setClientId(parseInt(parsedResponse.clientID))
        setAccountNumber(parsedResponse.accountNumber)
        // console.log('Response .... ')
        // console.log(response.data)
        } 
      } catch (error: unknown) {
        // console.log(error)
        setError(error)
      }
    }
    retrieveAccountMaintenanceGetContext()
  }, [])

  return (
    <AccountMaintenanceGetContext.Provider
      value={{
        clientId,
        accountNumber,
      }}
    >
      {children}
    </AccountMaintenanceGetContext.Provider>
  )
}
