import { useContext } from 'react'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { MailingAddressContext } from '../../services/mailingAddressContext/MailingAddressContext'
import { Backgrounds } from '../../shared/styles'
import { COLORS } from '../../theme/Colors'
import Label from '../label/Label'
import { SidebarMenuProps } from './ISidebarMenu'
import { LabelWrapper, SidebarMenuWrapper } from './styles'

/* This is a basic component, present in the Sidebar Menu. It just consists of one label, which is being re-used. */

export const SidebarMenu = ({ name, itemTitle }: SidebarMenuProps) => {

  return (
    <SidebarMenuWrapper
      bgColor={
        name === itemTitle
          ? `${COLORS.Background.NeutralBackgroundMedium}`
          : `${COLORS.Background.Primary}`
      }
      hoverBgColor={name === itemTitle ? '' : Backgrounds.Gray10}
    >
      <LabelWrapper>
        <Label
          fontFamily={
            name === itemTitle ? 'SourceSansPro-Bold' : 'SourceSansPro-Regular'
          }
          fontSize={'16px'}
          fontWeight={name === itemTitle ? 700 : 400}
          fontStyle="normal"
          lineHeight="32px"
          color={
            name === itemTitle
              ? `${COLORS.UI.BackgroundStrong}`
              : `${COLORS.UI.Text}`
          }
        >
          {name}
        </Label>
      </LabelWrapper>
    </SidebarMenuWrapper>
  )
}

/* This component can be re-used for creating a group of tabs/menu in the side bar. */
