import Label from '../../components/label/Label'
import { SvgExclamationTooltip } from '../../components/svg/SvgExclamationTooltip'
import { CombinedLabelWrapper, Date, ImageWrapper, TooltipWrapper, Wrapper } from './styles'

export const Tooltip = ({date}: Date) => {

  if (date === undefined || date === null){
    return null
  }
  return (
    <Wrapper id='tooltip'>
      <TooltipWrapper>
        <ImageWrapper>
          <SvgExclamationTooltip fillColor={'#475569'} />
        </ImageWrapper>
        <CombinedLabelWrapper>
        <Label>Account closed</Label>
        <Label>Closed date : {date}</Label>
        </CombinedLabelWrapper>
      </TooltipWrapper>
    </Wrapper>
  )
}
