import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import {
  MasterWrapper,
  HeaderRow,
  IconWrapper,
  DividerWrapper,
  StyledTableContainer,
  TitleIconWrap,
  TopRow,
  Row,
} from './styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Table from '../../components/table/Table'
import { COLORS } from '../../theme/Colors'
import {
  WDInputLabel,
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'
import { SvgAuditTrail } from '../../components/svg/SvgAuditTrail'
import { WDStyledCancelButton } from '../../components/ui/WDButtons'
import GroupingTable from '../../components/groupingTable/GroupingTable'
import { AccountsDetails } from './tableData'
import {
  AuditTrail,
  defaultAuditTrail,
} from '../../services/auditTrailContext/AuditTrailInterface'
import { LoaderRow } from './styles'
import Loader from '../../components/loader/Loader'
import { Tag } from '../Tag/Tag'
import { ErrorModal } from '../errorModal/ErrorModal'
import { StyledHeadWrapper } from '../addressUI/styles'
import { messages } from '../../shared/constants'

export const AuditTrailUI = () => {
  const auditTrailValues = useContext(AuditTrailContext)
  const auditTrail = auditTrailValues?.auditTrail
  const err = auditTrailValues?.errorMsg


  const reloadAuditTrail = () =>{
    auditTrailValues?.retrieveAuditTrail()
    setTimeout(()=>{
      auditTrailValues?.setIsLoading(true)
    },200)
    auditTrailValues?.setIsLoading(false)
  }

  const tableAllData = AccountsDetails

  let Bg = ''
  let Font = ''

  function convertResponse(apiResponse: AuditTrail[] | undefined) {
    if (apiResponse !== undefined) {
      const convertedResponse = []
      let id = 1
      for (let i = 0; i < apiResponse.length; i++) {
        if (
          apiResponse[i]?.status === 'Pending' ||
          apiResponse[i]?.status === 'Partially-Approved'
        ) {
          Font = '#C2410C'
          Bg = ' #FFF7ED'
        } else if (apiResponse[i]?.status === 'Approved') {
          Font = '#2E844A'
          Bg = '#EBF7E6;'
        } else if (
          apiResponse[i]?.status === 'Submitted' ||
          apiResponse[i]?.status === 'Re-Submitted'
        ) {
          Font = '#2563EB'
          Bg = '#DBEAFE'
        } else if (apiResponse[i]?.status === 'Rejected') {
          Font = '#BA0517'
          Bg = '#FEF1EE'
        } else if (apiResponse[i]?.status === 'Cancelled') {
          Font = '#734f96'
          Bg = '#E6E6FA'
        } else if (apiResponse[i]?.status === 'Removed') {
          Font = '#005b71'
          Bg = '#84E4F7'
        }
        const mainData = {
          ID: apiResponse[i].approvalRequestId,
          UpdatedBy: apiResponse[i].updatedBy,
          UpdatedDate: apiResponse[i].update_Date,
          Category: apiResponse[i].category,
          Association: apiResponse[i].association,
          Status: (
            <Tag
              ClientType={apiResponse[i]?.status}
              FontColor={Font}
              BgColor={Bg}
            />
          ),
          status: apiResponse[i]?.status,
          accountNumber: apiResponse[i]?.accountNumber,
          accountName: apiResponse[i]?.accountName,
          Notes: apiResponse[i].notes,
          id: apiResponse[i].id,
          PrincipalDeskReviewer: apiResponse[i].approver,
        }
        const subData = apiResponse[i].categoryList
        const resultData: any[] = []
        subData.map((element: any, index: number) => {
          if (apiResponse[i].categoryList !== undefined) {
            const item: any = apiResponse[i]?.categoryList[index]
            if (subData.length > 0) {
              if (
                item?.field === 'Business Phone' ||
                item?.field === 'Phone Number' ||
                item?.field === 'Home Phone' ||
                item?.field === 'Mobile Phone'
              ) {
                const valueOld = item?.oldValue?.replaceAll(' ', '')
                const valueNew = item?.newValue?.replaceAll(' ', '')
                resultData.push({
                  field: item?.field,
                  oldValue:
                    valueOld.length === 0
                      ? '--'
                      : `(${valueOld?.substring(0, 3)})` +
                        ' ' +
                        valueOld?.substring(3, 6) +
                        '-' +
                        valueOld?.substring(6, valueOld?.length),
                  newValue:
                    valueNew.length === 0
                      ? '--'
                      : `(${valueNew?.substring(0, 3)})` +
                        ' ' +
                        valueNew?.substring(3, 6) +
                        '-' +
                        valueNew?.substring(6, valueNew?.length),
                })
              } else {
                resultData.push({
                  field: item?.field,
                  oldValue: item?.oldValue ? item?.oldValue : '--',
                  newValue: item?.newValue ? item?.newValue : '--',
                })
              }
            }
          }
        })

        convertedResponse.push({
          mainData: { ...mainData },
          subData: [...resultData],
        })
        id++
      }
      return convertedResponse
    }
  }

  const convertedResponse = convertResponse(auditTrail)
  const convertedResponseMainData = convertedResponse?.map(
    (obj) => obj.mainData
  )

  const [isCollapse, setIsCollapse] = useState(true)

  const columnData = [
    {
      Header: 'ID',
      accessor: 'ID',
    },
    {
      Header: 'Requested By',
      accessor: 'UpdatedBy',
    },
    {
      Header: 'Updated Date',
      accessor: 'UpdatedDate',
    },
    {
      Header: 'Category',
      accessor: 'Category',
    },
    {
      Header: 'Association',
      accessor: 'Association',
    },
    {
      Header: 'Principal Desk Reviewer',
      accessor: 'PrincipalDeskReviewer',
    },
    {
      Header: 'Status',
      accessor: 'Status',
    },

    // {
    //   Header: 'Field',
    //   accessor: 'Field',
    // },
    // {
    //   Header: 'Old Value',
    //   accessor: 'OldValue',
    // },
    // {
    //   Header: 'New Value',
    //   accessor: 'NewValue',
    // },
  ]

  const subTableColumns = ['Field', 'Old Value', 'New Value']

  const collapse = () => {
    auditTrailValues?.setAuditTrailCollapse(
      !auditTrailValues?.auditTrailCollapse
    )
  }

  if (auditTrailValues?.isLoading) {
    return (
      <MasterWrapper>
        <TopRow>
          <HeaderRow>
            <TitleIconWrap>
              <IconWrapper>
                <SvgAuditTrail />
              </IconWrapper>
              <WDStyledSectionTitle>{'Audit Trail'}</WDStyledSectionTitle>
            </TitleIconWrap>
          </HeaderRow>
        </TopRow>
        {
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
        }
        <LoaderRow>
          <Loader />
        </LoaderRow>
      </MasterWrapper>
    )
  }
  return (
    <MasterWrapper
      id="audit-trail"
      borderShadow={auditTrailValues?.auditTrailCollapse ? true : false}
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              {/* <Icon icon={Icons.Relationship} /> */}
              <SvgAuditTrail />
            </IconWrapper>
            <WDStyledSectionTitle>{'Audit Trail'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <IconWrapper onClick={() => collapse()}>
          {auditTrailValues?.auditTrailCollapse && <SvgChevronUp />}
          {!auditTrailValues?.auditTrailCollapse && <SvgChevronDown />}
        </IconWrapper>
      </TopRow>
      {auditTrailValues?.auditTrailCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

      {(auditTrailValues?.auditTrail === undefined ||
        auditTrailValues?.auditTrail.length === 0) && (
        <>
          <React.Fragment>
            {auditTrailValues?.auditTrailCollapse && (
              <StyledHeadWrapper>
                <WDInputLabel>
                  {messages.audit_trail.no_audit_trail}
                </WDInputLabel>
              </StyledHeadWrapper>
            )}
          </React.Fragment>
        </>
      )}

      {auditTrailValues?.auditTrail !== undefined &&
        auditTrailValues?.auditTrail.length > 0 &&
        auditTrailValues?.auditTrailCollapse && (
          <React.Fragment>
            <StyledTableContainer>
              <GroupingTable
                tableColumns={columnData}
                tableData={
                  convertedResponseMainData ? convertedResponseMainData : []
                }
                subTableHead={subTableColumns}
                subTableData={convertedResponse}
                totalDataAvailable={
                  convertedResponseMainData
                    ? convertedResponseMainData.length
                    : 0
                }
                auditTrail={false}
              />
            </StyledTableContainer>
          </React.Fragment>
        )}
      {err === undefined || err === 100 ? null : err === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            auditTrailValues?.setErrorMsg(100)
            reloadAuditTrail()
          }}
          successMsg={'This request has been cancelled successfully.'}
        />
      ) : (
        <ErrorModal
          onClick={() => {
            auditTrailValues?.setErrorMsg(100)
          }}
          success={500}
        />
      )}
    </MasterWrapper>
  )
}
