import styled from 'styled-components'

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 50px;
  height: 24px;

  &.disabled{
    opacity: 0.5;
    cursor: no-drop;
  }

  input:checked + .slider {
    background-color: #2563eb;
    padding: 2px;
    z-index: auto;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`

export const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  -webkit-appearance: none;
  outline: none;
  &disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`

export const StyledSpan = styled.span`
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  &.slider {
    position: absolute;
    cursor: pointer;
    z-index: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e2e8f0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-appearance: none;
    outline: none;
    padding: 2px;
    

    &::before {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;

      /* neutral/background */

      background: #ffffff;
      /* Elevation/Elevation-low */

      box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
      border-radius: 100px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 100px;
    }
  }

  &.slider.round {
    box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
    border-radius: 100px;
  }

  .slider.round:before {
    box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
    border-radius: 100px;
  }
`
