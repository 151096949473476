import {
  createContext,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { AccountInfo } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import { protectedResources } from '../settings/authConfig'
import jwt from 'jwt-decode'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

interface User {
  adId: string
  name: string
}
// export interface userRoles {
//   OrgRoles: string[]
//   SegRoles: string[]
//   entityElement: string[]
// }

export interface AppContextInterface {
  isLoggedIn: boolean
  user: User
  userRoles: string | undefined
  idToken: string | null
  setLogin: (value: boolean, user: User) => void
  setUser: (user: User) => void
  setIdToken: (token: string) => void
  accessToken?: string
  b2cRefreshToken?: () => void
  account?: AccountInfo | null
  idTokenClaims?: SignInInterface
  isAuthenticated?: boolean
  client: any,
}
export interface SignInInterface {
  signinname: string
}
export interface IDTokenInterface {
  idTokenClaims: SignInInterface
}

export interface DecryptToken {
  Username: string
  aud: string
  azp: string
  email: string
  exp: number
  family_name: string
  given_name: string
  iat: string
  idp: string
  iss: string
  name: string
  nbf: number
  nonce: string
  roles: string
  scp: string
  sub: string
  tid: string
  upn: string
  ver: string
}
export interface userRoles {
  OrgRoles: string[]
  SegRoles: string[]
  entityElement: string[]
}

const getUser = () => {
  const itemStr = localStorage.getItem('user')
  let item: User = {
    adId: '',
    name: '',
  }
  if (!itemStr) {
    return {
      adId: '',
      name: '',
    }
  }
  try {
    item = JSON.parse(itemStr)
  } catch (e) {
    item = {
      adId: '',
      name: '',
    }
  }
  const user: User = {
    adId: '',
    name: '',
  }
  if (item) {
    user.adId = item?.adId
    user.name = item?.name
  }
  return user
}

const authStore: AppContextInterface = {
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true' ? true : false,
  user: getUser(),
  idToken: null,
  setLogin: (isLoggedIn: boolean, user: User) => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn))
    localStorage.setItem('user', JSON.stringify(user))
    authStore.isLoggedIn = isLoggedIn
    authStore.user = user
  },
  setUser: (user: User) => {
    localStorage.setItem('user', JSON.stringify(user))
    authStore.user = user
  },
  setIdToken: (token: string) => {
    // console.log(authStore.idToken)
    authStore.idToken = token
  },
  userRoles: undefined,
  accessToken: '',
  client: {},
}

// export const AuthContext = createContext({
//   isLoggedIn: false,
//   user: {
//     adId: '',
//     name: '',
//   },
//   idToken: '',
//   setLogin: Function(),
//   setUser: Function(),
//   setIdToken: Function(),
//   accessToken: '',
//   b2cRefreshToken: Function(),
//   account: AccountInfo,
//   isAuthenticated: false,
// })
export const AuthContext = createContext<
  AppContextInterface | null | undefined
>(undefined)

interface ContextProps {
  children?: ReactNode
}

export const AuthContextProvider = ({ children }: ContextProps) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accessToken, setAccessToken] = useState<string>('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [client, setClient] = useState<any>()

  const [user, setUser] = useState(authStore.user)
  const [isLoggedIn, setLogin] = useState(false)
  const [idToken, setIdToken] = useState('')
  const [userRoles, setUserRoles] = useState<string | undefined>('')

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))
    authStore.user = user
  }, [user])

  const authMiddleware = (authToken: any) =>
    new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      if (authToken) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      }

      return forward(operation)
    })

  const setValues = (token: string) => {
    const httpLink = new HttpLink({
      uri: 'https://dev.api.wedbush.com/hasura-graphql',
    })

    const authLink = setContext((_, { headers }) => {
      const headerValue = {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return headerValue
    })

    const client = new ApolloClient({
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: ApolloLink.from([authMiddleware(token), authLink.concat(httpLink)]),
    })

    setClient(client)
  }

  useEffect(() => {
    async function b2cAuth() {
      if (account && inProgress === 'none') {
        // console.log('AuthContextIf')
        // console.log('AuthContextIf', protectedResources.api.scopes)
        instance
          .acquireTokenSilent({
            scopes: protectedResources.api.scopes,
            account: account,
          })
          .then((response) => {
            // console.log('RESPONSE IN THE AUTHCONTEXT ~~~🚀🚀~~~', response)
            // console.log('AuthContext', response?.accessToken)
            setAccessToken(response?.accessToken)
            setValues(response?.accessToken)
            const tokenValues: DecryptToken = jwt(response?.accessToken)
            // console.log(tokenValues,"...")
            setUserRoles(JSON.parse(tokenValues.roles).Entitlements)
            setIsAuthenticated(true)
          })
      }
    }
    b2cAuth()
  }, [account, inProgress, instance])

  async function b2cRefreshToken() {
    if (account) {
      const newRequestToken = await instance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account,
        forceRefresh: true,
      })
      setAccessToken(newRequestToken.idToken)
      const tokenValues: DecryptToken = jwt(newRequestToken.accessToken)
      setUserRoles(JSON.parse(tokenValues.roles).Entitlements)
      return newRequestToken.accessToken
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        idToken,
        userRoles,
        setIdToken,
        setLogin,
        setUser,
        user,
        accessToken,
        b2cRefreshToken,
        account,
        isAuthenticated,
        client: client,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
