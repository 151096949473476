import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
  border-radius: 10px;
  border: 1px solid ${COLORS.Background.Border};
  overflow: hidden;
  textarea {
    border-radius: 4px;
    height: 5rem;
    min-width: 22.5rem;
    box-sizing: border-box;
    resize: none;
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    ::-webkit-scrollbar-track {
      background: ${COLORS.Background.Primary};
    }
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.Background.PrimaryActive};
      border-radius: 4px;
    }
    &:hover {
      border: 1px solid ${COLORS.Background.Border};
    }
    &:focus {
      box-shadow: inset 0px 0px 0px 1px
        ${() => COLORS.Background.PrimaryMedium};
      border-color: ${() => COLORS.Background.PrimaryMedium};
      color: ${() => COLORS.Text.Black};
    }
  }
`
