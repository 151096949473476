import { gql } from '@apollo/client'

export const GET_ACCOUNT_RESTRICTIONS_DETAILS = gql`
  query vwAccountRestrictionsReportQuery(
    $tradeDateStart: timestamptz
    $tradeDateEnd: timestamptz
  ){
    db_vwAccountRestrictions(
      limit: 1000
      where: {
        _or: [
          {
            _and: [
              {
                _and: [
                  { startdate: { _gte: $tradeDateStart } }
                  { startdate: { _lte: $tradeDateEnd } }
                ]
              }
              {
                _and: [
                  { enddate: { _gte: $tradeDateStart } }
                  { enddate: { _lte: $tradeDateEnd } }
                ]
              }
            ]
          }
          {
            _and: [
              {
                _and: [
                  { startdate: { _gte: $tradeDateStart } }
                  { startdate: { _lte: $tradeDateEnd } }
                ]
              }
              { enddate: { _is_null: true } }
            ]
          }
          {
            _and: [
              { startdate: { _is_null: true } }
              { enddate: { _is_null: true } }
            ]
          }
        ]
      }
    ) {
      AccountId
      AccountNumber
      enddate
      startdate
      restrictionname
      violationnumber
      vwAccountRestrictions_BalanceRepl {
        SettleDateBalance
        UpdateOn
      }
      vwAccountRestrictions_Order {
        Product
        CreatedBy
        CreatedOn
      }
      vwAccountRestrictions_Transaction {
        Cusip
        BuySellIndicator
        Quantity
        SecurityDescription1
        Symbol
        TradeDate
      }
      RestrictionStatus
    }
  }
`
