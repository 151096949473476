import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './settings/authConfig'
import AiwContextProvider from './services/aiwContext/AiwContext'
import platform from 'platform'

export const msalInstance = new PublicClientApplication(msalConfig)

const BrowserAppProvider: React.FC = () => (
  <AiwContextProvider>
    <App pca={msalInstance}/>
  </AiwContextProvider>
)

const Glue42Provider = React.lazy(
  () => import('./glue42/Glue42ContextProvider')
)

const ContextProvider: React.FC = () => {
  return platform.name === 'Electron' ? (
    <Glue42Provider />
  ) : (
    <BrowserAppProvider />
  )
}

const AppProvider = () => (
      <Suspense fallback={<div>Loading...</div>}>
        <ContextProvider />
      </Suspense>
)


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider />
    </BrowserRouter>
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
