import React, { useContext, useState } from 'react'
import Divider from '../../components/divider/Divider'
import {
  MasterWrapper,
  HeaderRow,
  IconWrapper,
  DividerWrapper,
  StyledTableContainer,
  TitleIconWrap,
  TopRow,
  Row,
} from './styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { COLORS } from '../../theme/Colors'
import { WDStyledSectionTitle } from '../../components/ui/WDTypography'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'
import { SvgAuditTrail } from '../../components/svg/SvgAuditTrail'
import GroupingTable from '../../components/groupingTable/GroupingTable'
import { AccountsDetails } from './tableData'
import { LoaderRow } from './styles'
import Loader from '../../components/loader/Loader'
import { Tag } from '../Tag/Tag'
import {
  Approval,
  ApproveReject,
} from '../../services/approvalContext/ApprovalInterface'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { RejectChangesModal } from '../rejectChangesModal/RejectChangesModal'
import { ErrorModal } from '../errorModal/ErrorModal'
import { AuthContext } from '../../services/AuthContext'
import { Radio } from '../radio/Radio'
import { SvgClientDetails } from '../../components/svg/SvgClientDetails'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { MailingAddressContext } from '../../services/mailingAddressContext/MailingAddressContext'

export const ApprovalUI = () => {
  const authCtx = useContext(AuthContext)
  const approvalValues = useContext(ApprovalContext)  
  const auditTrailValues = useContext(AuditTrailContext)
  const accountDetailsValues = useContext(AccountDetailsContext)
  const AddressFields = useContext(MailingAddressContext)
  const [showByStatus,setShowByStatus]=useState('All')
  const err = approvalValues?.errorMsg
  const rejectionErrorMsg = approvalValues?.rejectionErrorMsg
  const [reject, setReject] = useState<ApproveReject>({
    accountNumber: '',
    accountId: 0,
    approvalRequestId: 0,
    blsType: '',
    notes: '',
    updateBy: '',
    requestType: 'Rejected',
  })

  let Bg = ''
  let Font = ''
  function convertResponse(apiResponse: Approval[] | undefined) {
    if (apiResponse !== undefined) {
      const convertedResponse = []
      let id = 1

      for (let i = 0; i < apiResponse.length; i++) {
        if (
          apiResponse[i]?.status === 'Pending' ||
          apiResponse[i]?.status === 'Partially-Approved'
        ) {
          Font = '#C2410C'
          Bg = ' #FFF7ED'
        } else if (apiResponse[i]?.status === 'Approved') {
          Font = '#2E844A'
          Bg = '#EBF7E6;'
        } else if (
          apiResponse[i]?.status === 'Submitted' ||
          apiResponse[i]?.status === 'Re-Submitted'
        ) {
          Font = '#2563EB'
          Bg = '#DBEAFE'
        } else if (apiResponse[i]?.status === 'Rejected') {
          Font = '#BA0517'
          Bg = '#FEF1EE'
        }
        const mainData = {
          IdNumber: apiResponse[i].approvalRequestId,
          AccountName: apiResponse[i].accountName,
          UpdatedBy: apiResponse[i].updatedBy,
          UpdatedDate: apiResponse[i].update_Date,
          Category: apiResponse[i].category,
          Association: apiResponse[i].association,
          Status: (
            <Tag
              ClientType={apiResponse[i]?.status}
              FontColor={Font}
              BgColor={Bg}
            />
          ),
          status: apiResponse[i]?.status,
          accountNumber: apiResponse[i]?.accountNumber,
          accountId: apiResponse[i]?.accountId,
          accountName: apiResponse[i]?.accountName,
          Notes: apiResponse[i].notes,
          id: apiResponse[i].id,
          Section: true,
          PrincipalDeskReviewer: apiResponse[i].approver,
        }
        const subData = apiResponse[i].categoryList
        const resultData: any[] = []
        subData.map((element: any, index: number) => {
          if (apiResponse[i].categoryList !== undefined) {
            const item: any = apiResponse[i]?.categoryList[index]
            if (subData.length > 0) {
              if (
                item?.field === 'Business Phone' ||
                item?.field === 'Fax' ||
                item?.field === 'Home Phone' ||
                item?.field === 'Mobile Phone'
              ) {
                const valueOld = item?.oldValue?.replaceAll(' ','')
                const valueNew = item?.newValue?.replaceAll(' ','')
                resultData.push({
                  field: item?.field,
                  oldValue:
                  valueOld.length === 0
                      ? '--'
                      : `(${valueOld?.substring(0, 3)})` +
                        ' ' +
                        valueOld?.substring(3, 6) +
                        '-' +
                        valueOld?.substring(6, valueOld?.length),
                  newValue:
                  valueNew.length === 0
                     ? '--'
                     : `(${valueNew?.substring(0, 3)})` +
                    ' ' +
                    valueNew?.substring(3, 6) +
                    '-' +
                    valueNew?.substring(6, valueNew?.length),
                })
              } else {
                resultData.push({
                  field: item?.field,
                  oldValue: item?.oldValue ? item?.oldValue : '--',
                  newValue: item?.newValue ? item?.newValue : '--',
                })
              }
            }
          }
        })

        convertedResponse.push({
          mainData: { ...mainData },
          subData: [...resultData],
        })
        id++
      }
      return convertedResponse
    }
  }

  const data = AccountsDetails
  const convertedResponse = convertResponse(approvalValues?.approvalData)
  const convertedResponseMainData = convertedResponse?.map(
    (obj) => obj.mainData
  )

  // console.log('Testing: ', convertedResponseMainData)

  const columnData = [
    {
      Header: 'ID',
      accessor: 'IdNumber',
    },
    {
      Header: 'Account Name ',
      accessor: 'AccountName',
    },
    {
      Header: 'Requested By',
      accessor: 'UpdatedBy',
    },
    {
      Header: 'Updated Date',
      accessor: 'UpdatedDate',
    },
    {
      Header: 'Category',
      accessor: 'Category',
    },
    {
      Header: 'Association',
      accessor: 'Association',
    },
    {
      Header: 'Principal Desk Reviewer',
      accessor: 'PrincipalDeskReviewer',
    },
    {
      Header: 'Status',
      accessor: 'Status',
    },
  ]

  const subTableColumns = ['Field', 'Old Value', 'New Value']

  const collapse = () => {
    approvalValues?.setApprovalCollapse(!approvalValues?.approvalCollapse)
  }

  const approveFunction = (e: any) => {
    // console.log(e)
    const temp = {
      accountNumber: e.accountNumber,
      accountId: e.accountId,
      approvalRequestId: e.IdNumber,
      blsType: 'string',
      notes: e.Notes,
      updateBy: authCtx?.account?.name,
      requestType: 'Approved',
    }
    approvalValues?.setApprovalPayload(temp)
    approvalValues?.setCategory(e.Category)
    // console.log('Approved Payload: ', temp)
  }

  const rejectFunction = (e: any) => {
    // console.log(e)
    const temp = {
      accountNumber: e.accountNumber,
      accountId: e.accountId,
      approvalRequestId: e.IdNumber,
      blsType: 'string',
      notes: e.Notes,
      updateBy: authCtx?.account?.name,
      requestType: 'Rejected',
    }
    setReject(temp)
  }

  const rejectApiCall = () => {
    approvalValues?.setRejectPayload(reject)
    approvalValues?.setReject(false)
    // console.log('Reject Data: ', reject)
  }

  const rejectPayload = (e: any) => {
    reject.notes = e
    setReject({ ...reject })
    rejectApiCall()
  }

  
  if ( approvalValues?.isLoading ) {
    return (
      <MasterWrapper>
        <TopRow>
          <HeaderRow>
            <TitleIconWrap>
              <IconWrapper>
                <SvgClientDetails />
              </IconWrapper>
              <WDStyledSectionTitle>{'Request for Approval'}</WDStyledSectionTitle>
            </TitleIconWrap>
          </HeaderRow>
        </TopRow>
        {
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
        }
        <LoaderRow>
          <Loader />
        </LoaderRow>
      </MasterWrapper>
    )
  }


  const reloadAuditTrail = () =>{
    auditTrailValues?.retrieveAuditTrail()
    setTimeout(()=>{
      auditTrailValues?.setIsLoading(true)
    },200)
    auditTrailValues?.setIsLoading(false)
  }

  const reloadAccountDetails = () =>{
    accountDetailsValues?.retrieveAccountDetails()
    accountDetailsValues?.setAccountDetailsCollapse(accountDetailsValues?.accountDetailsCollapse)
    approvalValues?.setIsActiveAct(false)
  }

  const reloadAddressUI = () =>{
    AddressFields?.retrieveMailingAddress()
    AddressFields?.setMailingAddressCollapse(AddressFields?.mailingAddressCollapse)
    approvalValues?.setIsActiveAd(false)
  }
  const reloadApprovalTrail = () =>{
    approvalValues?.retrieveAuditTrail()
    setTimeout(()=>{
      approvalValues?.setIsLoading(true)
    },200)
    approvalValues?.setIsLoading(false)
  }


  return (
    <MasterWrapper
      id="request-for-approval"
      borderShadow={approvalValues?.approvalCollapse ? true : false}
    >
      {approvalValues?.reject && (
        <RejectChangesModal onSend={(e) => rejectPayload(e)} />
      )}
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              {/* <Icon icon={Icons.Relationship} /> */}
              <SvgAuditTrail />
            </IconWrapper>
            <WDStyledSectionTitle>
              {'Request for Approval'}
            </WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <IconWrapper onClick={() => collapse()}>
          {approvalValues?.approvalCollapse && <SvgChevronUp />}
          {!approvalValues?.approvalCollapse && <SvgChevronDown />}
        </IconWrapper>
      </TopRow>
      {approvalValues?.approvalCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

      {approvalValues?.approvalCollapse && (
        <React.Fragment>
          <Row>
          <Radio
              label="All"
              onChange={() => console.log()}
              name={'All'}
              value={'All'}
              selectedValue={showByStatus}
              onClick={() => setShowByStatus('All')}
            />
            <Radio
              label="Approved"
              onChange={() => console.log()}
              name={'Approved'}
              value={'Approved'}
              selectedValue={showByStatus}
              onClick={() => setShowByStatus('Approved')}
            />

            <Radio
              label="Pending"
              onChange={() => console.log()}
              name={'Pending'}
              value={'Pending'}
              selectedValue={showByStatus}
              onClick={() => setShowByStatus('Pending')}
            />
          </Row>
          <StyledTableContainer>
            {showByStatus==='Approved' && (
            <GroupingTable
              tableColumns={columnData}
              tableData={
                convertedResponseMainData ? convertedResponseMainData?.filter((obj)=>obj.Status.props.ClientType==='Approved') : []
              }
              subTableHead={subTableColumns}
              subTableData={convertedResponse}
              totalDataAvailable={
                convertedResponseMainData ? convertedResponseMainData?.filter((obj)=>obj.Status.props.ClientType==='Approved').length : 0
              }
              onClick={(e) => approveFunction(e)}
              onRejectClick={(e) => rejectFunction(e)}
              onRowClick={(e) => approvalValues?.setValue(e.accountNumber)}
            />
            )}
            {showByStatus==='All' && (
            <GroupingTable
              tableColumns={columnData}
              tableData={
                convertedResponseMainData ? convertedResponseMainData.filter( data => data.status !== 'Removed' &&  data.status !=='Cancelled'): []
              }
              subTableHead={subTableColumns}
              subTableData={convertedResponse}
              totalDataAvailable={
                convertedResponseMainData ? convertedResponseMainData.filter( data => data.status !== 'Removed' &&  data.status !=='Cancelled').length : 0
              }
              onClick={(e) => approveFunction(e)}
              onRejectClick={(e) => rejectFunction(e)}
              onRowClick={(e) => approvalValues?.setValue(e.accountNumber)}
            />
            )}
            {showByStatus==='Pending' && (
            <GroupingTable
              tableColumns={columnData}
              tableData={
                convertedResponseMainData ?  convertedResponseMainData?.filter((obj)=>obj.Status.props.ClientType==='Pending'): []
              }
              subTableHead={subTableColumns}
              subTableData={convertedResponse}
              totalDataAvailable={
                convertedResponseMainData ? convertedResponseMainData?.filter((obj)=>obj.Status.props.ClientType==='Pending').length : 0
              }
              onClick={(e) => approveFunction(e)}
              onRejectClick={(e) => rejectFunction(e)}
              onRowClick={(e) => approvalValues?.setValue(e.accountNumber)}
            />
            )}
          </StyledTableContainer>
         
        </React.Fragment>
      )}
      {err === undefined || err === 100 ? null : err === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            approvalValues?.setErrorMsg(100)
            reloadAuditTrail()
            reloadApprovalTrail()
            if(approvalValues?.categoryResponse==='Account Details')
              reloadAccountDetails()
            else if(approvalValues?.categoryResponse === 'Alternate' || approvalValues?.categoryResponse === 'MailingAddress')
              reloadAddressUI()
          }}
          successMsg={'Change request is approved successfully !'}
        />
      ) : (
        <ErrorModal
          onClick={() => {
            approvalValues?.setErrorMsg(100)
          }}
          success={500}
          blsErrorMsg={approvalValues?.blsError}
        />
      )}
      {rejectionErrorMsg === undefined ||
      rejectionErrorMsg === 100 ? null : rejectionErrorMsg === 200 ? (
        <ErrorModal
          success={200}
          onClick={() => {
            approvalValues?.setRejectionErrorMsg(100)
            reloadAuditTrail()
            reloadApprovalTrail()
            if(approvalValues?.categoryResponse==='Account Details')
              reloadAccountDetails()
            else if(approvalValues?.categoryResponse === 'MailingAddress' || approvalValues?.categoryResponse === 'Alternate')
              reloadAddressUI()
          }}
          successMsg={'Change request is rejected successfully !'}
        />
      ) : (
        <ErrorModal
          onClick={() => {
            approvalValues?.setRejectionErrorMsg(100)
          }}
          success={500}
        />
      )}
    </MasterWrapper>
  )
}
