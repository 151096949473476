import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { FONTS, FontSizes, FontWeights } from '../../theme/Fonts'
import { SIZES, TextSizes } from '../../theme/Sizes'
import { LineHeights } from '../../theme/Spacing'
interface typographyProps {
  children?: React.ReactNode
  isDown?: string
  stateChange?: boolean
}

const StyledInputLabel = styled.p`
  font-size: ${FontSizes.Paragraph}; // 16px
  font-family: ${FONTS.SourceSansProRegular};
  font-weight: ${FontWeights.Regular}; // 400
  line-height: ${LineHeights.Title}; // 24px
  color: ${COLORS.UI.Text};
  margin: 0;
  padding-bottom: ${SIZES[0]}; // 4px
`
const StyledSettingsLabel = styled.div`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; /*400*/
  font-size: ${FontSizes.Subtitle}; /*18px*/
  line-height: ${TextSizes[5]}; /*24px*/
  color: ${COLORS.Text.Neutral};
`
const StyledCaption = styled.div`
  font-family: 'SourceSansPro-Bold';
  font-weight: ${FontWeights.Bold}; /*700*/
  font-size: ${FontSizes.Subtitle}; /*18px*/
  line-height: ${SIZES[4]}; /*24px*/
  color: ${COLORS.UI.Text};
`

const StyledSidebarTitle = styled.p`
  font-family: 'SourceSansPro-Bold';
  font-weight: ${FontWeights.Bold}; /*700*/
  font-size: ${FontSizes.Paragraph}; /*16px*/
  line-height: ${SIZES[4]}; /*24px*/
  color: ${COLORS.UI.Text};
  font-style: normal;
`

const StyledSidebarTitleValue = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; /*700*/
  font-size: ${FontSizes.Subtitle}; /*16px*/
  line-height: ${SIZES[4]}; /*24px*/
  color: ${COLORS.UI.Text};
  font-style: normal;
`

const StyledApplicationHeading = styled.p`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Heading};
  line-height: ${SIZES[5]};
  color: ${COLORS.Background.PrimaryStrong};
  font-style: normal;
`

const StyledReadModeTitle = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[4]};
  color: ${COLORS.UI.TextWeak};
  font-style: normal;
`

const StyledAddressChangeTitle = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[3]};
  color: ${COLORS.UI.TextWeak};
  font-style: normal;
`

const StyledInvestmentSubLabel = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[3]};
  color: ${COLORS.Text.Neutral};
  font-style: normal;
`

const StyledReadModeValue = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular};
  font-size: ${FontSizes.Subtitle};
  line-height: ${SIZES[4]};
  color: ${COLORS.UI.Text};
  font-style: normal;
`

const StyledSidebarClientTitle = styled.p`
  font-family: 'SourceSansPro-Bold';
  font-weight: ${FontWeights.Bold};
  font-size: ${FontSizes.Subtitle};
  line-height: ${SIZES[5]};
  color: ${COLORS.UI.Text};
  font-style: normal;
`

const StyledSidebarClientNickname = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[3]};
  color: '#334155';
  font-style: normal;
`

const StyledNewProfileSidebarMenu = styled.p`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; /*700*/
  font-size: ${FontSizes.Paragraph}; /*16px*/
  line-height: ${SIZES[4]}; /*24px*/
  color: ${COLORS.UI.Text};
  font-style: normal;
`

const StyledWidgetSubHeading = styled.div`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; /*400*/
  font-size: ${FontSizes.Paragraph}; /*16px*/
  line-height: ${TextSizes[5]}; /*24px*/
  color: ${COLORS.Text.Neutral};
`

const StyledToastLabel = styled.div`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; /*400 */
  font-size: ${FontSizes.Caption}; /*12px*/
  line-height: ${SIZES[3]}; /*16px*/
  color: ${COLORS.Text.Neutral};
`
const StyledSubLabel = styled.div`
  font-family: 'SourceSansPro-Regular';
  font-weight: ${FontWeights.Regular}; 
  font-size: ${FontSizes.Paragraph}; 
  line-height: ${SIZES[4]}; 
  color: ${COLORS.Text.NeutralTextWeak};
`

const StyledSectionTitle = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Heading};
  line-height: ${SIZES[4]}; /*16px*/
  color: ${COLORS.Text.Text};
`

const StyledEditButtonLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[4]};
  font-style: normal;
`

const StyledCancelButtonLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[4]};
  font-style: normal;
`

const StyledCountryLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: ${COLORS.Text.Error};
`

const StyledSubValue = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Subtitle};
  line-height: ${SIZES[4]};
  font-style: normal;
`
const StyledSubHeading = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Button};
  line-height: ${SIZES[3]};
  color: ${COLORS.Text.NeutralTextWeak};
  font-style: normal;
`

const StyledSubHeadingLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[4]};
  color: ${COLORS.Text.NeutralTextWeak};
  font-style: normal;
`

const StyledAddAddressButtonLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  margin: 0;
`

const StyledSubSectionTitle = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: 600; /*400 */
  font-size: 20px; /*12px*/
  line-height: 24px; /*16px*/
  color: ${COLORS.Text.Text};
`
const StyledSubSectionMedium = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight:  ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[4]};
  color: ${COLORS.Text.Primary};
`

export const StyledTableHeader = styled.div`
  font-family: 'SourceSansPro-Bold';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.Text.Text};
`

const StyledTableLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[3]};
  font-style: normal;
`

const StyledPopupLabel = styled.div`
  font-family: 'SourceSansPro-Bold';
  font-weight: ${FontWeights.SemiBold}; /*700*/
  font-size: ${FontSizes.Subtitle}; /*18px*/
  line-height: ${SIZES[4]}; /*24px*/
  color: ${COLORS.UI.Text};
`

export const WDStyledPopupLabel = ({ children }: typographyProps) => {
  return <StyledPopupLabel>{children}</StyledPopupLabel>
}

export const WDTableLabel = ({ children }: typographyProps) => {
  return <StyledTableLabel>{children}</StyledTableLabel>
}

export const WDStyledTableHeader = ({
  children,
  ...props
}: typographyProps) => {
  return (
    <StyledTableHeader {...props}>{children}</StyledTableHeader>
  )
}

export const WDStyledCancelButtonLabel = ({
  children,
  ...props
}: typographyProps) => {
  return (
    <StyledCancelButtonLabel {...props}>{children}</StyledCancelButtonLabel>
  )
}

export const WDStyledCountryLabel = ({
  children,
  ...props
}: typographyProps) => {
  return (
    <StyledCountryLabel {...props}>{children}</StyledCountryLabel>
  )
}

export const WDStyledSubSectionTitle = ({
  children,
  ...props
}: typographyProps) => {
  return <StyledSubSectionTitle {...props}>{children}</StyledSubSectionTitle>
}

export const WDStyledAddAddressButtonLabel = ({
  children,
  ...props
}: typographyProps) => {
  return (
    <StyledAddAddressButtonLabel {...props}>
      {children}
    </StyledAddAddressButtonLabel>
  )
}

export const WDStyledEditButtonLabel = ({
  children,
  ...props
}: typographyProps) => {
  return <StyledEditButtonLabel {...props}>{children}</StyledEditButtonLabel>
}

export const WDStyledSectionTitle = ({
  children,
  ...props
}: typographyProps) => {
  return <StyledSectionTitle {...props}>{children}</StyledSectionTitle>
}

export const WDStyledWidgetSubHeading = ({
  children,
  ...props
}: typographyProps) => {
  return <StyledWidgetSubHeading {...props}>{children}</StyledWidgetSubHeading>
}

export const WDStyledToastLabel = ({ children, ...props }: typographyProps) => {
  return <StyledToastLabel {...props}>{children}</StyledToastLabel>
}

export const WDInputLabel = ({ children, ...props }: typographyProps) => {
  return <StyledInputLabel {...props}>{children}</StyledInputLabel>
}

export const WDStyledSettingsLabel = ({
  children,
  ...props
}: typographyProps) => {
  return <StyledSettingsLabel {...props}>{children}</StyledSettingsLabel>
}

export const WDCaption = ({ children }: typographyProps) => {
  return <StyledCaption>{children}</StyledCaption>
}

export const WDSidebarTitle = ({ children }: typographyProps) => {
  return <StyledSidebarTitle>{children}</StyledSidebarTitle>
}

export const WDSidebarTitleValue = ({ children }: typographyProps) => {
  return <StyledSidebarTitleValue>{children}</StyledSidebarTitleValue>
}

export const WDApplicationHeading = ({ children }: typographyProps) => {
  return <StyledApplicationHeading>{children}</StyledApplicationHeading>
}

export const WDReadModeTitle = ({ children }: typographyProps) => {
  return <StyledReadModeTitle>{children}</StyledReadModeTitle>
}

export const WDReadModeValue = ({ children }: typographyProps) => {
  return <StyledReadModeValue>{children}</StyledReadModeValue>
}

export const WDSidebarClientTitle = ({ children }: typographyProps) => {
  return <StyledSidebarClientTitle>{children}</StyledSidebarClientTitle>
}

export const WDSidebarClientNickname = ({ children }: typographyProps) => {
  return <StyledSidebarClientNickname>{children}</StyledSidebarClientNickname>
}

export const WDNewProfileSidebarMenu = ({ children }: typographyProps) => {
  return <StyledNewProfileSidebarMenu>{children}</StyledNewProfileSidebarMenu>
}

export const WDAddressChangeTitle = ({ children }: typographyProps) => {
  return <StyledAddressChangeTitle>{children}</StyledAddressChangeTitle>
}

export const WDInvestmentSubLabel = ({ children }: typographyProps) => {
  return <StyledInvestmentSubLabel>{children}</StyledInvestmentSubLabel>
}
export const WDSubHeadingLabel = ({ children }: typographyProps) => {
  return <StyledSubHeadingLabel>{children}</StyledSubHeadingLabel>
}

export const WDSubLabel = ({ children }: typographyProps) => {
  return <StyledSubLabel>{children}</StyledSubLabel>
}

export const WDSubValue = ({ children }: typographyProps) => {
  return <StyledSubValue>{children}</StyledSubValue>
}

export const WDSubHeading = ({ children }: typographyProps) => {
  return <StyledSubHeading>{children}</StyledSubHeading>
}
export const WDStyledSubSectionMedium = ({ children }: typographyProps) => {
  return <StyledSubSectionMedium>{children}</StyledSubSectionMedium>
}