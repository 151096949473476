import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  border-radius: 8px;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const TopRow = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  min-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  align-items: center;
  cursor: pointer;
`
export const StyledTableContainer = styled.div`
  width: 100% !important;
  background: '#FFFFFF' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  max-width: 100% !important;
`

export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
`
export const SubHeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  background: ${COLORS.Background.Primary10};
  width: 100%;
`

export const TitleIconWrap = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${COLORS.Text.Primary};
`

export const HSpacer = styled.div`
  margin-left: 600px;
`

export const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-radius: 4px;
  boxShadow={"0px 2px 6px -2px rgba(0, 36, 93, 0.3)"}
`
export const FlexItem = styled.div`  
  padding-left: 8px;
`
export const FlexItemButton = styled.div`  
  padding-left: 8px;
  margin-top: 36px;
`
export const FlexItemInput = styled.div`
  flex: 0 1 20% !important;
  width: 30% !important;
  padding-left: 8px;
`

export const RowContent = styled.div`
display: flex;
flex: 0 1 100%;
align-self: center;
width: 100%;
gap: 24px;
margin: 8px 0px;
align-items: center;
`
export const RowContentSecond = styled.div`
display: flex;
flex: 0 1 100%;
align-self: center;
width: 100%;
gap: 24px;
margin: 8px 0px;
align-items: center;
justify-content: space-between;
`

export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
`
export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* margin-bottom: 16px; */
`