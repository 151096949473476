import { element } from 'prop-types'
import { OptionProps } from '../components/selectDropdown/ISelectDropdown'
import { ContactList } from '../services/accountDetailsContext/AccountDetailsInterface'
import { AccRestrictionReportsDataInterface } from '../services/accountRestrictionReports/AccountRestrictionReportsDataInterface'
import {
  InvestmentProfileInterface,
  TableData,
} from '../services/investmentContext/InvestmentContextInterface'
import { messages } from './constants'

export const getObjectDifferences = <T extends InvestmentProfileInterface>(
  obj1: T,
  obj2: T,
  fieldObj: T
) => {
  const differences: TableData[] = []
  for (const key in obj1) {
    if (
      Object.hasOwnProperty.call(obj1, key) &&
      Object.hasOwnProperty.call(obj2, key)
    ) {
      if (obj1[key as keyof T] !== obj2[key as keyof T]) {
        differences.push({
          field: fieldObj[key as keyof T] as string,
          oldValue: obj1[key as keyof T] as string,
          newValue: obj2[key as keyof T] as string,
        })
      }
    }
  }
  return differences
}

export const getLabel = <T extends OptionProps>(
  listOfValues: T[],
  value: string
) => {
  const label = listOfValues.find((item) => item.value === value)
  return label?.label
}

export const formatPhoneNumber = (value: string) => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '')
  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`
}

export const findContact = (
  contactList: ContactList[],
  contactType: string
) => {
  const contact = contactList?.find(
    (contact) => contact.contactType === contactType
  )
  return contact
}

export const validateContact = (contact: ContactList, phone: string) => {
  if (contact?.contact?.phoneNumber !== '' || phone !== '') {
    const phoneNumber = phone.replace(/[^\d]/g, '')
    if (phoneNumber.toString() === '') {
      contact.errorMessage = messages.account_details_ui.phone_error.phone_empty
      contact.isError = true
      return true
    } else if (phoneNumber.toString().length < 10) {
      contact.errorMessage =
        messages.account_details_ui.phone_error.phone_length(
          contact.contactType
        )
      contact.isError = true
      return true
    } else {
      contact.errorMessage = messages.account_details_ui.phone_error.no_error
      contact.isError = false
      return false
    }
  }
  contact.errorMessage = messages.account_details_ui.phone_error.no_error
  contact.isError = false
  return false
}

export const countPhoneList = (phoneList: ContactList[]) => {
  const filtered = phoneList.filter((contact: ContactList) => {
    return contact.contact?.phoneNumber !== ''
  })
  return filtered.length
}

export const checkIsP3Account = (system: string) => {
  return system === 'P3'
}

export const checkZipCode = (zipCode: string) => {
  if (zipCode !== undefined && zipCode.match(/^[0-9]+$/)) return true
  return false
}

export const convertRestrictionsDetails = (
  data: any,
  startDateSelected?: string,
  endDateSelected?: string
) => {
  // console.log('Data', data?.db_vwAccountRestrictions)
  if (data !== undefined && data?.db_vwAccountRestrictions?.length > 0) {
    const convertedData = data?.db_vwAccountRestrictions?.map(
      (element: any) => {
        return {
          accountNumber: element?.AccountNumber ?? '',
          endDate: element?.enddate ?? '',
          startDate: element?.startdate ?? '',
          restrictionName: element?.restrictionname ?? '',
          violationNumber: element?.violationnumber ?? '',
          restrictionTypeId: element?.restrictiontypeid ?? '',
          restrictionStatus: element?.RestrictionStatus ?? '',
          product: element?.vwAccountRestrictions_Order?.Product ?? '',
          createdBy: element?.vwAccountRestrictions_Order?.CreatedBy ?? '',
          createdOn: element?.vwAccountRestrictions_Order?.CreatedOn ?? '',
          settleDataBalance:
            element?.vwAccountRestrictions_BalanceRepl?.SettleDateBalance ?? '',
          updateOn: element?.vwAccountRestrictions_BalanceRepl?.UpdateOn ?? '',
          buySellIndicator:
            element?.vwAccountRestrictions_Transaction?.BuySellIndicator ?? '',
          securityDescription1:
            element?.vwAccountRestrictions_Transaction?.SecurityDescription1 ??
            '',
          quantity: element?.vwAccountRestrictions_Transaction?.Quantity ?? '',
          tradeDate:
            element?.vwAccountRestrictions_Transaction?.TradeDate ?? '',
          cusip: element?.vwAccountRestrictions_Transaction?.Cusip ?? '',
          symbol: element?.vwAccountRestrictions_Transaction?.Symbol ?? '',
        }
      }
    )
    // console.log('Converted Data', convertedData)

    const startDate = new Date(startDateSelected ?? '')
    const endDate = new Date(endDateSelected ?? '')
    
    /* const filteredData = convertedData?.map(
      (element: AccRestrictionReportsDataInterface) => {
        const tradeDate = new Date(element.tradeDate as string)
        const updateOn = new Date(element?.updateOn as string)
        const createdOn = new Date(element?.createdOn as string)
        let updateElement = element
         if (tradeDate && (tradeDate < startDate || tradeDate > endDate)) {
          // console.log('TradeDate', tradeDate, startDate, tradeDate < startDate)
          updateElement = {
            ...updateElement,
            cusip: '',
            symbol: '',
            tradeDate: '',
            buySellIndicator: '',
            securityDescription1: '',
            quantity: '',
          }
        }
         if (createdOn && (createdOn < startDate || createdOn > endDate)) {
          // console.log('CreatedOn', createdOn, startDate, createdOn < startDate)
          updateElement = {
            ...updateElement,
            product: '',
          }
        }
        if (updateOn && (updateOn < startDate || updateOn > endDate)) {
          // console.log('UpdateOn', updateOn, startDate, updateOn < startDate)
          updateElement = {
            ...updateElement,
            settleDataBalance: '',

          }
        } 
        return updateElement       
      }
    ) */
    // console.log('Filtered Data', filteredData)
    return convertedData as AccRestrictionReportsDataInterface[]
  }
}

export const isoStringToFormattedDate = (isoString: string) => {
  const date = new Date(isoString)
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${month}-${day}-${year}`
}
