export interface MailingAddressInterface {
  addressType?: string
  addressTypeId?: string | number
  addressId?: number
  title?: string
  streetAddress1?: string
  streetAddress2?: string
  city?: number
  cityName?: string
  stateName?: string
  cityId?: number
  state?: number
  stateCode?: string
  country?: string
  countryName?: string
  zipCode?: string
  startDate?: string
  endDate?: string
  addressNickName?: string
}

export const defaultMailingAddress: MailingAddressInterface[] = [
  {
    addressTypeId: 1,
    addressType: 'Mailing Address',
    title: '',
    streetAddress1: '',
    streetAddress2: '',
    city: 2,
    cityName: '',
    state: 33,
    stateName: '',
    country: '',
    countryName: '',
    zipCode: '',
  },

  // {
  //   addressTypeId: 2,
  //   addressType: 'Alternate Address',
  //   title: '',
  //   streetAddress1: 'Test',
  //   streetAddress2: 'Test',
  //   city: 5,
  //   cityName: 'Test',
  //   state: 33,
  //   stateName: 'Test',
  //   country: 'US',
  //   countryName: 'Test',
  //   zipCode: '11748',
  //   startDate: '2020/09/10',
  //   endDate: '2021/09/10'
  // },
  // {
  //   addressTypeId: 4,
  //   addressType: 'Alternate Address',
  //   title: '',
  //   streetAddress1: 'Test',
  //   streetAddress2: 'Test',
  //   city: 5,
  //   cityName: 'Test',
  //   state: 33,
  //   stateName: 'Test',
  //   country: 'US',
  //   countryName: 'Test',
  //   zipCode: '11748',
  //   startDate: '2019/09/10',
  //   endDate: '2020/09/10'
  // },
]

export interface Countries {
  countryName: string
  countryAlpha2Code: string
}

export interface States {
  stateProvinceId: number
  stateProvinceName: string
  stateProvinceShortCode?: string
}

export interface AddressTypes {
  addressTypeId: number
  addressType: string
}

export interface AddUpdateMailingAddress {
  accountId?: number
  accountNumber?: string | undefined
  addressTypeId?: string | number | undefined
  addressId?: string | number | undefined
  addressType?: string | undefined
  streetAddress1?: string | undefined
  streetAddress2?: string | undefined
  streetAddress3?: string | undefined
  cityId?: number | undefined
  cityName?: string | number | null | undefined
  stateProvinceId?: string | number | null | undefined
  stateProvinceName?: string | undefined
  stateCode?: string | undefined
  countrycode?: string | number | null | undefined
  countryName?: string | number | null | undefined
  zipCode?: string | undefined
  blsType?: string | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  addressNickName?: string | undefined
  statusId?: number | undefined
  updateBy?: string
  createdBy?: string
  createdOn?: string
  updateOn?: string
}

export interface AddressList {
  accountId?: number
  accountNumber?: string | undefined
  addressTypeId?: string | number | undefined
  addressId?: string | number | undefined
  addressType?: string | undefined
  streetAddress1?: string | undefined
  streetAddress2?: string | undefined
  streetAddress3?: string | undefined
  cityId?: number | undefined
  cityName?: string | undefined
  stateProvinceId?: string | number | null | undefined
  stateProvinceName?: string | undefined
  stateCode?: string | undefined
  statusId?: number | undefined
  countrycode?: string | undefined
  countryName?: string | undefined
  zipCode?: string | undefined
  startDate?: string | undefined
  endDate?: string | undefined
  addressNickName?: string | undefined
  updateBy?: string
  createdBy?: string
  createdOn?: string
  updateOn?: string
}

export interface AddressSubmissionPayload {
  addressList?: AddressList[]
  notes?: string
}

export interface DeleteAlternateAddress{
  accountId?:number | string | undefined
  addressId?:number | string
  updateBy?:string
}

export interface AddressValidation{
  city?:string
  zipCode?:string
  stateProvince?:string
}
