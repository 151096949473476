import React, { useState, createContext, useEffect } from 'react'

interface SetPaperlessCollapse {
  (value: boolean): void | boolean
}

interface PaperlessContextInterface {
  paperlessCollapse: boolean
  setPaperlessCollapse: SetPaperlessCollapse
  isLoading: boolean
  error: unknown | null | object
}

export const PaperlessContext = createContext<
  PaperlessContextInterface | undefined | null
>(undefined)

interface PaperlessCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const PaperlessContextProvider = ({
    children,
  }: PaperlessCtxProviderProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<unknown | null | object>(null)

    
  const [paperlessCollapse, setPaperlessCollapse] = useState(false)

  useEffect(() => {
    async function retrievePaperless() {
      try {
        // console.log("Paperless collapse", paperlessCollapse)
      } catch (error: unknown) {
        // console.log(error)
        setError(error)
      }
    }
    retrievePaperless()
  }, [paperlessCollapse])

  return (
    <PaperlessContext.Provider
      value={{
        paperlessCollapse,
        setPaperlessCollapse,
        isLoading,
        error,
      }}
    >
      {children}
    </PaperlessContext.Provider>
  )
}