import React, { useState, createContext, useEffect, useContext } from 'react'
// import { fetchAuditTrail } from '../http'
import { AuditTrail, CancelRequest, defaultAuditTrail } from './AuditTrailInterface'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import useAxiosProtected from '../http/useAxiosProtected'
import { auditTrailCancelRequest, fetchAuditTrail } from './AuditTrailHttp'
import { AuthContext } from '../AuthContext'

interface SetAuditTrailCollapse {
  (value: boolean): void | boolean
}

interface SetCancelRequest{
  (value:CancelRequest): void | CancelRequest
}

interface AuditTrailContextInterface {
  auditTrail: AuditTrail[] | undefined
  auditTrailCollapse: boolean
  setAuditTrailCollapse: SetAuditTrailCollapse
  cancelRequest: CancelRequest | undefined
  setCancelRequest : SetCancelRequest
  errorMsg : number | undefined
  setErrorMsg : React.Dispatch<React.SetStateAction<number>>
  isLoading: boolean
  error: unknown | null | object
  retrieveAuditTrail : () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuditTrailContext = createContext<
  AuditTrailContextInterface | undefined | null
>(undefined)

interface AuditTrailCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const AuditTrailContextProvider = ({
  children,
}: AuditTrailCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [auditTrail, setAuditTrail] = useState<AuditTrail[]>([])
  const [cancelRequest,setCancelRequest]=useState<CancelRequest>()
  const [errorMsg,setErrorMsg]=useState<number>(100)
  const [auditTrailCollapse, setAuditTrailCollapse] = useState(false)
  const approval = useContext(ApprovalContext)
  const clientIdValue: any = approval?.value

  const axiosProtected = useAxiosProtected()
  const authCtx = useContext(AuthContext)

  async function retrieveAuditTrail() {
    if(clientIdValue!==undefined && clientIdValue!=='' )   
    {
    try {
      const data = await fetchAuditTrail(clientIdValue, axiosProtected)
      setAuditTrail(data)
     
    } catch (error: unknown) {
      setError(error)
    }
  }
setIsLoading(false)
}
  useEffect(() => {
    if(authCtx?.accessToken){
      retrieveAuditTrail()
    }
  }, [clientIdValue,authCtx?.accessToken])

  useEffect(()=>{
    async function cancelRequestDetails(){
      try{
        if(cancelRequest !== undefined){
          const data= await auditTrailCancelRequest(cancelRequest,axiosProtected)
          setErrorMsg(data)
        }
      }catch (error : unknown){
        setError(error)
      }
    }
    if(authCtx?.accessToken){
      cancelRequestDetails()
    }
  },[cancelRequest,authCtx?.accessToken])

  return (
    <AuditTrailContext.Provider
      value={{
        auditTrail,
        auditTrailCollapse,
        setAuditTrailCollapse,
        isLoading,
        error,
        errorMsg,
        setErrorMsg,
        cancelRequest,
        setCancelRequest,
        retrieveAuditTrail,
        setIsLoading,
      }}
    >
      {children}
    </AuditTrailContext.Provider>
  )
}
