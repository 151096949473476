import styled from 'styled-components';
import { COLORS } from '../../../theme/Colors';

export interface childProps {
  children: React.ReactElement
}

const StyledSidePane = styled.div<childProps>`
color: black;
flex: 0 1 25%;
background: ${COLORS.Background.NeutralBackgroundWeak};
position: fixed;
overflow-y: scroll;
min-height: 100%;
width: 20%;
/* padding-left: 100px; */
order: 1;
top: 0;
bottom: 0;
overflow-x: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
::-webkit-scrollbar {
  display: none;
}
z-index: 1;
`

export default StyledSidePane
