import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'
import {
  AddUpdateMailingAddress,
  AddressSubmissionPayload,
  DeleteAlternateAddress,
} from './MailingAddressInterface'

export async function fetchMailingAddress(
  accountId: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.MAILING_ADDRESS_GET_API
  const payload = {
    accountNumber: accountId,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  // console.log('🚀 ~ Mailing Addresses => ', data)
  return data
}

export async function fetchStates(axiosProtected: AxiosInstance) {
  const url = URLs.ADDRESS_STATES
  const response = await axiosProtected.get(url)
  const data = response.data
  // console.log('🚀 ~ Fetch States =>', data)
  return data
}

export async function fetchCountries(axiosProtected: AxiosInstance) {
  const url = URLs.ADDRESS_COUNTRIES
  const response = await axiosProtected.get(url)
  const data = response.data
  // console.log('🚀 ~ Fetch Countries =>', data)
  return data
}

export async function addUpdateMailingAddressMethod(
  payload: AddUpdateMailingAddress[] | null,
  axiosProtected: AxiosInstance
) {
  try {
    const url = URLs.MAILING_ADDRESS_ADD_UPDATE_API
    const response = await axiosProtected.post(url, payload)
    const data = response.status
    // console.log('🚀 ~ Add/Update response =>', response)
    // if(!response){
    //   alert('Mailing Address Add/Update status failed!' )
    // }
    // else{
    //   alert('Mailing Address Add/Update status is : ' + 'Success' )
    // }

    return data
  } catch (e) {
    return 500
  }
}

export async function addressSubmission(
  payload: AddressSubmissionPayload,
  axiosProtected: AxiosInstance
) {
  try {
    const url = URLs.ADDRESS_SUBMISSION
    const response = await axiosProtected.post(url, payload)
    const data = response.status
    return data
  } catch (e) {
    return 500
  }
}

export async function deleteAlternateAddress(
  payload: DeleteAlternateAddress | null,
  axiosProtected: AxiosInstance
) {
  // console.log('alternate address payload', payload)
  try {
    const url = URLs.DELETE_ALT_ADDRESS_BY_ID
    const response = await axiosProtected.post(url, payload)
    const data = response.status
    // console.log('🚀 ~ delete alternate address =>', response)
    // alert('Alternate Address Delete status is : ' + response.status)
    return data
  } catch (e:any) {
    return e?.response
  }
}

export async function fetchAddressValidationDetails(
  zipCode: string | null,
  axiosProtected: AxiosInstance
) {
  const url = URLs.ADDRESS_VALIDATION
  const payload = {
    zipCode: zipCode,
  }
  const response = await axiosProtected.post(url, payload)

  const data = response.data
  // console.log('🚀 ~ Fetch Address Response =>', data)

  return data
}

