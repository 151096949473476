export interface AccountDetails {
  accountId?: number
  accountType?: string
  clientName?: string
  accountNumber?: number
  fullTitleOfAccount?: string
  accountNickName?: string
  advisorName?: string
  repCode?: string
  phoneNumber?: string
  emailAddress?: string
  accountClassId?: number
  status?: string
  accountOpeningDate?: string
  contactInfo?: ContactInfo[]
  emailInfo?: EmailInfo[]
  contactType?: ContactType[]
  emailType?: EmailType[]
  system?: string
}

export const defaultAccountDetails: AccountDetails = {
  accountId: 0,
  accountType: '',
  fullTitleOfAccount: '',
  accountNickName: '',
  advisorName: '',
  repCode: '',
  phoneNumber: '',
  emailAddress: '',
  accountClassId: 1,
  status: '',
  accountOpeningDate: '',
}

export interface UpdateAccountDetails {
  accountId?: number | undefined
  fullTitleOfAccount?: string | undefined
  accountNickName?: string | undefined
  phoneNumber?: string | undefined
  emailAddress?: string | undefined
  contactId?: number
  emailId?: number
  updateBy?: string
}

export interface ContactInfo {
  contactId?: number
  contactTypeId?: number
  phoneNumber?: string
  description?: string
}

export interface ContactType {
  contactType?: string
  contactTypeId?: number
}

export interface EmailType{
  emailType?:string
  emailTypeId?:number
}

export interface EmailInfo {
  emailId?: number
  emailTypeId?: number
  emailAddress?: string
  emailType?: string
  isError?:boolean
  errorMessage?:string
}
export interface AccountDetailsFieldValues {
  accountType?: string
  fullTitleOfAccount?: string
  accountNickname?: string
  advisorName?: string
  repCode?: string
  accountClassId?: string
  status?: string
  accountOpeningDate?: string
  homePhoneType?: string
  homePhoneNumber?: string
  mobilePhoneType?: string
  mobilePhoneNumber?: string
  businessPhoneType?: string
  businessPhoneNumber?: string
  fax?: string
}
export interface TableData {
  field?: string | undefined
  oldValue?: string | undefined
  newValue?: string | undefined
}
export interface AccountDetailsUpdatedValues {
  accountId?: number
  accountNumber?: string
  accountNickName?: string
  updateBy?: string
  createdBy?: string
  contactInfo?: ContactInfo[]
  emailInfo?: EmailInfo[]
  notes?:string
}

export interface DeleteContact {
  accountContactId?: number
  accountId?: number
  emailId?:number
  emailTypeId?:number
  updateBy?: string
  notes?:string
}

export interface ContactList {
  contactType: string,
  contact: ContactInfo | undefined
  contactNumberFormatted: string
  isError: boolean
  errorMessage: string
}