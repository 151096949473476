import { Toast, useToast } from "@chakra-ui/react";
import { createContext, useState, useEffect, useContext, Dispatch } from "react";
import { AccountDetailsContext } from "../accountDetailsContext/AccountDetailsContext";
import { ApprovalContext } from "../approvalContext/ApprovalContext";
import { AuthContext } from "../AuthContext";
import useAxiosProtected from "../http/useAxiosProtected";
import { fetchAccountLoanDetails, fetchPledgedAccountStatusDropDown, pledgedAccountSubmissionDetails } from "./AccountLoanDetailsHttp";
import { AccountLoanDetailInterface, AccountPledgedStatusDetails, AccountPledgedSubmissionPayload, PledgedStatusDropwDown } from "./AccountLoanDetailsInterface";

interface AccountLoanDetailsContextInterface {
    accountLoanDetailsCollapse: boolean
    setAccountLoanDetailsCollapse: SetAccountLoanDetailsCollapse
    accountLoanDetailsPopUp: boolean
    setAccountLoanDetailsPopUp: SetAccountLoanDetailsCollapse
    pledgedAccountData: AccountPledgedStatusDetails | undefined
    setPledgedAccountData: SetAccountPledgedStatusDetailsFunc
    pledgedAccountStatusDropDownList: PledgedStatusDropwDown[] | undefined
    setPledgedAccountStatusDropDownList: SetPledgedStatusDropwDownFunc
    accountLoanDetailSubmissionPayload: AccountPledgedSubmissionPayload | undefined
    setAccountLoanDetailSubmissionPayload: AccountPledgedSubmissionPayloadFunc
    accountLoanDetailPopUpPayload: AccountPledgedSubmissionPayload | undefined
    setAccountLoanDetailPopUpPayload: AccountPledgedSubmissionPayloadFunc
    isLoading: boolean
    error: unknown | null | object
    errorMsg: number | undefined
    setErrorMsg: Dispatch<React.SetStateAction<number>>
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    getAccountLoanDetails: () => void
}

interface AccountPledgedSubmissionPayloadFunc {
    (value: AccountPledgedSubmissionPayload): AccountPledgedSubmissionPayload | void
}

interface SetPledgedStatusDropwDownFunc {
    (value: PledgedStatusDropwDown[]): PledgedStatusDropwDown[] | void
}

interface SetAccountPledgedStatusDetailsFunc {
    (value: AccountPledgedStatusDetails): AccountPledgedStatusDetails | void
}

interface SetAccountLoanDetailsCollapse {
    (value: boolean): void | boolean
}

interface AccountLoanDetailsCtxProviderProps {
    children: React.ReactNode | React.ReactNode[] | null
}

export const AccountLoanDetailsContext = createContext<AccountLoanDetailsContextInterface | undefined | null>(null)

export const AccountLoanDetailsContextProvider = ({ children }: AccountLoanDetailsCtxProviderProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const axiosProtected = useAxiosProtected()
    const toast = useToast()
    const [error, setError] = useState<unknown | null | object>(null)
    const [errorMsg, setErrorMsg] = useState<number>(100)
    const authCtx = useContext(AuthContext)
    const approval = useContext(ApprovalContext)
    const clientIdValue: any = approval?.value
    const accountId = clientIdValue


    const [accountLoanDetailsPopUp, setAccountLoanDetailsPopUp] = useState(false)
    const [accountLoanDetailsCollapse, setAccountLoanDetailsCollapse] = useState(false)
    const [pledgedAccountData, setPledgedAccountData] = useState<AccountPledgedStatusDetails | undefined>()
    const [pledgedAccountStatusDropDownList, setPledgedAccountStatusDropDownList] = useState<any>([])
    const [accountLoanDetailSubmissionPayload, setAccountLoanDetailSubmissionPayload] = useState<AccountPledgedSubmissionPayload | undefined>()
    const [accountLoanDetailPopUpPayload, setAccountLoanDetailPopUpPayload] = useState<AccountPledgedSubmissionPayload | undefined>()


    async function getAccountLoanDetails() {
        if (accountId !== undefined && accountId !=='') {
            try {
                const data = await fetchAccountLoanDetails(accountId, axiosProtected)
                setPledgedAccountData(data)
                setIsLoading(false)
            } catch (error: unknown) {
                setError(error)
            }
        }
    }

    useEffect(() => {
        if (authCtx?.accessToken) {
            getAccountLoanDetails()
        }
    }, [authCtx?.accessToken, accountId])

    useEffect(() => {
        async function getPledgedAccountStatusDropDown() {
            try {
                const data = await fetchPledgedAccountStatusDropDown(axiosProtected)
                
                setPledgedAccountStatusDropDownList(data)
            } catch (error: unknown) {
                setError(error)
            }
        }
        if (authCtx?.accessToken) {
            getPledgedAccountStatusDropDown()
        }
    }, [authCtx?.accessToken])

    useEffect(() => {
        async function getPledgedAccountSubmission() {
            if (accountLoanDetailSubmissionPayload !== undefined) {
                try {
                    const dataResponse = await pledgedAccountSubmissionDetails(accountLoanDetailSubmissionPayload, axiosProtected)
                    setErrorMsg(dataResponse)
                }

                catch (error: unknown) {
                    setError(error)
                }
            }
        }
        if (authCtx?.accessToken) {
            getPledgedAccountSubmission()
        }
    }, [accountLoanDetailSubmissionPayload, authCtx?.accessToken])

    return (
        <AccountLoanDetailsContext.Provider
            value={{
                isLoading,
                setIsLoading,
                error,
                errorMsg,
                setErrorMsg,
                getAccountLoanDetails,
                accountLoanDetailsCollapse,
                setAccountLoanDetailsCollapse,
                accountLoanDetailsPopUp,
                setAccountLoanDetailsPopUp,
                pledgedAccountData,
                setPledgedAccountData,
                pledgedAccountStatusDropDownList,
                setPledgedAccountStatusDropDownList,
                accountLoanDetailPopUpPayload,
                setAccountLoanDetailPopUpPayload,
                accountLoanDetailSubmissionPayload,
                setAccountLoanDetailSubmissionPayload,
            }}>
            {children}
        </AccountLoanDetailsContext.Provider>
    )
}