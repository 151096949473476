import { useTable, useGroupBy, useExpanded, usePagination } from 'react-table'

import {
  Wrapper,
  StyledRectangle,
  ThContentWrapper,
  ThWrapper,
  SvgWrapper,
  IconWrapper,
  TdContentWrapper,
  ViewMoreButtonWrapper,
  PaginationWrapper,
  StyledBottomDiv,
  PendingWrapper,
  SubWrapper,
  NoteWrapper,
  ButtonRow,
  SecondaryButtonWrapper,
  PrimaryButtonWrapper,
  Space,
} from './styles'
import { useMemo, Fragment, useEffect, useState, useContext } from 'react'
import { GroupingTableProps, RowProps, sortItemProps } from './IGroupingTable'

import {
  SvgChevronDownMedium,
  SvgChevronDownSort,
  SvgChevronUpMedium,
  SvgChevronUpSort,
} from '../svg/SvgChevron'
import { COLORS } from '../../theme/Colors'
import React from 'react'
import Button from '../button/Button'
import { WDStyledCancelButton, WDStyledSaveButton } from '../ui/WDButtons'
import {
  WDStyledCancelButtonLabel,
  WDStyledToastLabel,
  WDStyledWidgetSubHeading,
  WDTableLabel,
} from '../ui/WDTypography'
import { RejectChangesModal } from '../../composites/rejectChangesModal/RejectChangesModal'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { AuthContext } from '../../services/AuthContext'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'

const GroupingTable = ({
  tableColumns,
  tableData,
  subTableHead,
  subTableData,
  totalDataAvailable,
  onRowClick,
  onClick,
  onRejectClick,
  auditTrail,
}: GroupingTableProps) => {
  const [sorting, setSorting] = useState({
    key: 'description',
    isAscending: true,
  })

  const [data, setData] = useState(tableData)
  const authCtx = useContext(AuthContext)
  const auditContext = useContext(AuditTrailContext)
  const approval = useContext(ApprovalContext)
  const columns = useMemo(() => tableColumns, [tableColumns]) as []
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGroupBy,
    useExpanded,
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = tableInstance
  const childTableHeads = [
    'Acquisition Date',
    'Qty',
    'Unit Cost Basis',
    'Cost Basis',
    'Last Price',
    'Current Value',
    '$Chg',
    '%Chg',
  ]

  useEffect(() => {
    if (data !== undefined) {
      let currentData = [...data]
      currentData = currentData.sort((a: sortItemProps, b: sortItemProps) => {
        const sortOrder = sorting.isAscending ? 1 : -1
        const key1 = a[sorting.key]?.toString().replace(',', '')
        const key2 = b[sorting.key]?.toString().replace(',', '')
        const result = key1?.localeCompare(key2, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
        return result * sortOrder
      })
      // console.log('sort', currentData)
      setData(currentData)
    }
  }, [sorting])

  useEffect(()=>{
    setData(tableData)
  },[tableData])

  const [columnValue, setColumnValue] = useState<string[]>()

  useEffect(() => {
    const col = tableColumns.map((col) => col.Header)
    const columnName = Object.values(col)
    // console.log(columnName, 'col')
    setColumnValue(columnName as string[])
  }, [])

  const cancelFunction = (id: any) => {
    console.log(id, 'id value')
    const payload = {
      accountApprovalRequestId: id.ID as number,
      notes: id.Notes as string,
      updatedBy: authCtx?.account?.name as string,
    }
    auditContext?.setCancelRequest(payload)
  }

  useEffect(() => {
    if (totalDataAvailable && totalDataAvailable < 10) {
      setPageSize(totalDataAvailable)
    } else setPageSize(10)
  }, [setPageSize, totalDataAvailable])

  const handleColumnSort = (key: string, isAscending: boolean) => {
    if (sorting.key !== key) setSorting({ key: key, isAscending: true })
    else setSorting({ key: key, isAscending: isAscending })
  }

  const createRow = (rowData: RowProps[]) => {
    return rowData.map((row: RowProps, index: number) => {
      const testing =
        row && row.unrealizedGainLossValue
          ? row.unrealizedGainLossValue.toString()
          : ''
      const result = testing.includes('-')
      const concatCls = result ? ' negative' : ' positive'

      return (
        <tr key={index} className="child-row-two">
          {Object.values(row).map((el, i) => {
            return (
              <td
                key={i}
                colSpan={i % Object.values(row).length === 0 ? 3 : 1}
                className={
                  'child-row-data' + (concatCls && i === 6 ? concatCls : '')
                }
              >
                {el}
              </td>
            )
          })}
          <td></td>
        </tr>
      )
    })
  }

  const createFootRow = () => (
    <tr className="child-row-three">
      <td colSpan={1} className="child-row-foot"></td>
      <td className="child-row-foot">Total</td>
      <td colSpan={5} className="child-row-foot"></td>
      <td className="child-row-foot">$25,381.72</td>
      <td className="child-row-foot">$35,381.72</td>
      <td className="child-row-foot">$35,381.72</td>
      <td className="child-row-foot"></td>
    </tr>
  )

  const [rowId, setRowId] = useState([-1])
  const [rowToBeExpanded, setRowToBeExpanded] = useState<number>()
  const handleRowToggling = (id: number) => {
    setRowToBeExpanded(id)
    let shownState = rowId.slice()
    const index = shownState.indexOf(id)
    if (index >= 0) {
      shownState.splice(index, 1)
      setRowId(shownState)
    } else {
      shownState = []
      shownState.push(id)
      setRowId(shownState)
    }
  }
  // const rejectFunction = () => {
  //   approval?.setReject(true)
  // }

  //   const selectedColor = (
  //     e: React.MouseEvent<HTMLTableRowElement, MouseEvent>    ) => {
  //     for (const elem of (document as Document).getElementsByClassName(
  //         'active-selected'
  //     )) {
  //         elem.classList.remove('active-selected')
  //     }
  //     for (const elem of (document as Document).getElementsByClassName(
  //         'table-row'
  //     )) {
  //         elem.classList.remove('table-row')
  //     }
  //     e.currentTarget.classList.add('active-selected')
  // }

  const AuditTrailLength = tableData.filter((data) => data.status === 'Approved').length 


  return (
    <React.Fragment>
      <Wrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                <th>
                  <StyledRectangle id="test123"></StyledRectangle>
                </th>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps()}
                    key={index}
                    className={
                      column.id === sorting.key ? ' sortBackground' : ''
                    }
                    onClick={() =>
                      handleColumnSort(column.id, !sorting.isAscending)
                    }
                  >
                    <ThWrapper>
                      {/* <StyledRectangle id="remove_border"></StyledRectangle> */}
                      <ThContentWrapper>
                        {column.render('Header')}
                      </ThContentWrapper>
                      {headerGroup.headers.length !== index && (
                        <>
                          {auditTrail && (
                            <span>
                              <IconWrapper>
                                <SvgChevronUpSort
                                  fillColor={
                                    sorting.isAscending &&
                                    column.id === sorting.key
                                      ? COLORS.UI.Quaternary
                                      : COLORS.Background.Neutral40
                                  }
                                />
                              </IconWrapper>
                              <SvgWrapper>
                                <SvgChevronDownSort
                                  fillColor={
                                    !sorting.isAscending &&
                                    column.id === sorting.key
                                      ? COLORS.UI.Quaternary
                                      : COLORS.Background.Neutral40
                                  }
                                />
                              </SvgWrapper>
                            </span>
                          )}
                          <StyledRectangle></StyledRectangle>
                        </>
                      )}
                    </ThWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              const id = Number(row.id)
              return (
                <>
                  {columnValue?.includes('Account Name ') ? (
                    row.original.Status.props.ClientType !== 'Cancelled' ? (
                      row.original.Status.props.ClientType !== 'Removed' ? (
                        <Fragment key={i}>
                          <tr
                            {...row.getRowProps()}
                            className={
                              rowId?.includes(id)
                                ? 'toggled table-row'
                                : 'table-row'
                            }
                            onClick={(e) => {
                              handleRowToggling(id)
                              if (onRowClick) {
                                onRowClick(row.original)
                              }
                            }}
                          >
                            <td>
                              {rowId.includes(Number(row.id)) ? (
                                <SvgChevronUpMedium
                                  fillColor={COLORS.UI.BackgroundStrong}
                                />
                              ) : (
                                <SvgChevronDownMedium
                                  fillColor={COLORS.UI.Quaternary}
                                />
                              )}
                            </td>
                            {row.cells.map((cell, index) => {
                              return (
                                <td {...cell.getCellProps()} key={index}>
                                    {cell.render('Cell')}
                                </td>
                              )
                            })}
                          </tr>
                          {rowId?.includes(id) && (
                            <tr>
                              <td colSpan={10} id="subtd">
                                <table id="subtable">
                                  <thead className="child-row-two" id="sub-row">
                                    <td className="first-cell">
                                      <StyledRectangle className="head-border"></StyledRectangle>
                                    </td>
                                    {subTableHead.map((head, key) => (
                                      <td
                                        // colSpan={key === 0 ? 3 : 1}
                                        className="child-row-head"
                                        key={key}
                                      >
                                        <ThWrapper className="th-wrapper">
                                          <ThContentWrapper>
                                            {head}
                                          </ThContentWrapper>
                                          {/* {childTableHeads.length - 1 !== key && (
                                  <StyledRectangle></StyledRectangle>
                                )} */}

                                          <StyledRectangle></StyledRectangle>
                                        </ThWrapper>
                                      </td>
                                    ))}
                                  </thead>

                                  {/* <tr className="child-row-head">
                        <td></td>
                        <td>{subTableData[i].Field}</td>
                        <td>{subTableData[i].OldValue}</td>
                        <td>{subTableData[i].NewValue}</td>
                      </tr> */}

                                  {subTableData[
                                    subTableData
                                      .map((obj: any) => obj.mainData)
                                      .findIndex(
                                        (ob1: any) => ob1.id === row.original.id
                                      )
                                  ].subData.map(
                                    (subCell: any, subIndex: number) => {
                                      return (
                                        <Fragment key={subIndex}>
                                          <tr>
                                            <td></td>
                                            {Object.values(subCell).map(
                                              (
                                                childCell: any,
                                                childIndex: number
                                              ) => {
                                                return (
                                                  <td key={childIndex}>
                                                    {childCell}
                                                  </td>
                                                )
                                              }
                                            )}
                                          </tr>
                                        </Fragment>
                                      )
                                    }
                                  )}
                                </table>

                                <PendingWrapper>
                                  <NoteWrapper id="testingg">
                                    <SubWrapper>
                                      <WDTableLabel>{'Notes'}</WDTableLabel>
                                    </SubWrapper>
                                    <SubWrapper>
                                      <WDStyledWidgetSubHeading>
                                        {row.original.Notes}
                                      </WDStyledWidgetSubHeading>
                                    </SubWrapper>
                                  </NoteWrapper>
                                  {!columnValue?.includes('Account Name ') &&
                                    row.original.Status?.props.ClientType.includes(
                                      'Pending'
                                    ) && (
                                      <ButtonRow>
                                        <PrimaryButtonWrapper>
                                          <WDStyledSaveButton
                                            onClick={() =>
                                              cancelFunction(row.original)
                                            }
                                          >
                                            <WDStyledCancelButtonLabel>
                                              {'Cancel the Request'}
                                            </WDStyledCancelButtonLabel>
                                          </WDStyledSaveButton>
                                        </PrimaryButtonWrapper>
                                      </ButtonRow>
                                    )}
                                  {row.original.Status?.props &&
                                    row.original.Section &&
                                    row.original.Status?.props.ClientType &&
                                    row.original.Status?.props.ClientType.includes(
                                      'Pending'
                                    ) && (
                                      <ButtonRow>
                                        <SecondaryButtonWrapper>
                                          <WDStyledCancelButton
                                            onClick={() => {
                                              approval?.setReject(true)
                                              if (onRejectClick) {
                                                onRejectClick(row.original)
                                              }
                                            }}
                                          >
                                            <WDStyledCancelButtonLabel>
                                              {'Reject'}
                                            </WDStyledCancelButtonLabel>
                                          </WDStyledCancelButton>
                                        </SecondaryButtonWrapper>
                                        <PrimaryButtonWrapper>
                                          <WDStyledSaveButton
                                            onClick={() => {
                                              approval?.setApproved(true)
                                              if (onClick) {
                                                onClick(row.original)
                                              }
                                            }}
                                          >
                                            <WDStyledCancelButtonLabel>
                                              {'Approve'}
                                            </WDStyledCancelButtonLabel>
                                          </WDStyledSaveButton>
                                        </PrimaryButtonWrapper>
                                      </ButtonRow>
                                    )}
                                </PendingWrapper>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )
                  ) : (
                    <Fragment key={i}>
                      <tr
                        {...row.getRowProps()}
                        className={
                          rowId?.includes(id)
                            ? 'toggled table-row'
                            : 'table-row'
                        }
                        onClick={(e) => {
                          handleRowToggling(id)
                          if (onRowClick) {
                            onRowClick(row.original)
                          }
                        }}
                      >
                        <td>
                          {rowId.includes(Number(row.id)) ? (
                            <SvgChevronUpMedium
                              fillColor={COLORS.UI.BackgroundStrong}
                            />
                          ) : (
                            <SvgChevronDownMedium
                              fillColor={COLORS.UI.Quaternary}
                            />
                          )}
                        </td>
                        {row.cells.map((cell, index) => {
                          return (
                            <td {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                      {rowId?.includes(id) && (
                        <tr>
                          <td colSpan={10} id="subtd">
                            <table id="subtable">
                              <thead className="child-row-two" id="sub-row">
                                <td className="first-cell">
                                  <StyledRectangle className="head-border"></StyledRectangle>
                                </td>
                                {subTableHead.map((head, key) => (
                                  <td
                                    // colSpan={key === 0 ? 3 : 1}
                                    className="child-row-head"
                                    key={key}
                                  >
                                    <ThWrapper className="th-wrapper">
                                      <ThContentWrapper>
                                        {head}
                                      </ThContentWrapper>
                                      {/* {childTableHeads.length - 1 !== key && (
                                  <StyledRectangle></StyledRectangle>
                                )} */}

                                      <StyledRectangle></StyledRectangle>
                                    </ThWrapper>
                                  </td>
                                ))}
                              </thead>

                              {/* <tr className="child-row-head">
                        <td></td>
                        <td>{subTableData[i].Field}</td>
                        <td>{subTableData[i].OldValue}</td>
                        <td>{subTableData[i].NewValue}</td>
                      </tr> */}

                              {subTableData[
                                subTableData
                                  .map((obj: any) => obj.mainData)
                                  .findIndex(
                                    (ob1: any) => ob1.id === row.original.id
                                  )
                              ].subData.map(
                                (subCell: any, subIndex: number) => {
                                  return (
                                    <Fragment key={subIndex}>
                                      <tr>
                                        <td></td>
                                        {Object.values(subCell).map(
                                          (
                                            childCell: any,
                                            childIndex: number
                                          ) => {
                                            return (
                                              <td key={childIndex}>
                                                {childCell}
                                              </td>
                                            )
                                          }
                                        )}
                                      </tr>
                                    </Fragment>
                                  )
                                }
                              )}
                            </table>

                            <PendingWrapper>
                              <NoteWrapper id="testingg">
                                <SubWrapper>
                                  <WDTableLabel>{'Notes'}</WDTableLabel>
                                </SubWrapper>
                                <SubWrapper>
                                  <WDStyledWidgetSubHeading>
                                    {row.original.Notes}
                                  </WDStyledWidgetSubHeading>
                                </SubWrapper>
                              </NoteWrapper>
                              {!columnValue?.includes('Account Name ') &&
                                row.original.Status?.props.ClientType.includes(
                                  'Pending'
                                ) && (
                                  <ButtonRow>
                                    <PrimaryButtonWrapper>
                                      <WDStyledSaveButton
                                        onClick={() =>
                                          cancelFunction(row.original)
                                        }
                                      >
                                        <WDStyledCancelButtonLabel>
                                          {'Cancel the Request'}
                                        </WDStyledCancelButtonLabel>
                                      </WDStyledSaveButton>
                                    </PrimaryButtonWrapper>
                                  </ButtonRow>
                                )}
                              {row.original.Status?.props &&
                                row.original.Section &&
                                row.original.Status?.props.ClientType &&
                                row.original.Status?.props.ClientType.includes(
                                  'Pending'
                                ) && (
                                  <ButtonRow>
                                    <SecondaryButtonWrapper>
                                      <WDStyledCancelButton
                                        onClick={() => {
                                          approval?.setReject(true)
                                          if (onRejectClick) {
                                            onRejectClick(row.original)
                                          }
                                        }}
                                      >
                                        <WDStyledCancelButtonLabel>
                                          {'Reject'}
                                        </WDStyledCancelButtonLabel>
                                      </WDStyledCancelButton>
                                    </SecondaryButtonWrapper>
                                    <PrimaryButtonWrapper>
                                      <WDStyledSaveButton
                                        onClick={() => {
                                          approval?.setApproved(true)
                                          if (onClick) {
                                            onClick(row.original)
                                          }
                                        }}
                                      >
                                        <WDStyledCancelButtonLabel>
                                          {'Approve'}
                                        </WDStyledCancelButtonLabel>
                                      </WDStyledSaveButton>
                                    </PrimaryButtonWrapper>
                                  </ButtonRow>
                                )}
                            </PendingWrapper>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </>
              )
            })}
          </tbody>
          <tfoot>
            {footerGroups.map(
              (group, index) =>
                footerGroups.length !== index && (
                  <tr {...group.getFooterGroupProps()} key={index}>
                    {group.headers.map((column, i) => {
                      if (group.headers.length - 1 === i) {
                        return (
                          group.getFooterGroupProps().key !==
                            'footerGroup_0' && (
                            <Fragment key={i}>
                              <td {...column.getFooterProps()} key={i}>
                                {column.render('Footer')}
                              </td>
                              <td></td>
                            </Fragment>
                          )
                        )
                      } else {
                        return (
                          group.getFooterGroupProps().key !==
                            'footerGroup_0' && (
                            <td {...column.getFooterProps()} key={i}>
                              {column.render('Footer')}
                            </td>
                          )
                        )
                      }
                    })}
                  </tr>
                )
            )}
          </tfoot>
        </table>
      </Wrapper>
      <PaginationWrapper>
        <>
          {totalDataAvailable !== 0 && (
            <ViewMoreButtonWrapper>
              {pageSize !== tableData.length ? (
                <>
                  {totalDataAvailable !== 0 && pageSize === 10 ? (
                    <WDStyledCancelButton
                      onClick={() => {
                        if (tableData.length !== 0) {
                          tableData.length - pageSize > 10
                            ? setPageSize(pageSize + 10)
                            : setPageSize(
                                pageSize + (tableData.length - pageSize)
                              )
                        }
                      }}
                    >
                      Load more
                    </WDStyledCancelButton>
                  ) : (
                    <>
                      {pageSize >= 10 && (
                        <>
                          <WDStyledCancelButton
                            onClick={() => {
                              if (tableData.length !== 0) {
                                tableData.length - pageSize > 10
                                  ? setPageSize(pageSize + 10)
                                  : setPageSize(
                                      pageSize + (tableData.length - pageSize)
                                    )
                              }
                              console.log(pageSize, 'size2')
                            }}
                          >
                            Load more
                          </WDStyledCancelButton>
                          <Space></Space>
                          {pageSize >= 10 && (
                            <WDStyledCancelButton
                              onClick={() => {
                                setPageSize(pageSize - 10)
                                console.log(pageSize, 'size3')
                              }}
                            >
                              Load less
                            </WDStyledCancelButton>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {pageSize <= 10 ? null : (
                    <WDStyledCancelButton
                      onClick={() => setPageSize(pageSize - 10)}
                    >
                      Load Less
                    </WDStyledCancelButton>
                  )}
                </>
              )}
            </ViewMoreButtonWrapper>
          )}
        </>
        <>
          {totalDataAvailable !== 0 ? (
            <StyledBottomDiv>{`${pageSize} of ${totalDataAvailable}`}</StyledBottomDiv>
          ) : (
            <StyledBottomDiv>No Data Available</StyledBottomDiv>
          )}
        </>
      </PaginationWrapper>
    </React.Fragment>
  )
}

export default GroupingTable
