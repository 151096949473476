export const SvgCheck = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#2E844A" />
      <path
        d="M16.473 8.80657C16.411 8.74409 16.3373 8.69449 16.256 8.66065C16.1748 8.6268 16.0876 8.60938 15.9996 8.60938C15.9116 8.60938 15.8245 8.6268 15.7433 8.66065C15.662 8.69449 15.5883 8.74409 15.5263 8.80657L10.5596 13.7799L8.47297 11.6866C8.40863 11.6244 8.33267 11.5755 8.24943 11.5427C8.16619 11.5099 8.07731 11.4938 7.98786 11.4954C7.8984 11.4969 7.81013 11.5161 7.72808 11.5518C7.64602 11.5874 7.5718 11.6389 7.50964 11.7032C7.44748 11.7676 7.39861 11.8435 7.3658 11.9268C7.333 12.01 7.31691 12.0989 7.31846 12.1884C7.32001 12.2778 7.33916 12.3661 7.37482 12.4481C7.41049 12.5302 7.46196 12.6044 7.52631 12.6666L10.0863 15.2266C10.1483 15.2891 10.222 15.3387 10.3033 15.3725C10.3845 15.4063 10.4716 15.4238 10.5596 15.4238C10.6476 15.4238 10.7348 15.4063 10.816 15.3725C10.8973 15.3387 10.971 15.2891 11.033 15.2266L16.473 9.78657C16.5406 9.72415 16.5946 9.64838 16.6316 9.56404C16.6685 9.47971 16.6876 9.38864 16.6876 9.29657C16.6876 9.20451 16.6685 9.11344 16.6316 9.0291C16.5946 8.94477 16.5406 8.869 16.473 8.80657Z"
        fill="white"
      />
    </svg>
  )
}
