import { MutableRefObject, useEffect, useRef } from 'react'
import Data from '../../wedbushLoader.json'
import { StyledAnimation } from './styles'
import lottie from 'lottie-web'

const WedbushLoader = () => {
  const container = useRef() as MutableRefObject<HTMLDivElement>

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: container?.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Data,
    })
    return () => {
      instance.destroy()
    }
  }, [])

  return (
    <StyledAnimation>
      <div ref={container}></div>
    </StyledAnimation>
  )
}

export default WedbushLoader
