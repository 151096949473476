import styled from 'styled-components'

export interface ImageProps {
  image: string
  alt: string
  width?: string
  height?: string
  background?:string
}

export const StyledImage = styled.img``
