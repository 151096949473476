import { forwardRef } from 'react'
import { WDNewProfileSidebarMenu } from '../../components/ui/WDTypography'
import { InputFieldProps } from './IInputField'
import { InputWrapper, LabelWrap, MandatoryLabel, TextBoxWrap } from './styles'
import { Textbox } from '../../components/customTextBox/Textbox'

// eslint-disable-next-line react/display-name
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
    return (
        <InputWrapper>
            <LabelWrap>
                <WDNewProfileSidebarMenu>{props.label}</WDNewProfileSidebarMenu>
                {props.isMandatory && <MandatoryLabel>{' *'}</MandatoryLabel>}
            </LabelWrap>
            <TextBoxWrap>
                <Textbox
                    placeholder={props.textboxPlaceholder}
                    disabled={props.disabled}
                    defaultValue={props.textboxDefaultValue}
                    value={props.textboxValue}
                    name={props.name}
                    id={props.id}
                    max={props.max}
                    min={props.min}
                    ref={ref}
                    borderColor={props.borderColor}
                    backgroundColor={props.backgroundColor}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                />
            </TextBoxWrap>
        </InputWrapper>
    )
})
