import styled from "styled-components";
import { COLORS } from "../../theme/Colors";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  flex: 0 1 100%;
`;

export const TextBoxWrap = styled.div`
`;

export const LabelWrap = styled.div`
padding: 4px 0px;
display: flex;
`;

export const MandatoryLabel = styled.div`
  color: ${COLORS.Text.Danger};
  margin-left: 4px;
`
