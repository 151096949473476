export const LineHeights = {
  Title: '24px',
  Paragraph: '16px',
  Heading: '32px',
}

export const SPACES = [
  '0px',
  '4px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px',
]
