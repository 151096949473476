import React, {
  useState,
  createContext,
  useEffect,
  Dispatch,
  useContext,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  fetchAddressTypes,
  // fetchCountries,
  // fetchMailingAddress,
  // fetchStates,
  getContext,
} from '../http'
import {
  AddressList,
  AddressSubmissionPayload,
  AddressTypes,
  AddressValidation,
  AddUpdateMailingAddress,
  Countries,
  defaultMailingAddress,
  DeleteAlternateAddress,
  MailingAddressInterface,
  States,
} from './MailingAddressInterface'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import useAxiosProtected from '../http/useAxiosProtected'
import {
  addUpdateMailingAddressMethod,
  fetchCountries,
  fetchMailingAddress,
  fetchStates,
  addressSubmission,
  deleteAlternateAddress,
  fetchAddressValidationDetails,
} from './MailingAddressHttp'
import { AuthContext } from '../AuthContext'

interface SetValue {
  (value: string): void | string
}

interface MailingAddressContextInterface {
  accountID: number | undefined
  mailingAddress: MailingAddressInterface[] | undefined
  mailingAddressCollapse: boolean
  submissionPopUp: boolean
  setSubmissionPopUp: SetAccountDetailsCollapse
  setMailingAddressCollapse: SetMailingAddressCollapse
  addUpdateMailingAddress: AddUpdateMailingAddress[] | undefined
  setAddUpdateMailingAddress: SetAddUpdateMailingAddress
  addressSubmissionPayload: AddressSubmissionPayload | undefined
  setAddressSubmissionPayload: SetAddressSubmissionPayload
  addressSubmissionPayloadData: AddressList[] | undefined
  setAddressSubmissionPayloadData: SetAddressSubmissionPayloadData
  approvalPage: boolean
  rejectChangesModal: boolean
  setApprovalPage: SetMailingAddressCollapse
  setRejectChangesModal: SetMailingAddressCollapse
  addressTypes: AddressTypes[]
  states: States[]
  countries: Countries[]
  addressValidateData: AddressValidation | undefined
  setAddressValidateData: SetAddressValidationFunc
  zipCode: string
  setZipCode: SetZipCode
  addressValidateAlternate: AddressValidation | undefined
  setAddressValidateAlternate: SetAddressValidationFunc
  zipCodeAlternate: string
  setZipCodeAlternate: SetZipCode
  isLoading: boolean
  error: unknown | null | object
  errorMsg: number | undefined
  setErrorMsg: Dispatch<React.SetStateAction<number>>
  deleteAlternateAddressData: DeleteAlternateAddress | undefined
  setDeleteAlternateAddressData: setDeleteAlternateAddressFunc
  deleteErrorMsg: unknown | null | object
  setDeleteErrorMsg: Dispatch<React.SetStateAction<number>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  retrieveMailingAddress: () => void
  setBlsError:SetValue
  blsError:string| undefined
}

interface SetAddressValidationFunc {
  (value: AddressValidation): void | AddressValidation
}

interface SetZipCode {
  (value: string): void | string
}

interface setDeleteAlternateAddressFunc {
  (value: DeleteAlternateAddress): void | DeleteAlternateAddress
}
interface SetMailingAddressCollapse {
  (value: boolean): void | boolean
}

interface SetAddressSubmissionPayload {
  (value: AddressSubmissionPayload): void | AddressSubmissionPayload
}

interface SetAddressSubmissionPayloadData {
  (value: AddressList[]): void | AddressList[]
}

interface SetAddUpdateMailingAddress {
  (value: AddUpdateMailingAddress[]): void | AddUpdateMailingAddress[]
}

interface SetAccountDetailsCollapse {
  (value: boolean): void | boolean
}

export const MailingAddressContext = createContext<
  MailingAddressContextInterface | undefined | null
>(undefined)

interface MailingAddressCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const MailingAddressContextProvider = ({
  children,
}: MailingAddressCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<unknown | null | object>(null)
  const [submissionPopUp, setSubmissionPopUp] = useState(false)
  const [mailingAddress, setMailingAddress] = useState<
    MailingAddressInterface[]
  >(defaultMailingAddress)
  const [addUpdateMailingAddress, setAddUpdateMailingAddress] =
    useState<AddUpdateMailingAddress[]>()
  const [addressSubmissionPayload, setAddressSubmissionPayload] =
    useState<AddressSubmissionPayload>()
  const [addressSubmissionPayloadData, setAddressSubmissionPayloadData] =
    useState<AddressList[]>()
  const [mailingAddressCollapse, setMailingAddressCollapse] = useState(false)
  const [approvalPage, setApprovalPage] = useState(false)
  const [rejectChangesModal, setRejectChangesModal] = useState(false)
  const [addressTypes, setAddressTypes] = useState<AddressTypes[]>([])
  const [accountID, setAccountID] = useState<number>()
  const [states, setStates] = useState<States[]>([])
  const [countries, setCountries] = useState<Countries[]>([])
  const [deleteAlternateAddressData, setDeleteAlternateAddressData] =
    useState<DeleteAlternateAddress>()
  const [errorMsg, setErrorMsg] = useState<number>(100)
  const [deleteErrorMsg, setDeleteErrorMsg] = useState<number>(100)
  const [zipCode, setZipCode] = useState<string>('')
  const [addressValidateData, setAddressValidateData] =
    useState<AddressValidation>()
  const [zipCodeAlternate, setZipCodeAlternate] = useState<string>('')
  const [addressValidateAlternate, setAddressValidateAlternate] =
    useState<AddressValidation>()
    const [blsError,setBlsError]=useState<string>('')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  let accountIdString = searchParams.get('accountId')

  if (accountIdString === undefined || accountIdString === null) {
    accountIdString = '27'
  }
  const accountId = parseInt(accountIdString, 10)

  const approval = useContext(ApprovalContext)
  const authCtx = useContext(AuthContext)

  const clientIdValue: any = approval?.value

  const axiosProtected = useAxiosProtected()

  async function getStatesandCountries() {
    if (clientIdValue !== undefined && clientIdValue !== '') {
      try {
      const statesData = await fetchStates(axiosProtected)
      setStates(statesData)
      const countriesData = await fetchCountries(axiosProtected)
      setCountries(countriesData)
      } catch (error: unknown) {
        setError(error)
      }
    }
  }

  async function retrieveMailingAddress() {
    if (clientIdValue !== undefined && clientIdValue !== '') {
      try {
      const data = await fetchMailingAddress(clientIdValue, axiosProtected)
      setMailingAddress(data)
      setIsLoading(false)      
      } catch (error: unknown) {
        setError(error)
      }
    }
  }
  useEffect(() => {
    if (authCtx?.accessToken) {
      retrieveMailingAddress()
      getStatesandCountries()
    }
  }, [clientIdValue, authCtx?.accessToken])

  useEffect(() => {
    async function addUpdateMailingAddressFunction() {
      try {
        if (addUpdateMailingAddress !== undefined) {
          const mailingAddressData = await addUpdateMailingAddressMethod(
            addUpdateMailingAddress,
            axiosProtected
          )
          setErrorMsg(mailingAddressData)
        }
      } catch (error: unknown) {
        console.error('Error', error)
      }
    }
    if (authCtx?.accessToken) {
      addUpdateMailingAddressFunction()
    }
  }, [addUpdateMailingAddress, authCtx?.accessToken])

  useEffect(() => {
    async function addressSubmissionFunction() {
      try {
        if (addressSubmissionPayload !== undefined) {
          const addressSubmissionResponse = await addressSubmission(
            addressSubmissionPayload,
            axiosProtected
          )
          setErrorMsg(addressSubmissionResponse)
        }
      } catch (error: unknown) {
         console.error('Error', error)
      }
    }

    addressSubmissionFunction()
  }, [addressSubmissionPayload, authCtx?.accessToken])

  useEffect(() => {
    async function deleteAlternateAddressDetails() {
      try {
        if (deleteAlternateAddressData !== undefined) {
          const data = await deleteAlternateAddress(
            deleteAlternateAddressData,
            axiosProtected
          )
          setDeleteErrorMsg(data)
          setBlsError(data?.data)
        }
      } catch (error: unknown) {
        setError(error)
      }
    }
    deleteAlternateAddressDetails()
  }, [deleteAlternateAddressData, authCtx?.accessToken])

  useEffect(() => {
    async function retriveAddressValidation() {
      if (zipCode !== undefined && zipCode !== '' && zipCode.length === 5) {
        try {
          const data = await fetchAddressValidationDetails(
            zipCode,
            axiosProtected
          )
          const AddressValidateData = data
          setAddressValidateData(AddressValidateData)
        } catch (error: unknown) {
          setError(error)
        }
      }
    }
    if (authCtx?.accessToken) {
      retriveAddressValidation()
    }
  }, [zipCode, authCtx?.accessToken])

  useEffect(() => {
    async function retriveAddressValidationAlternate() {
      if (
        zipCodeAlternate !== undefined &&
        zipCodeAlternate !== '' &&
        zipCodeAlternate.length === 5
      ) {
        try {
          const dataAlternate = await fetchAddressValidationDetails(
            zipCodeAlternate,
            axiosProtected
          )
          const AddressValidateAlternate = dataAlternate
          setAddressValidateAlternate(AddressValidateAlternate)
        } catch (error: unknown) {
          setError(error)
        }
      }
    }
    if (authCtx?.accessToken) {
      retriveAddressValidationAlternate()
    }
  }, [zipCodeAlternate, authCtx?.accessToken])

  return (
    <MailingAddressContext.Provider
      value={{
        errorMsg,
        setErrorMsg,
        submissionPopUp,
        setSubmissionPopUp,
        accountID,
        addressTypes,
        mailingAddressCollapse,
        setMailingAddressCollapse,
        addUpdateMailingAddress,
        addressSubmissionPayload,
        setAddressSubmissionPayload,
        addressSubmissionPayloadData,
        setAddressSubmissionPayloadData,
        setAddUpdateMailingAddress,
        approvalPage,
        setApprovalPage,
        rejectChangesModal,
        setRejectChangesModal,
        states,
        countries,
        mailingAddress,
        deleteAlternateAddressData,
        setDeleteAlternateAddressData,
        isLoading,
        error,
        deleteErrorMsg,
        setDeleteErrorMsg,
        addressValidateData,
        setAddressValidateData,
        zipCode,
        setZipCode,
        addressValidateAlternate,
        setAddressValidateAlternate,
        zipCodeAlternate,
        setZipCodeAlternate,
        setIsLoading,
        retrieveMailingAddress,
        setBlsError,
        blsError,
      }}
    >
      {children}
    </MailingAddressContext.Provider>
  )
}
