import styled from 'styled-components';
import { COLORS } from '../../../theme/Colors';

export interface childProps {
  children: React.ReactElement
}

const StyledContainer = styled.div<childProps>`
  display: flex;
  color: ${COLORS.Background.Primary};
  background-color: ${COLORS.Background.NeutralBackgroundWeak};
`

export default StyledContainer
