import styled from 'styled-components'
import { BorderColors, Colors } from '../../shared/styles'
import { TextboxProps } from './ITextbox'
import { COLORS } from '../../theme/Colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 8px;
`
export const StyledError = styled.p`
  display: flex;
  color: red;
`
export const Main = styled.input`
  padding: 12px 16px;
  flex: 0 1 100%;
  width: 100%;
  background: ${(TextboxProps: TextboxProps) =>
    TextboxProps.backgroundColor
      ? TextboxProps.backgroundColor
      : COLORS.Background.Primary};
  border: 1px solid
    ${(TextboxProps: TextboxProps) =>
      TextboxProps.backgroundColor
        ? TextboxProps.borderColor
        : COLORS.Background.Border};
  &:hover {
    border: 1px solid ${COLORS.Background.HighLight};
    color: ${Colors.Gray20};
  }
  &:active {
    border: 1px solid ${COLORS.Background.Primary50};
  }
  &:disabled {
    border: 1px solid ${COLORS.Background.Border};
    color: ${Colors.Gray};
    background-color: ${COLORS.Background.StrongBackground};
    cursor: no-drop;
  }
  &:invalid {
    border: 1px solid #ea001e;
    background: #fef1ee;
  }
  &:focus {
    border: 2px solid ${COLORS.Background.HighLight};
    color: black;
  }
  &:required:valid {
    // border: 2px solid ${COLORS.UI.SuccessBorder};
  }
  box-sizing: border-box;
  border-radius: 8px;
  font-family: SourceSansPro-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height:100%;
  color: ${COLORS.Text.Neutral};
  outline: none;
`

