import { useState, useContext, useEffect } from 'react'
import Checkbox from '../../components/checkbox/Checkbox'
import Divider from '../../components/divider/Divider'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgInvestment } from '../../components/svg/SvgInvestment'
import { Textbox } from '../../components/textbox/Textbox'
import {
  WDStyledCancelButton,
  WDStyledEditButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  WDStyledCancelButtonLabel,
  WDStyledEditButtonLabel,
  WDStyledSectionTitle,
  WDStyledToastLabel,
  WDStyledWidgetSubHeading,
  WDSubHeadingLabel,
} from '../../components/ui/WDTypography'
import { InvestmentContext } from '../../services/investmentContext/InvestmentContext'
import { COLORS } from '../../theme/Colors'
import { DropdownField } from '../dropdownField/DropdownField'
import { Radio } from '../radio/Radio'
import { InvestmentView } from './InvestmentView'
import {
  ButtonRow,
  CheckboxWrapper,
  CompactWrapper,
  DividerWrapper,
  DividerWrapper2,
  DoubleLabelWrapper,
  ExperienceLabelWrapper,
  FlexItem2,
  HeaderRow,
  IconWrapper,
  InnerLabelWrapper,
  InputFieldInlineWrapper,
  LabelWrapper,
  LabelWrapper2,
  MasterWrapper,
  PrimaryButtonWrapper,
  Row,
  Row3,
  SecondaryButtonWrapper,
  SubHeadingWrapper,
  TertiaryButtonWrapper,
  TitleIconWrap,
  TopRow,
  InputFieldLiquidity,
  TextboxWrapper2,
  EditWrapper,
} from './styles'
import { OptionProps } from '../dropdownField/IDropdownField'

import { checkIsP3Account, getLabel, getObjectDifferences } from '../../shared/helper'
import { InvestmentProfileAPI, InvestmentProfileInterface } from '../../services/investmentContext/InvestmentContextInterface'
import { ErrorModal } from '../errorModal/ErrorModal'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'

export const Investment = () => {
  const [investAllocation, setInvestAllocation] = useState<OptionProps[]>([])
  const [investTimeHorizon, setInvestTimeHorizon] = useState<OptionProps[]>([])
  const [dividendandInstPay, setDividendandInstPay] = useState<OptionProps[]>([])
  const investmentFields = useContext(InvestmentContext)
  const accountDetails = useContext(AccountDetailsContext)
  const responseCode = investmentFields?.responseCode
  const investmentDetails = investmentFields?.investmentDetails
  const approval=useContext(ApprovalContext)
  const [riskTolerance, setRiskTolerance] = useState(investmentDetails?.riskTolerance ?? 1)
  const [illiquidInvestment, setIlliquidInvestment] = useState(investmentDetails?.allowIlliquidInvestments as string ?? 'N')


  const collapse = () => {
    investmentFields?.setInvestmentCollapse(
      !investmentFields?.investmentCollapse
    )
  }

  const investmentDetailsTitles = {
    investObjIncome: 'Invesment Objectives Income',
    investObjGrowth: 'Invesment Objectives Growth',
    investObjSpeculation: 'Invesment Objectives Speculation',
    investObjTrading: 'Invesment Objectives Trading',
    riskTolerance: 'Risk Tolerance',
    allowIlliquidInvestments: 'Allow Illiquid Investments',
    investAllocation: 'Investment Allocation',
    investTimeHorizon: 'Investment Time Horizon',
    liqNeedTimeFrm1: 'Liquidity Needs 0 to 5 years',
    liqNeedTimeFrm2: 'Liquidity Needs 5 to 10 years',
    liqNeedTimeFrm3: 'Liquidity Needs over 10 years',
    dividendandInstPay: 'Dividends and Interest Payments'
  }

  const [investmentDetailsFetched, setInvestmentDetailsFetched] = 
     useState<InvestmentProfileAPI>()
 
  
const investmentProfileOld = {
  investObjIncome: investmentDetails?.investObjIncome,
  investObjGrowth: investmentDetails?.investObjGrowth,
  investObjSpeculation: investmentDetails?.investObjSpeculation,
  investObjTrading: investmentDetails?.investObjTrading,
  riskTolerance: investmentDetails?.riskTolerance,
  allowIlliquidInvestments: investmentDetails?.allowIlliquidInvestments,
  investAllocation: investmentDetails?.investAllocationResponse,
  investTimeHorizon: investmentDetails?.investTimeHorizonResponse,
  liqNeedTimeFrm1: investmentDetails?.liqNeedTimeFrm1,
  liqNeedTimeFrm2: investmentDetails?.liqNeedTimeFrm2,
  liqNeedTimeFrm3: investmentDetails?.liqNeedTimeFrm3,
  dividendandInstPay: investmentDetails?.dividendandInstPayResponse,
}
  const [investmentDetailsEdited, setInvestmentDetailsEdited] =
  useState<InvestmentProfileInterface>(investmentProfileOld)
  
  const optionValues: OptionProps[] = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ]

  const setDropDownLists = () => {
    if (investmentDetails?.riskTolerance !== undefined) {
      setRiskTolerance(investmentDetails?.riskTolerance)
      setIlliquidInvestment(
        investmentDetails?.allowIlliquidInvestments as string
      )
    }
    if (investmentDetails?.investAllocation !== undefined) {
      const investAllocationList = investmentDetails?.investAllocation?.map(
        (item) => {
          return {
            value: item.sValue,
            label: item.investAllocation,
          }
        }
      )
      setInvestAllocation(investAllocationList)
    }
    if (investmentDetails?.investTimeHorizon !== undefined) {
       const investTimeHorizonList = investmentDetails?.investTimeHorizon?.map(
        (item) => {
          return {
            value: item.sValue,
            label: item.investTimeHorizon,
          }
        }
      )
      setInvestTimeHorizon(investTimeHorizonList)
    }
    if (investmentDetails?.dividendandInstPay !== undefined) {
       const dividendandInstPayList = investmentDetails?.dividendandInstPay?.map(
        (item) => {
          return {
            value: item.sValue,
            label: item.dividendandInstPay,
          }
        }
      )
      setDividendandInstPay(dividendandInstPayList)
      
    }
  }
  useEffect(() => {
    setInvestmentDetailsFetched({
      investObjIncome: investmentDetails?.investObjIncome,
      investObjGrowth: investmentDetails?.investObjGrowth,
      investObjSpeculation: investmentDetails?.investObjSpeculation,
      investObjTrading: investmentDetails?.investObjTrading,
      riskTolerance: investmentDetails?.riskTolerance,
      allowIlliquidInvestments: investmentDetails?.allowIlliquidInvestments,
      investAllocation: investmentDetails?.investAllocation,
      investAllocationResponse: investmentDetails?.investAllocationResponse,
      investTimeHorizon: investmentDetails?.investTimeHorizon,
      investTimeHorizonResponse: investmentDetails?.investTimeHorizonResponse,
      liqNeedTimeFrm1: investmentDetails?.liqNeedTimeFrm1,
      liqNeedTimeFrm2: investmentDetails?.liqNeedTimeFrm2,
      liqNeedTimeFrm3: investmentDetails?.liqNeedTimeFrm3,
      dividendandInstPay: investmentDetails?.dividendandInstPay,
      dividendandInstPayResponse: investmentDetails?.dividendandInstPayResponse,
    })
    setInvestmentDetailsEdited({
      investObjIncome: investmentDetails?.investObjIncome,
      investObjGrowth: investmentDetails?.investObjGrowth,
      investObjSpeculation: investmentDetails?.investObjSpeculation,
      investObjTrading: investmentDetails?.investObjTrading,
      riskTolerance: investmentDetails?.riskTolerance,
      allowIlliquidInvestments: investmentDetails?.allowIlliquidInvestments,
      investAllocation: investmentDetails?.investAllocationResponse,
      investTimeHorizon: investmentDetails?.investTimeHorizonResponse,
      liqNeedTimeFrm1: investmentDetails?.liqNeedTimeFrm1,
      liqNeedTimeFrm2: investmentDetails?.liqNeedTimeFrm2,
      liqNeedTimeFrm3: investmentDetails?.liqNeedTimeFrm3,
      dividendandInstPay: investmentDetails?.dividendandInstPayResponse,
    })
  setDropDownLists()
  }, [investmentFields?.investmentDetails, investmentDetails])

  const handleDropDownChange = (
    e: string | number | undefined,
    field: string
  ) => {
    if (e !== undefined) {
      if (field === 'Income') {
        investmentDetailsEdited.investObjIncome = Object.values(e)[1]       
      } else if (field === 'Growth') {
        investmentDetailsEdited.investObjGrowth = Object.values(e)[1]
      } else if (field === 'Speculation') {
        investmentDetailsEdited.investObjSpeculation = Object.values(e)[1]
      } else if (field === 'Trading') {
        investmentDetailsEdited.investObjTrading = Object.values(e)[1]
      }
       else if (field === 'Investment Allocation') {
        investmentDetailsEdited.investAllocation = Object.values(e)[1]
      }
       else if (field === 'Investment Time Horizon') {
        investmentDetailsEdited.investTimeHorizon = Object.values(e)[1]
      }
       else if (field === 'Dividends and Interest Payments') {
        investmentDetailsEdited.dividendandInstPay = Object.values(e)[1]
      }
      setInvestmentDetailsEdited({ ...investmentDetailsEdited })
    }
  }

  const handleRadioChange = (e: string, field: string) => {
    if (e !== undefined) {
      if (field === 'Risk Tolerance') {
        investmentDetailsEdited.riskTolerance = e
      } else if (field === 'Illiquid Investments') {
        investmentDetailsEdited.allowIlliquidInvestments = e
      }
      setInvestmentDetailsEdited({ ...investmentDetailsEdited })
    }
  }

  const handleChange = (e: string | undefined, field: string) => {
    if (e !== undefined) {
      if (field === 'TimeFirm1') {
        investmentDetailsEdited.liqNeedTimeFrm1 = e
      } else if (field === 'TimeFirm2') {
        investmentDetailsEdited.liqNeedTimeFrm2 = e
      } else if (field === 'TimeFirm3') {
        investmentDetailsEdited.liqNeedTimeFrm3 = e
      }
      setInvestmentDetailsEdited({ ...investmentDetailsEdited })
    }
  }
  
  const saveFunction = () => {
    const differences = getObjectDifferences<InvestmentProfileInterface>(
      investmentProfileOld, investmentDetailsEdited, investmentDetailsTitles)
    investmentFields?.setSubmissionTableData(differences)
    //console.log('---Submitted values', differences)
    investmentFields?.setInvestmentModal(true)
    investmentFields?.setInvestmentProfileData(investmentDetailsEdited)
  }
  
  const editFunction = () => {
    approval?.setIsActiveInv(!approval?.isActiveInv)
    investmentFields?.setInvestmentCollapse(true)
  }


  return (
    <MasterWrapper id="investment-profile">
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              <SvgInvestment />
            </IconWrapper>
            <WDStyledSectionTitle>{'Investment Profile'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <TertiaryButtonWrapper>
          {!approval?.isActiveInv && (
             <>
             { !approval?.isActiveAct && !approval?.isActiveAd && !approval?.isActiveAl && !checkIsP3Account(accountDetails?.accountDetails?.system as string) &&(
            <WDStyledEditButton onClick={() => editFunction()}>
              <WDStyledEditButtonLabel> {'Edit'}</WDStyledEditButtonLabel>
            </WDStyledEditButton>
             )}
             </>
          )}
          <EditWrapper onClick={() => collapse()}>
            {investmentFields?.investmentCollapse && <SvgChevronUp />}
            {!investmentFields?.investmentCollapse && <SvgChevronDown />}
          </EditWrapper>
        </TertiaryButtonWrapper>
      </TopRow>
      {investmentFields?.investmentCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}
      {/* Edit Mode */}
      {investmentFields?.investmentCollapse && (
        <>
          {approval?.isActiveInv && (
            <>
              <SubHeadingWrapper>
                <WDSubHeadingLabel>
                  {'Investment Objective(s)'}
                </WDSubHeadingLabel>
                <LabelWrapper>
                  <WDStyledToastLabel>
                    {
                      'Choose at least one objective. If choosing multiple, please rank in order of priority.'
                    }
                  </WDStyledToastLabel>
                </LabelWrapper>
              </SubHeadingWrapper>
              <Row>
                <InputFieldInlineWrapper>
                  <CompactWrapper id="test">
                    <DropdownField
                      label={'Income'}
                      options={optionValues}
                      defaultValue={{
                        value: investmentDetails?.investObjIncome,
                        label: investmentDetailsFetched?.investObjIncome,
                      }}
                      onChange={(e) => handleDropDownChange(e, 'Income')}
                      disabled={!approval?.isActiveInv}
                      borderColor={'#CBD5E1'}
                    />
                  </CompactWrapper>
                  <DoubleLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {'Emphasis on Investment that generate income'}
                    </WDStyledWidgetSubHeading>
                  </DoubleLabelWrapper>
                </InputFieldInlineWrapper>
              </Row>
              <Row>
                <InputFieldInlineWrapper>
                  <CompactWrapper>
                    <DropdownField
                      label={'Growth'}
                      options={optionValues}
                      defaultValue={{
                        value: investmentDetails?.investObjGrowth,
                        label: investmentDetailsFetched?.investObjGrowth,
                      }}
                      onChange={(e) => handleDropDownChange(e, 'Growth')}
                      disabled={!approval?.isActiveInv}
                      borderColor={'#CBD5E1'}
                    />
                  </CompactWrapper>
                  <DoubleLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {
                        'Emphasis on Investment more likely to appreciate in principal rather than generate income'
                      }
                    </WDStyledWidgetSubHeading>
                  </DoubleLabelWrapper>
                </InputFieldInlineWrapper>
              </Row>
              <Row>
                <InputFieldInlineWrapper>
                  <CompactWrapper>
                    <DropdownField
                      label={'Speculation'}
                      options={optionValues}
                      defaultValue={{
                        value: investmentDetails?.investObjSpeculation,
                        label: investmentDetailsFetched?.investObjSpeculation,
                      }}
                      onChange={(e) => handleDropDownChange(e, 'Speculation')}
                      disabled={!approval?.isActiveInv}
                      borderColor={'#CBD5E1'}
                    />
                  </CompactWrapper>
                  <DoubleLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {
                        'Emphasis on potential for significant appreciation; willing to accept a high risk for loss of principal.'
                      }
                    </WDStyledWidgetSubHeading>
                  </DoubleLabelWrapper>
                </InputFieldInlineWrapper>
              </Row>
              <Row>
                <InputFieldInlineWrapper>
                  <CompactWrapper>
                    <DropdownField
                      label={'Trading'}
                      options={optionValues}
                      defaultValue={{
                        value: investmentDetails?.investObjTrading,
                        label: investmentDetailsFetched?.investObjTrading,
                      }}
                      onChange={(e) => handleDropDownChange(e, 'Trading')}
                      disabled={!approval?.isActiveInv}
                      borderColor={'#CBD5E1'}
                    />
                  </CompactWrapper>
                  <DoubleLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {
                        'Seeks to take advantage of short term trading opportunities. High turnover, high risk.'
                      }
                    </WDStyledWidgetSubHeading>
                  </DoubleLabelWrapper>
                </InputFieldInlineWrapper>
              </Row>
              <>
                <SubHeadingWrapper>
                  <WDSubHeadingLabel>{'Risk Tolerance'}</WDSubHeadingLabel>
                  <LabelWrapper>
                    <WDStyledToastLabel>{'Choose one.'}</WDStyledToastLabel>
                  </LabelWrapper>
                </SubHeadingWrapper>
                <SubHeadingWrapper>
                  <Radio
                    label="Conservative"
                    label2="I want to preserve my initial principal in this account, with minimal risk, even if it means this account does not generate significant income or returns and may not keep pace with inflation."
                    onChange={(e) => handleRadioChange(e, 'Risk Tolerance')}
                    name={'riskToleranceValue'}
                    disabled={!approval?.isActiveInv}
                    selectedValue={riskTolerance as string}
                    value={'1'}
                    onClick={() => setRiskTolerance('1')}
                  />
                </SubHeadingWrapper>
                <SubHeadingWrapper>
                  <Radio
                    label="Moderate"
                    label2="I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns, and understand I could lose a portion of the money invested."
                    onChange={(e) => handleRadioChange(e, 'Risk Tolerance')}
                    name={'riskToleranceValue'}
                    disabled={!approval?.isActiveInv}
                    selectedValue={riskTolerance as string}
                    value={'2'}
                    onClick={() => setRiskTolerance('2')}
                  />
                </SubHeadingWrapper>
                <SubHeadingWrapper>
                  <Radio
                    label="Aggressive"
                    label2="I am willing to accept maximum risk to my initial principal  to aggresively seek maximum  returns, and understand I could lose all, or  almost all, of the money invested."
                    onChange={(e) => handleRadioChange(e, 'Risk Tolerance')}
                    name={'riskToleranceValue'}
                    disabled={!approval?.isActiveInv}
                    selectedValue={riskTolerance as string}
                    value={'3'}
                    onClick={() => setRiskTolerance('3')}
                  />
                </SubHeadingWrapper>
                <SubHeadingWrapper>
                  <WDStyledWidgetSubHeading>
                    {
                      'Do you wish to allow illiquid investments in this account.'
                    }
                  </WDStyledWidgetSubHeading>
                </SubHeadingWrapper>
                <Row3>
                  <Radio
                    label2="Yes"
                    onChange={(e) =>
                      handleRadioChange(e, 'Illiquid Investments')
                    }
                    name={'illiquidInvestment'}
                    disabled={!approval?.isActiveInv}
                    selectedValue={illiquidInvestment}
                    value={'Y'}
                    onClick={() => setIlliquidInvestment('Y')}
                  />
                  <Radio
                    label2="No"
                    onChange={(e) =>
                      handleRadioChange(e, 'Illiquid Investments')
                    }
                    name={'illiquidInvestment'}
                    disabled={!approval?.isActiveInv}
                    selectedValue={illiquidInvestment}
                    value={'N'}
                    onClick={() => setIlliquidInvestment('N')}
                  />
                </Row3>
              </>
              <DividerWrapper2>
                <Divider
                  bgColor={COLORS.Background.Border}
                  horizontal={true}
                  thickness="1px"
                />
              </DividerWrapper2>
             
              <Row>
                <FlexItem2>
                  <DropdownField
                    label={'Investment Allocation'}
                    options={investAllocation}
                    defaultValue={{
                      value: investmentDetailsFetched?.investAllocationResponse,
                      label: getLabel(investAllocation, investmentDetailsFetched?.investAllocationResponse as string),
                    }}
                    onChange={(e) =>
                      handleDropDownChange(e, 'Investment Allocation')
                    }
                    disabled={!approval?.isActiveInv}
                  />
                </FlexItem2>
               
                <FlexItem2>
                  <DropdownField
                    label={'Investment Time Horizon'}
                    options={investTimeHorizon}
                    defaultValue={{
                      value: investmentDetailsFetched?.investTimeHorizonResponse,
                      label: getLabel(investTimeHorizon, investmentDetailsFetched?.investTimeHorizonResponse as string),
                    }}
                    onChange={(e) =>
                      handleDropDownChange(e, 'Investment Time Horizon')
                    }
                    disabled={!approval?.isActiveInv}
                  />
                </FlexItem2>
              </Row>
              <>
                <SubHeadingWrapper>
                  <WDSubHeadingLabel>{'Liquidity Needs'}</WDSubHeadingLabel>
                  <LabelWrapper>
                    <WDStyledToastLabel>
                      {'(percent of portfolio you anticipate withdrawing)'}
                    </WDStyledToastLabel>
                  </LabelWrapper>
                </SubHeadingWrapper>
                <InputFieldLiquidity>
                  <WDStyledWidgetSubHeading>{'%'}</WDStyledWidgetSubHeading>
                </InputFieldLiquidity>
                <LabelWrapper2>
                  <ExperienceLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {'0 to 5 years'}
                    </WDStyledWidgetSubHeading>
                  </ExperienceLabelWrapper>
                  <TextboxWrapper2>
                    <Textbox
                      placeholder="%"
                      defaultValue={investmentDetailsFetched?.liqNeedTimeFrm1}
                      onChange={(value) => handleChange(value, 'TimeFirm1')}
                    />
                  </TextboxWrapper2>
                </LabelWrapper2>
                <LabelWrapper2>
                  <ExperienceLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {'5 to 10 years'}
                    </WDStyledWidgetSubHeading>
                  </ExperienceLabelWrapper>
                  <TextboxWrapper2>
                    <Textbox
                      placeholder="%"
                      defaultValue={investmentDetailsFetched?.liqNeedTimeFrm2}
                      onChange={(value) => handleChange(value, 'TimeFirm2')}
                    />
                  </TextboxWrapper2>
                </LabelWrapper2>
                <LabelWrapper2>
                  <ExperienceLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {'Over 10 years'}
                    </WDStyledWidgetSubHeading>
                  </ExperienceLabelWrapper>
                  <TextboxWrapper2>
                    <Textbox
                      placeholder="%"
                      defaultValue={investmentDetailsFetched?.liqNeedTimeFrm3}
                      onChange={(value) => handleChange(value, 'TimeFirm3')}
                    />
                  </TextboxWrapper2>
                </LabelWrapper2>
                <LabelWrapper2>
                  <CheckboxWrapper>
                    <Checkbox />
                  </CheckboxWrapper>
                  <InnerLabelWrapper>
                    <WDStyledWidgetSubHeading>
                      {'Unknown/Not applicable'}
                    </WDStyledWidgetSubHeading>
                  </InnerLabelWrapper>
                </LabelWrapper2>
              </>
              <>
                <DividerWrapper2>
                  <Divider
                    bgColor={COLORS.Background.Border}
                    horizontal={true}
                    thickness="1px"
                  />
                </DividerWrapper2>
                
                <Row>
                  <FlexItem2>
                    <DropdownField
                      label={'Dividends and Interest Payments'}
                      options={dividendandInstPay}
                      defaultValue={{
                        value:getLabel(dividendandInstPay, investmentDetailsFetched?.dividendandInstPayResponse as string),
                        label: getLabel(dividendandInstPay, investmentDetailsFetched?.dividendandInstPayResponse as string),
                      }}
                      onChange={(e) =>
                        handleDropDownChange(
                          e,
                          'Dividends and Interest Payments'
                        )
                      }
                      disabled={!approval?.isActiveInv}
                    />
                  </FlexItem2>
                </Row>
              </>
              <DividerWrapper2>
                <Divider
                  bgColor={COLORS.Background.Border}
                  horizontal={true}
                  thickness="1px"
                />
              </DividerWrapper2>
              <ButtonRow>
                <SecondaryButtonWrapper>
                  <WDStyledCancelButton onClick={() => approval?.setIsActiveInv(!approval?.isActiveInv)}>
                    <WDStyledCancelButtonLabel>
                      {'Cancel'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledCancelButton>
                </SecondaryButtonWrapper>
                <PrimaryButtonWrapper>
                  <WDStyledSaveButton onClick={() => saveFunction()}>
                    <WDStyledCancelButtonLabel>
                      {'Save'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledSaveButton>
                </PrimaryButtonWrapper>
              </ButtonRow>
            </>
          )}
        </>
      )}
       {responseCode === undefined || responseCode === 100 ? null : responseCode === 200 ? (
          <ErrorModal
            success={200}
            onClick={() => {
              investmentFields?.setResponseCode(100)
              window.location.reload()
            }}
            successMsg={
              'This request has been submitted for review and approval.'
            }
          />
        ) : (
          <ErrorModal
            onClick={() => {
              investmentFields?.setResponseCode(100)
              // window.location.reload()
            }}
            success={500}
          />
        )}
      {/* Read only Mode */}
      {investmentFields?.investmentCollapse && (
        <>{!approval?.isActiveInv && <InvestmentView />}</>
      )}
    </MasterWrapper>
  )
}
