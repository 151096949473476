import './style.css'
import { useEffect, useRef, useState } from 'react'
import { NoteWrapper } from './styles'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { allColors } from '../../theme/Colors'

type NotesProps = {
  onChange: (value: string) => void
  note?: string
}

const Notes = ({ onChange, note }: NotesProps) => {
  // const [notes, setNotes] = useState<string>('')
  const [notes, setNotes] = useState<string>(note as string)
  const editorRef = useRef<ReactQuill | null>(null)

  useEffect(() => {
    if (note === '<p><br></p>' || note === '') {
      setNotes(note as string)
    }
    return () => {
      //  setNotes('')
      setNotes(note as string)
    }
  }, [note])

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        {
          color: allColors,
        },
      ],
      [
        {
          background: allColors,
        },
      ],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'background',
    'align',
    'size',
    'font',
  ]

  const handleChange = (
    content: string,
    delta: any,
    source: any,
    editor: any
  ) => {
    setNotes(content)
    const plainTextContent = editorRef?.current
      ?.getEditor()
      ?.getText()
      ?.replace(/\n/g, '')
    onChange(plainTextContent as string) // Fix: Change 'true' to 'false'
  }

  return (
    <NoteWrapper className={'create-note'}>
      <ReactQuill
        theme="snow"
        ref={editorRef}
        placeholder="Take a note"
        value={notes}
        modules={modules}
        formats={formats}
        onChange={handleChange}
      />
    </NoteWrapper>
  )
}

export default Notes
