import { AxiosInstance } from "axios"
import { URLs } from "../httpConfiguration"
import { InvestmentProfileSubmitInterface } from "./InvestmentContextInterface"

export async function fetchInvestmentProfile(accountNumber: string,
    axiosProtected: AxiosInstance
  ) {
    const url = URLs.GET_INVESTMENT_DETAILS
    const payload = {
      accountNumber: accountNumber,
    }
    const response = await axiosProtected.post(url, payload)
    const data = response.data
    return data
}

export async function updateInvestmentProfile(
  updateDetailsPayload: InvestmentProfileSubmitInterface,
  axiosProtected: AxiosInstance
) {
  const url = URLs.UPDATE_INVESTMENT_DETAILS
  const response = await axiosProtected.post(url, updateDetailsPayload )
  const data = response.status
  return data
}