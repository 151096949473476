import React,{useContext,useState,useEffect} from 'react'
import { ModalWrapper } from '../addressChangePopup/styles'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
} from '../../components/ui/WDTypography'
import { WDStyledCancelButton, WDStyledSaveButton } from '../../components/ui/WDButtons'
import { ButtonRow, CloseWrapper, ContactPopupWrapper, DividerWrapper, DividerWrapper2, HeaderRow, NotesWrapper, PrimaryButtonWrapper, SecondaryButtonWrapper, StyledTableContainer, SubHeadingWrapper } from './styles'
import { SvgCancel } from '../../components/svg/SvgCancel'
import Divider from '../../components/divider/Divider'
import Table from '../../components/table/Table'
import { InputField } from '../inputField/InputField'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AccountDetailsFieldValues, AccountDetailsUpdatedValues, ContactInfo, EmailInfo, TableData } from '../../services/accountDetailsContext/AccountDetailsInterface'
import { tableData } from './../../components/dummyTableData';

const AccountDetailsSubmissionPage = () => {

  const accountDetailsValues = useContext(AccountDetailsContext)
  const accountDetails = accountDetailsValues?.accountDetails
  const [businessPhone, setBusinessPhone] = useState<ContactInfo[]>()
  const [fax, setFax] = useState<ContactInfo[]>()
  const [homePhone, setHomePhone] = useState<ContactInfo[]>()
  const [mobilePhone, setMobilePhone] = useState<ContactInfo[]>()
  const [work, setWork] = useState<EmailInfo>()
  const [personal, setPersonal] = useState<EmailInfo>()
  const [phone, setPhone] = useState<string>()
  const [phoneTypeSelected, setPhoneTypeSelected] = useState('HomePhone')
  const [updatedData, setUpdatedData] = useState<AccountDetailsUpdatedValues>()
  const [notes, setNotes] = useState('')
  const [mapped,setMapped]=useState<any[]>()


  // useEffect(() => {
  //   setUpdatedData(accountDetailsValues?.accountDetailsUpdatedData)
  // }, [])
  // console.log("Update valuess",accountDetailsValues?.accountDetailsUpdatedData)

  // const [accountDetailsTestValues, setAccountDetailsTestValues] = useState({
  //   accountType: `${accountDetails?.accountType}`,
  //   fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
  //   accountNickname: `${accountDetails?.accountNickName}`,
  //   advisorName: `${accountDetails?.advisorName}`,
  //   repCode: `${accountDetails?.repCode}`,
  //   accountClassId: `${accountDetails?.accountClassId}`,
  //   status: `Active`,
  //   accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
  //   homePhoneType: 'HomePhone',
  //   homePhoneNumber: homePhone?.[0]?.phoneNumber
  //     ? homePhone?.[0]?.phoneNumber
  //     : '',
  //   mobilePhoneType: 'MobilePhone',
  //   mobilePhoneNumber: mobilePhone?.[0]?.phoneNumber
  //     ? mobilePhone?.[0]?.phoneNumber
  //     : '',
  //   businessPhoneType: 'BusinessPhone',
  //   businessPhoneNumber: businessPhone?.[0]?.phoneNumber
  //     ? businessPhone?.[0]?.phoneNumber
  //     : '',
  //   fax: 'Fax',
  //   faxNumber: fax?.[0]?.phoneNumber ? fax?.[0]?.phoneNumber : '',
  //   emailAddress: work?.emailAddress ? work?.emailAddress : '',
  //   emailAddress2: personal?.emailAddress ? personal?.emailAddress : '',
  // })

  // useEffect(() => {
  //   setAccountDetailsTestValues({
  //     accountType: `${accountDetails?.accountType}`,
  //     fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
  //     accountNickname: `${accountDetails?.accountNickName}`,
  //     advisorName: `${accountDetails?.advisorName}`,
  //     repCode: `${accountDetails?.repCode}`,
  //     accountClassId: `${accountDetails?.accountClassId}`,
  //     status: `Active`,
  //     accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
  //     homePhoneType: 'HomePhone',
  //     homePhoneNumber: homePhone?.[0]?.phoneNumber
  //       ? homePhone?.[0]?.phoneNumber
  //       : '',
  //     mobilePhoneType: 'MobilePhone',
  //     mobilePhoneNumber: mobilePhone?.[0]?.phoneNumber
  //       ? mobilePhone?.[0]?.phoneNumber
  //       : '',
  //     businessPhoneType: 'BusinessPhone',
  //     businessPhoneNumber: businessPhone?.[0]?.phoneNumber
  //       ? businessPhone?.[0]?.phoneNumber
  //       : '',
  //     fax: 'Fax',
  //     faxNumber: fax?.[0]?.phoneNumber ? fax?.[0]?.phoneNumber : '',
  //     emailAddress: work?.emailAddress ? work?.emailAddress : '',
  //     emailAddress2: personal?.emailAddress ? personal?.emailAddress : '',
  //   })
  // }, [accountDetails?.accountClassId, accountDetails?.accountNickName, accountDetails?.accountOpeningDate, accountDetails?.accountType, accountDetails?.advisorName, accountDetails?.fullTitleOfAccount, accountDetails?.repCode, businessPhone, fax, homePhone, mobilePhone, personal?.emailAddress, work?.emailAddress])

  // const [accountDetailsFieldValues, setAccountDetailsFieldValues] = useState({
  //   accountType: `${accountDetails?.accountType}`,
  //   fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
  //   accountNickname: `${accountDetails?.accountNickName}`,
  //   advisorName: `${accountDetails?.advisorName}`,
  //   repCode: `${accountDetails?.repCode}`,
  //   accountClassId: `${accountDetails?.accountClassId}`,
  //   status: `Active`,
  //   accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
  //   homePhoneType: 'HomePhone',
  //   homePhoneNumber: homePhone?.[0]?.phoneNumber
  //     ? homePhone?.[0]?.phoneNumber
  //     : '',
  //   mobilePhoneType: 'MobilePhone',
  //   mobilePhoneNumber: mobilePhone?.[0]?.phoneNumber
  //     ? mobilePhone?.[0]?.phoneNumber
  //     : '',
  //   businessPhoneType: 'BusinessPhone',
  //   businessPhoneNumber: businessPhone?.[0]?.phoneNumber
  //     ? businessPhone?.[0]?.phoneNumber
  //     : '',
  //   fax: 'Fax',
  //   faxNumber: fax?.[0]?.phoneNumber ? fax?.[0]?.phoneNumber : '',
  //   emailAddress: work?.emailAddress ? work?.emailAddress : '',
  //   emailAddress2: personal?.emailAddress ? personal?.emailAddress : '',
  // })

  // useEffect(() => {
  //   setAccountDetailsFieldValues({
  //     accountType: `${accountDetails?.accountType}`,
  //     fullTitleOfAccount: `${accountDetails?.fullTitleOfAccount}`,
  //     accountNickname: `${accountDetails?.accountNickName}`,
  //     advisorName: `${accountDetails?.advisorName}`,
  //     repCode: `${accountDetails?.repCode}`,
  //     accountClassId: `${accountDetails?.accountClassId}`,
  //     status: `Active`,
  //     accountOpeningDate: accountDetails?.accountOpeningDate?.slice(0, 10),
  //     homePhoneType: 'HomePhone',
  //     homePhoneNumber: homePhone?.[0]?.phoneNumber
  //       ? homePhone?.[0]?.phoneNumber
  //       : '',
  //     mobilePhoneType: 'MobilePhone',
  //     mobilePhoneNumber: mobilePhone?.[0]?.phoneNumber
  //       ? mobilePhone?.[0]?.phoneNumber
  //       : '',
  //     businessPhoneType: 'BusinessPhone',
  //     businessPhoneNumber: businessPhone?.[0]?.phoneNumber
  //       ? businessPhone?.[0]?.phoneNumber
  //       : '',
  //     fax: 'Fax',
  //     faxNumber: fax?.[0]?.phoneNumber ? fax?.[0]?.phoneNumber : '',
  //     emailAddress: work?.emailAddress ? work?.emailAddress : '',
  //     emailAddress2: personal?.emailAddress ? personal?.emailAddress : '',
  //   })
  // }, [accountDetails?.accountClassId, accountDetails?.accountNickName, accountDetails?.accountOpeningDate, accountDetails?.accountType, accountDetails?.advisorName, accountDetails?.fullTitleOfAccount, accountDetails?.repCode, businessPhone, fax, homePhone, mobilePhone, personal?.emailAddress, work?.emailAddress])

  // const accountDetailsFieldTitles = {
  //   accountType: 'Account Type',
  //   fullTitleOfAccount: 'Full Title of Account',
  //   accountNickname: 'Account Nickname',
  //   advisorName: 'Advisor Name',
  //   repCode: 'Rep Code',
  //   accountClassId: 'Account Class ID',
  //   status: 'Status',
  //   accountOpeningDate: 'Account Opening Date',
  //   homePhoneType: 'Home Phone',
  //   homePhoneNumber: `Home Phone Number`,
  //   mobilePhoneType: 'MobilePhone',
  //   mobilePhoneNumber: `Mobile Phone Number`,
  //   businessPhoneType: 'BusinessPhone',
  //   businessPhoneNumber: `Business Phone Number`,
  //   fax: 'Fax',
  //   faxNumber: `Fax`,
  //   emailAddress: `Email Address`,
  //   emailAddress2: `Email Address 1`,
  // }

  // // const tableData = (
  // //   oldValue: any,
  // //   newValue: any,
  // //   accountDetailsFieldTitles: any
  // // ) => {
  // //   const result = []

  // //   for (const key in oldValue) {
  // //     if (Object.prototype.hasOwnProperty.call(oldValue, key)) {
  // //       result.push({
  // //         field: accountDetailsFieldTitles[key],
  // //         old: oldValue[key],
  // //         new: newValue[key],
  // //       })
  // //     }
  // //   }

  // //   return result
  // // }

  // const tableData = (
  //   obj1: AccountDetailsFieldValues,
  //   obj2: AccountDetailsFieldValues,
  //   accountDetailsFieldTitles: AccountDetailsFieldValues
  // ) => {
  //   const differences: TableData[] = []
  //   for (const key in obj1) {
  //     if (
  //       Object.hasOwnProperty.call(obj1, key) &&
  //       Object.hasOwnProperty.call(obj2, key)
  //     ) {
  //       if (
  //         obj1[key as keyof AccountDetailsFieldValues] !==
  //         obj2[key as keyof AccountDetailsFieldValues] 
  //       ) {
  //         differences.push({
  //           field: accountDetailsFieldTitles[key as keyof AccountDetailsFieldValues],
  //           oldValue: obj1[key as keyof AccountDetailsFieldValues],
  //           newValue: obj2[key as keyof AccountDetailsFieldValues],
  //         })
  //       }
  //     }
  //   }
  //   return differences
  // }
  const updateData = accountDetailsValues?.accountDetailsUpdatedData


  const handleNotes = (e: string) => {
    if (updateData !== undefined) {
      updateData.notes = e
      setUpdatedData(updateData)   
    }
  }

  useEffect(()=>{
    setMapped(accountDetailsValues?.submissionTableData)
  },[accountDetailsValues?.submissionTableData])

  // const mappedData: any = accountDetailsValues?.submissionTableData
  // // console.log('mapped',mappedData)
  // const updateData = accountDetailsValues?.accountDetailsUpdatedData
  // console.log('update data',updateData)

  const columnData = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Old value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
  ]

  const handleSubmit = () => {
    accountDetailsValues?.setSubmissionPopUp(false)
    // console.log('up',updateData)
    if (updateData !== undefined) {
      accountDetailsValues?.setAccountDetailsSubmissionPayload(updateData)
    }
  }
  const reject = () => {
    window.location.reload()
  }

  return (
    <ModalWrapper>
      <ContactPopupWrapper>
        <HeaderRow>
          <WDNewProfileSidebarMenu>Client Details</WDNewProfileSidebarMenu>
          <CloseWrapper onClick={() => accountDetailsValues?.setSubmissionPopUp(false)}>
            <SvgCancel />
          </CloseWrapper>
        </HeaderRow>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>
        <SubHeadingWrapper>
          <WDNewProfileSidebarMenu>
            You are about to change the Account details information.
          </WDNewProfileSidebarMenu>
        </SubHeadingWrapper>

        <StyledTableContainer>
          <Table
            tableColumns={columnData}
            tableData={mapped ? mapped : []}
            onClick={() => ''}
          />
        </StyledTableContainer>

        <NotesWrapper>
          <InputField
            type="textarea"
            label="Notes"
            onChange={(e) => handleNotes(e)}
            textboxPlaceholder={'Notes'}
          />
        </NotesWrapper>
        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>

        <ButtonRow>
          <SecondaryButtonWrapper>
            <WDStyledCancelButton onClick={() => {accountDetailsValues?.setSubmissionPopUp(false)
            accountDetailsValues?.setSubmissionTableData([])
            }}>
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper>
          <PrimaryButtonWrapper>
            <WDStyledSaveButton onClick={() => handleSubmit()}>
              <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper>
        </ButtonRow>
      </ContactPopupWrapper>
    </ModalWrapper>
  )
}

export default AccountDetailsSubmissionPage