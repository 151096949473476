import { Dispatch, createContext, useContext, useEffect, useState } from 'react'
import {
  InvestmentProfileAPI,
  InvestmentProfileSubmitInterface,
  TableData,
} from './InvestmentContextInterface'
import useAxiosProtected from '../http/useAxiosProtected'
import { AuthContext } from '../AuthContext'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import {
  fetchInvestmentProfile,
  updateInvestmentProfile,
} from './InvestmentContextHttp'

interface InvestmentContextInterface {
  isLoading: boolean
  error: unknown | null | object
  investmentCollapse: boolean
  setInvestmentCollapse: SetInvestmentCollapse
  investmentModal: boolean
  setInvestmentModal: SetInvestmentCollapse
  investmentDetails: InvestmentProfileAPI
  submissionTableData: TableData[] | undefined
  setSubmissionTableData: SetSubmissionTableData
  submissionPayload: InvestmentProfileSubmitInterface | undefined
  setSubmissionPayload: SetSubmissionPayload
  investmentProfileData: InvestmentProfileSubmitInterface | undefined
  setInvestmentProfileData: SetSubmissionPayload
  responseCode: number | undefined
  setResponseCode: Dispatch<React.SetStateAction<number>>
}

interface SetSubmissionTableData {
  (value: TableData[]): void | TableData[]
}

interface SetSubmissionPayload {
  (
    value: InvestmentProfileSubmitInterface
  ): void | InvestmentProfileSubmitInterface
}

interface SetInvestmentCollapse {
  (value: boolean): void | boolean
}

export const InvestmentContext = createContext<
  InvestmentContextInterface | undefined | null
>(undefined)

interface InvestmentCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const InvestmentContextProvider = ({
  children,
}: InvestmentCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown | null | object>(null)
  const [investmentCollapse, setInvestmentCollapse] = useState(false)
  const [investmentModal, setInvestmentModal] = useState(false)

  const [investmentDetails, setInvestmentDetails] =
    useState<InvestmentProfileAPI>({})
  const [submissionTableData, setSubmissionTableData] = useState<TableData[]>()
  const [submissionPayload, setSubmissionPayload] =
    useState<InvestmentProfileSubmitInterface>()
  const [investmentProfileData, setInvestmentProfileData] =
    useState<InvestmentProfileSubmitInterface>()
  const [responseCode, setResponseCode] = useState<number>(100)
  const axiosProtected = useAxiosProtected()

  const authCtx = useContext(AuthContext)
  const approval = useContext(ApprovalContext)

  const clientIdValue: any = approval?.value
  async function getInvestmentDetails() {
    if (clientIdValue !== undefined && clientIdValue !== '') {
      try {
        const data = await fetchInvestmentProfile(clientIdValue, axiosProtected)
        setInvestmentDetails(data)
        setIsLoading(false)
      } catch (error: unknown) {
        setError(error)
      }
    }
  }

  useEffect(() => {
    getInvestmentDetails()
  }, [clientIdValue, authCtx?.accessToken])

  useEffect(() => {
    async function submitInvestmentProfile() {
      try {
        if (submissionPayload !== undefined) {
          const data = await updateInvestmentProfile(
            submissionPayload as InvestmentProfileSubmitInterface,
            axiosProtected
          )
          setResponseCode(data)
        }
      } catch (errror: unknown) {
        setError(error)
      }
    }
    submitInvestmentProfile()
  }, [authCtx?.accessToken, submissionPayload])

  return (
    <InvestmentContext.Provider
      value={{
        investmentCollapse,
        setInvestmentCollapse,
        isLoading,
        error,
        setInvestmentModal,
        investmentModal,
        investmentDetails,
        submissionTableData,
        setSubmissionTableData,
        submissionPayload,
        setSubmissionPayload,
        investmentProfileData,
        setInvestmentProfileData,
        responseCode,
        setResponseCode,
      }}
    >
      {children}
    </InvestmentContext.Provider>
  )
}
