import {
  StyledLabel,
  LabelProps,
  StyledLabelSectionHeader,
  StyledLabelInputField,
  StyledLabelSidebarMenu,
  StyledSubHeading,
} from "./styles";

/**
 * Gives the label customizable component
 */
const Label = ({
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  color,
  padding,
  label,
  fontFamily,
  children,
}: LabelProps & { padding?: string }) => (
  <StyledLabel
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontStyle={fontStyle}
    fontWeight={fontWeight}
    lineHeight={lineHeight}
    color={color}
    label={label}
    padding={padding}
  >
    {children}
  </StyledLabel>
);

export default Label;

export const SectionHeader = ({ label }: LabelProps) => (
  <StyledLabelSectionHeader>{label}</StyledLabelSectionHeader>
);

export const InputFieldLabel = ({ label }: LabelProps) => (
  <StyledLabelInputField>{label}</StyledLabelInputField>
);

export const SidebarMenuLabel = ({ label }: LabelProps) => (
  <StyledLabelSidebarMenu>{label}</StyledLabelSidebarMenu>
);

export const SubHeading = ({ label }: LabelProps) => (
  <StyledSubHeading>{label}</StyledSubHeading>
)
