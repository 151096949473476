import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import {
  MasterWrapper,
  HeaderRow,
  IconWrapper,
  DividerWrapper,
  StyledTableContainer,
  TitleIconWrap,
  TopRow,
  LoaderRow,
} from './styles'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Table from '../../components/table/Table'
import { SvgAccountRoles } from '../../components/svg/SvgAccountRoles'
import { COLORS } from '../../theme/Colors'
import { WDInputLabel, WDStyledSectionTitle } from '../../components/ui/WDTypography'
import { AccountRolesContext } from '../../services/accountRolesContext/AccountRolesContext'
import Loader from '../../components/loader/Loader'
import { StyledHeadWrapper } from '../addressUI/styles'

export const AccountRoles = () => {
  const accountRolesValues = useContext(AccountRolesContext)
  const accountRoles = accountRolesValues?.accountRoles

  // console.log(accountRoles)

  useEffect(() => {
    accountRoles
  }, [accountRoles])
  

 const accountRolesData = accountRoles?.map(
  ({clientName, role}) => {
    return {
      ClientName: clientName,
      role: role
    }
  }
 )

  const [isCollapse, setIsCollapse] = useState(true)

  const columnData = [
    {
      Header: 'Client Name',
      accessor: 'ClientName',
    },
    {
      Header: 'Role',
      accessor: 'role',
    },
  ]

  const cardSummary = [
    {
      ClientName: '',
      Role: '',
    },
  ]

  const collapse = () => {
    accountRolesValues?.setAccountRolesCollapse(!accountRolesValues?.accountRolesCollapse)
  }

  if(accountRolesValues?.isLoading){
    return (
      <MasterWrapper>
      <TopRow>
        <HeaderRow>
          <TitleIconWrap>
            <IconWrapper>
              <SvgAccountRoles />
            </IconWrapper>
            <WDStyledSectionTitle>{'Account Roles'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
      </TopRow>
      { (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}
      <LoaderRow><Loader /></LoaderRow>
      </MasterWrapper>
    )
  }

  return (
    <MasterWrapper id="account-roles" borderShadow={accountRolesValues?.accountRolesCollapse ? true : false}>
      <TopRow>
      <HeaderRow onClick={() => collapse()}>
        <TitleIconWrap>
          <IconWrapper>
            {/* <Icon icon={Icons.Relationship} /> */}
            <SvgAccountRoles />
          </IconWrapper>
          <WDStyledSectionTitle>{'Account Roles'}</WDStyledSectionTitle>
        </TitleIconWrap>
      </HeaderRow>
        <IconWrapper onClick={() => collapse()}>
          {accountRolesValues?.accountRolesCollapse && <SvgChevronUp />}
          {!accountRolesValues?.accountRolesCollapse && <SvgChevronDown />}
        </IconWrapper>
        </TopRow>
      {accountRolesValues?.accountRolesCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

{accountRolesValues?.accountRolesCollapse && (
        <>
          <React.Fragment>
            {accountRolesData?.length===0 && (
              <StyledHeadWrapper>
              <WDInputLabel>
                {'Hey! No Roles associated to this Account.'}
              </WDInputLabel>
            </StyledHeadWrapper>
            )}
          </React.Fragment>
        </>
      )}

      {accountRolesValues?.accountRolesCollapse &&  accountRolesData?.length!==0 && (
        <React.Fragment>
          <StyledTableContainer>
            <Table
              tableColumns={columnData}
              tableData={accountRolesData ? accountRolesData : []}
              onClick={() => ''}
            />
          </StyledTableContainer>
        </React.Fragment>
      )}
    </MasterWrapper>
  )
}
