import { FC} from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import AuthContextProvider from './services/AuthContext'
import LandingPage from './pages/landingPage/LandingPage'

import React from 'react'
const AppRoutes: FC = () => {
  return (
    <AuthContextProvider>
      <Routes>
      <Route path="/" element={<LandingPage />} />
    </Routes>
    </AuthContextProvider>
  )
}

export default AppRoutes
