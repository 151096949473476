import WedbushLoader from '../../composites/wedbushLoader/WedbushLoader'
import { SvgLoadingData } from '../svg/SvgLoadingData'
import { Wrapper } from './styles'

const Loader = () => {
  return (
    <Wrapper>
      <WedbushLoader />
      <SvgLoadingData />
    </Wrapper>
  )
}

export default Loader
