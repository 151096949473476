import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
import { FontSizes, FontWeights } from '../../theme/Fonts'
import { SIZES } from '../../theme/Sizes'

interface AddressProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: AddressProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`
export const TopRow = styled.div`
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
`
export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`

export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  cursor: pointer;
  button {
    font-family: SourceSansPro-SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    padding: 4px 16px;
    color: ${COLORS.Text.Primary};
    background-color: ${COLORS.Background.NeutralWeak};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    border-radius: 4px;
    &:hover {
      background-color: ${COLORS.Background.Primary10};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:active {
      background-color: ${COLORS.Background.BackgroundActive};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:focus {
      border: 2px solid ${COLORS.Brand.Brand1};
    }
  }
`

export const LoaderRow = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  // max-height: 84px;
  gap: 24px;
  margin: 8px 0px;
`

export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`
export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0px;
`
export const DividerWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 4px;
`
export const LegalTab = styled.div`
  padding-bottom: 16px;
`
export const AlternateTab = styled.div``
export const AddSection = styled.div`
  margin: 8px 0px;
`

export const FlexItem = styled.div`
  flex: 0 1 50%;
  width: 50%;
`
export const EditWrapper = styled.div`
  padding-left:10px
`


export const FlexItem1 = styled.div`
  flex: 0 1 100%;
  width: 33.33%;
`
export const FlexItem2 = styled.div`
  flex: 0 1 23%;
  width: 23%;
`
export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 24px;
  margin: 8px 0px;
`
export const LabelWrap = styled.div`
  padding: 4px 0px;
`

export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
`
export const ButtonRow1 = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  /* max-height: 84px; */
  gap: 16px;
  margin-bottom: 16px;
`
export const SecondaryButtonWrapper1 = styled.div`
  /* height: 24px; */
  display: flex;
  flex-direction: row;
  font-family: 'SourceSansPro-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* margin-bottom: 16px; */
`

export const SubSectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 0px;
  button {
    font-family: SourceSansPro-SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    padding: 4px 16px;
    color: ${COLORS.Text.Primary};
    background-color: ${COLORS.Background.NeutralWeak};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    border-radius: 4px;
    &:hover {
      background-color: ${COLORS.Background.Primary10};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:active {
      background-color: ${COLORS.Background.BackgroundActive};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:focus {
      border: 2px solid ${COLORS.Brand.Brand1};
    }
  }
`

export const CloseWrapper = styled.div`
  cursor: pointer;
`

export const ColumnWrapper = styled.div``

export const DividerWrapper3 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0px;
`
export const DividerWrapper4 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px 8px;
`

export const ModalWrapper = styled.div`
  position: fixed;
  /* overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 1;
  // overflow-y: scroll;
  display: flex;
  height:100%;
  position: fixed;
  padding-top: 1.5rem;
  top: 0rem;
  bottom: 0rem;
  left: 0rem;
  right: 0rem;
  /* padding-bottom: 30rem; */
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 116, 139, 0.8);
  width: 100%;
  // margin: 0 -5%;
`

export const NotesWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 100px;
  gap: 16px;
  margin: 8px 0px;
`

export const ContactPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 555px !important;
  justify-content: space-between;
  position: absolute;
  flex: 0 1 100%;
  width: 62.77%;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  box-shadow: ${(AddressUIProps: AddressProps) =>
    AddressUIProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const BoxRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  /* max-height: 6.5rem; */
  margin: 8px 0px;
  padding: 0px 0px;
`

export const SubHeadingWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 10px;
  gap: 8px;
  margin: 8px 0px 24px;
`

export const PrimaryButtonWrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 97px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper2 = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.875rem;
  max-height: 40px;

  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const StyledTableContainer = styled.div`
  width: 100%;
  /* padding: 24px; */
  padding: 0px 0px 16px;
  margin-top: 16px;
  background: '#FFFFFF';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #0f172a;
          :nth-child(1) {
            padding: 8px;
            font-family: SourceSansPro-Regular !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 16px !important;
          }
        }
      }
    }

    thead {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #0f172a;
      tr {
        th {
          padding: 4px 0px 4px 8px;
          font-family: SourceSansPro-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #0f172a;
          width: 20%;
        }
      }
    }
  }
`

export const StyledActiveButtonLabel = styled.div`
  font-family: 'SourceSansPro-SemiBold';
  font-weight: ${FontWeights.SemiBold};
  font-size: ${FontSizes.Paragraph};
  line-height: ${SIZES[4]};
  font-style: normal;
  color: #0000FF;
  margin-left:6px;
`
export const StyledHeadWrapper=styled.div`
  margin-top:12px;
`