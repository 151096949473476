export const SvgHousehold = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        
        fillRule="evenodd"
        
        clipRule="evenodd"
        d="M12.0004 3.01166C11.7266 2.974 11.4385 3.05309 11.2174 3.25318L3.31694 10.4032C2.92145 10.7611 2.88947 11.3713 3.24538 11.7686C3.60385 12.1687 4.2194 12.201 4.61774 11.8405L5.21121 11.3034V19.057V20C5.21121 20.5523 5.65892 21 6.21121 21H7.14434H16.81H17.7431C18.2954 21 18.7431 20.5523 18.7431 20V19.057V11.2614L19.383 11.8405C19.7814 12.201 20.3969 12.1687 20.7554 11.7686C21.1113 11.3713 21.0793 10.7611 20.6838 10.4032L12.7833 3.25318C12.5622 3.05309 12.2742 2.974 12.0004 3.01166ZM7.14434 9.55393V10.342V10.7023V11.342V17.057V19V19.057H7.21121H9.14434H14.81H16.7431H16.81V19V17.057V11.342V10.7023V10.342V9.51188L12.0004 5.15918L7.14434 9.55393Z"
        fill="#475569"
      />
    </svg>
  )
}
