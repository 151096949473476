
import Divider from '../../components/divider/Divider'

import { SvgCancel } from '../../components/svg/SvgCancel'
import Table from '../../components/table/Table'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
} from '../../components/ui/WDTypography'
import { InputField } from '../inputField/InputField'
import {
    BoxRow,
  ButtonRow,
  CloseWrapper,
  DividerWrapper,
  DividerWrapper2,
  HeaderRow,
  InvestmentPopupWrapper,
  ModalWrapper,
  NotesWrapper,
  PrimaryButtonWrapper,
  SecondaryButtonWrapper,
  StyledTableContainer,
  SubHeadingWrapper,
  Wrapper2,
} from './styles'

import {
  WDStyledCancelButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { useContext, useEffect, useState } from 'react'
import { InvestmentContext } from '../../services/investmentContext/InvestmentContext'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { InvestmentProfileSubmitInterface } from '../../services/investmentContext/InvestmentContextInterface'


export const InvestmentChangePopup = () => {
  const accountDetailsValues = useContext(AccountDetailsContext)
  const accountId = accountDetailsValues?.accountDetails?.accountId
  console.log('----Acc Id', accountId)
const investmentDetails=useContext(InvestmentContext)
const investmentProfileData = investmentDetails?.investmentProfileData
const [modifiedInvestmentDetails, setModifiedInvestmentDetails] = useState<any[]>()
const [investmentProfilePayload, setInvestmentProfilePayload] = useState<InvestmentProfileSubmitInterface>()
useEffect(()=>{
  setModifiedInvestmentDetails(investmentDetails?.submissionTableData)
  setInvestmentProfilePayload({
    ...investmentProfileData,
    accountId: accountId,
    updateBy: 'System',
    createdBy: 'System', 
    notes: '',
  })
},[investmentDetails?.submissionTableData])
  const handleSubmit = () => {
    investmentDetails?.setInvestmentModal(false)
    investmentDetails?.setSubmissionPayload(investmentProfilePayload as InvestmentProfileSubmitInterface)
  }
  const reject = () => {
    investmentDetails?.setSubmissionTableData([])
    investmentDetails?.setInvestmentModal(false)
  }

  const handleChange = (value: string) => {
    if(investmentProfilePayload !== undefined){
      investmentProfilePayload.notes = value
      setInvestmentProfilePayload(investmentProfilePayload)
    }
  
  }
  const columnData = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Old Value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
  ]

  return (
    <ModalWrapper>
      <InvestmentPopupWrapper>
        <HeaderRow>
          <WDNewProfileSidebarMenu>Investment Profile</WDNewProfileSidebarMenu>
          <CloseWrapper onClick={() => investmentDetails?.setInvestmentModal(false)}>
            <SvgCancel />
          </CloseWrapper>
        </HeaderRow>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>
        <SubHeadingWrapper>
          <WDNewProfileSidebarMenu>
            You are about to change the Account Investment Profile.
            {/* We found <StyledBoldLabel>(4)</StyledBoldLabel> accounts associated
            with <StyledBoldLabel>Mike Smith</StyledBoldLabel>, select the
            accounts you want to update the mailing address.{' '} */}
          </WDNewProfileSidebarMenu>
        </SubHeadingWrapper>
        {/* {NewAddress.map((value: any, index: number) => {
          return ( */}

        <BoxRow>
        <Wrapper2>
      <StyledTableContainer>
        <Table
          tableColumns={columnData}
          tableData={modifiedInvestmentDetails ? modifiedInvestmentDetails : []}
          onClick={() => ''}
        />
      </StyledTableContainer>
    </Wrapper2>
        </BoxRow>

        <NotesWrapper>
          <InputField
            type="textarea"
            label="Notes"
            onChange={(value: string) => {handleChange(value)}}
            textboxPlaceholder={'Notes'}
          />
        </NotesWrapper>
        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>

        <ButtonRow>
          <SecondaryButtonWrapper>
            <WDStyledCancelButton onClick={() => reject()}>
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper>
          <PrimaryButtonWrapper>
            <WDStyledSaveButton onClick={() => handleSubmit()}>
              <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper>
        </ButtonRow>
      </InvestmentPopupWrapper>
    </ModalWrapper>
  )
}
