import { AxiosInstance } from "axios";
import useAxiosProtected from "../http/useAxiosProtected";
import { URLs } from "../httpConfiguration";
import { AccountPledgedSubmissionPayload } from "./AccountLoanDetailsInterface";

export async function fetchAccountLoanDetails(payload: string, axiosProtected: AxiosInstance) {
    const url = URLs.ACCOUNT_LOAN_DETAILS_GET_ALL + payload
    const response = await axiosProtected.get(url)
    return response.data

}

export async function fetchPledgedAccountStatusDropDown(axiosProtected: AxiosInstance) {
    const url = URLs.ACCOUNT_LOAN_DETAILS_DROPDOWN
    const response = await axiosProtected.get(url)
    return response.data
}

export async function pledgedAccountSubmissionDetails(payload: AccountPledgedSubmissionPayload, axiosProtected: AxiosInstance) {
    const url = URLs.ACCOUNT_LOAN_DETAILS_SUBMISSION
    const response = await axiosProtected.post(url, payload)
    return response.status
}