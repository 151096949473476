import styled from 'styled-components';
import { colors } from '../../../shared/styles';
import { COLORS } from '../../../theme/Colors';

export const StyledArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  flex: 0 1 83.7%;
  /* min-height: 100%; */
  width: 94%;

  // color: white;
  background: ${COLORS.Background.NeutralBackgroundWeak};
  margin-top: 90px;
`;

export const FlexItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 32px;
`;

export const DividerWrapper2 = styled.div`
  display: flex;
  width: 63.5vw;
  position: fixed;
  top: 64px;
  justify-content: center;
  margin: 8px 0px;
  background: ${COLORS.Background.NeutralBackgroundWeak};
  /* padding-right: 30rem; */
`

export const AccessWrapper=styled.div`
display:flex;
width:100%;
`
export const LineBreakWrapper=styled.div`
  line-break:auto;
`
export const AIWRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  color: black;
  width: 60%;
  background-color: ${colors.BackgroundWeak};
  padding: 8px 0px;
  margin-top:120px;
  gap: 1.5rem;
  z-index: 1;
  margin-left: 49px;
  height: 72px;
  align-items: flex-start;
  justify-content: flex-start;
  p {
    z-index: 1;
  }
`