export const COLORS = {
  Brand: {
    Primary: '#FFFFFF',
    Secondary: '#',
    Muted: '#374253',
    Brand1: '#00A9CE',
    Brand2: '#326295',
    Brand3: '#00245D',
  },
  UI: {
    Primary: '#192638',
    Primary50: '#3B82F6',
    Secondary: '#596579',
    Tertiary: '#ffffff',
    Quaternary: '#475569', //icon color
    ActiveIcon: '#1E293B', //Name has to be provide by UX team
    Disabled: '#',
    DangerBackground: '#EA001E',
    Icon: '#475569',
    Error: '#C72F24',
    Success: '#2E844A',
    Text: '#0F172A',
    Border: '#CBD5E1', //Name has to be provided by the UX team
    TextWeak: '#475569', //Name has to be provided by the UX team
    BorderStrong: '#FE7765', //Name has to be provided by UX team
    BackgroundStrong: '#2563EB', //Name has to be provided by UX team
    PrimaryBorderStrong: '#60A5FA',
    Gray: '#d0d7e2',
    SuccessBorder: '#22C373',
    NeutralBorderWeak: '#e2e8f0',
  },
  Background: {
    Primary: '#FFFFFF',
    PrimaryStrong: '#1E3A8A', //Name has to be provide by UX team
    PrimaryText: '#2563EB', //Name has to be provided by Ux team
    Primary50: '#3B82F6',
    Primary10: '#DBEAFE',
    Primary70: '#1D4ED8',
    Neutral40: '#A7AFBC',
    PrimaryBg: '#EFF6FF',
    Secondary: '#E5E5E5', // Name has to be provide by UX team : my comment landing page background color
    Tertiary: '#E6EAF0',
    HighLight: '#1F5EB7',
    NeutralIcons: '#475569', //Name has to be provided by the Ux team
    Settings: '#00245D',
    Border: '#CBD5E1',
    BorderHover: '#64748B',
    DangerBgIconHover: '#FEDED8',
    WarningBgIconHover: '#FFEDD5',
    StrongBackground: '#E2E8F0',
    PrimaryActive: '#1E40AF',
    WarningBackground: '#FFF7ED',
    Neutral50: '#64748B', //Name has to be provided by UX
    Warning: '#F97316', //Name has to be provide by UX team
    WarningBorderStrong: '#FB923C',
    WarningBackgroundStrong: '#F97316',
    NeutralWeak: '#F8FAFC',
    NeutralBackground: '#FFF',
    PrimaryBorderStrong: '#60A5FA',
    NeutralBackgroundMedium: '#F1F5F9',
    NeutralBorder: '#bfdbfe',
    SuccessBorderStrong: '#41b658',
    SuccessBackgroundLight: '#EBF7E6', //Name has to be provide by UX team
    SuccessBackgroundStrong: '#2E844A',
    SuccessBgIconHover: '#CDEFC4',
    DangerBg: '#FEF1EE',
    DangerBorderStrong: '#FE7765',
    SuccessBg: '#E8FCF2',
    BackgroundActive: '#BFDBFE',
    NeutralBackgroundWeak: '#F8FAFC',
    Neutral70: '#334155',
    PrimaryMedium: '#2684FF',
  },
  Text: {
    Primary: '#2563EB',
    PrimaryTextStrong: '#1E3A8A',
    Gray80: '#192638',
    Tertiary: '#ffffff',
    Neutral: '#0F172A',
    NeutralTextWeak: '#475569',
    NeutralTextInverted: '#FFF',
    NeutralTextDisabled: ' #A7AFBC',
    Quaternary: '#1E293B', //neutral-80
    Disabled: '#',
    Error: '#C72F24',
    Success: '#2E844A',
    Success2: '#3BA755', //Name has to be provided by UX team
    Text: '#0F172A',
    TextWeak: '#475569', //Name has to be provided by UX team
    Danger: '#BA0517', //Name has to be provided by UX team
    Black: '#000000', //Name has to be provided by UX team
    GrayScaleBlack: '#181E27',
    Warning: '#C2410C',
  },
  Icons: {
    NeutralIconInverted: '#FFFFFF',
    PrimaryIcon: '#2563EB',
    DangerIcon: '#EA001E',
    SuccessIcon: '#3BA755',
    NeutralIcon: '#475569',
  },
}
 export const allColors = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
]