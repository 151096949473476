import styled from 'styled-components'

export interface Date {
  date?: string | null | undefined
}
export const Wrapper = styled.div`
  /* position: sticky;
  z-index: 1;
  top: 714px;
  left: 500px; */
  font-family: 'SourceSansPro-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::before {
    position: absolute;
    content: '';
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    left: 47%;
    bottom: -9px;
    z-index: 1;
  }
  &::after {
    position: absolute;
    content: '';
    border-top: 10px solid #334155;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    left: 100px;
    /* bottom: -8px; */
    z-index: 6;
    top: 2px;
    /* bottom:; */
    z-index: 6;
    rotate: 90;
    -webkit-transform: rotate(180deg);
    border-radius: 2px;
  }
`
export const ImageWrapper = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
  svg path {
    fill: #ffffff;
  }
`
export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 0px 4px 8px;
  gap: 8px;
  width: 240px;
  background: #334155;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    font-family: 'SourceSansPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  span {
    font-weight: 600;
  }
`
export const CombinedLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
`
export const TooltipImageWrapper = styled.div`
  position: absolute;
  width: 12.73px;
  height: 12.73px;
  left: calc(50% - 12.73px / 2 + 6.36px);
  top: 8.5px;
  border: 1px solid #60a5fa;
  border-radius: 2px;
  transform: rotate(-135deg);
`
