import React, { useContext, useEffect, useState } from 'react'
import { SvgTrade } from '../../components/svg/SvgTrade'
import {
  WDAddressChangeTitle,
  WDReadModeValue,
  WDStyledToastLabel,
} from '../../components/ui/WDTypography'
import { AddressChangeWidgetProps } from './IAddressChangeWidget'
import { IconWrapper, StyledTableContainer, Wrapper } from './styles'
import Table from '../../components/table/Table'


export const AddressChangeWidget = ({
  newAddress,
  oldAddress,
  alternate,
}: AddressChangeWidgetProps) => {
  const columnData = [
    {
      Header: 'Fields',
      accessor: 'Fields',
    },
    {
      Header: 'Old Value',
      accessor: 'OldValue',
    },
    {
      Header: 'New Value',
      accessor: 'NewValue',
    },
  ]

  const cardSummary = [
    {
      Fields: 'Street Address 1',
      OldValue: `${
        oldAddress?.streetAddress1 ? oldAddress?.streetAddress1 : '--'
      }`,
      NewValue: `${
        newAddress?.streetAddress1 ? newAddress?.streetAddress1 : '--'
      }`,
    },
    {
      Fields: 'Street Address 2',
      OldValue: `${
        oldAddress?.streetAddress2 ? oldAddress?.streetAddress2 : '--'
      }`,
      NewValue: `${
        newAddress?.streetAddress2 ? newAddress?.streetAddress2 : '--'
      }`,
    },
    {
      Fields: 'City',
      OldValue: `${oldAddress?.cityName ? oldAddress?.cityName : '--'}`,
      NewValue: `${newAddress?.cityName ? newAddress?.cityName : '--'}`,
    },
    {
      Fields: 'State',
      OldValue: `${oldAddress?.stateName ? oldAddress?.stateName : '--'}`,
      NewValue: `${newAddress?.stateName ? newAddress?.stateName : '--'}`,
    },
    {
      Fields: 'Country',
      OldValue: `${oldAddress?.countryName ? oldAddress?.countryName : '--'}`,
      NewValue: `${newAddress?.countryName ? newAddress?.countryName : '--'}`,
    },
    {
      Fields: 'Zip Code',
      OldValue: `${oldAddress?.zipCode ? oldAddress?.zipCode : '--'}`,
      NewValue: `${newAddress?.zipCode ? newAddress?.zipCode : '--'}`,
    },
  ]

  const cardSummary2 = [
    {
      Fields: 'NickName',
      OldValue: `${
        oldAddress?.addressNickName ? oldAddress?.addressNickName : '--'
      }`,
      NewValue: `${
        newAddress?.addressNickName ? newAddress?.addressNickName : '--'
      }`,
    },
    {
      Fields: 'Street Address 1',
      OldValue: `${
        oldAddress?.streetAddress1 ? oldAddress?.streetAddress1 : '--'
      }`,
      NewValue: `${
        newAddress?.streetAddress1 ? newAddress?.streetAddress1 : '--'
      }`,
    },
    {
      Fields: 'Street Address 2',
      OldValue: `${
        oldAddress?.streetAddress2 ? oldAddress?.streetAddress2 : '--'
      }`,
      NewValue: `${
        newAddress?.streetAddress2 ? newAddress?.streetAddress2 : '--'
      }`,
    },
    {
      Fields: 'City',
      OldValue: `${oldAddress?.cityName ? oldAddress?.cityName : '--'}`,
      NewValue: `${newAddress?.cityName ? newAddress?.cityName : '--'}`,
    },
    {
      Fields: 'State',
      OldValue: `${oldAddress?.stateName ? oldAddress?.stateName : '--'}`,
      NewValue: `${newAddress?.stateName ? newAddress?.stateName : '--'}`,
    },
    {
      Fields: 'Country',
      OldValue: `${oldAddress?.countryName ? oldAddress?.countryName : '--'}`,
      NewValue: `${newAddress?.countryName ? newAddress?.countryName : '--'}`,
    },
    {
      Fields: 'Zip Code',
      OldValue: `${oldAddress?.zipCode ? oldAddress?.zipCode : '--'}`,
      NewValue: `${newAddress?.zipCode ? newAddress?.zipCode : '--'}`,
    },
    {
      Fields: 'Start Date',
      OldValue: `${oldAddress?.startDate ? new Date(oldAddress?.startDate).toISOString().split('T')[0] : ''}`,
      NewValue: `${newAddress?.startDate ? newAddress?.startDate : ''}`,
    },
    {
      Fields: 'End Date',
      OldValue: `${oldAddress?.endDate ? new Date(oldAddress?.endDate).toISOString().split('T')[0] : ''}`,
      NewValue: `${newAddress?.endDate ? newAddress?.endDate : ''}`,
    },
  ]

  return (
    <Wrapper>
      <StyledTableContainer>
        <Table
          tableColumns={columnData}
          tableData={alternate ? cardSummary2 : cardSummary}
          onClick={() => ''}
        />
      </StyledTableContainer>
    </Wrapper>
  )
}
