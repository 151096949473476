import React from 'react'
import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'
import { CancelRequest } from './AuditTrailInterface'


export async function fetchAuditTrail(
  accountNumber: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.GET_AUDIT_TRAIL
  const payload = {
    accountNumber: accountNumber,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  return data
}

export async function auditTrailCancelRequest(
  cancelRequest: CancelRequest | null,
  axiosProtected: AxiosInstance
) {
  const url = URLs.AUDIT_TRAIL_CANCEL_REQUEST
  const response = await axiosProtected.post(url, cancelRequest)
  const data = response.status
  return data
}