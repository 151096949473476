import { COLORS } from '../../theme/Colors'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { useContext, useEffect, useState } from 'react'
import { AccountRestrictionReportsContext } from '../../services/accountRestrictionReports/AccountRestrictionReportsContext'
import {
  DividerWrapper,
  FlexItem,
  FlexItemButton,
  HeaderRow,
  HorizontalWrapper,
  IconWrapper,
  MasterWrapper,
  PrimaryButtonWrapper,
  RowContent,
  RowContentSecond,
  StyledTableContainer,
  TitleIconWrap,
  TopRow,
} from './styles'
import {
  WDStyledCancelButtonLabel,
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { DropdownField } from '../dropdownField/DropdownField'
import { WDStyledSaveButton } from '../../components/ui/WDButtons'
import Divider from '../../components/divider/Divider'
import { pageOptions, tableHeaders } from './constants'
import { OptionProps } from '../dropdownField/IDropdownField'
import { AccRestrictionReportsDataInterface } from '../../services/accountRestrictionReports/AccountRestrictionReportsDataInterface'
import { InputField } from '../inputDateField/InputField'
import Table from '../../components/tableCommon/Table'
import { useLazyQuery } from '@apollo/client'
import { GET_ACCOUNT_RESTRICTIONS_DETAILS } from '../../services/graphQl/AccountRestrictionsReportGraphQl'
import { convertRestrictionsDetails } from '../../shared/helper'

const AccountRestrictionReports = () => {
  const accountRestrictionReportsContext = useContext(
    AccountRestrictionReportsContext
  )
  const [graphQlAccountRestrictionsDetails, { data }] = useLazyQuery(
    GET_ACCOUNT_RESTRICTIONS_DETAILS
  )
  const [startDateSelected, setStartDateSelected] = useState<string>('')
  const [endDateSelected, setEndDateSelected] = useState<string>('')
  const [filteredTableData, setFilteredTableData] = useState<any[]>([])
  const [pageNumberSelected, setPageNumberSelected] = useState<OptionProps>({
    value: 10,
    label: '10',
  })

  const getRestrictionDetails = () => {
    graphQlAccountRestrictionsDetails({
      variables: {
        tradeDateStart: startDateSelected,
        tradeDateEnd: endDateSelected,
      },
      fetchPolicy: 'no-cache',
    })
  }

  useEffect(() => {
    if (data !== undefined) {
      setFilteredTableData(
        convertRestrictionsDetails(data, startDateSelected, endDateSelected) as AccRestrictionReportsDataInterface[]
      )
    }
  }, [data])

  const tableColumnData = tableHeaders

  const currentDate = new Date()
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  )
  const formattedDate = `${firstDayOfMonth.getFullYear()}-${(
    '0' +
    (firstDayOfMonth.getMonth() + 1)
  ).slice(-2)}-${('0' + firstDayOfMonth.getDate()).slice(-2)}`
  useEffect(() => {
    setFilteredTableData([])

    setStartDateSelected(formattedDate)
    setEndDateSelected(currentDate.toISOString().split('T')[0])
  }, [])

  const downloadCSV = (
    data: AccRestrictionReportsDataInterface[],
    filename: string
  ) => {
    let csv = ''
    const keys = Object.keys(data[0])
    csv += keys.join(',') + '\n'

    data.forEach((row: any) => {
      keys.forEach((key, i) => {
        if (i > 0) csv += ','
        csv += row[key]
      })
      csv += '\n'
    })
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url

    const date = new Date()
    const dateStr = `${date.getFullYear()}${
      date.getMonth() + 1
    }${date.getDate()}`

    link.download = `${filename}_${dateStr}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleChange = (value: string, fieldName: string) => {
    if (fieldName === 'Trade Date Start') {
      setStartDateSelected(value)
    }
    if (fieldName === 'Trade Date End') {
      setEndDateSelected(value)
    }
  }

  const collapse = () => {
    accountRestrictionReportsContext?.setAccountRestrictionReportsCollapse(
      !accountRestrictionReportsContext?.accountRestrictionReportsCollapse
    )
  }

  const handlePageChange = (value: number | string | undefined) => {
    if (value !== undefined)
      setPageNumberSelected({
        value: Object.values(value)[0],
        label: Object.values(value)[1],
      })
  }

  return (
    <MasterWrapper id="account-restrictions-report">
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <WDStyledSectionTitle>
              Account Restrictions Report
            </WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <IconWrapper onClick={() => collapse()}>
          {accountRestrictionReportsContext?.accountRestrictionReportsCollapse && (
            <SvgChevronUp />
          )}
          {!accountRestrictionReportsContext?.accountRestrictionReportsCollapse && (
            <SvgChevronDown />
          )}
        </IconWrapper>
      </TopRow>
      <DividerWrapper>
        <Divider
          bgColor={COLORS.Background.Border}
          horizontal={true}
          thickness="1px"
        />
      </DividerWrapper>
      {accountRestrictionReportsContext?.accountRestrictionReportsCollapse && (
        <HorizontalWrapper>
          <RowContent>
            <FlexItem>
              <InputField
                label="Trade Date Start"
                textboxValue={startDateSelected}
                onChange={(e: string) => {
                  handleChange(e, 'Trade Date Start')
                }}
                type="date"
              />
            </FlexItem>
            <FlexItem>
              <InputField
                label="Trade Date End"
                textboxValue={endDateSelected}
                onChange={(e: string) => {
                  handleChange(e, 'Trade Date End')
                }}
                type="date"
                min={startDateSelected}
              />
            </FlexItem>

            <FlexItemButton>
              <PrimaryButtonWrapper>
                <WDStyledSaveButton onClick={getRestrictionDetails}>
                  <WDStyledCancelButtonLabel>
                    {'Search'}
                  </WDStyledCancelButtonLabel>
                </WDStyledSaveButton>
              </PrimaryButtonWrapper>
            </FlexItemButton>
          </RowContent>
          <RowContentSecond>
            <FlexItem>
              <DropdownField
                label="Items per Page"
                options={pageOptions.map((option) => ({
                  ...option,
                  isDisabled:
                    option.value >
                    Math.ceil(filteredTableData?.length / 10) * 10,
                }))}
                onChange={(e) => handlePageChange(e)}
                value={pageNumberSelected}
              />
            </FlexItem>
            <FlexItem>
              <PrimaryButtonWrapper>
                <WDStyledSaveButton
                  onClick={() =>
                    downloadCSV(filteredTableData, 'Account_Restrictions')
                  }
                >
                  <WDStyledCancelButtonLabel>
                    {'Download'}
                  </WDStyledCancelButtonLabel>
                </WDStyledSaveButton>
              </PrimaryButtonWrapper>
            </FlexItem>
          </RowContentSecond>
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
          <StyledTableContainer>
            <Table
              tableColumns={tableColumnData}
              tableData={filteredTableData?.length > 0 ? filteredTableData : []}
              onClick={() => console.log('')}
              itemsPerPage={pageNumberSelected.value as number}
            />
          </StyledTableContainer>
        </HorizontalWrapper>
      )}
    </MasterWrapper>
  )
}

export default AccountRestrictionReports
