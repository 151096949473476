import styled from 'styled-components'
import { Colors } from '../../shared/styles'

export const Wrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  table {
    width: 100%;
    th, td {
      white-space: nowrap ; 
      overflow: hidden; 
      text-overflow: ellipsis; 
    }
    thead {
      tr {
        border-bottom: 0.5px solid #cbd5e1;
        align-items: center;
        box-shadow: inset 0px -1px 0px ${Colors.Gray30};
      }
      th {
        font-size: 12px !important;
        padding: 4px 0px 4px 8px !important;
        box-shadow: inset 0px -1px 0px ${Colors.Gray30};
      }
    }
    tbody {
      tr {
        td {
          height: 32px;
          border-bottom: 1px solid #cbd5e1;
          line-height: 16px;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          padding: 8px;
          color: #0f172a;
        }
      }
    }
  }
`

export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ThContentWrapper = styled.div`
  padding-right: 8px;
`

export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 16px;
  border-radius: 1px;
  background-color: #cbd5e1 !important;
`
export const TableFooterRow = styled.div`
  display: flex;
  height: 32px;
  margin-top: 15px;
  justify-content: space-between;
`
interface TableFooterProps {
  flexContent?: string
}

export const TableFooterRowItem = styled.div<TableFooterProps>`
  display: flex;
  justify-content: ${(props) => props.flexContent};
  align-items: center;
  margin-left: 10px;
`
export const TableFooterRowDropdownItem = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-left: -10px !important;
  div {
    :nth-child(3) {
      height: 32px;
      //border-radius: 4px;
      margin-right: 10px;
    }
  }
`

export const TableFooterRowSpacer = styled.div`
  margin-left: 5px;
  margin-right: 20px;
`
export const TableFooterRowSpacerDropDown = styled.div`
  margin: 0px 5px;
`
export const TableFooterSpacer = styled.div`
  display: flex !important;
  flex-wrap: nowrap;
  justify-items: center;
  align-items: center;
`
