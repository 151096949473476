import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5.33px;
  width: 100%;
  height: 100%;
`

export const StyledTableContainer = styled.div`
  width: 100%;
  /* padding: 24px; */
  padding: 0px 0px 16px;
  background: '#FFFFFF';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #0f172a;
          :nth-child(1) {
            padding: 8px;
            font-family: SourceSansPro-Regular !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 16px !important;
          }
        }
      }
    }

    thead {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      color: #0f172a;
      tr {
        th {
          padding: 4px 0px 4px 8px;
          font-family: SourceSansPro-Bold !important;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #0f172a;
          width: 20%;
        }
      }
    }
  }
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 16px 24px;
  gap: 8px;
  background: #f8fafc;
  /* neutral/border-weak */
  border: 1px solid #e2e8f0;
  border-radius: 8px;
`

export const IconWrapper = styled.div`
position:relative;
`

export const LabelWrap = styled.div`
  /* padding: 0.25rem 0rem; */
`

export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 24px;
  margin: 8px 0px;
`
export const DateWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const DateBlock = styled.div`
  display: flex;
  gap: 80px;
`