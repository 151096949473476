import LandingPageLayout from '../../components/layouts/landingPage/LandingPageLayout'
import { useContext } from 'react'
import HeaderLayout from '../../components/layouts/header/HeaderLayout'
import ArticleLayout from '../../components/layouts/article/ArticleLayout'

import {
  MainContentWrapper,
  MainPane,
  RootContainer,
} from '../../components/layouts/landingPage/styles'

import SidePaneLayout from '../../components/layouts/sidePane/SidePaneLayout'

import HeaderContent from './headerContent/HeaderContent'
import SidePaneContent from './sidePaneContent/SidePaneContent'
import ArticleContent from './articleContent/ArticleContent'
import {
  MailingAddressContext,
  MailingAddressContextProvider,
} from '../../services/mailingAddressContext/MailingAddressContext'
import { HouseholdContextProvider } from '../../services/householdContext/HouseholdContext'
import { AccountDetailsContextProvider } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AccountRolesContextProvider } from '../../services/accountRolesContext/AccountRolesContext'
import { AuditTrailContextProvider } from '../../services/auditTrailContext/AuditTrailContext'
import { PaperlessContextProvider } from '../../services/paperlessContext/PaperlessContext'
import {
  InvestmentContext,
  InvestmentContextProvider,
} from '../../services/investmentContext/InvestmentContext'
import { ApprovalContextProvider } from '../../services/approvalContext/ApprovalContext'
import { AddressUISubmissionPage } from '../../composites/addressUI/AddressUISubmissionPage'
import AiwContextProvider from '../../services/aiwContext/AiwContext'
import { AccountMaintenanceGetContextProvider } from '../../services/accountMaintenanceGetContext/AccountMaintenanceGetContext'
import { InvestmentChangePopup } from '../../composites/investment/InvestmentChangePopup'
import AccountRestrictionsContextProvider from '../../services/accountRestrictionsContext/AccountRestrictionsContext '
import {
  AccountLoanDetailsContext,
  AccountLoanDetailsContextProvider,
} from '../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import AccountRestrictionReportsContextProvider from '../../services/accountRestrictionReports/AccountRestrictionReportsContext'

import { ApolloProvider } from '@apollo/client'
import { AppContextInterface, AuthContext } from '../../services/AuthContext'
//import AccountRestrictionsContextProvider from '../../services/accountRestrictionsContext/AccountRestrictionsContext'

function PageContent() {
  const addressFields = useContext(MailingAddressContext)
  const investment = useContext(InvestmentContext)
  return (
    <RootContainer>
      <SidePaneLayout>
        <SidePaneContent />
      </SidePaneLayout>

      <MainPane>
        <HeaderLayout>
          <HeaderContent />
        </HeaderLayout>

        <MainContentWrapper>
          <ArticleLayout>
            <ArticleContent />
          </ArticleLayout>
        </MainContentWrapper>
      </MainPane>
      {addressFields?.submissionPopUp && <AddressUISubmissionPage />}
      {investment?.investmentModal && <InvestmentChangePopup />}
    </RootContainer>
  )
}
const LandingPage = () => {
  const { client } = useContext(AuthContext) as AppContextInterface;
  return (
    <AiwContextProvider>
      <AccountMaintenanceGetContextProvider>
        <ApprovalContextProvider>
          <AccountDetailsContextProvider>
            <AccountLoanDetailsContextProvider>
              <InvestmentContextProvider>
                <MailingAddressContextProvider>
                  <AccountRolesContextProvider>
                    <PaperlessContextProvider>
                      <AuditTrailContextProvider>
                        <HouseholdContextProvider>
                          <AccountRestrictionsContextProvider>
                            <AccountRestrictionReportsContextProvider>
                              {client && (
                                <ApolloProvider client={client}>
                                  <LandingPageLayout>
                                    <PageContent />
                                  </LandingPageLayout>
                                </ApolloProvider>
                              )}
                            </AccountRestrictionReportsContextProvider>
                          </AccountRestrictionsContextProvider>
                        </HouseholdContextProvider>
                      </AuditTrailContextProvider>
                    </PaperlessContextProvider>
                  </AccountRolesContextProvider>
                </MailingAddressContextProvider>
              </InvestmentContextProvider>
            </AccountLoanDetailsContextProvider>
          </AccountDetailsContextProvider>
        </ApprovalContextProvider>
      </AccountMaintenanceGetContextProvider>
    </AiwContextProvider>
  )
}

export default LandingPage
