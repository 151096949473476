import { isoStringToFormattedDate } from "../../shared/helper";

export const pageOptions = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
]



export const tableHeaders = [
  {
    Header: 'Account Number',
    accessor: 'accountNumber',
  },
  {
    Header: 'Restriction Name',
    accessor: 'restrictionName',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }: { value: string | Date }) => {
      return value === '' ? value : isoStringToFormattedDate(value as string);
    },
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    Cell: ({ value }: { value: string | Date }) => {
      return value === '' ? value : isoStringToFormattedDate(value as string);
    },
  },
  {
    Header: 'Buy/Sell',
    accessor: 'buySellIndicator',
  },
  {
    Header: 'CUSIP',
    accessor: 'cusip',
  },
  {
    Header: 'Description',
    accessor: 'securityDescription1',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Trade Date',
    accessor: 'tradeDate',
    Cell: ({ value }: { value: string | Date }) => {
      return value === '' ? value : isoStringToFormattedDate(value as string);
    },
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Submitted By',
    accessor: 'createdBy',
  },
  {
    Header: 'Violation Number',
    accessor: 'violationNumber',
  },
  {
    Header: 'Restriction Status',
    accessor: 'restrictionStatus',
  },
  {
    Header: 'Start of Day Settled Funds',
    accessor: 'settleDataBalance',
  },
]
