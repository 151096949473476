import { createContext, useState } from 'react'
import {
  AiwContextInterface,
  AiwContextProps,
  PayloadProps,
} from './AiwContextInterface'
// import { useLocation, useNavigate } from 'react-router-dom'
import platform from 'platform'
import React from 'react'

export const AiwContext = createContext<AiwContextProps>({} as AiwContextProps)

const AiwContextProviderBrowser: React.FC<AiwContextInterface> = ({
  children,
}) => {
  // const [isContextAvailable, setIsContextAvailable] = useState(false)
  const [inputValue, setInputValue] = useState<PayloadProps>({ input: '' })
  return (
    <AiwContext.Provider
      value={{
        inputValue,
        setInputValue,
      }}
    >
      {children}
    </AiwContext.Provider>
  )
}

const AiwContextProviderElectron = React.lazy(
  () => import('./AiwContextProviderElectron')
)

const AiwContextProvider: React.FC<AiwContextInterface> = (props) => {
  return platform.name === 'Electron' ? (
    <AiwContextProviderElectron {...props} />
  ) : (
    <AiwContextProviderBrowser {...props} />
  )
}

export default AiwContextProvider
