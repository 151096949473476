import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'
export const Wrapper = styled.div`
  overflow-x: auto;

  &::-webkit-scrollbar {
    background: ${COLORS.Background.NeutralBackgroundMedium};
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(167, 175, 188, 0.3);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(167, 175, 188, 0.3);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.Background.NeutralBackgroundMedium};
  }
  table {
    table-layout: flex;
    border-collapse: collapse;
    min-width: 100%;
    flex-direction: column;
    border: none;
    background: ${COLORS.Background.Primary};
    text-align: left;
    color: ${COLORS.UI.Text};
    thead {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      &#sub-row {
        :first-child {
          width: 1% !important;
        }
        :nth-child(2),
        :nth-child(3),
        :nth-child(4) {
          width: 33% !important;
        }
      }
      tr {
        display: table-row;
        flex-wrap: wrap;
        box-shadow: inset 0px -1px 0px ${COLORS.UI.Gray};
        th {
          :nth-child(1),
          :nth-child(3),
          :nth-child(4),
          :nth-child(5),
          :nth-child(6),
          :nth-child(7) {
            div {
              &#remove_border {
                display: none;
              }
            }
          }
          :nth-child(2) {
            div {
              &#remove_border {
                margin-right: 8px;
              }
            }
          }
          cursor: pointer;
          &.sortBackground {
            background-color: ${COLORS.UI.NeutralBorderWeak};
          }
        }
        :first-child {
          th {
            :first-child {
              width: 5% !important;
              div {
                display: none;
              }
            }
            :nth-child(2) {
              width: 10% !important;
            }
            :nth-child(3) {
              width: 10% !important;
            }
          }
        }
        :nth-child(2) {
          th {
            :first-child {
              div {
                margin-left: 28px;
              }
            }
          }
        }
        &.toggled {
          font-weight: 700 !important;
          font-size: 14px;
          line-height: 16px;
          color: ${COLORS.UI.BackgroundStrong};
          background-color: ${COLORS.Background.NeutralBackgroundMedium};
          button {
            background-color: ${COLORS.Background.NeutralBackgroundMedium};
          }
        }
        th {
          display: table-cell;
          align-items: center;
          justify-content: space-between;
          flex: 4 1 auto;
          padding: 4px 0px 4px 8px;
          gap: 8px;
          font-family: 'SourceSansPro-SemiBold';
          span {
            padding-right: 8px;
          }
          :first-child {
            width: 32px !important;
            svg {
              display: none;
            }
          }
          :nth-child(2) {
            width: 126px !important;
          }
          :nth-child(9) {
            svg {
              display: none;
            }
          }
          :nth-child(10) {
            svg {
              display: none;
            }
          }
        }
      }
    }
    tbody {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      min-width: 100%;
      &#sub-row {
        :nth-child(1),
        :nth-child(3),
        :nth-child(4) {
          div {
            display: none !important;
          }
        }
        :nth-child(2) {
          div {
            margin-right: 8px !important;
          }
        }
        .first-cell {
          width: 2% !important;
        }
      }

      &.toggled {
        width: 100%;
        font-weight: 400px !important;
        font-size: 14px;
        line-height: 16px;
        color: ${COLORS.UI.BackgroundStrong} !important;
        background: rgba(167, 175, 188, 0.3);
        button {
          background-color: ${COLORS.Background.NeutralBackgroundMedium};
          svg {
            background-color: rgba(167, 175, 188, 0.2);
          }
        }
      }
      tr {
        display: table-row;
        align-items: center;
        height: 32px;
        cursor: pointer;
        &#sub-row {
          td {
            :first-child {
              width: 2% !important;
            }
          }
        }
        button {
          svg {
            background-color: transparent;
          }
        }
        &.child-row-two {
          height: 24px !important;

          font-family: 'SourceSansPro-Regular' !important;
          :first-child {
            width: 8px !important;
          }
        }
        &.child-row-four {
          background-color: ${COLORS.Background.NeutralBackgroundMedium};
        }
        &.toggled {
          width: 100%;
          font-weight: 400px !important;
          font-size: 14px;
          line-height: 16px;
          color: ${COLORS.UI.BackgroundStrong} !important;
          background: rgba(167, 175, 188, 0.3);
          button {
            background-color: ${COLORS.Background.NeutralBackgroundMedium};
            svg {
              background-color: rgba(167, 175, 188, 0.2);
            }
          }
          td {
            color: ${COLORS.UI.BackgroundStrong} !important;
          }
        }
        td {
          display: table-cell;
          align-items: center;
          flex: 1 1 auto;
          width: content-width;
          gap: 8px;
          font-family: SourceSansPro-Regular !important;

          &#subtd {
            padding-right: 0px;
          }
          &.toggled {
            color: #2563eb;
          }
          &#first-cell {
            text-align: right !important;
          }

          :first-child {
            width: 3% !important;
            padding: 0 4px;
          }
          :last-child {
            width: 6% !important;
            padding: 0 4px;
          }
          :nth-child(2) { 
            div {
              &.head-border {
                margin-right: 16px;
              }
            }
          }
          :nth-child(3),
          :nth-child(4) {
            div {
              &.head-border {
                display: none;
              }
            }
          }
          &.child-row-head {
            width: 33% !important;
            height: 16px !important;
            font-family: 'SourceSansPro-SemiBold' !important;
            font-style: normal !important;
            font-weight: 700 !important;
            font-size: 12px !important;
            line-height: 16px !important;
            color: #0f172a !important;
            :nth-child(4) {
              padding-right: 0px;
            }
          }
        }
      }
    }
    tfoot {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      min-width: 100%;
      tr {
        display: table-row;
        align-items: center;
        td {
          display: table-cell;
          align-items: center;
          flex: 1 1 auto;
          width: content-width;
          padding: 8px;
          gap: 8px;
          text-align: right;
          :nth-child(2) {
            text-align: left;
          }
        }
      }
    }
    th,
    td {
      white-space: nowrap;
      display: flex;
      align-items: center;
      box-shadow: inset 0px -1px 0px ${COLORS.UI.Gray};
      gap: 8px;
    }
  }
  &#sub-row {
    :first-child {
      width: 1% !important;
    }
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      width: 33% !important;
    }
  }
`

export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ThContentWrapper = styled.div`
  width: 100%;
  padding-right: 8px;
  font-family: 'SourceSansPro-SemiBold' !important;
`

export const TdContentWrapper = styled.div`
  width: 100%;
  padding-right: 8px;
  font-family: 'SourceSansPro-Regular' !important;
`
export const StyledRectangle = styled.div`
  width: 1px !important;
  height: 16px;
  background: ${COLORS.Background.Border} !important;
  border-radius: 1px;
`
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 2.66px;
  }
`
export const SvgWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledBottomDiv = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.UI.Quaternary};
  white-space: pre;
`
export const PaginationWrapper = styled.div`
  display: flex;
  color: ${COLORS.UI.Quaternary};
  align-items: center;
`

export const ViewMoreButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 6px 0px 6px 0px;
  button {
    /* background: none;
    border: none; */
    padding: 0px 8px !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${COLORS.Text.Primary};
    font-family: 'SourceSansPro-SemiBold';
    font-style: normal;
  }
`
export const PendingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  /* align-items: center;
padding: 8px 24px 12px 32px;
gap: 8px; */

  /* width: 856px; */
  height: 64px;
`
export const NoteWrapper = styled.div`
  margin-left: 10px;
`

export const SubWrapper = styled.div`
  margin-top: 4px;
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
`
export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  min-width: 97px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;

  font-family: 'SourceSansPro-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const Space = styled.div`
 width:5px;
 height:5px;
 margin:0 5px;
`
export const TableContentWrap = styled.div`
  white-space:initial !important;
`
