import React, { ReactEventHandler, useState } from "react";
import Label from "../../components/label/Label";

import {
  RadioProps,
  Main,
  Wrapper,
  RadioButton,
  LabelWrap,
  RadioButtonLabel,
  LabelWrap2,
} from "./styles";

export const Radio = ({
  disabled,
  type,
  placeholder,
  required,
  onChange,
  onBlur,
  defaultValue,
  value,
  name,
  id,
  label,
  label2,
  checked,
  selectedValue,
  onClick,
}: RadioProps) => {
  return (
    <RadioButton>
      <Wrapper>
        <Main
          type={"radio"}
          name={name}
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          checked={value === selectedValue}
          required={required}
          onClick={onClick}
          onBlur={onBlur}
          disabled={disabled ? (value === selectedValue ? false : true) : false}
        ></Main>
        <RadioButtonLabel />
      </Wrapper>
      <LabelWrap id='testing'>
        {label && (
          <Label
            fontFamily={"SourceSansPro-SemiBold"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={600}
            lineHeight={"24px"}
            color={disabled ? (value === selectedValue ? "#0F172A" : "#A7AFBC") : "#0F172A" }
          >
            {label}
          </Label>
        )}

        {label2 && (
          <Label
            fontFamily={"SourceSansPro-Regular"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={400}
            lineHeight={"24px"}
            color={disabled ? (value === selectedValue ? "#0F172A" : "#A7AFBC") : "#0F172A" }
          >
            {label2}
          </Label>
        )}
      </LabelWrap>
    </RadioButton>
  );
};
