import styled from "styled-components";
export interface CheckboxProps {
  bgColor?: string;
  title?: string;
  checkBoxId?: string;
  disabled?: boolean;
  onChange?: () => void
}

export const Wrapper = styled.div<CheckboxProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const StyledCheckbox = styled.input<CheckboxProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  // margin: 0rem 0.625rem;
  background-color: ${(props: any) =>
    props.bgColor ? props.bgColor : "#2563EB"};
  border: 0.0625rem solid #d0d7e2;
  accent-color: #2563eb;

  &:checked {
    background-color: "#2563EB";
  }

  &:disabled {
    background: #e2e8f0;
    accent-color: #e2e8f0;
    border-radius: 4px;
  }
`;
export const StyledLabel = styled.label<CheckboxProps>`
  &:disabled {
    color: #a7afbc;
  }
  font-family: SourceSansPro-Regular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
`;
