import React, { useState, createContext, useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
// import { fetchHousehold, getContext } from '../http'
import { defaultHousehold, Household } from './HouseholdInterface'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import useAxiosProtected from '../http/useAxiosProtected'
import { fetchHousehold } from './HouseholdHttp'
import { AuthContext } from '../AuthContext'

interface SetHouseholdCollapse {
  (value: boolean): void | boolean
}

interface HouseholdContextInterface {
  accountID: number | undefined
  household: Household[]
  householdCollapse: boolean
  setHouseholdCollapse: SetHouseholdCollapse
  isLoading: boolean
  error: unknown | null | object
}

export const HouseholdContext = createContext<
  HouseholdContextInterface | undefined | null
>(undefined)

interface HouseholdCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const HouseholdContextProvider = ({
  children,
}: HouseholdCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [household, setHousehold] = useState([defaultHousehold])
  const [accountID, setAccountID] = useState<number>()
  const [householdCollapse, setHouseholdCollapse] = useState(false)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  let accountIdString = searchParams.get('accountId')

  if (accountIdString === undefined || accountIdString ===null){
    accountIdString = "27"
  }
  const accountId = parseInt(accountIdString, 10)
  const approval=useContext(ApprovalContext)

  const clientIdValue:any=approval?.value

  const axiosProtected = useAxiosProtected()
  const authCtx = useContext(AuthContext)
  useEffect(() => {
    async function retrieveHousehold() {
      if(clientIdValue !== undefined && clientIdValue !== ''){
        try {
        
          // const accountIDResponse = await getContext()
          // const parsedResponse = JSON.parse(accountIDResponse)
          // // console.log(parsedResponse.accountId)
          // const accountIdNumber = parseInt(parsedResponse.accountId, 10)
          // setAccountID(accountIdNumber)
          // // console.log("setContext", accountIdNumber)
  
          
          const data = await fetchHousehold(clientIdValue,axiosProtected)
          setHousehold(data)
          setIsLoading(false)
          
        } catch (error: unknown) {
          // console.log(error)
          setError(error)
        }
      }
    }
    if(authCtx?.accessToken){
      retrieveHousehold()
    }
  }, [clientIdValue,authCtx?.accessToken])

  return (
    <HouseholdContext.Provider
      value={{
        accountID,
        household,
        householdCollapse,
        setHouseholdCollapse,
        isLoading,
        error,
      }}
    >
      {children}
    </HouseholdContext.Provider>
  )
}
