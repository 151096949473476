export const SvgApprove = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect width="16" height="16" rx="8" fill="#2563EB" />
      <path
        d="M12.4735 4.80657C12.4115 4.74409 12.3378 4.69449 12.2565 4.66065C12.1753 4.6268 12.0881 4.60938 12.0001 4.60938C11.9121 4.60938 11.825 4.6268 11.7437 4.66065C11.6625 4.69449 11.5888 4.74409 11.5268 4.80657L6.56013 9.77991L4.47346 7.68657C4.40911 7.62441 4.33315 7.57554 4.24992 7.54274C4.16668 7.50993 4.0778 7.49385 3.98834 7.49539C3.89889 7.49694 3.81062 7.51609 3.72857 7.55176C3.64651 7.58742 3.57229 7.63889 3.51013 7.70324C3.44797 7.76759 3.39909 7.84355 3.36629 7.92679C3.33349 8.01002 3.3174 8.09891 3.31895 8.18836C3.3205 8.27781 3.33965 8.36609 3.37531 8.44814C3.41097 8.53019 3.46245 8.60441 3.5268 8.66657L6.0868 11.2266C6.14877 11.2891 6.22251 11.3387 6.30374 11.3725C6.38498 11.4063 6.47212 11.4238 6.56013 11.4238C6.64814 11.4238 6.73527 11.4063 6.81651 11.3725C6.89775 11.3387 6.97149 11.2891 7.03346 11.2266L12.4735 5.78657C12.5411 5.72415 12.5951 5.64838 12.6321 5.56404C12.669 5.47971 12.6881 5.38864 12.6881 5.29657C12.6881 5.20451 12.669 5.11344 12.6321 5.0291C12.5951 4.94477 12.5411 4.869 12.4735 4.80657V4.80657Z"
        fill="white"
      />
    </svg>
  )
}
