import { useContext, useEffect } from 'react'
import { AuthContext } from '../AuthContext'
import axiosInstance from './axiosService'
import { useMsal, useAccount } from '@azure/msal-react'

let failedApiCall = true

const useAxiosProtected = () => {
  const { accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const authCtx = useContext(AuthContext)

  useEffect(() => {
    
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {

          config.headers.Authorization = `Bearer ${authCtx.accessToken}`
          // config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ikl5b3FCWTNXMGNVR2xpRVVDempPTUZYaHB1aF9oN3ZORFBwNlVYN29xVncifQ.eyJpc3MiOiJodHRwczovL3dlZGJ1c2hjbGllbnRzZGV2LmIyY2xvZ2luLmNvbS9lNTdmNGJkNy05YzhiLTQ1YjctYmNmMy04MmE2NmRjMzk0NGQvdjIuMC8iLCJleHAiOjE2ODA2NzQ0MjEsIm5iZiI6MTY4MDY3MzIyMSwiYXVkIjoiYmEzZmQ4ZGEtMmYzNi00YjBmLWEzZDAtNWE2NThlNWMwZDQ3Iiwic2lnbmlubmFtZSI6ImtodXRraW4iLCJzdWIiOiJjOGRjMGU0My1kOGU5LTQwMzAtOTBkZi02MGJjZTZkMDQwYjIiLCJuYW1lIjoiS2VuIEh1dGtpbiIsImdpdmVuX25hbWUiOiJLZW4gSHV0a2luIiwidGlkIjoiZTU3ZjRiZDctOWM4Yi00NWI3LWJjZjMtODJhNjZkYzM5NDRkIiwic2NwIjoiYXBpX2FjY2VzcyIsImF6cCI6ImYwMzQ4MWUxLTExODUtNGQwNS1hY2QyLTM4MTRkODc3ZDI4YSIsInZlciI6IjEuMCIsImlhdCI6MTY4MDY3MzIyMX0.lVaNjvDwq2scWZw9b8ZICqYRsfrVNVYwanz6CKlfXQYVzP7nQHgmgLrIjl9f4KKWMG0luM0XrEyzMWCDPbTmc48TLbGhF3b5qyaA4jZeGEpPedrZ_9o0fbEL4cqs4kAwEl-hRUSgM__xLhplBhD7T2ytK3qFTcL_TGmvzgHnYJRRyE-hZ1sXtdPKIz_bhBOzqdd10kf4lwkMjrxLC5YKlen7xYqxc0aKa7CKfAk_V_84nCoJ3At6q9ku6wQ7fxvBXm6mVztXFz-qFA2RO6Dz9BCzGY_l2U955FVe_0Wl2uQa0_Ua4Bw6mLGizZqnTY4pzM1wGu6U_XxwaOtTb6ye7w`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        const expDate = new Date(account?.idTokenClaims?.exp * 1000),
          now = new Date()
        const forceRefreshPage = expDate < now
        if (forceRefreshPage) {
          failedApiCall = true
        }
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true
          if (failedApiCall) {
            failedApiCall = false
            const newAccessToken = authCtx?.b2cRefreshToken()
             
            if (newAccessToken) {
              prevRequest.headers.Authorization = `Bearer ${authCtx.accessToken}`
              return axiosInstance(prevRequest)
            }
          } else {
            
            prevRequest.headers.Authorization = `Bearer ${authCtx.accessToken}`
            return axiosInstance(prevRequest)
          }
        }
        return Promise.reject(error)
      }
    )
    
      return () => {
        axiosInstance.interceptors.request.eject(requestIntercept)
        axiosInstance.interceptors.response.eject(responseIntercept)
      }
    
  }, [account?.idTokenClaims?.exp, authCtx, authCtx?.accessToken])
  return axiosInstance
}

export default useAxiosProtected
