import { useContext, useState, useEffect } from 'react'
import { ModalWrapper } from '../addressChangePopup/styles'
import {  useToast } from '@chakra-ui/react'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
} from '../../components/ui/WDTypography'
import {
  WDStyledCancelButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  ButtonRow,
  CloseWrapper,
  ContactPopupWrapper,
  DividerWrapper,
  DividerWrapper2,
  HeaderRow,
  ListItems,
  PrimaryButtonWrapper,
  SecondaryButtonWrapper,
  SubHeadingWrapper,
} from './styles'
import { SvgCancel } from '../../components/svg/SvgCancel'
import Divider from '../../components/divider/Divider'
import Toast from '../../components/toast/Toast'
import {
  AccountOtherRestrictionTypePayload,
  AccountRestrictionsInterface,
  AccountRestrictionsTypePayload,
} from '../../services/accountRestrictionsContext/AccountRestrictionsInterface'
import {
  addUpdateAccountRestrictionsTypes,
  addUpdateOtherAccountRestrictionsTypes,
} from '../../services/accountRestrictionsContext/AccountRestrictionsHttp'
import useAxiosProtected from '../../services/http/useAxiosProtected'
import { AccountRestrictionsContext } from '../../services/accountRestrictionsContext/AccountRestrictionsContext '
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'

const AccountRestrictionsPopup = () => {
  const {
    accountRestrictionsDetails,
    originalAccountRestrictionsDetails,
    setAccountRestrictionsPopup,
    reloadAccountRestrictions,
    setIsLoading,
    setIsEdited,
  } = useContext(AccountRestrictionsContext)
  //const accountDetailsValues = useContext(AccountDetailsContext)
  const toast = useToast()
  const axiosProtected = useAxiosProtected()
  const approval = useContext(ApprovalContext)
  const reloadAccountRestrictionsUI = () => {
    reloadAccountRestrictions()
    setTimeout(() => {
      setIsLoading(true)
    }, 200)
    setIsLoading(false)
  }
  const [disabled, setDisabled] = useState(false)

  const ExcessiveFedCallLiquidation = 'Excessive Fed Call Liquidation (Funds on Hand Margin)'
  const ExcessiveRegTCashLiquidation = 'Excessive Reg-T Cash Type Liquidation'

  const [filteredRestrictions, setFilteredRestrictions] =
    useState<AccountRestrictionsInterface>()
  useEffect(() => {
    if (accountRestrictionsDetails.restrictionTypes !== undefined) {
      const filteredData = accountRestrictionsDetails.restrictionTypes.filter(
        (restriction, index) => {
          if (
            (originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              restriction.enabled &&
              restriction.isModified) ||
            (originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              !restriction.enabled &&
              restriction.isModified) ||
            (!originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              restriction.enabled &&
              restriction.isModified) ||
            (restriction.isModified && restriction.newViolation != null)
          )
            return restriction
        }
      )
      setFilteredRestrictions({
        ...accountRestrictionsDetails,
        restrictionTypes: filteredData,
      })
    }
  }, [accountRestrictionsDetails.restrictionTypes])
  console.log('Payload,', accountRestrictionsDetails, filteredRestrictions)
  const handleSubmit = async () => {
    setDisabled(true)

    const clientIdValue: any = approval?.value
    const accountId = clientIdValue //parseInt(clientIdValue!, 10)

    const payloadList = [] as AccountRestrictionsTypePayload[]
    const otherPayloadList = [] as AccountOtherRestrictionTypePayload[]

    const selectedRestrictionTypes =
      filteredRestrictions?.restrictionTypes ?? []

    for (let i = 0; i <= selectedRestrictionTypes.length - 1; i++) {
      const pload = {} as AccountRestrictionsTypePayload
      pload.accountId = accountId
      pload.accountRestrictionId =
        selectedRestrictionTypes[i]?.accountRestrictionId
      pload.restrictionTypeId = selectedRestrictionTypes[i]?.restrictionTypeId
      pload.note = selectedRestrictionTypes[i]?.note
      pload.violationNumber = selectedRestrictionTypes[i]?.newViolation
      pload.restrictionStatus = selectedRestrictionTypes[i].enabled
        ? 'ENABLED'
        : 'DISABLED'
      pload.startDate =
        selectedRestrictionTypes[i]?.startDate != undefined
          ? new Date(selectedRestrictionTypes[i].startDate as string)
              .toISOString()
              .split('T')[0]
          : undefined
      pload.endDate =
        selectedRestrictionTypes[i]?.endDate != undefined &&
        selectedRestrictionTypes[i]?.endDate !== ''
          ? new Date(selectedRestrictionTypes[i].endDate as string)
              .toISOString()
              .split('T')[0]
          : undefined
      payloadList.push(pload)
    }

    if (accountRestrictionsDetails.otherOnlyEnabled) {
      const otherPayload = {} as AccountOtherRestrictionTypePayload
      otherPayload.accountId = accountId
      otherPayload.defaulttermdays = 90
      otherPayload.name = accountRestrictionsDetails.otherRestrictionType
      otherPayload.description = accountRestrictionsDetails.otherInfo
      otherPayload.restrictionlevel = 'WARNING'
      otherPayload.note = accountRestrictionsDetails.otherInfo
      otherPayloadList.push(otherPayload)
    }

    //etAccountRestrictionsPayload(payloadList)
    if (payloadList.length > 0 || otherPayloadList.length > 0) {
      const dataResponse = await addUpdateAccountRestrictionsTypes(
        payloadList,
        axiosProtected
      )
      if (dataResponse == 200) {
        if (otherPayloadList.length > 0) {
          const otherResponse = await addUpdateOtherAccountRestrictionsTypes(
            otherPayloadList,
            axiosProtected
          )
        }
        toast({
          position: 'top-right',
          duration: null,
          isClosable: true,
          render(props: { onClose: () => void }) {
            return (
              <Toast
                title={'Changes Saved'}
                description={
                  'Account Restrictions changes have been successfully updated.'
                }
                variant={'success'}
                onClose={props.onClose}
              />
            )
          },
          async onCloseComplete() {
            console.log('')
          },
        })
        setAccountRestrictionsPopup(false)
      } else if (dataResponse == 500) {
        toast({
          position: 'top-right',
          duration: null,
          isClosable: true,
          render(props: { onClose: () => void }) {
            return (
              <Toast
                title={'Account Restrictions update failed'}
                description={'Account Restrictions changes updade failed.'}
                variant={'critical'}
                onClose={props.onClose}
              />
            )
          },
          async onCloseComplete() {
            console.log('')
          },
        })
        setAccountRestrictionsPopup(false)
      }
    }
    reloadAccountRestrictionsUI()
  }

  return (
    <ModalWrapper>
      <ContactPopupWrapper>
        <HeaderRow>
          <WDNewProfileSidebarMenu>
            Account Restrictions
          </WDNewProfileSidebarMenu>
          <CloseWrapper onClick={() => setAccountRestrictionsPopup(false)}>
            <SvgCancel />
          </CloseWrapper>
        </HeaderRow>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>
        <SubHeadingWrapper>
          <WDNewProfileSidebarMenu></WDNewProfileSidebarMenu>
        </SubHeadingWrapper>
        <p>
          <b>Please Confirm the following changes:</b>
        </p>
        <ListItems>
          <ul>
            {filteredRestrictions?.restrictionTypes &&
              filteredRestrictions?.restrictionTypes?.map((row) => {
                return (
                  <>
                    {row.isModified && (
                      <li>
                        {row.name === ExcessiveFedCallLiquidation &&
                          (row.newViolation != null
                            ? row.newViolation === 1
                              ? 'Excessive Fed Call Liquidation: 1st Violation'
                              : row.newViolation === 2
                              ? 'Excessive Fed Call Liquidation: 2nd Violation'
                              : row.newViolation === 0
                              ? 'Reset 90 Day Restriction (Excessive Fed Call Liquidation (Funds on Hand Margin))'
                              : row.enabled
                              ? 'Enabled 90 Day Restriction (Excessive Fed Call Liquidation (Funds on Hand Margin))'
                              : 'Disabled 90 Day Restriction (Excessive Fed Call Liquidation (Funds on Hand Margin))'
                            : row.enabled
                            ? 'Enabled 90 Day Restriction (Excessive Fed Call Liquidation (Funds on Hand Margin))'
                            : 'Disabled 90 Day Restriction (Excessive Fed Call Liquidation (Funds on Hand Margin))')}
                        {row.name === ExcessiveRegTCashLiquidation &&
                          (row.newViolation != null
                            ? row.newViolation === 1
                              ? 'Excessive Reg-T Cash Type Liquidation: 1st Violation'
                              : row.newViolation === 2
                              ? 'Excessive Reg-T Cash Type Liquidation: 2nd Violation'
                              : row.newViolation === 0
                              ? 'Reset 90 Day Restriction (Excessive Reg-T Cash Type Liquidation)'
                              : row.enabled
                              ? 'Enabled 90 Day Restriction (Excessive Reg-T Cash Type Liquidation)'
                              : 'Disabled 90 Day Restriction (Excessive Reg-T Cash Type Liquidation)'
                            : row.enabled
                            ? 'Enabled 90 Day Restriction (Excessive Reg-T Cash Type Liquidation)'
                            : 'Disabled 90 Day Restriction (Excessive Reg-T Cash Type Liquidation)')}
                        {row.name === ExcessiveFedCallLiquidation ||
                        row.name === ExcessiveRegTCashLiquidation
                          ? ''
                          : row.enabled
                          ? `Enabled ${
                              row.defaultTermDays != null ? '90 Day' : ''
                            } Restriction (${row.name})`
                          : `Disabled ${
                              row.defaultTermDays != null ? '90 Day' : ''
                            } Restriction (${row.name})`}
                      </li>
                    )}
                  </>
                )
              })}
            {accountRestrictionsDetails.otherOnlyEnabled && (
              <li>
                Enabled 90 Day Restriction (
                {accountRestrictionsDetails.otherRestrictionType})
              </li>
            )}
          </ul>
        </ListItems>

        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>

        <ButtonRow>
          <SecondaryButtonWrapper>
            <WDStyledCancelButton
              onClick={() => {
                setAccountRestrictionsPopup(false)
                setIsEdited(false)
                reloadAccountRestrictionsUI()
                //accountDetailsValues?.setSubmissionTableData([])
              }}
            >
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper>
          <PrimaryButtonWrapper>
            <WDStyledSaveButton
              onClick={() => handleSubmit()}
              disabled={disabled}
            >
              <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper>
        </ButtonRow>
      </ContactPopupWrapper>
    </ModalWrapper>
  )
}

export default AccountRestrictionsPopup
