import styled from 'styled-components'
import { TagProps } from './ITag'

export const IconLabelWrapper = styled.div`
  display: flex;
  background-color: ${(TagProps: TagProps) => TagProps.BgColor};
  gap: 5px;
  border-radius: 1000px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
`
