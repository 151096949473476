import { useState } from 'react'
import { Icon } from '../../components/icon/Icon'
import Label from '../../components/label/Label'
import { SvgClient_16 } from '../../components/svg/SvgClient-16'
import { TagProps } from './ITag'
import { IconLabelWrapper, IconWrapper } from './styles'

export const Tag = ({ ClientType, IconPath, FontColor, BgColor, fillColor, sidebar }: TagProps) => {
  return (
    <IconLabelWrapper BgColor={BgColor}>
      {sidebar && (
        <IconWrapper>
          <SvgClient_16 fillColor={FontColor} />
        </IconWrapper>
      )}
      <Label
        fontFamily="SourceSansPro-Regular"
        fontSize="14px"
        fontStyle="normal"
        fontWeight={400}
        lineHeight="16px"
        color={FontColor}
      >
        {ClientType}
      </Label>
    </IconLabelWrapper>
  )
}
