import { useContext, useEffect } from 'react'
import './App.css'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  IPublicClientApplication,
} from '@azure/msal-browser'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import AppRoutes from './AppRoutes'
import { ChakraProvider } from '@chakra-ui/react'
import { } from './theme'
import AuthContextProvider, { AuthContext } from './services/AuthContext'
import { loginRequest } from './settings/authConfig'
import appConfig from './settings/appConfig'
import { useLocation } from 'react-router-dom'

type AppProps = {
  pca: IPublicClientApplication
}

const App = ({ pca }: AppProps) => {
  const authRequest = {
    ...loginRequest,
  }
  const authContext = useContext(AuthContext)
  // const { setUser } = useContext(AuthContext)

  // console.log(authContext, 'authContext..')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const getContextKey = searchParams.get('key')
  if (getContextKey !== undefined && getContextKey !== null) {
    // const keyValue = parseInt(getContextKey)
    // if (keyValue)
    localStorage.setItem('key', getContextKey)
  }

  pca.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      pca.setActiveAccount(account)
      const user = {
        adId: account?.username,
        name: account?.name,
      }
      // setUser({ ...user })
      authContext?.setIdToken(payload.idToken)
    }
  })
  // useEffect(() => {
  //   console.log("Token:", authContext?.accessToken)
  //   console.log(appConfig,"appConfig...")
  // }, [authContext?.accessToken])


  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}>
       <ChakraProvider>
        <AuthContextProvider>
          <AppRoutes />
              </AuthContextProvider>
       </ChakraProvider>

      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

export default App
