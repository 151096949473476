import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'

export async function fetchHousehold(accountId: string,axiosProtected:AxiosInstance) {
    const url = URLs.HOUSEHOLD_GET_API
    const payload = {
      accountNumber: accountId,
    }
    const response = await axiosProtected.post(url, payload)
    const data = response.data
    // console.log('🚀 ~ Fetch Household =>', data)
    return data
  }
