import React from 'react'
import { Textbox } from '../../components/textbox/Textbox'
import { WDNewProfileSidebarMenu } from '../../components/ui/WDTypography'
import { InputFieldProps } from './IInputField'
import { InputWrapper, LabelWrap, MandatoryLabel, TextBoxWrap } from './styles'

export const InputField = ({
  disabled,
  label,
  type,
  textboxPlaceholder,
  textboxDefaultValue,
  name,
  id,
  max,
  borderColor,
  backgroundColor,
  textboxValue,
  onChange,
  mandatory,
}: InputFieldProps) => {
  return (
    <InputWrapper>
      <LabelWrap>
        <WDNewProfileSidebarMenu>{label}</WDNewProfileSidebarMenu>
        {mandatory && <MandatoryLabel>{' *'}</MandatoryLabel>}
      </LabelWrap>
      <TextBoxWrap>
        <Textbox
          placeholder={textboxPlaceholder}
          type={type}
          disabled={disabled}
          defaultValue={textboxDefaultValue}
          name={name}
          id={id}
          max={max}
          borderColor={borderColor}
          backgroundColor={backgroundColor}
          onChange={onChange}
          value={textboxValue}
        />
      </TextBoxWrap>
    </InputWrapper>
  )
}
