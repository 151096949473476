import React, { useState, createContext, useEffect, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getContext } from '../http'
import { AccountRoles, defaultAccountRoles } from './AccountRolesInterface'
import axios from 'axios'
import useAxiosProtected from '../http/useAxiosProtected'
import { fetchAccountRoles } from './AccountsRolesHttp'
import { AuthContext } from '../AuthContext'
import { ApprovalContext } from '../approvalContext/ApprovalContext'

interface SetAccountRolesCollapse {
  (value: boolean): void | boolean
}

interface AccountRolesContextInterface {
  accountID: number | undefined
  accountRoles: AccountRoles[] | undefined
  accountRolesCollapse: boolean
  setAccountRolesCollapse: SetAccountRolesCollapse
  isLoading: boolean
  error: unknown | null | object
}

export const AccountRolesContext = createContext<
  AccountRolesContextInterface | undefined | null
>(undefined)

interface AccountRolesCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const AccountRolesContextProvider = ({
  children,
}: AccountRolesCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [accountRoles, setAccountRoles] = useState<AccountRoles[]>()
  const [accountID, setAccountID] = useState<number>()
  const [accountRolesCollapse, setAccountRolesCollapse] = useState(false)

  const approval = useContext(ApprovalContext)

  const axiosProtected = useAxiosProtected()
  const authCtx = useContext(AuthContext)


  useEffect(() => {
    async function retrieveAccountRoles() {
      if(approval?.value!==undefined && approval?.value!==''){
      try {
        const data = await fetchAccountRoles(approval?.value, axiosProtected)
        setAccountRoles(data)
        setIsLoading(false)
      } catch (error: unknown) {
        // console.log(error)
        setError(error)
      }
    }
  }
    if(authCtx?.accessToken){
      retrieveAccountRoles()
    }
  }, [approval?.value, authCtx?.accessToken])

  return (
    <AccountRolesContext.Provider
      value={{
        accountID,
        accountRoles,
        accountRolesCollapse,
        setAccountRolesCollapse,
        isLoading,
        error,
      }}
    >
      {children}
    </AccountRolesContext.Provider>
  )
}
