import styled from 'styled-components'
import { colors } from '../../shared/styles'
import { SidebarMenuProps } from './ISidebarMenu'

export const LabelWrapper = styled.div<SidebarMenuProps>`
  /* padding: 0.5rem 0.5rem 0.5rem 0rem; */

  &:select {
    background: ${colors.Primary50};
    color: ${colors.NeutralsWhite};
  }
` /* This is a container that wraps the label */

export const SidebarMenuWrapper = styled.div<SidebarMenuProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background-color: ${(SidebarMenuProps: SidebarMenuProps) =>
    SidebarMenuProps.bgColor};

  &:hover {
    background: ${(SidebarMenuProps: SidebarMenuProps) =>
      SidebarMenuProps.hoverBgColor};
    color: ${colors.NeutralsWhite};
  }

  &:active {
    color: ${colors.NeutralsWhite};
    font-weight: 700;
    font-family: SourceSansPro-Regular;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &:select {
    background: ${colors.BackgroundStrongActive};
    color: ${colors.NeutralsWhite};
    font-weight: 700;
    font-family: SourceSansPro-Regular;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  &:visited {
    background: ${colors.Primary50};
    color: ${colors.NeutralsWhite};
    font-weight: 700;
    font-family: SourceSansPro-Regular;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
  }
` /* This is a parent container of the Sidebar menu basic component */

export const ListIconWrapper = styled.div<SidebarMenuProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 1;
  margin: 0px 0px 0.5px 1rem;
  flex: 1 1 12%;
`
