/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { TextboxProps } from './ITextbox'
import { Main, Wrapper } from './styles'

export const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
  (props, ref) => {
    return (
      <Wrapper
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange?.(e.target.value)
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          props.onKeyDown?.(event.key)
        }}
      >
        <Main
          type='Date'
          name={props.name}
          id={props.id}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          value={props.value}
          disabled={props.disabled}
          required={props.required}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          ref={ref as React.RefObject<HTMLInputElement>}
          max={props.max}
          min={props.min}
          borderColor={props.borderColor}
          backgroundColor={props.backgroundColor}
        />
      </Wrapper>
    )
  }
)
