export const messages = {
  account_details_ui: {
    title: 'Account Details',
    field_names:{
      personal:'Personal',
      alternate:'Alternate',
      mobile_phone: 'MobilePhone',
      home_phone: 'HomePhone',
      business_phone: 'BusinessPhone',
      fax_phone: 'Fax',
      phone:'phone',
      email:'email',
    },
    phone_list: {
      mobile_phone: 'Mobile Phone Number',
      home_phone: 'Home Phone Number',
      business_phone: 'Business Phone Number',
      fax_phone: 'Fax Number',
    },
    phone_error: {
      phone_length: (value: string) => `Enter 10 digit ${value} Number !`,
      no_error: '',
      phone_empty: `Empty value can't be saved.`,
    },
    email_error: {
      email_empty: `Empty value can't be saved.`,
      email_invalid: `Please provide a valid Email ID !`,
      no_error: '',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
      edit: 'Edit',
      delete: 'Delete',
      add: 'Add',
      remove: 'Remove',
    },
    error_message: {
      error: 'Error',
      error_message: 'Something went wrong. Please try again later.',
    },
    success_message: {
      success: 'Success',
      success_message:
        'This request has been submitted for review and approval.',
      success_message_remove_phone: (value: string) =>
        `Hey! You have successfully removed the ${value} number from Account Details`,
      success_message_remove_email: (value: string) =>
        `Hey! You have successfully removed the ${value} email address from Account Details`,
    },
    email_regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  confimation_dialog: {
    title: 'Confirmation',
    message_phone: (value: string) =>
      `Hey! Are you sure you want to remove ${value} number from Account Details? `,
    message_email: (value: string) =>
      `Hey! Are you sure you want to remove ${value} email address from Account Details? `,
    cancel: 'No',
    delete: 'Yes',
  },
  error_modal: {
    title: 'Error',
    message: 'Something went wrong. Please try again later.',
    ok: 'Ok',
  },    
  address_ui:{
    error_messages: {
      required_field: "This field is required!",
      valid_zip_code_combination:'Enter a valid Zip Code, State and City combination !',
      valid_start_date: 'Enter a valid Start Date !',
      start_date_after_end_date: `Start date should not fall after End date. \n \n Provide correct value for start date and end date !`,
      start_date_before_today: `Start date should not fall before Today's date`,
      valid_end_date: 'Enter a valid End Date !',
      end_Date_after_today: `Enter a valid End Date, End Date > Today's Date !`,
    }
  },
  audit_trail: {
    title: 'Audit Trail',
    no_audit_trail: "Hey! there are no Audit Details for this Account/Party.",
    success_messages: {
        cancel_success: 'This request has been cancelled successfully.',
    },
  },

  account_loan_details: {
    title: 'Account Loan Details',

  }
}
