import styled from 'styled-components'
import { colors } from '../../../shared/styles'
import { COLORS } from '../../../theme/Colors'

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  color: #374253;
  height: 100%;
  padding: 24px 0% 0% 20%;
  /* width: 13rem; */
  background-color: ${colors.BackgroundWeak};
`

export const StyledMenuListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.NeutralsWhite};
  padding: 8px 16px;
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  margin-top: 64px;
  margin-bottom: 16px;
`

export const StyledMenuListContainer2 = styled.div`
  background-color: ${colors.NeutralsWhite};
  padding: 16px;
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  margin-bottom: 16px;
`

export const FlexItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 8px; */
  gap: 4px;
  p {
    line-height: 24px;
  }
`


export const FlexItem3 = styled.div`
display: flex;
flex-direction: column;
position: fixed;
color: black;
width: 100%;
margin-top:-27px;
background-color:${colors.BackgroundWeak};
padding:9px 0px 14px ;
z-index: 1;
height: 75px;
align-items: flex-start;
justify-content: flex-start;
p {
z-index: 1;
}
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  min-width: 144px;
  max-height: 32px;
  z-index: 2;
  // margin-top:2.5rem;
  // position:absolute;

  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const StyledAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px;
`

export const DividerWrapper2 = styled.div`
  display: flex;
  width: 91.7vw;
  position: fixed;
  top: 64px;
  justify-content: center;
  margin: 8px 0px;
  background: ${colors.BackgroundWeak};
  /* padding-right: 30px; */
`

export const TagWrap = styled.div`
  height: 24px;
  padding: 12px 0px 16px 0px;
  margin-bottom: 16px;
`

export const LabelWrap = styled.div`
  display: flex;
  justify-content: center;
`

export const DropdownWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  div {
    :nth-child(3) {
      border: none;
      div {
        font-family: SourceSansPro-Bold !important;
        font-size: 20px;
        line-height: 24px;
        padding-right: 0px;
      }
    }
  }
`
