import React, { useContext, useState, useEffect } from 'react'
import {
  ButtonRow,
  CancelWrap,
  DividerWrapper,
  EditWrapper,
  FlexItem,
  HeaderRow,
  IconWrapper,
  InlineErrorWrapper,
  LabelWrapper,
  MasterWrapper,
  NotesWrapper,
  PrimaryButtonWrapper,
  Row,
  SecondaryButtonWrapper,
  TertiaryButtonWrapper,
  TitleIconWrap,
  TopRow,
} from './styles'
import { AccountLoanDetailsContext } from '../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import {
  WDReadModeTitle,
  WDReadModeValue,
  WDStyledCancelButtonLabel,
  WDStyledEditButtonLabel,
  WDStyledSectionTitle,
  WDTableLabel,
} from '../../components/ui/WDTypography'
import { messages } from '../../shared/constants'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import {
  WDStyledCancelButton,
  WDStyledEditButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { AuthContext } from '../../services/AuthContext'
import Divider from '../../components/divider/Divider'
import { COLORS } from '../../theme/Colors'
import { LabelWrap } from '../inputField/styles'
import { DropdownField } from '../dropdownField/DropdownField'
import AccountLoanDetailPopUp from './AccountLoanDetailPopUp'
import { AccountPledgedSubmissionPayload } from '../../services/accountLoanDetailsContext/AccountLoanDetailsInterface'
import { AuditTrailContext } from '../../services/auditTrailContext/AuditTrailContext'
import { checkIsP3Account } from '../../shared/helper'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { LoaderRow } from '../accountDetailsUI/styles'
import Loader from '../../components/loader/Loader'
import {
  ApprovedWrapper,
  ChangeTextWrapper,
} from '../../pages/landingPage/headerContent/styles'
import { SvgCheck } from '../../components/svg/SvgCheck'
import { SvgCancel } from '../../components/svg/SvgCancel'
import Notes from '../../components/notes/Notes'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import Label from '../../components/label/Label'

const AccountLoanDetailsUI = () => {
  const authCtx = useContext(AuthContext)
  const approval = useContext(ApprovalContext)
  const accountDetails = useContext(AccountDetailsContext)
  const auditTrailValues = useContext(AuditTrailContext)
  const accountLoadDetailsContext = useContext(AccountLoanDetailsContext)
  const err = accountLoadDetailsContext?.errorMsg
  const [pledgedStatusDropDown, setPledgedStatusDropDown] = useState<any>()
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [pledgedStatusPayload, setPledgedStatusPayload] = useState<any>({
    pledgedAccountStatus: '',
    notes: '',
    isNotesMandatory: false,
    createdBy: '',
  })
  const [notes, setNotes] = useState<string>('' as string)
  const [isNotesMandatory, setIsNotesMandatory] = useState(false)

  useEffect(() => {
    if (accountLoadDetailsContext?.pledgedAccountData !== undefined) {
      setPledgedStatusPayload({
        ...pledgedStatusPayload,
        pledgedAccountStatus:
          accountLoadDetailsContext?.pledgedAccountData?.accountPledgedWith,
      })
    }
  }, [accountLoadDetailsContext?.pledgedAccountData])

  useEffect(() => {
    if (
      accountLoadDetailsContext?.pledgedAccountStatusDropDownList !== undefined
    ) {
      const filterData =
        accountLoadDetailsContext?.pledgedAccountStatusDropDownList?.filter(
          (item) => item?.pledgedStatus !== 'Other'
        )
      const data = filterData?.map((item) => {
        return {
          label: item.pledgedStatus,
          value: item.sValue,
        }
      })
      setPledgedStatusDropDown(data)
    }
  }, [accountLoadDetailsContext?.pledgedAccountStatusDropDownList])

  const handleDropDownChange = (e: string, field: string) => {
    if (e !== undefined) {
      if (field === 'Pledged Account Status') {
        pledgedStatusPayload.pledgedAccountStatus = Object.values(e)[0]
        pledgedStatusPayload.pledgedAccountStatusId = Object.values(e)[1]
        pledgedStatusPayload.notes = ''
        setNotes('')
        setPledgedStatusPayload({ ...pledgedStatusPayload })
      }
    }
  }

  const handleChange = (e: string) => {
    setIsNotesMandatory(false)
    setNotes(e?.replace(/\n/g, ''))
  }

  const collapse = () => {
    accountLoadDetailsContext?.setAccountLoanDetailsCollapse(
      !accountLoadDetailsContext?.accountLoanDetailsCollapse
    )
  }

  const reloadApprovalTrail = () => {
    approval?.retrieveAuditTrail()
    setTimeout(() => {
      approval?.setIsLoading(true)
    }, 200)
    approval?.setIsLoading(false)
  }

  const reloadAuditTrail = () => {
    auditTrailValues?.retrieveAuditTrail()
    setTimeout(() => {
      auditTrailValues?.setIsLoading(true)
    }, 200)
    auditTrailValues?.setIsLoading(false)
  }

  const reloadAccountLoanDetailsUI = () => {
    accountLoadDetailsContext?.getAccountLoanDetails()
    accountLoadDetailsContext?.setAccountLoanDetailsCollapse(false)
    approval?.setIsActiveAl(!approval?.isActiveAl)
  }

  useEffect(() => {
    if (
      pledgedStatusPayload.pledgedAccountStatus !== undefined &&
      accountLoadDetailsContext?.pledgedAccountData?.accountPledgedWith !==
      pledgedStatusPayload.pledgedAccountStatus
    ) {
      setDisableSaveButton(false)
    } else {
      setDisableSaveButton(true)
    }
  }, [
    accountLoadDetailsContext?.pledgedAccountData?.accountPledgedWith,
    pledgedStatusPayload.pledgedAccountStatus,
  ])

  const editFunction = () => {
    approval?.setIsActiveAl(!approval?.isActiveAl)
    accountLoadDetailsContext?.setAccountLoanDetailsCollapse(true)
  }

  const cancel = () => {
    approval?.setIsActiveAl(!approval?.isActiveAl)
    setPledgedStatusPayload({
      ...pledgedStatusPayload,
      pledgedAccountStatus:
        accountLoadDetailsContext?.pledgedAccountData?.accountPledgedWith,
      notes: '',
    })
    setNotes('')
  }

  if (accountLoadDetailsContext?.isLoading) {
    return (
      <MasterWrapper>
        <TopRow>
          <HeaderRow>
            <TitleIconWrap>
              <IconWrapper>{/* <SvgAccountLoanDetails /> */}</IconWrapper>
              <WDStyledSectionTitle>
                {'Account Loan Details'}
              </WDStyledSectionTitle>
            </TitleIconWrap>
          </HeaderRow>
        </TopRow>
        {
          <DividerWrapper>
            <Divider
              bgColor={COLORS.Background.Border}
              horizontal={true}
              thickness="1px"
            />
          </DividerWrapper>
        }
        <LoaderRow>
          <Loader />
        </LoaderRow>
      </MasterWrapper>
    )
  }

  const reload = () => {
    accountLoadDetailsContext?.setErrorMsg(100)
    reloadApprovalTrail()
    reloadAuditTrail()
    setTimeout(() => {
      reloadAccountLoanDetailsUI()
      approval?.setIsActiveAl(!approval?.isActiveAl)
    }, 1000)
  }

  const save = () => {
    if (notes === '') {
      pledgedStatusPayload.isNotesMandatory = true
      setIsNotesMandatory(true)
    } else {
      pledgedStatusPayload.isNotesMandatory = false
      setIsNotesMandatory(false)
    }
    pledgedStatusPayload.createdBy = authCtx?.account?.name
    pledgedStatusPayload.notes = notes
    if (
      !isNotesMandatory &&
      (notes !== '' &&
        accountLoadDetailsContext?.pledgedAccountData?.accountPledgedWith !==
        pledgedStatusPayload?.pledgedAccountStatus)
    ) {
      accountLoadDetailsContext?.setAccountLoanDetailPopUpPayload(
        pledgedStatusPayload as AccountPledgedSubmissionPayload
      )
      accountLoadDetailsContext?.setAccountLoanDetailsPopUp(true)
    }
  }

  return (
    <MasterWrapper
      borderShadow={
        accountLoadDetailsContext?.accountLoanDetailsCollapse ? true : false
      }
      id="account-loan-detail"
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper></IconWrapper>
            <WDStyledSectionTitle>
              {messages.account_loan_details.title}
            </WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <TertiaryButtonWrapper>
          {authCtx?.userRoles?.includes('AM-ACCOUNTLOAN') &&
            !approval?.isActiveAl && (
              <>
                {!approval?.isActiveAct &&
                  !approval?.isActiveInv &&
                  !approval?.isActiveAd && (
                    <WDStyledEditButton onClick={() => editFunction()}>
                      <WDStyledEditButtonLabel>
                        {' '}
                        {'Edit'}
                      </WDStyledEditButtonLabel>
                    </WDStyledEditButton>
                  )}
              </>
            )}
          <EditWrapper onClick={() => collapse()}>
            {accountLoadDetailsContext?.accountLoanDetailsCollapse && (
              <SvgChevronUp />
            )}
            {!accountLoadDetailsContext?.accountLoanDetailsCollapse && (
              <SvgChevronDown />
            )}
          </EditWrapper>
        </TertiaryButtonWrapper>
      </TopRow>
      {accountLoadDetailsContext?.accountLoanDetailsCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

      {accountLoadDetailsContext?.accountLoanDetailsCollapse && (
        <>
          {!approval?.isActiveAl && (
            <React.Fragment>
              <Row>
                <FlexItem>
                  <LabelWrap>
                    <WDReadModeTitle>
                      {'Pledged Account Status'}
                    </WDReadModeTitle>
                  </LabelWrap>
                  <LabelWrap>
                    <WDReadModeValue>
                      {accountLoadDetailsContext?.pledgedAccountData
                        ?.accountPledgedWith ? (
                        accountLoadDetailsContext?.pledgedAccountData
                          ?.accountPledgedWith
                      ) : (
                        <span style={{ paddingLeft: '15px' }}>{'--'}</span>
                      )}
                    </WDReadModeValue>
                  </LabelWrap>
                </FlexItem>
              </Row>
            </React.Fragment>
          )}
          {approval?.isActiveAl && (
            <React.Fragment>
              <Row>
                <FlexItem>
                  <DropdownField
                    label="Pledged Account Status"
                    defaultValue={{
                      label: accountLoadDetailsContext?.pledgedAccountData
                        ?.accountPledgedWith as string,
                      value: accountLoadDetailsContext?.pledgedAccountData
                        ?.pledgedAccountsId as number,
                    }}
                    onChange={(e: any) =>
                      handleDropDownChange(e, 'Pledged Account Status')
                    }
                    options={pledgedStatusDropDown}
                  />
                </FlexItem>
              </Row>

              <NotesWrapper>
                <Notes
                  onChange={(notes: string) => {
                    handleChange(notes)
                  }}
                  note={pledgedStatusPayload.notes ?? ''}
                />
              </NotesWrapper>
              {isNotesMandatory && (
                <InlineErrorWrapper style={{ marginBottom: '8px' }}>
                  <SvgExclamation />
                  <Label
                    fontSize={'14px'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    lineHeight={'16px'}
                    color={'#BA0517'}
                  >
                    {messages.address_ui.error_messages.required_field}
                  </Label>
                </InlineErrorWrapper>
              )}
              <ButtonRow>
                <SecondaryButtonWrapper>
                  <WDStyledCancelButton onClick={() => cancel()}>
                    <WDStyledCancelButtonLabel>
                      {'Cancel'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledCancelButton>
                </SecondaryButtonWrapper>
                <PrimaryButtonWrapper>
                  <WDStyledSaveButton
                    // disabled={disableSaveButton}
                    onClick={() => save()}
                  >
                    <WDStyledCancelButtonLabel>
                      {'Save'}
                    </WDStyledCancelButtonLabel>
                  </WDStyledSaveButton>
                </PrimaryButtonWrapper>
              </ButtonRow>
            </React.Fragment>
          )}
        </>
      )}
      {accountLoadDetailsContext?.accountLoanDetailsPopUp && (
        <AccountLoanDetailPopUp />
      )}
      {err === undefined || err === 100 ? null : err === 200 ? (
        <ApprovedWrapper>
          <Row>
            <SvgCheck />
            <ChangeTextWrapper>
              <WDTableLabel>{'Changes Saved'}</WDTableLabel>
            </ChangeTextWrapper>
            <CancelWrap onClick={() => reload()}>
              <SvgCancel />
            </CancelWrap>
          </Row>
          <LabelWrapper>
            {'Account Pledged Status Updated Successfully.'}
          </LabelWrapper>
        </ApprovedWrapper>
      ) : (
        <ApprovedWrapper>
          <Row>
            <SvgCheck />
            <ChangeTextWrapper>
              <WDTableLabel>{'Error'}</WDTableLabel>
            </ChangeTextWrapper>
            <CancelWrap onClick={() => reload()}>
              <SvgCancel />
            </CancelWrap>
          </Row>
          <LabelWrapper>
            {'Account Pledged Status Not Updated Successfully.'}
          </LabelWrapper>
        </ApprovedWrapper>
      )}
    </MasterWrapper>
  )
}

export default AccountLoanDetailsUI
