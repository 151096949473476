import React, { useState } from 'react'
import Divider from '../../components/divider/Divider'
import { SvgApprove } from '../../components/svg/SvgApprove'
import { SvgCancel } from '../../components/svg/SvgCancel'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import { WDStyledSaveButton } from '../../components/ui/WDButtons'
import {
  WDSidebarClientNickname,
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import {
  ButtonWrap,
  DividerWrapper,
  ErrorMessageWrapper,
  ErrorModalProps,
  IconHeadWrapper,
  IconWrapper,
  Modal,
  Row,
  Wrapper,
} from './styles'

export const ErrorModal = ({
  success,
  successMsg,
  onClick,
  blsErrorMsg,
}: ErrorModalProps) => {
  const [modal, setModal] = useState(success)
  const errorMsg = useState(AccountDetailsContext)

  if (modal === undefined) {
    return null
  }
  // console.log(modal)
  const handleClick = () => {
    setModal(undefined)
  }

  return (
    <Wrapper>
      <Modal>
        <Row>
          <IconHeadWrapper>
            {modal === 500 && (
              <React.Fragment>
                <SvgExclamation />
                <WDStyledSectionTitle>
                  Couldn&apos;t process your request.
                </WDStyledSectionTitle>
              </React.Fragment>
            )}

            {modal === 200 && (
              <React.Fragment>
                <SvgApprove />
                <WDStyledSectionTitle>Success!</WDStyledSectionTitle>
              </React.Fragment>
            )}
          </IconHeadWrapper>
          <IconWrapper onClick={onClick}>
            <SvgCancel />
          </IconWrapper>
        </Row>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>
        {modal === 500 && (blsErrorMsg === '' || blsErrorMsg===undefined || blsErrorMsg===null || blsErrorMsg === 'Fail')&& (
          <React.Fragment>
            <ErrorMessageWrapper>
              <WDSidebarClientNickname>
                Sorry, Due to Application Failure! <br></br>
                <br></br>
                We couldn’t process your request! Try again.
              </WDSidebarClientNickname>
            </ErrorMessageWrapper>
          </React.Fragment>
        )}

        {modal === 500 && (blsErrorMsg !== '' && blsErrorMsg!=='Fail' ) && (
          <React.Fragment>
            <ErrorMessageWrapper>
              <WDSidebarClientNickname>
                {blsErrorMsg}
              </WDSidebarClientNickname>
            </ErrorMessageWrapper>
          </React.Fragment>
        )}

        {modal === 200 && (
          <React.Fragment>
            <ErrorMessageWrapper>
              <WDSidebarClientNickname>{successMsg}</WDSidebarClientNickname>
            </ErrorMessageWrapper>
          </React.Fragment>
        )}

        <ButtonWrap>
          {modal === 500 && (
            <WDStyledSaveButton onClick={onClick}>Retry</WDStyledSaveButton>
          )}
          {modal === 200 && (
            <WDStyledSaveButton onClick={onClick}>OK</WDStyledSaveButton>
          )}
        </ButtonWrap>
      </Modal>
    </Wrapper>
  )
}
