
// import { ChevronDown } from './../../components/image/Image.stories';

import ChevronDown24 from '../assets/icons/24-ChevronDown.png'
import ChevronUp24 from'../assets/icons/24-ChevronUp.png'
import DeleteIcon from '../assets/icons/Delete_Icon.png'
import ExclamationRed from '../assets/icons/Exclamation.png'
import NumberOneHighlighted from '../assets/icons/NumberOneHighlighted.png'
import NumberTwoHighlighted from '../assets/icons/NumberTwoHighlighted.png'
import NumberThreeHighlighted from '../assets/icons/NumberThreeHighlighted.png'
import NumberTwo from '../assets/icons/NumberTwo.png'
import NumberThree from '../assets/icons/NumberThree.png'
import DoneIcon from '../assets/icons/DoneIcon.png'
import Vector from '../assets/icons/Vector.png'
import Client from '../assets/icons/ClientDetails.png'
import Address from '../assets/icons/Address.png'
import Contact from '../assets/icons/Contact.png'
import Relationship from '../assets/icons/Relationship.png'
import AccountRoles from '../assets/icons/AccountRoles.png'
import Lead from '../assets/icons/Lead.png'
import Prospect from '../assets/icons/Prospect.png'
import ClientType from '../assets/icons/ClientType.png'


export const Icons = {

  ChevronDown24,
  ChevronUp24,
  ExclamationRed,
  DeleteIcon,
  NumberOneHighlighted,
  NumberTwoHighlighted,
  NumberThreeHighlighted,
  NumberTwo,
  NumberThree,
  DoneIcon,
  Vector,
  Client,
  Address,
  Contact,
  Relationship,
  AccountRoles,
  Lead,
  Prospect,
  ClientType,
}
