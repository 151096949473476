import styled, { createGlobalStyle, css } from 'styled-components'
import { normalize } from 'styled-normalize'
import { COLORS } from '../../../theme/Colors'

export interface childProps {
  children: React.ReactElement
}

const BodyStyles = css`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }
  #root {
    margin: 0 auto;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${BodyStyles}
`

export const MainPane = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 83.33%;
  width: 83.33%;
  max-width: 1024px;
  min-width: 752px;
  min-height: 100vh;
  background: ${COLORS.Background.NeutralBackgroundWeak};
  overflow: auto;
  margin-left: 20%;
  @media (min-width: 1024px) {
    flex: 0 1 90%;
    width: 90%;
    max-width: 100%;
  }
`

export const MainContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  color: black;
  background-color: ${COLORS.Background.NeutralBackgroundWeak};
`

export const RootContainer = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 1200px) {
    flex: 1 1 auto;
    width: 90%;
  }
`
