import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ApprovalContext } from "../approvalContext/ApprovalContext"
import { AuthContext } from "../AuthContext"
import useAxiosProtected from "../http/useAxiosProtected"
import { fetchAccountRestrictionsAuditTrails, fetchAccountRestrictionsTypes } from "./AccountRestrictionsHttp"
import { AccountRestrictionsAuditTrailInterface, AccountRestrictionsInterface, AccountRestrictionsPayload, AccountRestrictionsTypePayload, RestrictionTypesInterface } from "./AccountRestrictionsInterface"

interface SetAccountRestrictonsCollapse {
    (value: boolean): void | boolean
}
interface SetAccountRestrictionsPayload {
    (value: AccountRestrictionsTypePayload[]): void | AccountRestrictionsTypePayload[]
}

interface SetAccountRestrictionsAuditTrail {
    (value: AccountRestrictionsAuditTrailInterface[]): void | AccountRestrictionsAuditTrailInterface[]
}

interface SetRestrictionsTypes {
    (value: RestrictionTypesInterface[]): void | RestrictionTypesInterface[]
}
export interface AccountRestrictionsContextProviderProps {
    children: ReactNode | ReactNode[]
}
interface AccountRestrictionsContextInterface {
    accountRestrictonsCollapse: boolean
    setAccountRestrictonsCollapse: SetAccountRestrictonsCollapse
    accountRestrictionsDetails: AccountRestrictionsInterface
    setAccountRestrictionsDetails: React.Dispatch<React.SetStateAction<AccountRestrictionsInterface>>
    originalAccountRestrictionsDetails: AccountRestrictionsInterface
    setOriginalAccountRestrictionsDetails: React.Dispatch<React.SetStateAction<AccountRestrictionsInterface>>
    accountRestrictionsPopup: boolean
    setAccountRestrictionsPopup: SetAccountRestrictonsCollapse
    createHttpResponseCode: number | undefined
    setCreateHttpResponseCode: React.Dispatch<React.SetStateAction<number>>
    accountRestrictionsPayload: AccountRestrictionsTypePayload[] | undefined
    setAccountRestrictionsPayload: SetAccountRestrictionsPayload
    accountRestrictionsAuditTrail: AccountRestrictionsAuditTrailInterface[] | undefined
    setAccountRestrictionsAuditTrail: SetAccountRestrictionsAuditTrail
    violationRestrictionTypes: RestrictionTypesInterface[] | undefined
    //setViolationRestrictionTypes: SetRestrictionsTypes
    isLoading: boolean
    error: unknown | null | object
    reloadAccountRestrictions: () => void
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    isEdited: boolean
    setIsEdited: React.Dispatch<React.SetStateAction<boolean>>

}
export const AccountRestrictionsContext = createContext<AccountRestrictionsContextInterface>(
    {} as AccountRestrictionsContextInterface,
)


const AccountRestrictionsContextProvider = ({
    children,
}: AccountRestrictionsContextProviderProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [accountRestrictonsCollapse, setAccountRestrictonsCollapse] = useState(false)
    const [accountRestrictionsDetails, setAccountRestrictionsDetails] = useState({} as AccountRestrictionsInterface)
    const [originalAccountRestrictionsDetails, setOriginalAccountRestrictionsDetails] = useState({} as AccountRestrictionsInterface)
    const [accountRestrictionsPopup, setAccountRestrictionsPopup] = useState(false)
    const [createHttpResponseCode, setCreateHttpResponseCode] = useState(100)
    const [accountRestrictionsPayload, setAccountRestrictionsPayload] = useState<AccountRestrictionsTypePayload[] | undefined>()
    const [accountRestrictionsAuditTrail, setAccountRestrictionsAuditTrail] = useState<AccountRestrictionsAuditTrailInterface[] | undefined>()
    const [violationRestrictionTypes, setViolationRestrictionTypes] = useState <RestrictionTypesInterface[] | undefined>()
    const [error, setError] = useState<unknown | null | object>(null)
    const [isEdited, setIsEdited] = useState<boolean>(false)

    const axiosProtected = useAxiosProtected()
    const authCtx = useContext(AuthContext)

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const accountIdString = searchParams.get('accountId')

    //if (accountIdString === undefined || accountIdString === null) {
    //    accountIdString = '1'//'27'
    //}
    
    const approval = useContext(ApprovalContext)
    const clientIdValue: any = approval?.value
    const accountId = clientIdValue//parseInt(clientIdValue!, 10)

    
    async function retrieveAccountRestrictionTypes() {
        try {
            if (accountId !== undefined && accountId !== "") {
                const data = await fetchAccountRestrictionsTypes(accountId, axiosProtected) as RestrictionTypesInterface[]
                accountRestrictionsDetails.restrictionTypes = data 
                const violationRestrictionTypes = accountRestrictionsDetails.restrictionTypes.filter(x => x.name?.toLowerCase().includes('excessive fed') || x.name?.toLowerCase().includes('excessive reg-t'))
                setViolationRestrictionTypes(violationRestrictionTypes)

                const restrictionsDetails = {
                    ...accountRestrictionsDetails
                }
                setAccountRestrictionsDetails(restrictionsDetails)

                setOriginalAccountRestrictionsDetails(restrictionsDetails)
                //setAccountRoles(data)
                setIsLoading(false)
            }
        } catch (error: unknown) {
            // console.log(error)
            setError(error)
        }
    }
    async function retrieveAccountRestrictionAuditTrails() {
        try {
            if (accountId !== undefined && accountId !== "") {
                const data = await fetchAccountRestrictionsAuditTrails(accountId, axiosProtected)

                setAccountRestrictionsAuditTrail(data)
                //setAccountRoles(data)
                setIsLoading(false)
            }
        } catch (error: unknown) {
            // console.log(error)
            setError(error)
        }
    }
    const reloadAccountRestrictions = async () => {
        retrieveAccountRestrictionTypes()
        retrieveAccountRestrictionAuditTrails()
    }
    useEffect(() => {
        if (authCtx?.accessToken) {
            reloadAccountRestrictions()
        }
    }, [authCtx?.accessToken, accountId])


    //useEffect(() => {
    //    async function updateAccountRestrictions() {
    //        try {
    //            if (accountRestrictionsPayload !== undefined) {
    //                console.log('Go')
    //                //const data = await updateBranchData(
    //                //    updateBranchDataPayload,
    //                //    axiosProtected
    //                //)
    //                //const data = 200
    //                setCreateHttpResponseCode(200)
    //                //console.log(data)
    //            }
    //        } catch (error: unknown) {
    //            console.log(error)
    //            //setError(error)
    //        }
    //    }
    //    updateAccountRestrictions()
    //}, [accountRestrictionsPayload])
    ////}


    return (
        <AccountRestrictionsContext.Provider
            value={{
                accountRestrictonsCollapse,
                setAccountRestrictonsCollapse,
                accountRestrictionsDetails,
                setAccountRestrictionsDetails,
                accountRestrictionsPopup,
                setAccountRestrictionsPopup,
                createHttpResponseCode,
                setCreateHttpResponseCode,
                accountRestrictionsPayload,
                setAccountRestrictionsPayload,
                originalAccountRestrictionsDetails,
                setOriginalAccountRestrictionsDetails,
                accountRestrictionsAuditTrail,
                setAccountRestrictionsAuditTrail,
                violationRestrictionTypes,
                //setViolationRestrictionTypes,
                isLoading,
                error,
                reloadAccountRestrictions,
                setIsLoading,
                isEdited,
                setIsEdited
            }}
        >
            {children}
        </AccountRestrictionsContext.Provider>
    )
}

export default AccountRestrictionsContextProvider