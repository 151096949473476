export interface Approval {
  id: number
  approvalRequestId?: number
  accountId?:number
  accountNumber?: string
  accountName?: string
  updatedBy: string
  update_Date: string
  category: string
  association?: string | number
  status: string
  notes?: string
  IdNumber: number | string
  categoryList: CategoryList[]
  approver?: string
}

export interface CategoryList {
  field: string
  oldValue: string
  newValue: string
}

export const defaultApproval: Approval[] = [
  {
    id: 1,
    updatedBy: '',
    update_Date: '',
    category: '',
    association: '',
    status: '',
    notes: 'Updated Values',
    IdNumber: 1,
    categoryList: [
      {
        field: '',
        oldValue: '',
        newValue: '',
      },
    ],
  },
]

export interface ApproveReject {
  accountNumber: string
  accountId: number
  approvalRequestId: number
  blsType: string
  notes: string
  updateBy: string | undefined
  requestType: string
}

/* 

{
  "accountNumber": "string",
  "accountId": 0,
  "approvalRequestId": 0,
  "blsType": "string",
  "notes": "string",
  "updateBy": "string",
  "requestType": "string"
}

  */
