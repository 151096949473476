import { IToggleButton } from './ISwitchButton'
import { StyledInput, StyledLabel, StyledSpan } from './styles'

const SwitchButton = ({ onChange, checked = false, disabled }: IToggleButton) => {
  return (
    <StyledLabel className={disabled ? 'disabled' : 'checked'}>
      <StyledInput type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
      <StyledSpan className="slider round"></StyledSpan>
    </StyledLabel>
  )
}

export default SwitchButton