import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface RelationshipUIProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  /* padding-top: 32px;
padding-bottom: 32px;
gap: 1 rem; */
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(RelationshipUIProps: RelationshipUIProps) =>
    RelationshipUIProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`

export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
`

export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 5.25rem;
  gap: 1rem;
  margin: 0.5rem 0rem;
`

export const LoaderRow = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  // max-height: 84px;
  gap: 24px;
  margin: 8px 0px;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 5.25rem;
  gap: 1rem;
  // margin: 0.5rem 0rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const ButtonRowSingle = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 5.25rem;
  gap: 1rem;
  // margin: 0.5rem 0rem;
  padding-top: 1rem;
`

export const LabelWrap = styled.div`
  padding: 0.25rem 0rem;
`

export const FlexItem = styled.div`
  flex: 0 1 50%;
  width: 100%;
`

export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
`

export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`

export const TopRow = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  align-items: center;
  cursor: pointer;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
`

export const AddWrapper = styled.div`
  position: absolute;
  left: 90%;
  top: 63%;
`

export const ContentWrapper = styled.div`
  position: relative;
`

export const StyledTableContainer = styled.div`
  width: 100% !important;
  /* padding: 24px; */
  padding: 0px 0px 16px !important;
  background: '#FFFFFF' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  /* border: 1px solid #cbd5e1;
  box-sizing: border-box;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 0px 0px 8px 8px; */
  border-top: none;
  table {
    tbody {
      tr {
        text-align: left !important;
        td {
          padding: 8px;
          font-family: SourceSansPro-Regular !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 16px !important;
          color: #0f172a !important;
        }
      }
    }
  }

  thead {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #0f172a !important;
    tr {
      th {
        padding: 4px 0px 4px 8px;
        font-family: SourceSansPro-Bold !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 16px !important;
        color: #0f172a !important;
      }
    }
  }
`
