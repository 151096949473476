import React from "react";
import styled from "styled-components";
interface InputFunc {
  (value: string): void | string | boolean | number;
}

export interface RadioProps {
  backgroundColor?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange: InputFunc;
  onClick: () => void;
  ref?: React.RefObject<HTMLInputElement>;
  onBlur?: () => void;
  errorText?: string;
  required?: boolean;
  name: string;
  id?: string;
  max?: string;
  pattern?: string;
  title?: string;
  label?: string;
  label2?: string;
  checked?: boolean;
  selectedValue?: string;
}

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

export const StyledError = styled.p`
  display: flex;
  color: red;
`;

export const RadioButton = styled.div`
  display: flex;
  position: relative;
  gap: 16px;
  margin-right: 16px;
  cursor: pointer;
`;

export const LabelWrap = styled.div`
  display: flex;
  gap:24px;
  p {
    min-width:12%;
    align-self: center;
  }
`;
export const LabelWrap2 = styled.div`
  display: flex;
  margin-bottom:2px;
`;

export const RadioButtonLabel = styled.label`
  position: relative;
  align-self: center;
  width: 16px;
  height: 16px;
  border: 1px solid #cbd5e1;
  cursor: pointer;
  border-radius: 100%;
  padding-left: 20%;
  padding-top: 19%;
  

  &::after {
    content: "";
    display: none;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
  }
`;

export const Main = styled.input`
  accent-color: #2563eb;
  position: absolute;
  z-index: 1;
  opacity: 0;

  &:checked ~ ${RadioButtonLabel} {
    background-color: #2563eb;
    /* margin-left:0.5px; */
  }

  &:checked ~ ${RadioButtonLabel}:after {
    display: block;
  }
`;
