import { usePagination, useRowSelect, useTable } from 'react-table'
import {
  StyledRectangle,
  TableFooterRow,
  TableFooterRowItem,
  TableFooterRowSpacer,
  ThContentWrapper,
  ThWrapper,
  Wrapper,
} from './styles'
import { useEffect, useMemo, useState } from 'react'
import { TableProps } from './ITable'
import { Tooltip } from '../../composites/tooltip/Tooltip'
import React from 'react'
import { WDStyledCancelButtonLabel } from '../ui/WDTypography'
import { WDStyledClearButtonBordered } from '../ui/WDButtons'

const Table = ({
  tableColumns,
  tableData,
  onClick,
  hasFooter = false,
  hover,
  status,
  itemsPerPage,
}: TableProps) => {
  const totalItemsAvailable = tableData.length
  const [pageSize, setPageSize] = useState<number>(itemsPerPage ?? 10)
  const totalPages = Math.ceil(tableData.length / pageSize)
  const [pageNum, setPageNum] = useState<number>(1)
  const [dataSorted, setDataSorted] = useState(tableData)
  const indexOfLastPost = pageNum * pageSize
  const indexOfFirstPost = indexOfLastPost - pageSize
  const data = useMemo(
    () => dataSorted.slice(indexOfFirstPost, indexOfLastPost),
    [indexOfFirstPost, indexOfLastPost, dataSorted]
  )
  const columns = useMemo(() => tableColumns, [tableColumns])

  useEffect(() => {
    if (tableData !== undefined && itemsPerPage !== undefined) {
      if (tableData.length < (itemsPerPage as number))
        setPageSize(tableData.length)
      else {
        setPageSize(itemsPerPage as number)
        setPageNum(1)
      }
      setDataSorted(tableData)
    }
  }, [tableData, itemsPerPage])

  const selectedColor = (e: any) => {
    for (const elem of (document as any).getElementsByClassName(
      'active-selected'
    )) {
      elem.classList.remove('active-selected')
    }
    for (const elem of (document as any).getElementsByClassName('table-row')) {
      elem.classList.remove('table-row')
    }
    e.currentTarget.classList.add('active-selected')
  }

  const [tooltip, setTooltip] = useState(false)

  const tooltipHover = (i: number) => {
    if (i === 0) setTooltip(true)
  }

  const tooltipHoverLeave = (i: number) => {
    if (i === 0) setTooltip(false)
  }

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    usePagination
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
  } = tableInstance

  return (
    <>
      <Wrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, key) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                {headerGroup.headers.map((column, index) => (
                  <th {...column.getHeaderProps()} key={index}>
                    <ThWrapper>
                      <ThContentWrapper>
                        {column.render('Header')}
                      </ThContentWrapper>
                      {/* {headerGroup.headers.length - 1 !== index && ( */}
                      <StyledRectangle></StyledRectangle>
                    </ThWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  onClick={(e) => {
                    onClick(row.original)
                    selectedColor(e)
                  }}
                  className="table-row"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <React.Fragment key={i}>
                        <td
                          {...cell.getCellProps()}
                          onFocus={() => console.log()}
                          onMouseOver={() => tooltipHover(i)}
                          onMouseLeave={() => tooltipHoverLeave(i)}
                          className={i === 0 && hover === true ? 'tooltip' : ''}
                        >
                          {cell.render('Cell')}
                          {hover &&
                            i === 0 &&
                            tooltip &&
                            status !== undefined &&
                            status !== null &&
                            status[index].accountStatus === 'Closed' && (
                              <Tooltip date={status[index].closingDate} />
                            )}
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {hasFooter ? (
            <tfoot>
              {footerGroups.map((group, index) => (
                <tr {...group.getFooterGroupProps()} key={index}>
                  {group.headers.map((column, i) => (
                    <td {...column.getFooterProps()} key={i}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          ) : null}
        </table>
      </Wrapper>
      <TableFooterRow>
        <TableFooterRowItem>
          <TableFooterRowSpacer>
            <b>
              Showing{' '}
              {pageSize * pageNum > totalItemsAvailable
                ? totalItemsAvailable
                : pageSize * pageNum}{' '}
              of {totalItemsAvailable} items
            </b>
          </TableFooterRowSpacer>
        </TableFooterRowItem>
        <TableFooterRowItem>
          <WDStyledClearButtonBordered
            disabled={pageNum === 1 ? true : false}
            onClick={() => {
              setPageNum(1)
            }}
          >
            <WDStyledCancelButtonLabel>{'<<'}</WDStyledCancelButtonLabel>
          </WDStyledClearButtonBordered>
          <WDStyledClearButtonBordered
            disabled={pageNum === 1 ? true : false}
            onClick={() => {
              setPageNum(pageNum - 1)
            }}
          >
            <WDStyledCancelButtonLabel>{'<'}</WDStyledCancelButtonLabel>
          </WDStyledClearButtonBordered>
          <WDStyledClearButtonBordered isCenterButton={true}>
            <WDStyledCancelButtonLabel>{pageNum}</WDStyledCancelButtonLabel>
          </WDStyledClearButtonBordered>
          <WDStyledClearButtonBordered
            disabled={pageNum === (totalPages || 1)}
            onClick={() => {
              setPageNum(pageNum + 1)
            }}
          >
            <WDStyledCancelButtonLabel>{'>'}</WDStyledCancelButtonLabel>
          </WDStyledClearButtonBordered>
          <WDStyledClearButtonBordered
            disabled={pageNum === (totalPages || 1)}
            onClick={() => {
              setPageNum(totalPages)
            }}
          >
            <WDStyledCancelButtonLabel>{'>>'}</WDStyledCancelButtonLabel>
          </WDStyledClearButtonBordered>
        </TableFooterRowItem>
      </TableFooterRow>
    </>
  )
}

export default Table
