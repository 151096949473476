import React, {
  useState,
  createContext,
  useEffect,
  Dispatch,
  useContext,
} from 'react'

import { Approval, ApproveReject, defaultApproval } from './ApprovalInterface'
import useAxiosProtected from '../http/useAxiosProtected'
import { fetchAuditTrail, updateApproveReject } from './ApprovalHttps'
import { AuthContext } from '../AuthContext'
import { AiwContext } from '../aiwContext/AiwContext'
import { AccountMaintenanceGetContext } from '../accountMaintenanceGetContext/AccountMaintenanceGetContext'

interface SetApproval {
  (value: boolean): void | boolean
}

interface SetApprovalCollapse {
  (value: boolean): void | boolean
}

interface SetApprovalData {
  (value: Approval[]): void | Approval[]
}
interface SetApprovalPayload {
  (value: ApproveReject): void | ApproveReject
}
interface SetValue {
  (value: string): void | string
}

interface ClientTypeFunc {
  (value: string): void | string
}

interface ApprovalContextInterface {
  isLoading: boolean
  error: unknown | null | object
  approval: boolean
  setApproval: SetApproval
  countryValid:boolean
  setCountryValid:SetApproval
  isActiveAd:boolean 
  setIsActiveAd:SetApproval
  isActiveAct:boolean 
  setIsActiveAct:SetApproval
  isActiveInv:boolean 
  setIsActiveInv:SetApproval
  isActiveAl:boolean
  setIsActiveAl:SetApproval
  approvalCollapse: boolean
  setApprovalCollapse: SetApprovalCollapse
  approvalData: Approval[]
  setApprovalData: SetApprovalData
  reject: boolean
  setReject: SetApproval
  approved: boolean
  setApproved: SetApproval
  approvalPayload: ApproveReject | undefined
  setApprovalPayload: SetApprovalPayload
  rejectPayload: ApproveReject | undefined
  setRejectPayload: SetApprovalPayload
  errorMsg: number | undefined
  setErrorMsg: Dispatch<React.SetStateAction<number>>
  value: string | undefined
  setValue: SetValue
  admin: boolean
  setAdmin: SetApproval
  requester: boolean
  setRequester: SetApproval
  approver: boolean
  setApprover: SetApproval
  restrictionUser:boolean
  setRestrictionUser: SetApproval
  requesterAndRestriction: boolean
  setRequesterAndRestriction:SetApproval
  approverAndRestriction: boolean
  setApproverAndRestriction: SetApproval
  clientType: string | undefined
  setClientType: ClientTypeFunc
  clientSecondType: string | undefined
  setClientSecondType: ClientTypeFunc
  rejectionErrorMsg: number | undefined
  setRejectionErrorMsg: React.Dispatch<React.SetStateAction<number>>
  retrieveAuditTrail : () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  category: string | undefined
  setCategory: CategoryFunc
  categoryResponse: string | undefined
  setCategoryResponse: CategoryFunc
  setBlsError:SetValue
  blsError:string| undefined
}

interface CategoryFunc {
  (value: string): void | string
}
export const ApprovalContext = createContext<
  ApprovalContextInterface | undefined | null
>(undefined)

interface ApprovalCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const ApprovalContextProvider = ({
  children,
}: ApprovalCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [approval, setApproval] = useState(false)
  const [isActiveAd, setIsActiveAd] = useState(false)
  const [isActiveAct, setIsActiveAct] = useState(false)
  const [isActiveInv, setIsActiveInv] = useState(false)
  const [isActiveAl, setIsActiveAl] = useState(false)
  const [approvalCollapse, setApprovalCollapse] = useState(true)
  const [reject, setReject] = useState(false)
  const [approved, setApproved] = useState(false)
  const [approvalData, setApprovalData] = useState(defaultApproval)
  const [approvalPayload, setApprovalPayload] = useState<ApproveReject>()
  const [rejectPayload, setRejectPayload] = useState<ApproveReject>()
  const [errorMsg, setErrorMsg] = useState<number>(100)
  const contextObject = useContext(AccountMaintenanceGetContext)
  const [rejectionErrorMsg, setRejectionErrorMsg] = useState<number>(100)
  const [requester, setRequester] = useState(false)
  const [approver, setApprover] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [requesterAndRestriction,setRequesterAndRestriction] = useState(false)
  const [approverAndRestriction,setApproverAndRestriction]=useState(false)
  const [restrictionUser, setRestrictionUser] = useState(false)
  const [clientType, setClientType] = useState('CM-APPROVER')
  const [clientSecondType, setClientSecondType] = useState('CM-REQUESTER')
  const [value,setValue]=useState<string >()
  const [category, setCategory] = useState<string>()
  const [categoryResponse, setCategoryResponse] = useState<string>()
  const [blsError,setBlsError]=useState<string>('')
  const [countryValid, setCountryValid]=useState(true)

  const axiosProtected = useAxiosProtected()
  const authCtx= useContext(AuthContext)
  const electron = useContext(AiwContext)

  useEffect(() => {
   if (electron.inputValue.input !== '') {
        setValue(electron.inputValue.input)
      } else {
        setValue(value)
      }
    if (authCtx?.userRoles?.length) {
      setClientType(authCtx?.userRoles[0])
      setClientSecondType(authCtx?.userRoles[1])
    }
  }, [authCtx?.userRoles, electron])


    
    useEffect(() => {
        if ((authCtx?.userRoles?.includes('AM-REQUESTER') && authCtx?.userRoles?.includes('AM-APPROVER'))
             ||
            (authCtx?.userRoles?.includes('AM-REQUESTER') && authCtx?.userRoles?.includes('AM-APPROVER') && authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT'))
        ) {
            setAdmin(true)
        }else if(authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && authCtx?.userRoles?.includes('AM-APPROVER')){
          setApproverAndRestriction(true)
        }else if(authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT') && authCtx?.userRoles?.includes('AM-REQUESTER')){
          setRequesterAndRestriction(true)
        } else if (authCtx?.userRoles?.includes('AM-REQUESTER')) {
            setRequester(true)
        } else if (authCtx?.userRoles?.includes('AM-APPROVER')) {
            setApprover(true)
        } else if (authCtx?.userRoles?.includes('AM-RESTRICTIONMANAGEMENT')) {
            setRestrictionUser(true)
        }
        //setAdmin(true)
        //setRestrictionUser(true)
    }, [authCtx?.userRoles])

  useEffect(() => {
    if(contextObject?.accountNumber!==null && contextObject?.accountNumber!==undefined){
   setValue(contextObject?.accountNumber)}
    }, [contextObject?.accountNumber])

  async function retrieveAuditTrail() {
      try {
        const data = await fetchAuditTrail('0', axiosProtected)

        setApprovalData(data)
        setIsLoading(false)
      } catch (error: unknown) {
        setError(error)
      }
  }
  useEffect(() => {
    if (authCtx?.accessToken) {
      retrieveAuditTrail()
    }
  }, [authCtx?.accessToken, axiosProtected])

  useEffect(() => {
    async function ApproveReject() {
      try {
        if (approvalPayload !== undefined) {
          const data = await updateApproveReject(
            approvalPayload,
            axiosProtected
          )
          setBlsError(data?.data)
          setErrorMsg(data)
          setApprovalPayload(undefined)
          setCategoryResponse(category)
        }
        if (rejectPayload !== undefined) {
          
          const data = await updateApproveReject(rejectPayload, axiosProtected)
          setBlsError(data?.data)
          setRejectionErrorMsg(data)
          setRejectPayload(undefined)
        }
      } catch (error: unknown) {
        setError(error)
      }
    }
    if (authCtx?.accessToken) {
      ApproveReject()
    }
  }, [approvalPayload, rejectPayload, authCtx?.accessToken])

  console.log('Status',errorMsg)


  return (
    <ApprovalContext.Provider
      value={{
        errorMsg,
        setErrorMsg,
        isLoading,
        error,
        approval,
        setApproval,
        approvalCollapse,
        setApprovalCollapse,
        approved,
        setApproved,
        reject,
        setReject,
        approvalData,
        setApprovalData,
        approvalPayload,
        setApprovalPayload,
        setRejectPayload,
        rejectPayload,
        value,
        setValue,
        setRejectionErrorMsg,
        rejectionErrorMsg,
        admin,
        setAdmin,
        requester,
        setRequester,
        approver,
        setApprover,
        restrictionUser,
        setRestrictionUser,
        clientType,
        setClientType,
        clientSecondType,
        setClientSecondType,
        isActiveAd,
        setIsActiveAd,
        isActiveAct,
        setIsActiveAct,
        isActiveInv,
        setIsActiveInv,
        isActiveAl,
        setIsActiveAl,
        retrieveAuditTrail,
        category,
        setCategory,
        categoryResponse,
        setCategoryResponse,
        setIsLoading,
        countryValid,
        setCountryValid,
        approverAndRestriction,
        setApproverAndRestriction,
        requesterAndRestriction,
        setRequesterAndRestriction,
        setBlsError,
        blsError,
      }}
    >
      {children}
    </ApprovalContext.Provider>
  )
}
