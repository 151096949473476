import React, { FC } from 'react'

import { childProps, GlobalStyle } from './styles'

import ContainerLayout from '../container/ContainerLayout'

const LandingPageLayout: FC<childProps> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ContainerLayout>{children}</ContainerLayout>
    </>
  )
}

export default LandingPageLayout
