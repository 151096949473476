import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

interface ButtonProps {
  children?: React.ReactNode
  isDown?: string
  stateChange?: boolean
  onClick?: () => void
  disabled?: boolean
  isCenterButton?: boolean
}

const StyledEditButton = styled.button`
  cursor: pointer;
  padding: 4px 16px;
  color: ${COLORS.Text.Primary};
  background-color: ${COLORS.Background.Primary};
  border: 1px solid ${COLORS.Background.PrimaryText};
  border-radius: 3px;
  &:hover {
    background-color: ${COLORS.Background.Primary10};
  }
  &:active {
    background-color: ${COLORS.Background.BackgroundActive};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
`

const StyledCancelButton = styled.button`
  cursor: pointer;
  padding: 4px 32px;
  color: ${COLORS.Text.Primary};
  background-color: ${COLORS.Background.NeutralWeak};
  border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.Background.Primary10};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:active {
    background-color: ${COLORS.Background.BackgroundActive};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
`
const StyledClearButton = styled.button`
  cursor: pointer;
  padding: 4px 32px;
  color: ${COLORS.Text.Primary};
  background-color: ${COLORS.Background.Primary};
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.Background.Primary10};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:active {
    background-color: ${COLORS.Background.BackgroundActive};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
`
const StyledClearButtonBordered = styled.button`
  cursor: pointer;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.Text.Primary}
  background-color: ${COLORS.Background.Primary};
  border: 1px solid ${COLORS.Background.Neutral70};
  &:hover {
    background-color: ${COLORS.Background.Primary10};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:active {
    background-color: ${COLORS.Background.BackgroundActive};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${COLORS.UI.NeutralBorderWeak};
  }
`
const StyledCenterButtonBordered = styled.button`
  cursor: pointer;
  padding: 8px 8px;
  justify-content: center;
  align-items: center;
  color: ${COLORS.Background.Primary};
  background-color: ${COLORS.Text.Primary};
  border: 1px solid ${COLORS.Background.Neutral70};
  &:disabled {
    cursor: not-allowed;
    background-color: ${COLORS.Text.NeutralTextDisabled};
  }
`

const StyledSaveButton = styled.button`
  cursor: pointer;
  padding: 4px 32px;
  color: ${COLORS.Background.Primary};
  background-color: ${COLORS.Text.Primary};
  border: 1px solid ${COLORS.UI.BackgroundStrong};
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.Background.Primary70};
  }
  &:active {
    background-color: ${COLORS.Background.PrimaryActive};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
  &:disabled {
    background: #e2e8f0;
    accent-color: #e2e8f0;
    border-radius: 4px;
    border:none;
  }
`

const StyledAddAddressButton = styled.button`
  cursor: pointer;
  padding: 8px 32px !important;
  color: ${COLORS.Text.Primary};
  background-color: ${COLORS.Background.NeutralWeak};
  border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  border-radius: 4px;
  &:hover {
    background-color: ${COLORS.Background.Primary10};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:active {
    background-color: ${COLORS.Background.BackgroundActive};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
  }
  &:focus {
    border: 2px solid ${COLORS.Brand.Brand1};
  }
  
`

export const WDStyledEditButton = ({
  children,
  onClick,
  ...props
}: ButtonProps) => {
  return <StyledEditButton onClick={onClick}>{children}</StyledEditButton>
}

export const WDStyledAddAddressButton = ({
  children,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <StyledAddAddressButton onClick={onClick}>
      {children}
    </StyledAddAddressButton>
  )
}

export const WDStyledCancelButton = ({
  children,
  onClick,
  ...props
}: ButtonProps) => {
  return <StyledCancelButton onClick={onClick}>{children}</StyledCancelButton>
}
export const WDStyledClearButton = ({
  children,
  onClick,
  ...props
}: ButtonProps) => {
  return <StyledClearButton onClick={onClick}>{children}</StyledClearButton>
}
export const WDStyledClearButtonBordered = ({
  children,
  onClick,
  disabled,
  isCenterButton,
  ...props
}: ButtonProps) => {
  if(isCenterButton)
    return <StyledCenterButtonBordered disabled={disabled} onClick={onClick}>{children}</StyledCenterButtonBordered>
    else
    return <StyledClearButtonBordered disabled={disabled} onClick={onClick}>{children}</StyledClearButtonBordered>
}

export const WDStyledSaveButton = ({
  children,
  onClick,
  disabled,
  ...props
}: ButtonProps) => {
  return <StyledSaveButton disabled={disabled} onClick={onClick}>{children}</StyledSaveButton>
}
