import styled from 'styled-components';
import { colors } from '../../shared/styles';

export const SidebarMenuListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0rem;
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  &:hover {
    color: ${colors.NeutralsWhite};
  }
`;