import { Flex, IconButton, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
//import { SUCCESS, DANGER, WARNING, PRIMARY } from '@/theme/Colors'
//import SvgCancel from '../svg/SvgCancel'
import textStyles from '../../styles/Text.module.css'
import { SvgToastIcon } from '../svg/SvgToastIcon'
import { SvgCancel } from '../svg/SvgCancel'
import { COLORS } from '../../theme/Colors'

interface Props {
  title: string
  description?: string
  variant: 'critical' | 'warning' | 'success' | 'info'
  onClose?: () => void
  width?: string
  isCloseAble?: boolean
}
const Toast = ({
  title,
  variant,
  onClose,
  description,
  width = '330px',
  isCloseAble = true,
}: Props) => {
  const [variantStyles, setVariantStyles] = useState<{
    background: string
    borderColor: string
    iconColor: string
    iconHoverBg: string
  }>()

  useEffect(() => {
    switch (variant) {
      case 'critical':
        setVariantStyles({
          background: COLORS.Background.DangerBg,//DANGER.Backgrounds.danger_background,
          borderColor:COLORS.Background.DangerBorderStrong, //DANGER.Borders.danger_border_strong,
          iconColor: COLORS.Icons.DangerIcon,//DANGER.Backgrounds.danger_background_strong,
          iconHoverBg: COLORS.Background.DangerBgIconHover, //DANGER.Backgrounds.danger_background_hover,
        })
        break
      case 'warning':
        setVariantStyles({
          background: COLORS.Background.WarningBackground,//WARNING.Backgrounds.warning_background,
          borderColor: COLORS.Background.WarningBorderStrong, //WARNING.Borders.warning_border_strong,
          iconColor: COLORS.Background.WarningBackgroundStrong,//WARNING.Backgrounds.warning_background_strong,
          iconHoverBg: COLORS.Background.WarningBgIconHover, //WARNING.Backgrounds.warning_background_hover,
        })
        break
      case 'success':
        setVariantStyles({
          background: COLORS.Background.SuccessBg,//SUCCESS.Backgrounds.success_background,
          borderColor:COLORS.Background.SuccessBorderStrong, //SUCCESS.Borders.success_border_strong,
          iconColor: COLORS.Background.SuccessBackgroundStrong ,//SUCCESS.Backgrounds.success_background_strong,
          iconHoverBg: COLORS.Background.SuccessBgIconHover //SUCCESS.Backgrounds.success_background_hover,
        })
        break
      case 'info':
        setVariantStyles({
          background: COLORS.Background.PrimaryBg,//PRIMARY.Backgrounds.primary_background,
          borderColor: COLORS.Background.PrimaryBorderStrong ,//PRIMARY.Borders.primary_border_strong,
          iconColor: COLORS.Background.PrimaryStrong,//PRIMARY.Backgrounds.primary_background_strong,
          iconHoverBg: COLORS.Background.Primary10,//PRIMARY.Backgrounds.primary_background_hover,
        })
        break

      default:
        setVariantStyles({
          background: COLORS.Background.PrimaryBg, //PRIMARY.Backgrounds.primary_background,
          borderColor: COLORS.Background.PrimaryBorderStrong, //PRIMARY.Borders.primary_border_strong,
          iconColor: COLORS.Background.PrimaryStrong ,//PRIMARY.Backgrounds.primary_background_strong,
          iconHoverBg: COLORS.Background.Primary10,//PRIMARY.Backgrounds.primary_background_hover,
        })
        break
    }
  }, [variant])

  return (
    <>
      {variantStyles ? (
        <Flex
          padding={'8px 16px'}
          width={width}
          borderRadius={'4px'}
          background={variantStyles?.background}
          border={`1px solid ${variantStyles?.borderColor}`}
          boxShadow={'0px 2px 6px -2px rgba(0, 36, 93, 0.3)'}
          flexDirection={'column'}
          gap={'8px'}
        >
          <Flex gap={'8px'}>
            <SvgToastIcon
              fillColor={variantStyles?.iconColor}
              variant={variant}
            />
            <Text
              className={textStyles.neutral_text_600_16px_24px}
              width={'full'}
            >
              {title}
            </Text>
            {isCloseAble && (
              <IconButton
                aria-label="Clear"
                icon={<SvgCancel />}
                onClick={onClose}
                height={'24px !important'}
                minWidth={'24px'}
                border={'none'}
                bg={'none'}
                borderRadius={'none'}
                _hover={{
                  backgroundColor: variantStyles?.iconHoverBg,
                  borderRadius: '4px',
                }}
              />
            )}
          </Flex>
          {description && (
            <Flex>
              <Text
                width={'full'}
                className={textStyles.neutral_text_weak_400_12px_16px}
              >
                {description}
              </Text>
            </Flex>
          )}
        </Flex>
      ) : null}
    </>
  )
}

export default Toast
