import axios from 'axios'
import appConfig from '../../settings/appConfig'

const axiosInstance = axios.create({
  baseURL: appConfig.apiEndPoint,
  // baseURL: "https://uat.api.wedbush.com/wedbush-clientlink-api",
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosInstance
