import { ReactNode, createContext, useState } from 'react'

export interface AccountRestrictionReportsContextProviderProps {
  children: ReactNode | ReactNode[]
}

interface AccountRestrictionReportsContextInterface {
  accountRestrictionReportsCollapse: boolean
  setAccountRestrictionReportsCollapse: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

export const AccountRestrictionReportsContext =
  createContext<AccountRestrictionReportsContextInterface>(
    {} as AccountRestrictionReportsContextInterface
  )

const AccountRestrictionReportsContextProvider = ({
    children,}: AccountRestrictionReportsContextProviderProps) => {
    const [accountRestrictionReportsCollapse, setAccountRestrictionReportsCollapse] = useState(false)
    return (
        <AccountRestrictionReportsContext.Provider
            value={{
                accountRestrictionReportsCollapse,
                setAccountRestrictionReportsCollapse,
            }}
        >
            {children}
        </AccountRestrictionReportsContext.Provider>
    )
}

export default AccountRestrictionReportsContextProvider