const appConfig = {
  envName: "DEV",
  appName: "Account Maintenance Web App",
  appDomain: "https://accountui.dev.wedbush.com",
  apiEndPoint: "https://dev.api.wedbush.com",
  b2cDomain: "https://wedbushclientsdev.b2clogin.com/wedbushclientsdev.onmicrosoft.com/B2C_1A_SUSI_ADFED",
  b2cClientId: "164d9e3b-b75f-4f89-927c-8ee902c43d82",
  b2cScope: "https://wedbushclientsdev.onmicrosoft.com/3a0d6004-ab60-4056-b6eb-3d14aa33b105/api_access",
  b2cSecret: "GYB8Q~M_b3Vi_rwJdLBwjXQptmYTwoD~nr12ea-Z"
}

export default appConfig

//   REACT_APP_ENV_NAME=!ENV!
// REACT_APP_APP_NAME=Account Maintenance Web App
// REACT_APP_APP_DOMAIN=AccountMaintenanceUI_AppDomain
// REACT_APP_API_ENDPOINT=AccountMaintenanceUI_EndPoint
// REACT_APP_B2C_SCOPE=REACT_APP_B2C_SCOPE
// REACT_APP_B2C_DOMAIN=AccountMaintenanceUI_B2CDomain
// REACT_APP_B2C_CLIENT_ID=REACT_APP_B2C_CLIENT_ID
// REACT_APP_B2C_SECRET=REACT_APP_B2C_SECRET
