import React from 'react'
import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'
import { ApproveReject } from './ApprovalInterface'

export async function fetchAuditTrail(
  accountNumber: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.GET_AUDIT_TRAIL
  const payload = {
    accountNumber: accountNumber,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  return data
}

export async function updateApproveReject(
    payload: ApproveReject,
    axiosProtected: AxiosInstance
  ) {
    try {
        const url = URLs.APPROVE_REJECT
        const response = await axiosProtected.post(url, payload)
        
        const data = response.status
        return data
      } catch (e:any) {
        return e?.response
      }
  }