import styled from 'styled-components'
import { colors } from '../../../shared/styles'

export interface childProps {
  children: React.ReactElement
}

const StyledHeader = styled.div<childProps>`
  /* display: flex; */
  flex: 0 0 auto;
  padding: 0;
  margin: 0;
  width: 100%;

  /* height: 240px; */

  color: black;
  background-color: ${colors.BackgroundWeak};
  border-bottom: 5px;
`

export default StyledHeader
