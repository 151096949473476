import { AxiosInstance } from 'axios'
import { URLs } from '../httpConfiguration'
import { AddUpdateMailingAddress } from '../mailingAddressContext/MailingAddressInterface'
import {
  AccountDetailsUpdatedValues,
  DeleteContact,
  UpdateAccountDetails,
} from './AccountDetailsInterface'

export async function fetchAccountDetails(
  accountId: string,
  axiosProtected: AxiosInstance
) {
  const url = URLs.ACCOUNT_DETAILS_GET_API
  const payload = {
    accountNumber: accountId,
  }
  const response = await axiosProtected.post(url, payload)
  const data = response.data
  // console.log('🚀 ~ Fetch Account Details =>', data)
  return data
}
export async function addUpdateAccountDetailsMethod(
  updateAccountDetails: UpdateAccountDetails | null,
  axiosProtected: AxiosInstance
) {
  const url = URLs.ACCOUNT_DETAILS_ADD_UPDATE_API
  const response = await axiosProtected.post(url, updateAccountDetails)
  const data = response.data
  // console.log('🚀 ~ Add/Update response =>', response)
  return data
}
export async function accountDetailsSubmissionMethod(
  payload: AccountDetailsUpdatedValues | null,
  axiosProtected: AxiosInstance
) {
  try{
  const url = URLs.ACCOUNT_DETAILS_SUBMISSION
  const response = await axiosProtected.post(url, payload)
  const data = response.status
  // console.log('🚀 ~ Update response =>', response)
  return data
  }catch(e){
    return 500
  }
}

export async function deleteContact(
  payload: DeleteContact | null,
  axiosProtected: AxiosInstance
) {
  try {
    const url = URLs.DELETE_CONTACT_BY_ID
    const response = await axiosProtected.post(url, payload)
    // console.log('🚀 ~ Delete contact Response =>', response)
    // alert('Contact Delete status is : ' + response.status)
    const data = response.status
    return data
  } catch (e) {
    return 500
  }
}
export async function deleteEmail(
  payload: DeleteContact | null,
  axiosProtected: AxiosInstance
) {
  try {
    const url = URLs.DELETE_EMAIL_BY_ID
    const response = await axiosProtected.post(url, payload)
    const data = response.status
    return data
  } catch (e) {
    return 500
  }
}