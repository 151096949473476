import React, { useContext, useState } from 'react'
import {
  ButtonWrap,
  DividerWrapper,
  IconHeadWrapper,
  IconWrapper,
  MessageWrapper,
  Modal,
  Row,
  Wrapper,
} from './styles'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import Divider from '../../components/divider/Divider'
import { SvgCancel } from '../../components/svg/SvgCancel'
import { WDStyledSaveButton } from '../../components/ui/WDButtons'
import {
  WDSidebarClientNickname,
  WDStyledSectionTitle,
} from '../../components/ui/WDTypography'
import { messages } from '../../shared/constants'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AuthContext } from '../../services/AuthContext'

interface ConfirmationDailogProps {
  id?: number
  type?: string
  isEmail?:boolean
  isPhone?:boolean
  onClick?: (value:boolean) => void
  accountId?: number,
  emailTypeId?: number,
}

const ConfirmationDailog = ({ onClick, id, type, isPhone, isEmail, accountId, emailTypeId }: ConfirmationDailogProps) => {
    const accountDetailsValues = useContext(AccountDetailsContext)
    const authCtx = useContext(AuthContext)
    const [remove, setRemove] = useState<boolean>(false)
  const removeInfo = () => {
    if (id !== -1) {    
      if(isPhone !== undefined && isPhone){
        accountDetailsValues?.setDeleteContactData({
            accountContactId: id,
            updateBy: authCtx?.account?.name,
            notes: 'contact deleted',
          })
          setRemove(true)
      }
      if(isEmail !== undefined && isEmail){
        accountDetailsValues?.setDeleteEmailData({
          accountId: accountId,
          emailTypeId: emailTypeId,
          emailId: id,
          updateBy: authCtx?.account?.name,
          notes: 'email deleted',
        })
        setRemove(true)
      }
    }
    if(onClick)
      onClick(true)
  }

  return (
    <Wrapper>
      <Modal>
        <Row>
          <IconHeadWrapper>
            <React.Fragment>
              <SvgExclamation />
              <WDStyledSectionTitle>
                {messages.confimation_dialog.title}
              </WDStyledSectionTitle>
            </React.Fragment>
          </IconHeadWrapper>
          <IconWrapper onClick={()=> {
            setRemove(false)
            if(onClick) onClick(remove)
          }}>
            <SvgCancel />
          </IconWrapper>
        </Row>
        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>

        <React.Fragment>
          <MessageWrapper>
            <WDSidebarClientNickname>
              {isPhone ? messages.confimation_dialog.message_phone(type as string) : messages.confimation_dialog.message_email(type as string)}
            </WDSidebarClientNickname>
          </MessageWrapper>
        </React.Fragment>

        <ButtonWrap>
          <WDStyledSaveButton onClick={removeInfo}>
            {messages.confimation_dialog.delete}
          </WDStyledSaveButton>

          <WDStyledSaveButton onClick={()=> {
            setRemove(false)
            if(onClick) onClick(remove)
          }}>
            {messages.confimation_dialog.cancel}
          </WDStyledSaveButton>
        </ButtonWrap>
      </Modal>
    </Wrapper>
  )
}

export default ConfirmationDailog
