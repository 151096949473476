import React, { useContext, useState } from 'react'
import { Icon } from '../../components/icon/Icon'
import Label from '../../components/label/Label'
import Divider from '../../components/divider/Divider'
import {
  MasterWrapper,
  HeaderRow,
  IconWrapper,
  DividerWrapper,
  StyledTableContainer,
  TitleIconWrap,
  TopRow,
  LoaderRow,
} from './styles'
import { SvgHousehold } from '../../components/svg/SvgHousehold'
import { SvgChevronUp } from '../../components/svg/SvgChevronUp'
import { SvgChevronDown } from '../../components/svg/SvgChevronDown'
import Table from '../../components/table/Table'
import { WDCaption, WDInputLabel, WDStyledSectionTitle } from '../../components/ui/WDTypography'
import { COLORS } from '../../theme/Colors'
import { HouseholdContext } from '../../services/householdContext/HouseholdContext'
import Loader from '../../components/loader/Loader'

export const HouseholdUI = () => {
  const [isCollapse, setIsCollapse] = useState(true)

  const householdValues = useContext(HouseholdContext)
  const household = householdValues?.household

  const householdData = household?.map(
    ({
      accountNumber,
      accountTitle,
      accountNickName,
      accountType,
      phone,
      email,
      accountValue
    }) => {
      return {
        AccountNumber: accountNumber,
        AccountTitle: accountTitle,
        AccountNickName: accountNickName,
        AccountType: accountType,
        Phone: phone,
        Email: email,
        Value:accountValue,
      }
    }
  )

  const accountStatus = household?.map(
    ({
      accountStatus,
      closingDate
    }) => {
      return {
        accountStatus: accountStatus,
        closingDate: closingDate
      }
    }
  )

  const columnData = [
    {
      Header: 'Account Number',
      accessor: 'AccountNumber',
    },
    {
      Header: 'Account Title',
      accessor: 'AccountTitle',
    },

    {
      Header: 'Account NickName',
      accessor: 'AccountNickName',
    },
    {
      Header: 'Account Type',
      accessor: 'AccountType',
    },
    {
      Header: 'Phone',
      accessor: 'Phone',
    },
    {
      Header: 'Email',
      accessor: 'Email',
    },
    {
      Header: 'Value',
      accessor: 'Value',
    },
  ]

  const cardSummary = [
    {
      AccountNumber: '12345678',
      AccountTitle: 'Peter M. Tassone',
      AccountNickName: '[NickName]',
      AccountType: '[Account Type]',
      Phone: '[Phone]',
      Email: '[Email]',
      Value: '$1.00',
    },
    {
      AccountNumber: '12345121',
      AccountTitle: 'Layla Tassone',
      AccountNickName: '[NickName]',
      AccountType: '[Account Type]',
      Phone: '[Phone]',
      Email: '[Email]',
      Value: '$2.00',
    },
    {
      AccountNumber: '12345231',
      AccountTitle: 'Maria Tassone',
      AccountNickName: '[NickName]',
      AccountType: '[Account Type]',
      Phone: '[Phone]',
      Email: '[Email]',
      Value: '$3.00',
    },
    {
      AccountNumber: '12345432',
      AccountTitle: 'Rose Tassone',
      AccountNickName: '[NickName]',
      AccountType: '[Account Type]',
      Phone: '[Phone]',
      Email: '[Email]',
      Value: '$3.45',
    },
  ]

  const collapse = () => {
    householdValues?.setHouseholdCollapse(!householdValues?.householdCollapse)
  }

  if(householdValues?.isLoading){
    return (
      <MasterWrapper>
      <TopRow>
        <HeaderRow>
          <TitleIconWrap>
            <IconWrapper>
              <SvgHousehold />
            </IconWrapper>
            <WDStyledSectionTitle>{'Household'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
      </TopRow>
      { (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}
      <LoaderRow><Loader /></LoaderRow>
      </MasterWrapper>
    )
  }




  return (
    <MasterWrapper
      id="household"
      borderShadow={householdValues?.householdCollapse ? true : false}
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              {/* <Icon icon={Icons.Relationship} /> */}
              <SvgHousehold />
            </IconWrapper>
            <WDStyledSectionTitle>{'Household'}</WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
        <IconWrapper onClick={() => collapse()}>
          {householdValues?.householdCollapse && <SvgChevronUp />}
          {!householdValues?.householdCollapse && <SvgChevronDown />}
        </IconWrapper>
      </TopRow>
      {householdValues?.householdCollapse &&  (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

{household?.length===0 && householdValues?.householdCollapse &&(
   <WDInputLabel>
   {'Hey! There is no Household Details related to this account.'}
 </WDInputLabel>
)}

      {householdValues?.householdCollapse && household?.length!==0 &&(
        <React.Fragment>
          <StyledTableContainer>
            <Table
              tableColumns={columnData}
              tableData={
                householdData === undefined ? cardSummary : householdData
              }
              status={accountStatus ? accountStatus : []}
              onClick={() => ''}
              hover={true}
            />
          </StyledTableContainer>
        </React.Fragment>
      )}
    </MasterWrapper>
  )
}
