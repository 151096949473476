import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import { SvgPadlock24 } from '../../components/svg/SvgPadlock24'
import SwitchButton from '../../components/switchButton/SwitchButton'
import {
  WDStyledCancelButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  WDStyledCancelButtonLabel,
  WDStyledSectionTitle,
  WDStyledSettingsLabel,
  WDStyledSubSectionTitle,
} from '../../components/ui/WDTypography'
import { COLORS } from '../../theme/Colors'
import AccountRestrictionsPopup from '../accountRestrictionsPopup/accountRestrictionsPopup'
import { InputField } from '../inputField/InputField'
import {
  ButtonRow,
  DividerWrapper,
  ErrorMsgWrapper,
  HeaderRow,
  IconWrapper,
  MasterWrapper,
  NinetyDayNotesWrapper,
  NinetyDaySubSectionWrapper,
  NinetyDaySwitchWrapper,
  OtherInputFieldWrapper,
  OtherTextAreaFieldWrapper,
  OtherWrapper,
  PrimaryButtonWrapper,
  SecondaryButtonWrapper,
  SubSectionStatusWrapper,
  SubSectionWrapper,
  SwicthWrapper,
  TitleIconWrap,
  ToggleSwitchWrapper,
  TopRow,
  ViolationFieldWrapper,
  ViolationStatusWrapper,
} from './styles'
import { SvgAlert } from '../../components/svg/SvgAlert'
import { AccountRestrictionsContext } from '../../services/accountRestrictionsContext/AccountRestrictionsContext '
import { DropdownField } from '../dropdownField/DropdownField'
import {
  AccountRestrictionsInterface,
  RestrictionTypesInterface,
} from '../../services/accountRestrictionsContext/AccountRestrictionsInterface'

export const AccountRestrictionsUI = () => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isOtherModified, setOtherIsModified] = useState(false)
  const [startDate, setStartDate] = useState<string[]>([])
  const [endDate, setEndDate] = useState<string[]>([])
  const {
    accountRestrictionsDetails,
    accountRestrictonsCollapse,
    setAccountRestrictionsDetails,
    setAccountRestrictonsCollapse,
    accountRestrictionsPopup,
    setAccountRestrictionsPopup,
    violationRestrictionTypes,
    reloadAccountRestrictions,
    setIsLoading,
    isEdited,
    originalAccountRestrictionsDetails,
  } = useContext(AccountRestrictionsContext)

  const [isModified, setIsModified] = useState(isEdited)
  const [dataModified, setDataModified] = useState<boolean>(false)
  const [editedRestrictionDetails, setEditedRestrictionDetails] = useState<
    RestrictionTypesInterface[]
  >(accountRestrictionsDetails?.restrictionTypes)
  const [initialRestrictionDetails, setInitialRestrictionDetails] = useState<
    RestrictionTypesInterface[]
  >(accountRestrictionsDetails?.restrictionTypes)
  const [editedAccountRestrictionDetails, setEditedAccountRestrictionDetails] =
    useState<AccountRestrictionsInterface>(accountRestrictionsDetails)

  const [isToggledOn, setIsToggledOn] = useState<boolean[]>([])

  const checkExpiryDateValid = (startDate: string, endDate: string) => {
    const expiryDate = new Date(startDate).getTime()
    const today = new Date(endDate).getTime()
    if (expiryDate < today) return false
    return true
  }

  useEffect(() => {
    if (accountRestrictionsDetails.restrictionTypes !== undefined) {
      const restrictionDetails =
        accountRestrictionsDetails.restrictionTypes.map((restriction) => {
          return {
            ...restriction,
            enabled:
              restriction.enabled && restriction.endDate !== null
                ? checkExpiryDateValid(
                    restriction.endDate as string,
                    new Date().toISOString().split('T')[0]
                  )
                  ? true
                  : false
                : restriction.enabled,
            endDate: restriction.endDate ?? '',
            isToggledOn: restriction.enabled,
            note: '',
            isModified: false,
          }
        })
      setEditedRestrictionDetails(restrictionDetails)
      //setInitialRestrictionDetails(restrictionDetails)
      const startDateList: string[] =
        accountRestrictionsDetails.restrictionTypes.map((restrictions) => {
          return restrictions.startDate ?? ''
        })
      const endDateList: string[] =
        accountRestrictionsDetails.restrictionTypes.map((restrictions) => {
          return restrictions.endDate ?? ''
        })
      setStartDate(startDateList)
      setEndDate(endDateList)
    }
  }, [accountRestrictionsDetails])

  useEffect(() => {
    if (accountRestrictionsDetails.restrictionTypes !== undefined) {
      const restrictionDetails =
        accountRestrictionsDetails.restrictionTypes.map((restriction) => {
          return {
            ...restriction,
            enabled:
              restriction.enabled && restriction.endDate !== null
                ? checkExpiryDateValid(
                    restriction.endDate as string,
                    new Date().toISOString().split('T')[0]
                  )
                  ? true
                  : false
                : restriction.enabled,
            endDate: restriction.endDate ?? '',
          }
        })
      setInitialRestrictionDetails(restrictionDetails)
    }
  }, [accountRestrictionsDetails.restrictionTypes])

  useEffect(() => {
    setIsModified(isEdited)
  }, [isEdited])

  const convertToDateFormat = (date: string) => {
    if (date !== undefined && date !== '') {
      const today = new Date(date)
      const newDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      )
      return newDate.toISOString().split('T')[0]
    }
  }
  const collapse = () => {
    setAccountRestrictonsCollapse(!accountRestrictonsCollapse)
  }
  const reloadAccountRestrictionsUI = () => {
    reloadAccountRestrictions()
    setTimeout(() => {
      setIsLoading(true)
    }, 200)
    setIsLoading(false)
  }
  const cancel = () => {
    setIsEditMode(false)
    setIsModified(false)
    setEditedRestrictionDetails(initialRestrictionDetails)
    const resetRestrictionDetails = initialRestrictionDetails?.map(
      (restriction) => {
        delete restriction?.newViolation
        return restriction
      }
    )
    setEditedAccountRestrictionDetails({
      ...accountRestrictionsDetails,
      restrictionTypes: resetRestrictionDetails,
      isOtherModified: accountRestrictionsDetails.isOtherModified,
      otherStartDateError: false,
      otherInfo: '',
      otherRestrictionType: '',
      otherOnlyEnabled: false,
      otherNotesError: false,
    })

    reloadAccountRestrictionsUI()
  }

  useEffect(() => {
    if (editedRestrictionDetails !== undefined) {
      const filteredData = editedRestrictionDetails.filter(
        (restriction, index) => {
          if (
            (originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              restriction.enabled &&
              restriction.isModified) ||
            (originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              !restriction.enabled &&
              restriction.isModified) ||
            (!originalAccountRestrictionsDetails.restrictionTypes[index]
              .enabled &&
              restriction.enabled &&
              restriction.isModified) ||
            (restriction.isModified && restriction.newViolation != null)
          )
            return restriction
        }
      )
      if (
        filteredData.length > 0 ||
        editedAccountRestrictionDetails.isOtherModified
      )
        setDataModified(true)
      else setDataModified(false)
    }
  }, [editedRestrictionDetails])

  const validateForm = () => {
    let isVal = true
    if (editedRestrictionDetails !== undefined) {
      const editedRestrictionDetailsError = new Array<boolean>(editedRestrictionDetails?.length).fill(false)
      editedRestrictionDetails.map((restriction, index) => {
        if (
          (originalAccountRestrictionsDetails.restrictionTypes[index].enabled &&
            restriction.enabled &&
            restriction.isModified) ||
          (originalAccountRestrictionsDetails.restrictionTypes[index].enabled &&
            !restriction.enabled &&
            restriction.isModified) ||
          (!originalAccountRestrictionsDetails.restrictionTypes[index]
            .enabled &&
            restriction.enabled &&
            restriction.isModified) ||
          (restriction.isModified && restriction.newViolation != null)
        ) {
          if (
            restriction.note === null ||
            restriction.note?.trim().length === 0
          ) {
            editedRestrictionDetails[index].isThereError = true
            setIsValid(false)
            editedRestrictionDetailsError[index]=false
            // isVal = false
          } else {
            editedRestrictionDetails[index].isThereError = false
            setIsValid(true)
            editedRestrictionDetailsError[index]=true
            // isVal = true
          }
          if (restriction.enabled) {
            if (
              restriction.startDate === undefined ||
              restriction.startDate === null
            ) {
              editedRestrictionDetails[index].isThereDateError = true
              setIsValid(false)
              editedRestrictionDetailsError[index]=false
              // isVal = false
            } else {
              editedRestrictionDetails[index].isThereDateError = false
              setIsValid(true)
              editedRestrictionDetailsError[index]=true
              // isVal = true
            }
          }
          isVal = editedRestrictionDetailsError.some((error) => error === false)
          if (
            restriction.endDate !== undefined &&
            restriction.endDate !== '' &&
            checkExpiryDateValid(
              editedRestrictionDetails[index].startDate as string,
              editedRestrictionDetails[index].endDate as string
            )
          ) {
            editedRestrictionDetails[index].isEndDateError = true
            isVal = false
          } else {
            editedRestrictionDetails[index].isEndDateError = false
            isVal = true
          }
        }
        if (
          editedRestrictionDetails[index].isEndDateError ||
          editedRestrictionDetails[index].isThereDateError ||
          editedRestrictionDetails[index].isThereError
        ) {
          isVal = false
        }
        setEditedRestrictionDetails([...editedRestrictionDetails])
      })
    }
    if (editedAccountRestrictionDetails !== undefined) {
      if (editedAccountRestrictionDetails.otherOnlyEnabled) {
        if (
          editedAccountRestrictionDetails.otherStartDate === undefined ||
          editedAccountRestrictionDetails.otherStartDate === null
        ) {
          editedAccountRestrictionDetails.otherStartDateError = true
          setIsValid(false)
          isVal = false
        } else {
          editedAccountRestrictionDetails.otherStartDateError = false
          setIsValid(true)
          isVal = true
        }
        if (
          editedAccountRestrictionDetails.otherInfo === null ||
          editedAccountRestrictionDetails.otherInfo?.trim().length === 0 ||
          !('otherInfo' in editedAccountRestrictionDetails)
        ) {
          editedAccountRestrictionDetails.otherNotesError = true
          setIsValid(false)
          isVal = false
        } else {
          editedAccountRestrictionDetails.otherNotesError = false
          setIsValid(true)
          isVal = true
        }
        if(
          editedAccountRestrictionDetails.otherRestrictionType === null ||
          editedAccountRestrictionDetails.otherRestrictionType?.trim().length === 0 ||
          !('otherRestrictionType' in editedAccountRestrictionDetails)){
            editedAccountRestrictionDetails.otherRestrictionTypeError = true
            setIsValid(false)
            isVal = false
          }
          else{
            editedAccountRestrictionDetails.otherRestrictionTypeError = false
            setIsValid(true)
            isVal = true
          }
      }
    }
    if (isVal && (dataModified || editedAccountRestrictionDetails.isOtherModified)) {
      setAccountRestrictionsPopup(true)
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        restrictionTypes: editedRestrictionDetails,
      }
      setAccountRestrictionsDetails(restrictionsDetails)
      setIsEditMode(false)
      setIsModified(false)
      setOtherIsModified(false)
      setEditedAccountRestrictionDetails({
        ...accountRestrictionsDetails,
        restrictionTypes: initialRestrictionDetails,
        isOtherModified: accountRestrictionsDetails.isOtherModified,
        otherStartDateError: false,
        otherInfo: '',
        otherRestrictionType: '',
        otherOnlyEnabled: false,
        otherNotesError: false,
      })
    }
  }
  
  const handleArraySwitchChange = (index: number) => {
    if (index !== undefined && editedRestrictionDetails !== undefined) {
      const previousState = editedRestrictionDetails[index].enabled
      if (previousState) {
        editedRestrictionDetails[index].originalDescription =
          editedRestrictionDetails[index].note
        editedRestrictionDetails[index].note = ''
      }
      editedRestrictionDetails[index].enabled =
        !editedRestrictionDetails[index].enabled
      //setNinetyDayRestrictionTypes([...editedRestrictionDetails!])
      //editedRestrictionDetails![index].editMode
      editedRestrictionDetails[index].isToggledOn =
        !editedRestrictionDetails[index].isToggledOn
      editedRestrictionDetails[index].isModified = true
      setEditedRestrictionDetails([...editedRestrictionDetails])
      setIsModified(true)
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        restrictionTypes: editedRestrictionDetails,
      }
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }
  }

  const handleSwitchChange = (fieldName: string) => {
    if (fieldName.toLowerCase() == 'sales only') {
      editedAccountRestrictionDetails.salesOnlyEnabled =
        !editedAccountRestrictionDetails.salesOnlyEnabled
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        ['salesOnlyEnabled']: editedAccountRestrictionDetails.salesOnlyEnabled,
      }
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }

    if (fieldName.toLowerCase() == 'final ext') {
      editedAccountRestrictionDetails.finalExtEnabled =
        !editedAccountRestrictionDetails.finalExtEnabled
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        ['finalExtEnabled']: editedAccountRestrictionDetails.finalExtEnabled,
      }
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }

    if (fieldName.toLowerCase() == 'other') {
      editedAccountRestrictionDetails.otherOnlyEnabled =
        !editedAccountRestrictionDetails.otherOnlyEnabled
      editedAccountRestrictionDetails.isOtherModified = true
      setOtherIsModified(true)
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        ['otherOnlyEnabled']: editedAccountRestrictionDetails.otherOnlyEnabled,
        ['isOtherModified']: editedAccountRestrictionDetails.isOtherModified,
      }
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }
  }
  const handleArrayNotes = (e: string, index: number) => {
    if (index !== undefined && editedRestrictionDetails !== undefined) {
      editedRestrictionDetails[index].isThereError = false
      editedRestrictionDetails[index].isModified = true
      editedRestrictionDetails[index].note = e
      setEditedRestrictionDetails([...editedRestrictionDetails])
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        restrictionTypes: editedRestrictionDetails,
      }
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }
  }

  const handleArrayViolations = (e: any, index: number) => {
    if (
      (index !== undefined || e != undefined) &&
      editedRestrictionDetails !== undefined
    ) {
      editedRestrictionDetails[index].newViolation = e?.value
      editedRestrictionDetails[index].note = ''
      editedRestrictionDetails[index].isModified = true
      if (Object.values(e)[0] === 3) {
        editedRestrictionDetails[index].enabled = true
      } else {
        if (editedRestrictionDetails[index].enabled === false) {
          editedRestrictionDetails[index].enabled = false
        }
      }
      setIsModified(true)
      setEditedRestrictionDetails([...editedRestrictionDetails])
      const restrictionsDetails = {
        ...editedAccountRestrictionDetails,
        restrictionTypes: editedRestrictionDetails,
      }
      setEditedRestrictionDetails(editedRestrictionDetails)
      setEditedAccountRestrictionDetails(restrictionsDetails)
    }
  }

  const handleDateArrayChange = (
    index: number,
    e: string | undefined,
    field: string
  ) => {
    if (e !== undefined && editedRestrictionDetails !== undefined) {
      if (field === 'Start Date') {
        editedRestrictionDetails[index].isThereDateError = false
        editedRestrictionDetails[index].startDate = e
        const restrictionsDetails = {
          ...editedAccountRestrictionDetails,
        }
        if (
          editedRestrictionDetails[index].enabled &&
          editedRestrictionDetails[index].defaultTermDays !== null
        ) {
          editedRestrictionDetails[index].endDate = ''
          endDate[index] = ''
        }
        startDate[index] = e
        setStartDate([...startDate])
        setEditedAccountRestrictionDetails(restrictionsDetails)
      } else if (field === 'End Date') {
        editedRestrictionDetails[index].isEndDateError = false
        editedRestrictionDetails[index].endDate = e
        const restrictionsDetails = {
          ...editedAccountRestrictionDetails,
        }
        setEditedAccountRestrictionDetails(restrictionsDetails)
        endDate[index] = e
        setEndDate([...endDate])

        if (
          editedRestrictionDetails[index].endDate !== undefined &&
          checkExpiryDateValid(
            editedRestrictionDetails[index].startDate as string,
            editedRestrictionDetails[index].endDate as string
          )
        ) {
          editedRestrictionDetails[index].isEndDateError = true
        } else {
          editedRestrictionDetails[index].isEndDateError = false
        }
      }
      setEditedRestrictionDetails([...editedRestrictionDetails])
    }
  }

  const handleNotes = (e: string, fieldName: string) => {
    if (fieldName !== undefined) {
      if (fieldName.toLowerCase() == 'sales only') {
        const accountRestrictionDetails = {
          ...editedAccountRestrictionDetails,
          ['salesOnlyInfo']: e,
        }
        setEditedAccountRestrictionDetails(accountRestrictionDetails)
      }
      if (fieldName.toLowerCase() == 'final ext') {
        const accountRestrictionDetails = {
          ...editedAccountRestrictionDetails,
          ['finalExtInfo']: e,
        }
        setEditedAccountRestrictionDetails(accountRestrictionDetails)
      }

      if (fieldName.toLowerCase() == 'other') {
        const accountRestrictionDetails = {
          ...editedAccountRestrictionDetails,
          ['otherInfo']: e,
          ['otherNotesError']: false
        }
        setEditedAccountRestrictionDetails(accountRestrictionDetails)
      }
    }
  }

  const handleOtherRestrictionType = (e: string, fieldName: string) => {
    if (fieldName !== undefined) {
      if (fieldName.toLowerCase() == 'other') {
        const accountRestrictionDetails = {
          ...editedAccountRestrictionDetails,
          ['otherRestrictionType']: e,
          ['otherRestrictionTypeError']:false
        }
        setEditedAccountRestrictionDetails(accountRestrictionDetails)
      }
    }
  }

  const violationOptions: any[] = [
    {
      value: 0,
      label: 'Reset',
    },
    {
      value: 1,
      label: 'Violation 1',
    },
    {
      value: 2,
      label: 'Violation 2',
    },
    {
      value: 3,
      label: 'Violation 3',
    },
  ]

  return (
    <MasterWrapper
      borderShadow={accountRestrictonsCollapse ? true : false}
      id="account-restrictions"
    >
      <TopRow>
        <HeaderRow onClick={() => collapse()}>
          <TitleIconWrap>
            <IconWrapper>
              {/* <Icon icon={Icons.Address} /> */}
              <SvgPadlock24 />
            </IconWrapper>
            <WDStyledSectionTitle>
              {'Account Restrictions'}
            </WDStyledSectionTitle>
          </TitleIconWrap>
        </HeaderRow>
      </TopRow>
      {accountRestrictonsCollapse && (
        <DividerWrapper>
          <Divider
            bgColor={COLORS.Background.Border}
            horizontal={true}
            thickness="1px"
          />
        </DividerWrapper>
      )}

      {accountRestrictonsCollapse && (
        <React.Fragment>
          <p>
            <b>90 Day Restrictions</b>
          </p>
          <p>
            Due to trading violations, this account must have cash or securities
            available before executing trades for 90 days after the date of
            violation.
          </p>
          {editedRestrictionDetails &&
            editedRestrictionDetails?.map((row, index: number) => {
              return (
                <>
                  {row.defaultTermDays != null && (
                    /* row.accountRestrictionId !== null && */ <>
                      <NinetyDaySubSectionWrapper>
                        <WDStyledSubSectionTitle>
                          {row.name}
                          {/*{' '}*/}
                          {/*{row.isModified ? 'True': 'False'}*/}
                        </WDStyledSubSectionTitle>
                        {!row.isModified &&
                          editedRestrictionDetails[index].enabled &&
                          row.defaultTermDays &&
                          row.startDate &&
                          row.endDate && (
                            <>
                              <SubSectionStatusWrapper>
                                Enabled on {startDate[index]}
                              </SubSectionStatusWrapper>
                              <SubSectionStatusWrapper>
                                End Date: {endDate[index]}
                              </SubSectionStatusWrapper>
                            </>
                          )}

                        {row.isModified &&
                          editedRestrictionDetails[index].enabled &&
                          row.defaultTermDays && ( //row.startDate &&
                            //row.endDate &&
                            <>
                              <SubSectionStatusWrapper>
                                <InputField
                                  label="Start Date"
                                  type={'date'}
                                  mandatory={true}
                                  textboxValue={convertToDateFormat(
                                    startDate[index]
                                  )}
                                  onChange={(e) =>
                                    handleDateArrayChange(
                                      index,
                                      e,
                                      'Start Date'
                                    )
                                  }
                                />
                              </SubSectionStatusWrapper>
                            </>
                          )}

                        {row.defaultTermDays && row.violation && (
                          <>
                            <ViolationStatusWrapper>
                              Violation Number:{row.violation}{' '}
                            </ViolationStatusWrapper>
                          </>
                        )}

                        <SwicthWrapper>
                          <SwitchButton
                            key={'switch_' + index}
                            onChange={() => handleArraySwitchChange(index)}
                            /* checked={checkExpiryDateValid(
                              editedRestrictionDetails[index].endDate as string
                            )} */
                            checked={editedRestrictionDetails[index].enabled}
                          />
                        </SwicthWrapper>
                      </NinetyDaySubSectionWrapper>
                      <NinetyDaySwitchWrapper>
                        {
                          //editedRestrictionDetails![index].enabled ||
                          editedRestrictionDetails !== undefined &&
                          violationRestrictionTypes !== undefined &&
                          violationRestrictionTypes.filter(
                            (x) => x.restrictionTypeId == row.restrictionTypeId
                          ).length == 0 &&
                          ((originalAccountRestrictionsDetails.restrictionTypes[
                            index
                          ].enabled &&
                            editedRestrictionDetails[index].enabled &&
                            editedRestrictionDetails[index].isModified) ||
                            (originalAccountRestrictionsDetails
                              .restrictionTypes[index].enabled &&
                              !editedRestrictionDetails[index].enabled &&
                              editedRestrictionDetails[index].isModified) ||
                            (!originalAccountRestrictionsDetails
                              .restrictionTypes[index].enabled &&
                              editedRestrictionDetails[index].enabled &&
                              editedRestrictionDetails[index].isModified)) ? (
                            //editedRestrictionDetails[index]?.restrictionTypeId != 10 &&
                            //editedRestrictionDetails[index]?.restrictionTypeId != 11
                            <>
                              <NinetyDayNotesWrapper>
                                {/*<FieldWrapper>*/}
                                <InputField
                                  key={'textarea_' + index}
                                  type="textarea"
                                  onChange={(e) => handleArrayNotes(e, index)}
                                  textboxPlaceholder={'Notes'}
                                />
                                {/*    <div>Error Msg</div>*/}
                                {/*</FieldWrapper>*/}
                              </NinetyDayNotesWrapper>
                            </>
                          ) : (
                            <></>
                          )
                        }

                        {
                          //editedRestrictionDetails![index].enabled ||
                          //editedRestrictionDetails[index]?.isModified == true &&
                          violationRestrictionTypes !== undefined &&
                            violationRestrictionTypes.filter(
                              (x) =>
                                x.restrictionTypeId == row?.restrictionTypeId
                            ).length > 0 && (
                              //editedRestrictionDetails[index]?.restrictionTypeId == 10 ||
                              //editedRestrictionDetails[index]?.restrictionTypeId == 11
                              <>
                                <ViolationFieldWrapper>
                                  <OtherInputFieldWrapper>
                                    <DropdownField
                                      key={'violation_' + index}
                                      label={'Violation Number'}
                                      onChange={(e) =>
                                        handleArrayViolations(e, index)
                                      }
                                      options={violationOptions}
                                      value={{
                                        value: editedRestrictionDetails[index]
                                          .newViolation
                                          ? editedRestrictionDetails[index]
                                              .newViolation
                                          : editedRestrictionDetails[index]
                                              .violation,
                                        label:
                                          editedRestrictionDetails[index]
                                            .newViolation !== undefined
                                            ? editedRestrictionDetails[index]
                                                .newViolation !== 0
                                              ? 'Violation ' +
                                                editedRestrictionDetails[index]
                                                  .newViolation
                                              : 'Reset'
                                            : '',
                                      }}
                                    />
                                  </OtherInputFieldWrapper>

                                  <OtherTextAreaFieldWrapper>
                                    <InputField
                                      key={'textarea_' + index}
                                      type="textarea"
                                      onChange={(e) =>
                                        handleArrayNotes(e, index)
                                      }
                                      textboxPlaceholder={'Notes'}
                                      textboxValue={
                                        editedRestrictionDetails[index].note ??
                                        ''
                                      }
                                    />
                                  </OtherTextAreaFieldWrapper>
                                </ViolationFieldWrapper>
                              </>
                            )
                        }

                        {
                          //editedRestrictionDetails![index].enabled &&
                          editedRestrictionDetails !== undefined &&
                            !editedRestrictionDetails[index].isModified && (
                              <WDStyledSettingsLabel>
                                {editedRestrictionDetails[index].description}
                              </WDStyledSettingsLabel>
                            )
                        }

                        {editedRestrictionDetails !== undefined &&
                          !editedRestrictionDetails[index].enabled && (
                            <WDStyledSettingsLabel>{''}</WDStyledSettingsLabel>
                          )}
                      </NinetyDaySwitchWrapper>
                      {editedRestrictionDetails !== undefined &&
                        editedRestrictionDetails[index].isThereError && (
                          <ErrorMsgWrapper>
                            <SvgAlert />
                            Adding a Note is required to toggle this
                            restriction.
                          </ErrorMsgWrapper>
                        )}

                      {editedRestrictionDetails !== undefined &&
                        editedRestrictionDetails[index].isThereDateError && (
                          <ErrorMsgWrapper>
                            <SvgAlert />
                            Start Date is required.
                          </ErrorMsgWrapper>
                        )}
                    </>
                  )}
                </>
              )
            })}

          <NinetyDaySubSectionWrapper>
            <WDStyledSubSectionTitle>{'Other'}</WDStyledSubSectionTitle>
            {editedAccountRestrictionDetails.isOtherModified &&
              editedAccountRestrictionDetails.otherOnlyEnabled && (
                <SubSectionStatusWrapper>
                  <InputField
                    label="Start Date"
                    type={'date'}
                    mandatory={true}
                    // textboxValue={convertToDateFormat(startDate[index])}
                    onChange={(e) => {
                      if (e !== undefined) {
                        editedAccountRestrictionDetails.otherStartDateError = false
                        editedAccountRestrictionDetails.otherStartDate = e
                        setEditedAccountRestrictionDetails({...editedAccountRestrictionDetails})
                      }
                    }}
                  />
                </SubSectionStatusWrapper>
              )}
            <SwicthWrapper>
              <SwitchButton
                onChange={() => handleSwitchChange('other')}
                checked={editedAccountRestrictionDetails.otherOnlyEnabled}
              />
            </SwicthWrapper>
          </NinetyDaySubSectionWrapper>
          <NinetyDaySwitchWrapper>
            {
              //isEditMode &&
              editedAccountRestrictionDetails.otherOnlyEnabled && (
                <>
                  <OtherWrapper>
                    <OtherInputFieldWrapper>
                      {/*<FlexItem>*/}
                      <InputField
                        onChange={(e) => handleOtherRestrictionType(e, 'other')}
                        textboxPlaceholder={'Other Restriction Type'}
                        textboxValue={
                          editedAccountRestrictionDetails.otherRestrictionType
                        }
                      />
                    </OtherInputFieldWrapper>
                    {/*</FlexItem >*/}
                    <OtherTextAreaFieldWrapper>
                      <InputField
                        type="textarea"
                        onChange={(e) => handleNotes(e, 'other')}
                        textboxPlaceholder={'Notes'}
                        textboxValue={editedAccountRestrictionDetails.otherInfo}
                      />
                    </OtherTextAreaFieldWrapper>
                  </OtherWrapper>
                </>
              )
            }
            {/*{editedAccountRestrictionDetails.otherOnlyEnabled && !isEditMode &&*/}
            {/*    (<WDStyledSettingsLabel>{editedAccountRestrictionDetails.otherInfo}</WDStyledSettingsLabel>)*/}
            {/*}*/}

            {!editedAccountRestrictionDetails.otherOnlyEnabled &&
              !isEditMode && (
                <WDStyledSettingsLabel>{''}</WDStyledSettingsLabel>
              )}
            {
              !editedAccountRestrictionDetails.otherOnlyEnabled &&
                isEditMode && (
                  <WDStyledSettingsLabel>{''}</WDStyledSettingsLabel>
                )
              // disabled={!isEditMode } editedAccountRestrictionDetails.isOtherModified &&
            }

            {/*<SwitchButton*/}
            {/*    onChange={() => handleSwitchChange('other')}*/}
            {/*    checked={editedAccountRestrictionDetails.otherOnlyEnabled}*/}
            {/* />*/}
          </NinetyDaySwitchWrapper>
          {editedAccountRestrictionDetails !== undefined &&
            editedAccountRestrictionDetails.otherStartDateError && (
              <ErrorMsgWrapper>
                <SvgAlert />
                Start Date is required.
              </ErrorMsgWrapper>
            )}
          {editedAccountRestrictionDetails !== undefined &&
            editedAccountRestrictionDetails.otherNotesError && (
              <ErrorMsgWrapper>
                <SvgAlert />
                Adding a Note is required to toggle this restriction.
              </ErrorMsgWrapper>
            )}
          {editedAccountRestrictionDetails !== undefined &&
            editedAccountRestrictionDetails.otherRestrictionTypeError && (
              <ErrorMsgWrapper>
                <SvgAlert />
                Adding a Restriction Type is required to toggle this restriction.
              </ErrorMsgWrapper>
            )}

          {/*Non 90 Day Restriction*/}

          {editedRestrictionDetails &&
            editedRestrictionDetails?.map((row, index: number) => {
              return (
                <div key={index}>
                  {row.defaultTermDays === null && (
                    /* row.accountRestrictionId !==
                      null && */ <>
                      <SubSectionWrapper>
                        <WDStyledSubSectionTitle>
                          {row.name}
                          {/*{' '}*/}
                          {/*{row.isModified ? 'True' : 'False'}*/}
                        </WDStyledSubSectionTitle>
                        {!row.isModified &&
                          editedRestrictionDetails[index].enabled &&
                          editedRestrictionDetails[index].startDate && (
                            <>
                              <SubSectionStatusWrapper>
                                Enabled on {startDate[index]}
                              </SubSectionStatusWrapper>
                              {editedRestrictionDetails[index].endDate && (
                                <SubSectionStatusWrapper>
                                  End Date: {endDate[index]}
                                </SubSectionStatusWrapper>
                              )}
                            </>
                          )}

                        {row.isModified &&
                          editedRestrictionDetails[index].enabled && ( //&& row.startDate
                            <>
                              <SubSectionStatusWrapper>
                                <InputField
                                  label="Start Date"
                                  type={'date'}
                                  mandatory={true}
                                  textboxValue={convertToDateFormat(
                                    startDate[index]
                                  )}
                                  onChange={(e) =>
                                    handleDateArrayChange(
                                      index,
                                      e,
                                      'Start Date'
                                    )
                                  }
                                />
                              </SubSectionStatusWrapper>

                              <SubSectionStatusWrapper>
                                <InputField
                                  label="End Date"
                                  type={'date'}
                                  name=""
                                  textboxPlaceholder=""
                                  textboxValue={convertToDateFormat(
                                    endDate[index]
                                  )}
                                  onChange={(e) =>
                                    handleDateArrayChange(index, e, 'End Date')
                                  }
                                />
                              </SubSectionStatusWrapper>
                            </>
                          )}
                        <SwitchButton
                          key={'switch_' + index}
                          onChange={() => handleArraySwitchChange(index)}
                          /* checked={
                              editedRestrictionDetails[index].endDate !==
                                null ||
                              editedRestrictionDetails[index].endDate !==
                                undefined
                                ? checkExpiryDateValid(
                                    editedRestrictionDetails[index]
                                      .endDate as string
                                  )
                                : editedRestrictionDetails[index].enabled
                            } */
                          checked={editedRestrictionDetails[index].enabled}
                        />
                      </SubSectionWrapper>
                      <ToggleSwitchWrapper>
                        {
                          //editedRestrictionDetails![index].enabled ||
                          (editedRestrictionDetails !== undefined &&
                            editedRestrictionDetails[index].enabled &&
                            editedRestrictionDetails[index].isModified) ||
                          (originalAccountRestrictionsDetails.restrictionTypes[
                            index
                          ].enabled &&
                            !editedRestrictionDetails[index].enabled &&
                            editedRestrictionDetails[index].isModified) ||
                          (!originalAccountRestrictionsDetails.restrictionTypes[
                            index
                          ].enabled &&
                            editedRestrictionDetails[index].enabled &&
                            editedRestrictionDetails[index].isModified) ? (
                            <>
                              <NinetyDayNotesWrapper>
                                {/*<FieldWrapper>*/}
                                <InputField
                                  key={'textarea_' + index}
                                  type="textarea"
                                  onChange={(e) => handleArrayNotes(e, index)}
                                  textboxPlaceholder={'Notes'}
                                />
                                {/*    <div>Error Msg</div>*/}
                                {/*</FieldWrapper>*/}
                              </NinetyDayNotesWrapper>
                            </>
                          ) : (
                            <></>
                          )
                        }

                        {
                          //editedRestrictionDetails![index].enabled &&
                          editedRestrictionDetails !== undefined &&
                            !(
                              (editedRestrictionDetails !== undefined &&
                                editedRestrictionDetails[index].enabled &&
                                editedRestrictionDetails[index].isModified) ||
                              (originalAccountRestrictionsDetails
                                .restrictionTypes[index].enabled &&
                                !editedRestrictionDetails[index].enabled &&
                                editedRestrictionDetails[index].isModified) ||
                              (!originalAccountRestrictionsDetails
                                .restrictionTypes[index].enabled &&
                                editedRestrictionDetails[index].enabled &&
                                editedRestrictionDetails[index].isModified)
                            ) && (
                              <WDStyledSettingsLabel>
                                {editedRestrictionDetails[index].description !==
                                null
                                  ? editedRestrictionDetails[index].description
                                  : ''}
                              </WDStyledSettingsLabel>
                            )
                        }

                        {/*{editedRestrictionDetails![index].enabled && (*/}
                        {/*    <WDStyledSettingsLabel>{editedRestrictionDetails![index].description}</WDStyledSettingsLabel>*/}
                        {/* )*/}
                        {/*}*/}

                        {editedRestrictionDetails !== undefined &&
                          !editedRestrictionDetails[index].enabled && (
                            <WDStyledSettingsLabel>{''}</WDStyledSettingsLabel>
                          )}
                        {/*{!editedRestrictionDetails![index].enabled && isEditMode &&*/}
                        {/*    (<WDStyledSettingsLabel>{''}</WDStyledSettingsLabel>)*/}
                        {/*}*/}

                        {/*<SwitchButton*/}
                        {/*    onChange={() => handleArraySwitchChange(index)}*/}
                        {/*    checked={editedRestrictionDetails[index].enabled}*/}

                        {/*/>*/}
                      </ToggleSwitchWrapper>
                      {editedRestrictionDetails !== undefined &&
                        editedRestrictionDetails[index].isThereError && (
                          <ErrorMsgWrapper>
                            <SvgAlert />
                            Adding a Note is required to toggle this
                            restriction.
                          </ErrorMsgWrapper>
                        )}

                      {editedRestrictionDetails !== undefined &&
                        editedRestrictionDetails[index].isThereDateError && (
                          <ErrorMsgWrapper>
                            <SvgAlert />
                            Start Date is required.
                          </ErrorMsgWrapper>
                        )}
                      {editedRestrictionDetails !== undefined &&
                        editedRestrictionDetails[index].isEndDateError && (
                          <ErrorMsgWrapper>
                            <SvgAlert />
                            End Date cannot be before Start Date.
                          </ErrorMsgWrapper>
                        )}
                    </>
                  )}
                </div>
              )
            })}
        </React.Fragment>
      )}
      {
        //editedAccountRestrictionDetails != null && editedRestrictionDetails?.length > 0 &&
        (isModified || isOtherModified) && (
          <ButtonRow>
            <SecondaryButtonWrapper>
              <WDStyledCancelButton onClick={() => cancel()}>
                <WDStyledCancelButtonLabel>
                  {'Cancel'}
                </WDStyledCancelButtonLabel>
              </WDStyledCancelButton>
            </SecondaryButtonWrapper>
            <PrimaryButtonWrapper>
              <WDStyledSaveButton
                onClick={() => {
                  validateForm()
                }}
              >
                <WDStyledCancelButtonLabel>{'Save'}</WDStyledCancelButtonLabel>
              </WDStyledSaveButton>
            </PrimaryButtonWrapper>
          </ButtonRow>
        )
      }
      {/*{isModified ||editedAccountRestrictionDetails.isOtherModified && (*/}

      {/* )}*/}

      {accountRestrictionsPopup && <AccountRestrictionsPopup />}
    </MasterWrapper>
  )
}