export const colors = {
  Brand: '#00A9CE',
  BrandMedium: '#326295',
  BrandStrong: '#00245D',
  NeutralsWhite: '#FFFFFF',
  Neutrals10: '#F1F5F9',
  Neutrals30: '#CBD5E1',
  Neutrals40: '#A7AFBC',
  Neutrals60: '#475569',
  Neutrals70: '#334155',
  Neutrals80: '#1E293B',
  Neutrals90: '#0F172A',
  NeutralBorder: '#CBD5E1',
  BackgroundStrong: '#2563EB',
  BackgroundStrongHover: '#1D4ED8',
  BackgroundStrongActive: '#1E40AF',
  BackgroundWeak: '#F8FAFC',
  BackgroundHover: '#DBEAFE',
  BorderStrong: '#60A5FA',
  Primary20: '#BFDBFE',
  Primary50: '#3B82F6',
  Primary70: '#1D4ED8',
  Gray10: '#F0F2F5',
}
export const Colors = {
  Brand3: '#00245D',
  PrimaryText: '#2563EB',
  Primary10: '#A4C5E8',
  Primary20: '#6699D2',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  White: '#FFFFFF',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#334155',
  Gray30: '#D0D7E2',
  Gray: '#475569',
  Gray50: ' #7B8698',
  Gray60: '#596579',
  Gray80: '#192638',
  Elevation01: '#00245D',
  Success2: '#22C373',
  Black: '#181E27',
  Gray70: '#374253',
  Blue9: '#01155F',
  GrayscaleBlack: '#181E27',
  Brand2: '#326295',
  Gray90: '#0F1825',
  Blue1: '#00245D', //name to be provided by UX team
  LightWhite: '#EEF3F5', //,/name to be provided by UX team
  NeutralText: '#0F172A',
  Negative2: '#C72F24',
  DangerText: '#BA0517',
  SuccessText: '#2E844A',
}

export const Backgrounds = {
  Primary10: '#A4C5E8',
  Primary20: '#6699D2',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  Info100: '#D5FEFD',
  Info800: '#0F5490',
  Warning100: '#FEF9D4',
  White: '#FFFFFF',
  Brand1: '#00A9CE',
  Brand2: '#326295',
  Brand3: '#00245D',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#E6EAF0',
  Gray30: '#D0D7E2',
  Gray40: '#A7AFBC',
  Gray50: '#cbd5e1',
  Gray60: '#596579',
  Gray70: '#374253',
  Saffron: '#CF4242', //name to be provided by UX team
  Green: '#42CF8C', //name to be provided by UX team
  Accent: '#1F5EB7',
  Gray: '#EBF2FA', //name to be provided by UX team
  Success2: '#22C373',
  NeutralMedium: '#F1F5F9',
}

export const BorderColors = {
  PrimaryBorderStrong: '#60A5FA',
  PrimaryText: '#2563EB',
  Primary5: '#bfdbfe',
  Primary10: '#A4C5E8',
  Primary20: '#6699D2',
  Primary30: '#00a9ce',
  Primary50: '#1F5EB7',
  Primary60: '#3B82F6',
  Primary70: '#0847BE',
  Primary80: '#002698',
  Primary90: '#000833',
  Primary100: '#CFF2FE',
  Primary800: '#05388E',
  Info100: '#D5FEFD',
  Info800: '#0F5490',
  Warning100: '#FEF9D4',
  White: '#FFFFFF',
  Brand1: '#00A9CE',
  Brand2: '#326295',
  Brand3: '#00245D',
  Gray05: '#FAFBFC',
  Gray10: '#F0F2F5',
  Gray20: '#E6EAF0',
  Gray30: '#D0D7E2',
  Gray40: '#A7AFBC',
  Gray50: '#cbd5e1',
  Gray60: '#596579',
  Gray70: '#374253',
  Saffron: '#CF4242', //name to be provided by UX team
  Danger: '#C72F24',
  DangerStrong: '#EA001E',
  Green: '#42CF8C', //name to be provided by UX team
  Accent: '#1F5EB7',
  Gray: '#EBF2FA', //name to be provided by UX team
  Success2: '#22C373',
  SuccessStrong: '#41B658',
}



// export const Colors = {
//   BrandStrong: '#00245D',
//   Primary50: '#1F5EB7',
//   Primary80: '#002698',
//   White: '#FFFFFF',
//   Gray05: '#FAFBFC',
//   Gray10: '#F0F2F5',
//   Gray60: '#596579',
//   Gray80: '#192638',
//   Elevation01: '#00245D',
//   Success2: '#22C373',
//   Negative2: '#C72F24',
//   Black: '#181E27',
//   Gray70: '#374253',
//   Blue9: '#01155F',
//   GrayscaleBlack: '#181E27',
//   Brand2: '#326295',
//   Gray90: '#0F1825',
//   Gray30: '#D0D7E2',
//   Gray50: ' #7B8698',
//   Blue1: '#00245D', //name to be provided by UX team
//   LightWhite: '#EEF3F5', //,/name to be provided by UX team
//   Neutrals90: '#0F172A'
// }

// export const Backgrounds = {
//   Primary10: '#A4C5E8',
//   Primary50: '#1F5EB7',
//   Primary100: '#CFF2FE',
//   Primary800: '#05388E',
//   Info100: '#D5FEFD',
//   Info800: '#0F5490',
//   Warning100: '#FEF9D4',
//   White: '#FFFFFF',
//   Brand1: '#00A9CE',
//   Brand2: '#326295',
//   Brand3: '#00245D',
//   Gray05: '#FAFBFC',
//   Gray10: '#F0F2F5',
//   Gray30: '#D0D7E2',
//   Gray60: '#596579',
//   Gray70: '#374253',
//   Saffron: '#CF4242', //name to be provided by UX team
//   Green: '#42CF8C', //name to be provided by UX team
//   Gray20: '#E6EAF0',
//   Accent: '#1F5EB7',
//   Gray: '#EBF2FA', //name to be provided by UX team
//   Success2: '#22C373',
//   LightWhite: '#EEF3F5',
// }