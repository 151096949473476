import styled from "styled-components";
import { COLORS } from "../../theme/Colors";
import { FONTS, FontSizes, FontWeights } from "../../theme/Fonts";
import { SIZES } from "../../theme/Sizes";
import { LineHeights } from "../../theme/Spacing";

interface AddressDetailsUIProps {
  borderShadow?: boolean
}

const borderShadowProperty = '0px 2px 6px -2px rgba(0, 36, 93, 0.3)'

export const MasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex: 0 1 100%;
  width: 100%;
  padding: 8px 24px;
  margin-bottom: 16px;
  margin-left: 24px;
  /* padding-top: 32px;
padding-bottom: 32px;
gap: 1 rem; */
  background: ${COLORS.Background.Primary};
  /* neutral/border-weak */

  border: 1px solid #e2e8f0;
  /* Elevation/Elevation-low */

  box-shadow: ${(AddressProps: AddressDetailsUIProps) =>
    AddressProps.borderShadow ? borderShadowProperty : null};
  border-radius: 8px;
`

export const TopRow = styled.div`
  /* padding-top: 0.5rem; */
  display: flex;
  max-height: 48px;
  padding-bottom: 8px;
  width: 100%;
  margin: 0px;
  cursor: pointer;
`
export const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 100%;
  max-height: 48px;
  gap: 16px;
  margin: 0px;
  cursor: pointer;
`
export const TitleIconWrap = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  gap: 10px;
`

export const IconWrapper = styled.div`
  /* padding-top: 0.5rem; */
`
export const TertiaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 92px;
  min-height: 32px;
  align-items: center;
  justify-content: flex-end;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-self: flex-end;
`

export const EditWrapper = styled.div`
  padding-left:10px
`
export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  /* margin-bottom: 16px; */
`

export const SubSectionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 16px 0px;
  button {
    font-family: SourceSansPro-SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    padding: 4px 16px;
    color: ${COLORS.Text.Primary};
    background-color: ${COLORS.Background.NeutralWeak};
    border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    border-radius: 4px;
    &:hover {
      background-color: ${COLORS.Background.Primary10};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:active {
      background-color: ${COLORS.Background.BackgroundActive};
      border: 1px solid ${COLORS.Background.PrimaryBorderStrong};
    }
    &:focus {
      border: 2px solid ${COLORS.Brand.Brand1};
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  gap: 24px;
  margin: 8px 0px;
`
export const LegalTab = styled.div`
  padding-bottom: 16px;
`
export const FlexItem1 = styled.div`
  flex: 0 1 100%;
  width: 33.33%;
`
export const FlexItem = styled.div`
  flex: 0 1 50%;
  width: 50%;
`

export const NotesWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: auto;
  gap: 4px;
  margin: 8px 0px;
`
export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 84px;
  gap: 16px;
  // margin: 8px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const SecondaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 110px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const PrimaryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 6.0648px;
  max-height: 40px;
  font-family: SourceSansPro-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const ModalWrapper = styled.div`
  position: fixed;
  /* overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  } */
  z-index: 1;
  // overflow-y: scroll;
  display: flex;
  height:100%;
  position: fixed;
  //padding-top: 24px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* padding-bottom: 30px; */
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 116, 139, 0.8);
  width: 100%;
  // margin: 0 -5%;
`

export const ContactPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 284px !important;
  position: absolute;
  flex: 0 1 100%;
  width: 508px;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  background: ${COLORS.Background.Primary};
  border: 1px solid #e2e8f0;
  box-shadow: none;
  border-radius: 8px;
  //overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: scroll;
  }
`
export const DividerWrapper2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 16px 0px 0px 0px;
`

export const CloseWrapper = styled.div`
  cursor: pointer;
`

export const SubHeadingWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
  max-height: 10px;
  gap: 8px;
  margin: 8px 0px;
  `
export const ListItems = styled.div`
  display: flex;
  flex: 0 1 100%;
  align-self: stretch;
  width: 100%;
 
  gap: 8px;
  margin-left: 20px;
  `

export const CancelWrap = styled.div`
background: #ebf7e6;
min-height:45px;
border-radius:5px;
width: 10%;
padding: 2px;
margin-left:76px;
display: flex;
align-self: stretch;
// align-items: center;
justify-content: space-between;
margin-top: -4px;
border: 2px ;
`

export const LabelWrapper = styled.p`
  font-size: ${FontSizes.Paragraph}; // 16px
  font-family: ${FONTS.SourceSansProRegular};
  font-weight: ${FontWeights.Regular}; // 400
  line-height: ${LineHeights.Title}; // 24px
  color: ${COLORS.UI.Text};
  margin: -20px;
  margin-left: 0.2px;
  padding-bottom: ${SIZES[0]}; // 4px
`

export const InlineErrorWrapper = styled.div`
  color: #ba0517;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  font-family: SourceSansPro-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
`