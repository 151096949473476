import React, {
  useState,
  createContext,
  useEffect,
  Dispatch,
  useContext,
} from 'react'
import { useLocation } from 'react-router-dom'
import {
  AccountDetails,
  AccountDetailsUpdatedValues,
  defaultAccountDetails,
  DeleteContact,
  TableData,
  UpdateAccountDetails,
} from './AccountDetailsInterface'
import { ApprovalContext } from '../approvalContext/ApprovalContext'
import { AuthContext } from '../AuthContext'
import useAxiosProtected from '../http/useAxiosProtected'
import {
  accountDetailsSubmissionMethod,
  addUpdateAccountDetailsMethod,
  deleteContact,
  deleteEmail,
  fetchAccountDetails,
} from './AccountDetailsHttp'

interface SetAccountDetailsCollapse {
  (value: boolean): void | boolean
}

interface SetUpdateAccountDetails {
  (value: UpdateAccountDetails): void | UpdateAccountDetails
}

interface SetSubmissionTableData {
  (value: TableData[]): void | TableData[]
}
interface SetErrorMsg {
  (value: number): void | number
}

interface SetAccountDetailsUpdateData {
  (value: AccountDetailsUpdatedValues): void | AccountDetailsUpdatedValues
}


interface SetDeleteContactFunc {
  (value: DeleteContact): void | DeleteContact
}

interface AccountDetailsContextInterface {
  accountID: number | undefined
  accountDetails: AccountDetails | undefined
  accountDetailsCollapse: boolean
  submissionPopUp: boolean
  setSubmissionPopUp: SetAccountDetailsCollapse
  submissionTableData: TableData[] | undefined
  setSubmissionTableData: SetSubmissionTableData
  errorMsg: number | undefined
  setErrorMsg: Dispatch<React.SetStateAction<number>>
  updateAccountDetails: UpdateAccountDetails | undefined
  setUpdateAccountDetails: SetUpdateAccountDetails
  setAccountDetailsCollapse: SetAccountDetailsCollapse
  deleteContactData: DeleteContact | undefined
  setDeleteContactData: SetDeleteContactFunc
  isLoading: boolean
  error: unknown | null | object
  accountDetailsUpdatedData: AccountDetailsUpdatedValues | undefined
  setAccountDetailsUpdateData: SetAccountDetailsUpdateData
  accountDetailSubmissionPayload: AccountDetailsUpdatedValues | undefined
  setAccountDetailsSubmissionPayload: SetAccountDetailsUpdateData
  removePhoneResponseCode: number | undefined
  setRemovePhoneResponseCode: Dispatch<React.SetStateAction<number>>
  showConfirmDialog: boolean
  setShowConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>
  deleteEmailData: DeleteContact | undefined
  setDeleteEmailData: SetDeleteContactFunc
  removeEmailResponseCode: number | undefined
  setRemoveEmailResponseCode: Dispatch<React.SetStateAction<number>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  retrieveAccountDetails: () => void

}
export const AccountDetailsContext = createContext<
  AccountDetailsContextInterface | undefined | null
>(undefined)

interface AccountDetailsCtxProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

export const AccountDetailsContextProvider = ({
  children,
}: AccountDetailsCtxProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<unknown | null | object>(null)
  const [accountDetails, setAccountDetails] = useState(defaultAccountDetails)
  const [accountID, setAccountID] = useState<number>()
  const [accountDetailsCollapse, setAccountDetailsCollapse] = useState(true)
  const [updateAccountDetails, setUpdateAccountDetails] =
    useState<UpdateAccountDetails>()
  const [deleteContactData, setDeleteContactData] = useState<DeleteContact>()
  const [submissionPopUp, setSubmissionPopUp] = useState(false)
  const [submissionTableData, setSubmissionTableData] = useState<TableData[]>()
  const [errorMsg, setErrorMsg] = useState<number>(100)
  const [accountDetailsUpdatedData, setAccountDetailsUpdateData] =
    useState<AccountDetailsUpdatedValues>()
  const [accountDetailSubmissionPayload, setAccountDetailsSubmissionPayload] =
    useState<AccountDetailsUpdatedValues>()
  const [removePhoneResponseCode, setRemovePhoneResponseCode]= useState<number>(100)
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
  const [deleteEmailData, setDeleteEmailData] = useState<DeleteContact>()
  const [removeEmailResponseCode, setRemoveEmailResponseCode]= useState<number>(100)
  
  

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  let accountIdString = searchParams.get('accountId')

  if (accountIdString === undefined || accountIdString === null) {
    accountIdString = '27'
  }
  const accountId = parseInt(accountIdString, 10)
  const approval = useContext(ApprovalContext)
  const clientIdValue: any = approval?.value
  const authCtx = useContext(AuthContext)
  const axiosProtected = useAxiosProtected()

  async function retrieveAccountDetails() {
    if(clientIdValue !== undefined && clientIdValue !== ''){
      try {  
        const data = await fetchAccountDetails(clientIdValue, axiosProtected)
        setAccountDetails(data)
        setIsLoading(false)
      } catch (error: unknown) {
        setError(error)
      }
    }
  }
  useEffect(() => {
    if(authCtx?.accessToken){
      retrieveAccountDetails()
    }
  }, [clientIdValue, errorMsg, authCtx?.accessToken])

  useEffect(() => {
    async function deleteContactDetails() {
      try {
        if (deleteContactData !== undefined) {
          const data = await deleteContact(deleteContactData,axiosProtected)
          setRemovePhoneResponseCode(data)

        }
      } catch (error: unknown) {
        setError(error)
      }
    }
    if(authCtx?.accessToken){
      deleteContactDetails()
    }
  }, [deleteContactData,authCtx?.accessToken])

  useEffect(() => {
    async function deleteEmailDetails() {
      try {
        if (deleteEmailData !== undefined) {
          const data = await deleteEmail(deleteEmailData,axiosProtected)
          setRemoveEmailResponseCode(data)

        }
      } catch (error: unknown) {
        setError(error)
      }
    }
    if(authCtx?.accessToken){
      deleteEmailDetails()
    }
  }, [deleteEmailData,authCtx?.accessToken])

  useEffect(() => {
    async function updateAccountDetailsFunction() {
      if (updateAccountDetails !== undefined) {
        const accountDetailsData = await addUpdateAccountDetailsMethod(
          updateAccountDetails,
          axiosProtected
        )
        setErrorMsg(accountDetailsData)
      }
    }
    if(authCtx?.accessToken){
      updateAccountDetailsFunction()
    }
  }, [updateAccountDetails,authCtx?.accessToken])

  useEffect(() => {
    async function accountDetailsSubmission() {
      try {
        if (accountDetailSubmissionPayload !== undefined) {
          const data = await accountDetailsSubmissionMethod(
            accountDetailSubmissionPayload,
            axiosProtected
          )
          setErrorMsg(data)
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
    if(authCtx?.accessToken){
      accountDetailsSubmission()
    }
  }, [accountDetailSubmissionPayload,authCtx?.accessToken])

  return (
    <AccountDetailsContext.Provider
      value={{
        errorMsg,
        setErrorMsg,
        accountID,
        accountDetails,
        accountDetailsCollapse,
        setAccountDetailsCollapse,
        updateAccountDetails,
        setUpdateAccountDetails,
        isLoading,
        error,
        submissionPopUp,
        setSubmissionPopUp,
        submissionTableData,
        setSubmissionTableData,
        accountDetailsUpdatedData,
        setAccountDetailsUpdateData,
        accountDetailSubmissionPayload,
        setAccountDetailsSubmissionPayload,
        deleteContactData,
        setDeleteContactData,
        removePhoneResponseCode,
        setRemovePhoneResponseCode,
        showConfirmDialog,
        setShowConfirmDialog,
        deleteEmailData,
        setDeleteEmailData,
        removeEmailResponseCode,
        setRemoveEmailResponseCode,
        setIsLoading,
        retrieveAccountDetails,
      }}
    >
      {children}
    </AccountDetailsContext.Provider>
  )
}
