import React from 'react'
import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'
import { AccountOtherRestrictionTypePayload, AccountRestrictionsTypePayload, RestrictionTypesInterface } from './AccountRestrictionsInterface';

export async function fetchAccountRestrictionsTypes(accountId: string, axiosProtected: AxiosInstance) {
    const url = URLs.ACCOUNT_RESTRICTIONS_TYPE_GET_API
    //const payload = {
    //    accountNumber: '76154719',
    //}
    const urlParam = accountId.toString();
    const response = await axiosProtected.get(url + urlParam)
    const data = response.data as RestrictionTypesInterface[]
    const sortedData = data.sort((a, b) => (b.defaultTermDays! - a.defaultTermDays!))
     console.log('🚀 ~ Fetch Account Restriction Types =>', data)
    return sortedData
}

export async function addUpdateAccountRestrictionsTypes(
    payload: AccountRestrictionsTypePayload[] | null,
    axiosProtected: AxiosInstance
) {
    try {
        const url = URLs.ACCOUNT_RESTRICTIONS_TYPE_ADD_UPDATE_API
        const response = await axiosProtected.post(url, payload)
        const data = response.status
        console.log('🚀 ~ Update Account Restrictions =>', response)
        return data
    } catch (e) {
        return 500
    }
}

export async function addUpdateOtherAccountRestrictionsTypes(
    payload: AccountOtherRestrictionTypePayload[] | null,
    axiosProtected: AxiosInstance) {
    try {
        const url = URLs.ACCOUNT_RESTRICTIONS_TYPE_ADD_UPDATE_OTHER_API
        const response = await axiosProtected.post(url, payload)
        const data = response.status
        console.log('🚀 ~ Update Account Other Restrictions Type =>', response)
        return data
    } catch (e) {
        return 500
    }

}

export async function fetchAccountRestrictionsAuditTrails(accountId: string, axiosProtected: AxiosInstance) {
    const url = URLs.ACCOUNT_RESTRICTIONS_AUDIT
    //const payload = {
    //    accountNumber: '76154719',
    //}
    const urlParam = accountId
    const response = await axiosProtected.get(url + urlParam)
    const data = response.data as RestrictionTypesInterface[]
    
    console.log('🚀 ~ Fetch Account Restriction Audit Trail =>', data)
    return data
}