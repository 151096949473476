export const ContactValues = {
  addressFields: {
    addressLine1: 'Street Address 1*',
    addressLine2: 'Street Address 2',
    city: 'City*',
    zipCode: 'Zip Code*',
    state: 'State*',
    country: 'Country*',
    startdate: 'Start Date*',
    enddate: 'End Date*',
  },

  buttons: {
    editButton: 'Edit',
    cancelButton: 'Cancel',
    saveButton: 'Save',
  },
}

export const AccountRolesValues = {
  sectionHeaders: {
    accountRoles: 'Account Roles',
  },
  checkbox: {
    value: 'Agree Terms and Condition',
  },

  inputFields: {
    order: 'Order Execution',
    trade: 'Trade and Open Order Confirmation ',
    pemail: 'Personal Email Address',
    aemail: 'Additional Email Address',
    changepassword: 'Change Password',
    changeusername: 'Change User Name',
    accountype: 'Account Type',
    estatement: 'Estatement',
    edeliveryproxy: 'EDelivery of Shareholder Proxy ',
    edeliverypostsale: 'EDelivery of Shareholder Post-Sale',
    defaultlogin: 'Default Login Page',
    secuirty: 'Security Question',
    answer: 'Answer',
    adduser: 'Add Additional User',
    acntno: 'Account Number',
    acnttype: 'Account Type',
  },

  buttons: {
    edit: 'Edit',
    cancel: 'Cancel',
    save: 'Save',
  },
}

export const noUserRoleMenuItemsList: string[] = [
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  //'Account Restrictions'
]

export const noUserRoleMenuItemsListWithPledged : string[] = [
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
]

export const sidebarMenuItems: string[] = [
  'Request for Approval',
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  //'Account Restrictions'
]

export const sidebarMenuItemsWithPledged: string[] = [
  'Request for Approval',
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  //'Account Restrictions'
]

export const sidebarMenuRequesterItems: string[] = [
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
]

export const sidebarMenuRequesterItemsWithPledged: string[] = [
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
]

export const requesterAndRestrictionsMenuItemList: string[] = [
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  'Account Restrictions',
]

export const requesterAndRestrictionsMenuItemListWithPledged: string[] = [
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  'Account Restrictions',
]

export const approverAndRequesterItemsList:string[] = [
  'Request for Approval',
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
]

export const approverAndRequesterItemsListWithPledged:string[] = [
  'Request for Approval',
  'Account Details',
  'Account Loan Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
]

export const sidebarRestrictionsMenuItems: string[] = [
  'Request for Approval',
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  'Account Restrictions',
  'Account Restrictions Report',
]

export const sidebarRestrictionsMenuItemsWithPledged: string[] = [
  'Request for Approval',
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Investment Profile',
  'Audit Trail',
  'Account Restrictions',
  'Account Restrictions Report',
]

export const restrictionsMenuItemList: string[] = [
  'Account Details',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Account Restrictions',
]

export const restrictionsMenuItemListWithPledged: string[] = [
  'Account Details',
  'Account Loan Detail',
  'Mailing Address',
  'Account Roles',
  'Household',
  'Account Restrictions',
]

export const applicationValues = {
  applicationHeading: 'Account Updates',
  sidebarMenuHeading: 'Client',
}

export const countriesList = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
  },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  {
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
  },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina', label: 'Burkina' },
  { value: 'Faso', label: 'Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: 'Cabo Verde', label: 'Cabo Verde' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
  },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  {
    value: 'Congo (Congo-Brazzaville)',
    label: 'Congo (Congo-Brazzaville)',
  },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  {
    value: 'Czechia (Czech Republic)',
    label: 'Czechia (Czech Republic)',
  },
  {
    value: 'Democratic Republic of the Congo',
    label: 'Democratic Republic of the Congo',
  },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  {
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  {
    value: "Eswatini (fmr. 'Swaziland')",
    label: "Eswatini (fmr. 'Swaziland')",
  },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Holy See', label: 'Holy See' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  {
    value: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  {
    value: 'Myanmar (formerly Burma)',
    label: 'Myanmar (formerly Burma)',
  },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'North Korea', label: 'North Korea' },
  {
    value: 'North Macedonia',
    label: 'North Macedonia',
  },
  { value: 'Norway', label: 'Norway' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  {
    value: 'Palestine State',
    label: 'Palestine State',
  },
  { value: 'Panama', label: 'Panama' },
  {
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
  },
  { value: 'Saint Lucia', label: 'Saint Lucia' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
  },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  {
    value: 'Solomon Islands',
    label: 'Solomon Islands',
  },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Korea', label: 'South Korea' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Timor-Leste', label: 'Timor-Leste' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
  },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Uganda', label: 'Uganda' },
  { value: 'Ukraine', label: 'Ukraine' },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    value: 'United States of America',
    label: 'United States of America',
  },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Zimbabwe', label: 'Zimbabwe' },
]

export const statesList = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DC', label: 'DC' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
]
