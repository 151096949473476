import React from 'react'
import { URLs } from '../httpConfiguration'
import { AxiosInstance } from 'axios'

export async function fetchAccountRoles(accountId: string| undefined,axiosProtected:AxiosInstance) {
    const url = URLs.ACCOUNT_ROLES_GET_API
    const payload = {
      accountNumber: accountId,
    }
    const response = await axiosProtected.post(url, payload)
    const data = response.data
    // console.log('🚀 ~ Fetch Account Roles =>', data)
    return data
}

 