import React, { useContext, useEffect, useState } from 'react'
import Divider from '../../components/divider/Divider'
import { SvgApprove } from '../../components/svg/SvgApprove'
import { SvgCancel } from '../../components/svg/SvgCancel'
import { SvgExclamation } from '../../components/svg/SvgExclamation'
import {
  WDStyledCancelButton,
  WDStyledEditButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  WDSidebarClientNickname,
  WDStyledEditButtonLabel,
  WDStyledSectionTitle,
  WDSubLabel,
} from '../../components/ui/WDTypography'
import {
  ButtonWrap,
  CancelSaveButtonWrap,
  CountWrapper,
  DividerWrapper,
  ErrorMessageWrapper,
  IconHeadWrapper,
  IconWrapper,
  Modal,
  Row,
  TextAreaRow,
  Wrapper,
} from './styles'
import { InputField } from '../inputField/InputField'
import { ApprovalContext } from '../../services/approvalContext/ApprovalContext'
import { RejectProps } from './IRejectChangesModal'
import { InlineErrorWrapper } from '../mailingAddress/styles'
import Label from '../../components/label/Label'

export const RejectChangesModal = ({ onSend }: RejectProps) => {
  const Approval = useContext(ApprovalContext)
  const [word, setWord] = useState('')
  const [isEmpty, setIsEmpty] = useState(word.length === 0)
  const [error, setError] = useState(false)

  const handleChange = (e: string) => {
    setWord(e)
  }

  useEffect(() => {
    setIsEmpty(word.length === 0)
  }, [word])

  const validate = () => {
    if(isEmpty){
      setError(true)
      // console.log("Reject test - Empty")
    }
    else if (!isEmpty) {
      setError(false)
      // console.log("Reject test - Non Empty")
      Approval?.setApproval(false)
      onSend(word)
    }
  }

  if (Approval?.reject === false) {
    return null
  }
  // console.log('onSend', onSend)

  return (
    <Wrapper>
      <Modal>
        <Row>
          <IconHeadWrapper>
            <React.Fragment>
              <SvgExclamation />
              <WDSubLabel>Reject Changes</WDSubLabel>
            </React.Fragment>
          </IconHeadWrapper>
          <IconWrapper onClick={() => Approval?.setReject(false)}>
            <SvgCancel />
          </IconWrapper>
        </Row>

        <DividerWrapper>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper>

        <React.Fragment>
          <ErrorMessageWrapper>
            <WDSubLabel>
              Please explain why is this request rejected. This message will be
              forwarded to the advisor who requested the change.
            </WDSubLabel>
          </ErrorMessageWrapper>
        </React.Fragment>

        <TextAreaRow>
          <InputField
            label="Reject Reason*"
            id="textarea"
            type="textarea"
            onChange={(e) => handleChange(e)}
            borderColor={(isEmpty && error) ? '#BA0517' : '#CBD5E1'}
            backgroundColor={(isEmpty && error) ? '#FEF1EE' : '#FFFFFF'}
          />
          {(isEmpty && error) && (
            <InlineErrorWrapper style={{ marginBottom: '8px' }}>
              <SvgExclamation />
              <Label
                fontFamily={'SourceSansPro-Regular'}
                fontSize={'14px'}
                fontStyle={'normal'}
                fontWeight={400}
                lineHeight={'16px'}
                color={'#BA0517'}
              >
                {'This field is required !'}
              </Label>
            </InlineErrorWrapper>
          )}

          <CountWrapper>
            <WDSubLabel>{`${word.length}/500`}</WDSubLabel>
          </CountWrapper>
        </TextAreaRow>

        <ButtonWrap>
          <CancelSaveButtonWrap>
            <WDStyledEditButton
              onClick={() => {
                Approval?.setReject(false)
                setWord('')
              }}
            >
              Return
            </WDStyledEditButton>
          </CancelSaveButtonWrap>
          <CancelSaveButtonWrap>
            <WDStyledCancelButton
              onClick={() => {
                Approval?.setReject(false)
                // AddressFields?.setApprovalPage(false)
                setWord('')
              }}
            >
              Discard
            </WDStyledCancelButton>

            <WDStyledSaveButton
              onClick={() => {
                validate()}}
            >
              Send
            </WDStyledSaveButton>
          </CancelSaveButtonWrap>
        </ButtonWrap>
      </Modal>
    </Wrapper>
  )
}
