import { useContext } from 'react'
import Divider from '../../components/divider/Divider'
import { SvgCancel } from '../../components/svg/SvgCancel'
import {
  WDStyledCancelButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
} from '../../components/ui/WDTypography'
import { AccountDetailsContext } from '../../services/accountDetailsContext/AccountDetailsContext'
import { AccountLoanDetailsContext } from '../../services/accountLoanDetailsContext/AccountLoanDetailsContext'
import { AccountPledgedSubmissionPayload } from '../../services/accountLoanDetailsContext/AccountLoanDetailsInterface'
import {
  ButtonRow,
  CloseWrapper,
  ContactPopupWrapper,
  DividerWrapper2,
  HeaderRow,
  ListItems,
  ModalWrapper,
  PrimaryButtonWrapper,
  SecondaryButtonWrapper,
} from './styles'

const AccountLoanDetailPopUp = () => {
  const accountLoanDetailsContext = useContext(AccountLoanDetailsContext)
  const accountDetailsContext = useContext(AccountDetailsContext)
  const accountId = accountDetailsContext?.accountDetails?.accountId

  const save = () => {
    accountLoanDetailsContext?.setAccountLoanDetailSubmissionPayload({
      ...(accountLoanDetailsContext?.accountLoanDetailPopUpPayload as AccountPledgedSubmissionPayload),
      accountId: accountId as number,
    })
    accountLoanDetailsContext?.setAccountLoanDetailsPopUp(false)
    accountDetailsContext?.setAccountDetailsCollapse(true)
  }

  return (
    <ModalWrapper>
      <ContactPopupWrapper>
        <HeaderRow>
          <WDNewProfileSidebarMenu>
            Account Loan Details
          </WDNewProfileSidebarMenu>
          <CloseWrapper
            onClick={() =>
              accountLoanDetailsContext?.setAccountLoanDetailsPopUp(false)
            }
          >
            <SvgCancel />
          </CloseWrapper>
        </HeaderRow>
        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>
        <p>
          <b>Please Confirm the following changes:</b>
        </p>
        <ListItems>
          <ul>
            {accountLoanDetailsContext?.pledgedAccountData
              ?.accountPledgedWith !==
              accountLoanDetailsContext?.accountLoanDetailPopUpPayload
                ?.pledgedAccountStatus && (
              <li>
                Pledged Account: {
                  accountLoanDetailsContext?.accountLoanDetailPopUpPayload
                    ?.pledgedAccountStatus
                }
              </li>
            )}
            {accountLoanDetailsContext?.accountLoanDetailPopUpPayload?.notes !==
              '' && (
              <li>
                Notes: {
                  accountLoanDetailsContext?.accountLoanDetailPopUpPayload
                    ?.notes
                }
              </li>
            )}
          </ul>
        </ListItems>

        <DividerWrapper2>
          <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
        </DividerWrapper2>

        <ButtonRow>
          <SecondaryButtonWrapper>
            <WDStyledCancelButton
              onClick={() =>
                accountLoanDetailsContext?.setAccountLoanDetailsPopUp(false)
              }
            >
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper>
          <PrimaryButtonWrapper>
            <WDStyledSaveButton onClick={() => save()}>
              <WDStyledCancelButtonLabel>{'Save'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper>
        </ButtonRow>
      </ContactPopupWrapper>
    </ModalWrapper>
  )
}

export default AccountLoanDetailPopUp
