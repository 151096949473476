import styled from 'styled-components'
import { COLORS } from '../../theme/Colors'

export interface ErrorModalProps {
    success?: number
    successMsg?: string
    onClick?: () => void
    blsErrorMsg?:string
}

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 116, 139, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

export const Modal = styled.div`
  background: ${COLORS.Background.Primary};
  display: flex;
  flex-direction: column;
  width: 480px;
  /* height: 192px; */
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  border-radius: 8px;
  padding: 16px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IconHeadWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
`

export const ErrorMessageWrapper = styled.div`
  display: flex;
  margin: 16px 0px 8px 0px;
`

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end !important;
  margin-top: 8px;
  button {
    font-family: SourceSansPro-SemiBold;
    padding: 4px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
`

export const IconWrapper = styled.div`
    cursor: pointer;
`
