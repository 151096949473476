import React, { useContext, useEffect, useState } from 'react'
import {
  BoxRow,
  ButtonRow,
  CloseWrapper,
  ColumnWrapper,
  ContactPopupWrapper,
  DividerWrapper3,
  DividerWrapper4,
  HeaderRow,
  ModalWrapper,
  NotesWrapper,
  PrimaryButtonWrapper2,
  Row,
  SecondaryButtonWrapper2,
  StyledTableContainer,
  SubHeadingWrapper,
} from './styles'
import {
  WDNewProfileSidebarMenu,
  WDStyledCancelButtonLabel,
  WDStyledPopupLabel,
} from '../../components/ui/WDTypography'
import { SvgCancel } from '../../components/svg/SvgCancel'
import Divider from '../../components/divider/Divider'
import Table from '../../components/table/Table'
import { InputField } from '../inputField/InputField'
import {
  WDStyledCancelButton,
  WDStyledSaveButton,
} from '../../components/ui/WDButtons'
import { MailingAddressContext } from '../../services/mailingAddressContext/MailingAddressContext'
import {
  AddressList,
  MailingAddressInterface,
} from '../../services/mailingAddressContext/MailingAddressInterface'
import { AddressChangeWidget } from '../addressChangeWidget/AddressChangeWidget'
import { AuthContext } from '../../services/AuthContext'

export const AddressUISubmissionPage = () => {
  const authCtx = useContext(AuthContext)
  const AddressFields = useContext(MailingAddressContext)
  const addressList = AddressFields?.addressSubmissionPayloadData

  const [notes, setNotes] = useState('')
  const [mailNew, setMailNew] = useState<AddressList[]>([])
  const [alternateAddressNew, setAlternateAddressNew] = useState<AddressList[]>(
    []
  )

  const [mailPayload, setMailPayload] = useState<AddressList[]>([])
  const [alternateAddressPayload, setAlternateAddressPayload] = useState<
    AddressList[]
  >([])

  const [mailOld, setMailOld] = useState<MailingAddressInterface[]>([])
  const [alternateOld, setAlternateOld] = useState<MailingAddressInterface[]>(
    []
  )

  const [mailTableData, setMailTableData] = useState<MailingAddressInterface[]>(
    []
  )
  const [alternateTableData, setAlternateTableData] = useState<
    MailingAddressInterface[]
  >([])

  console.log('Address List', addressList)

  useEffect(() => {
    if (AddressFields?.mailingAddress !== undefined) {
      const tempOne = AddressFields?.mailingAddress?.filter(
        (mailingAddressType) =>
          mailingAddressType.addressType === 'MailingAddress'
      )
      setMailOld(tempOne)
      const tempTwo = AddressFields?.mailingAddress?.filter(
        (mailingAddressType) => mailingAddressType.addressType === 'Alternate'
      )
      setAlternateOld(tempTwo)
    }
  }, [AddressFields?.mailingAddress])

  useEffect(() => {
    if (addressList !== undefined) {
      const tempOne = addressList?.filter(
        (addressType) => addressType.addressType === 'MailingAddress'
      )
      setMailNew(tempOne)
      const tempTwo = addressList?.filter(
        (addressType) => addressType.addressType === 'Alternate'
      )

      setAlternateAddressNew(tempTwo)
    }
  }, [addressList])

  useEffect(() => {
    setMailTableData([
      {
        streetAddress1: mailNew[0]?.streetAddress1,
        streetAddress2: mailNew[0]?.streetAddress2,
        cityName: mailNew[0]?.cityName,
        stateName: mailNew[0]?.stateProvinceName,
        countryName: mailNew[0]?.countryName,
        zipCode: mailNew[0]?.zipCode,
      },
    ])

    setAlternateTableData([
      {
        addressNickName: alternateAddressNew[0]?.addressNickName,
        streetAddress1: alternateAddressNew[0]?.streetAddress1,
        streetAddress2: alternateAddressNew[0]?.streetAddress2,
        cityName: alternateAddressNew[0]?.cityName,
        stateName: alternateAddressNew[0]?.stateProvinceName,
        countryName: alternateAddressNew[0]?.countryName,
        zipCode: alternateAddressNew[0]?.zipCode,
        startDate: alternateAddressNew[0]?.startDate,
        endDate: alternateAddressNew[0]?.endDate,
      },
    ])
  }, [alternateAddressNew, mailNew])

  useEffect(() => {
    setMailPayload([
      {
        accountId: mailNew[0]?.accountId,
        accountNumber: mailNew[0]?.accountNumber,
        addressTypeId: mailNew[0]?.addressTypeId,
        addressId: mailNew[0]?.addressId,
        streetAddress1: mailNew[0]?.streetAddress1,
        streetAddress2: mailNew[0]?.streetAddress2,
        streetAddress3: '',
        cityId: mailNew[0]?.cityId,
        cityName: mailNew[0]?.cityName,
        stateProvinceId: mailNew[0]?.stateProvinceId,
        stateProvinceName: mailNew[0]?.stateProvinceName,
        stateCode: mailNew[0]?.stateCode,
        countrycode: mailNew[0]?.countrycode,
        countryName: mailNew[0]?.countryName,
        zipCode: mailNew[0]?.zipCode,
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])

    setAlternateAddressPayload([
      {
        accountId: alternateAddressNew[0]?.accountId,
        accountNumber: alternateAddressNew[0]?.accountNumber,
        addressTypeId: alternateAddressNew[0]?.addressTypeId,
        addressId: alternateAddressNew[0]?.addressId,
        streetAddress1: alternateAddressNew[0]?.streetAddress1,
        streetAddress2: alternateAddressNew[0]?.streetAddress2,
        streetAddress3: '',
        cityId: alternateAddressNew[0]?.cityId,
        cityName: alternateAddressNew[0]?.cityName,
        stateProvinceId: alternateAddressNew[0]?.stateProvinceId,
        stateProvinceName: alternateAddressNew[0]?.stateProvinceName,
        stateCode: alternateAddressNew[0]?.stateCode,
        countrycode: alternateAddressNew[0]?.countrycode,
        countryName: alternateAddressNew[0]?.countryName,
        zipCode: alternateAddressNew[0]?.zipCode,
        addressNickName: alternateAddressNew[0]?.addressNickName,
        startDate: alternateAddressNew[0]?.startDate,
        endDate: alternateAddressNew[0]?.endDate,
        statusId: 0,
        updateBy: authCtx?.account?.name,
        createdBy: authCtx?.account?.name,
        createdOn: '2023-06-02 11:37:36.524582+00',
        updateOn: '2023-06-02 11:37:36.524582+00',
      },
    ])
  }, [alternateAddressNew, mailNew])

  let merged: AddressList[] = []

  const setAddressPayload = () => {
    if (mailNew.length !== 0) {
      if (alternateAddressNew.length !== 0) {
        merged = [...mailPayload, ...alternateAddressPayload]
      } else {
        merged = [...mailPayload]
      }
    } else {
      merged = [...alternateAddressPayload]
    }
  }

  const setAddressSubmissionPayload = () => {
    const payload = {
      addressList: merged,
      notes: notes,
    }
    return payload
  }

  const submit = () => {
    setAddressPayload()
    const payload = setAddressSubmissionPayload()
    AddressFields?.setAddressSubmissionPayload(payload)
    AddressFields?.setSubmissionPopUp(false)
  }

  const columnData = [
    {
      Header: 'Field',
      accessor: 'field',
    },
    {
      Header: 'Old value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
  ]

  return (
    <ModalWrapper>
      <ContactPopupWrapper>
        <ColumnWrapper>
          <HeaderRow>
            <WDNewProfileSidebarMenu>Mailing Address</WDNewProfileSidebarMenu>
            <CloseWrapper
              onClick={() => AddressFields?.setSubmissionPopUp(false)}
            >
              <SvgCancel />
            </CloseWrapper>
          </HeaderRow>
          <DividerWrapper3>
            <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
          </DividerWrapper3>
          <SubHeadingWrapper>
            {mailNew.length !== 0 && alternateAddressNew.length === 0 && (
              <WDNewProfileSidebarMenu>
                You are about to change the account Mailing Address.
              </WDNewProfileSidebarMenu>
            )}
            {mailNew.length === 0 && alternateAddressNew.length !== 0 && (
              <WDNewProfileSidebarMenu>
                You are about to change the Account Alternate/Temporary Address.
              </WDNewProfileSidebarMenu>
            )}
            {mailNew.length !== 0 && alternateAddressNew.length !== 0 && (
              <WDNewProfileSidebarMenu>
                You are about to change the Account mailing address.
              </WDNewProfileSidebarMenu>
            )}
          </SubHeadingWrapper>

          {mailNew.length !== 0 && (
            <>
              <>
                <WDStyledPopupLabel>{'Mailing Address'}</WDStyledPopupLabel>
              </>
              <BoxRow>
                <AddressChangeWidget
                  newAddress={mailTableData[0]}
                  oldAddress={mailOld[0]}
                />
              </BoxRow>
            </>
          )}
          {alternateAddressNew.length !== 0 && (
            <>
              <>
                <WDStyledPopupLabel>
                  {'Alternate/Temporary Address'}
                </WDStyledPopupLabel>
              </>
              <BoxRow>
                <AddressChangeWidget
                  alternate={'Alternate'}
                  newAddress={alternateTableData[0]}
                  oldAddress={alternateOld[0]}
                />
              </BoxRow>
            </>
          )}

          <NotesWrapper>
            <InputField
              type="textarea"
              label="Notes"
              onChange={(e) => setNotes(e)}
              textboxPlaceholder={'Notes'}
            />
          </NotesWrapper>
          <DividerWrapper4>
            <Divider bgColor="#E2E8F0" horizontal={true} thickness="1px" />
          </DividerWrapper4>
        </ColumnWrapper>
        <ButtonRow>
          <SecondaryButtonWrapper2>
            <WDStyledCancelButton
              onClick={() => AddressFields?.setSubmissionPopUp(false)}
            >
              <WDStyledCancelButtonLabel>{'Cancel'}</WDStyledCancelButtonLabel>
            </WDStyledCancelButton>
          </SecondaryButtonWrapper2>
          <PrimaryButtonWrapper2>
            <WDStyledSaveButton
              onClick={() => {
                // console.log('Check : ', mailNew, alternateAddressNew)
                submit()
              }}
            >
              <WDStyledCancelButtonLabel>{'Submit'}</WDStyledCancelButtonLabel>
            </WDStyledSaveButton>
          </PrimaryButtonWrapper2>
        </ButtonRow>
      </ContactPopupWrapper>
    </ModalWrapper>
  )
}
