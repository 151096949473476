import { StyledButton, ButtonProps } from './styles'

import Image from '../image/Image'

const Button = ({
  type,
  bgColor,
  color,
  children,
  borderColor,
  padding,
  borderRadius,
  prefixedIcon,
  suffixedIcon,
  disabled,
  onClick,
  hover,
  hasBorder = true,
  iconWidth,
  iconHeight,
  borderLessBgColor,
  hoverBgColor,
  hoverTextColor,
  hoverBorder,
  activeBgColor,
  activeBorder,
  focusBrColor,
  index,
  svg,
}: ButtonProps) => {
  return (
    <StyledButton
      type={type}
      className="hover-button"
      bgColor={bgColor}
      color={color}
      borderColor={borderColor}
      padding={padding}
      borderRadius={borderRadius}
      onClick={onClick}
      disabled={disabled}
      hover={hover}
      hasBorder={hasBorder}
      borderLessBgColor={borderLessBgColor}
      hoverBgColor={hoverBgColor}
      hoverTextColor={hoverTextColor}
      hoverBorder={hoverBorder}
      activeBgColor={activeBgColor}
      activeBorder={activeBorder}
      focusBrColor={focusBrColor}
    >
      {/* {prefixedIcon && (
        <Image
          image={prefixedIcon}
          alt={'Icon'}
          width={iconWidth}
          height={iconHeight}
        />
      )} */}
      {prefixedIcon && (
        svg
      )}
      {children}
      {suffixedIcon && (
        <Image
          image={suffixedIcon}
          alt={'Icon'}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </StyledButton>
  )
}

export default Button
