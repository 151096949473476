import { useRowSelect, useTable } from 'react-table'
import { StyledRectangle, ThContentWrapper, ThWrapper, Wrapper } from './styles'
import { useMemo, useState } from 'react'
import { TableProps } from './ITable'
import { Tooltip } from '../../composites/tooltip/Tooltip'
import React from 'react'

const Table = ({
  tableColumns,
  tableData,
  onClick,
  hasFooter = false,
  hover = true,
  status,
}: TableProps) => {
  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(() => tableColumns, [tableColumns])
  const tableInstance = useTable({ columns, data }, useRowSelect)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const selectedColor = (e: any) => {
    for (const elem of (document as any).getElementsByClassName(
      'active-selected'
    )) {
      elem.classList.remove('active-selected')
    }
    for (const elem of (document as any).getElementsByClassName('table-row')) {
      elem.classList.remove('table-row')
    }
    e.currentTarget.classList.add('active-selected')
  }

  const [tooltip, setTooltip] = useState(false)

  const tooltipHover = (i: number) => {
    if (i === 0) setTooltip(true)
  }

  const tooltipHoverLeave = (i: number) => {
    if (i === 0) setTooltip(false)
  }

  return (
    <Wrapper>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps()} key={index}>
                  <ThWrapper>
                    <ThContentWrapper>
                      {column.render('Header')}
                    </ThContentWrapper>
                    {/* {headerGroup.headers.length - 1 !== index && ( */}
                    <StyledRectangle></StyledRectangle>
                  </ThWrapper>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <>
                <tr
                  {...row.getRowProps()}
                  key={index}
                  onClick={(e) => {
                    onClick(row.original)
                    selectedColor(e)
                  }}
                  className="table-row"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <React.Fragment key={i}>
                        <td
                          {...cell.getCellProps()}
                          onFocus={() => console.log()}
                          onMouseOver={() => tooltipHover(i)}
                          onMouseLeave={() => tooltipHoverLeave(i)}
                          className={i === 0 ? 'tooltip' : ''}
                        >
                          {cell.render('Cell')}
                          {hover &&
                            i === 0 &&
                            tooltip &&
                            status !== undefined &&
                            status !== null &&
                            status[index].accountStatus === 'Closed' && (
                              <Tooltip date={status[index].closingDate} />
                            )}
                        </td>
                      </React.Fragment>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
        {hasFooter ? (
          <tfoot>
            {footerGroups.map((group, index) => (
              <tr {...group.getFooterGroupProps()} key={index}>
                {group.headers.map((column, i) => (
                  <td {...column.getFooterProps()} key={i}>
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        ) : null}
      </table>
    </Wrapper>
  )
}

export default Table
